// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid"; 
import PropTypes from "prop-types"; 
import {   useParams } from "react-router-dom"; 
import React, { useEffect } from "react";
import { HttpService } from "Api-Services/httpService";
 
import { 
  Box, 
  CardContent,
  CardHeader, 
  Skeleton,
  Typography,
} from "@mui/material";
import { Card } from "@mui/material"; 
import PostList from "./postList";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { isMobile } from "react-device-detect";
import InfiniteScroll from "react-infinite-scroller";
import ProfilesYouMayKnow from "pages/GlobalComponents/Contacts/ProfilesYouMayKnow";
import MKBox from "components/MKBox";
// import AgencyShortInfoBox from "./components/agencyShortInfoBox";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, px: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function FilterPosts(props) {     
    const [hasMoreContent, setHasMoreContent] = React.useState(true);
    // const [forceRenderer, setForceRenderer] = React.useState(true);
    const params = useParams(); 
  if (params?.jobTab) {
    tabn = params?.jobTab;
  }
  
  useEffect(() => { 
    try {
      setHasMoreContent(true)
      setForceRenderer(!forceRenderer)
    } catch (error) {}
  }, [props?.filter]);

  let filterData={}  
  try { 
    let filter=props?.filter 
    if (filter) {    
      filterData['post']=filter?.postFilter 
      filterData['profile']=filter?.profileFilter 
  }   
  } catch (error) {
    // console.log("errore ocuuu",error)
  }   

  const loadMore=async ( {pageParam=1} )=>{  
  let resD =  await HttpService.postService("/api/post/find"+"?pageSize="+5+"&page="+pageParam ,{filter:filterData} ,{});;
    let ress=[] 
    resD=resD?.data 
    if(resD ){ 
      if(resD?.record?.length){  
          ress=resD?.record   
    }}
    if(resD?.record?.length>4){
      if(!hasMoreContent){
      setHasMoreContent(true)
      }
    }
    else{
      setHasMoreContent(false)
    }

    return ress
  } 

const {
    data ,
    fetchNextPage, 
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch 
    } = useInfiniteQuery({
    // queryKey: ["/api/profilefollowers/followings/"+myCurrentProfile?.id],
    queryKey: ["/api/post/find",{filter:filterData} ],
    queryFn:loadMore,
    getNextPageParam: (lastPage, pages) => {
      // If the last page has less than the page size, return undefined
      if (lastPage.length < 5) {
        return undefined;
      }
      // Otherwise, return the next page number
      return pages.length + 1;
    },    
    // getNextPageParam: (lastPage, pages) => lastPage.nextCursor,

    })

  function LoadingComp(){ 
    try {
      return (
        <>
          <Container sx={{ py: 3 }}>
            <Grid container spacing={0.2}>
              {[...Array(1)].map(() => (
                <Grid mb={1} item xs={12}>
                  <Card  sx={{boxShadow: 0 , width: "100%" }} elevation={0}>
                    <CardHeader
                      avatar={
                        <Skeleton animation="wave" variant="circular" width={40} height={40} />
                      }
                      title={
                        <Skeleton
                          animation="wave"
                          height={10}
                          width="80%"
                          style={{ marginBottom: 6 }}
                        />
                      }
                      subheader={<Skeleton animation="wave" height={10} width="40%" />}
                    />
                    <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />

                    <CardContent>
                      <React.Fragment>
                        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={10} width="80%" />
                      </React.Fragment>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </>
      );
    } catch (error) {
      // console.log("loadingComp error", error);
      return <></>;
    }
  };
 
  return (
    <>  
            {/* <Container sx={{ p: 0 ,mx:0, mt:isMobile?0.5:1}}>    */}
            <MKBox sx={{  mt:isMobile?0.5:1, mx:isMobile?0.2:0}}>   
              <InfiniteScroll
                  loadMore={() => { 
                    fetchNextPage()
                  }}
                  hasMore={hasMoreContent}
                  // hasMore={hasNextPage&&(!isFetching)}
                  // hasMore={
                  //   hasNextPage&&
                  //   (data?.pages[data?.pages?.length-1]?.length>4)}
                  loader={<LoadingComp/>}
                >   
                 {data?.pages?.map((postsG,index)=>{

                 return <>
                 <PostList posts={postsG} />
                {(index%3==0)&&<ProfilesYouMayKnow />}
                 </>} )} 
              </InfiniteScroll>   
            </MKBox>
            {/* </Container>  */}
          {/* {forceRenderer}  */}
          </> 
  );
}

export default FilterPosts;
