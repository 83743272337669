// @mui material components
import Container from "@mui/material/Container";
import React, { useEffect } from "react";
import imageCompression from "browser-image-compression";
// Images
// import bgImage from "assets/images/bg-rental.jpeg";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import {
  AppBar, 
  Avatar, 
  Box, 
  Chip, 
  DialogContent, 
  Divider, 
  IconButton,
  List,
  ListItem,
  ListItemAvatar, 
  ListItemText, 
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material"; 
import {
  AddCircle,
  ArrowBack,
  CheckCircle,
  Logout,
  Person,
  Send, 
} from "@mui/icons-material"; 
import { makeStyles } from "@mui/styles"; 
import { CommonServices } from "Api-Services/commonServices";
import { CommonApiServices } from "Api-Services/commonApiServices";
import MKAvatar from "components/MKAvatar";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { HttpService } from "Api-Services/httpService";
import MKTypography from "components/MKTypography";

const postStylesDropzone = makeStyles((theme) => ({
  root: {
    "& .css-9igssw-MuiDialogContent-root": {
      padding: "5px",
    },
    "& .ql-toolbar.ql-snow ": {
      padding: "0px",
    },
  },
  dropzoneClass: {
    // maxHeight: "124px  !important",
    minHeight: "5px !important",
    "& .css-f8f0pk-MuiSvgIcon-root ": {
      height: "30px",
      width: "30px",
    },
    "& .css-1g96e5k-MuiTypography-root": {
      fontSize: "0.9rem",
      fontWeight: "700",
      marginTop: "4px",
      marginBottom: "2px",
    },
  },
  postTextArea: {
    "& .ql-editor ": {
      minHeight: "120px",
    },
  },
}));

// {"& .css-1agvk75" :{
//   minHeight: "250px"}}
function Friendship(props) {
  // profile={myCurrentProfile} friendshipTab={friendshipTab}
  const postStyles = postStylesDropzone();
  const [currentData] = useCurrentUser();
  const navigate= useNavigate();
  const params= useParams();
  const { myFriendships, currentUser } = currentData; 
  const [forceRerender, setForceRerender] = React.useState(true);
  const [friendshipTab, setFriendshipTab] = React.useState("followings"); 
  // const [followers,setFollowers] = React.useState([]);
  // const [followings,setFollowings] = React.useState([]);
  const [suggestions,setSuggestions] = React.useState([]); 

  let userName=params?.userName||props?.profile?.userName
  let {data:profile} = useQuery({
    queryKey: ["/api/profile/getbyusername/"+userName],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/profile/getbyusername/"+userName);
    },
    initialData:props?.profile
  });

  let {data:followings} = useQuery({
    queryKey: ["/api/profilefollowers/followings/"+profile?.id],
    queryFn: async () => {
      let res=[]
      let resD = await HttpService.getApiData("/api/profilefollowers/followings/"+profile?.id);
      if(resD?.length){
        resD?.reverse()
        res=resD
      }
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return res
    }, 
  });

  let {data:followers} = useQuery({
    queryKey: ["/api/profilefollowers/followers/"+profile?.id],
    queryFn: async () => { 
      let res=[]
      let resD =  await HttpService.getApiData("/api/profilefollowers/followers/"+profile?.id);
      if(resD?.length){
        resD?.reverse()
        res=resD
      }
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return res
    }, 
  });
  
  let {data:profilesYouMayKnow} = useQuery({
    queryKey: ["/api/profile/profilesyoumayknow"],
    queryFn: async () => {
      return await HttpService.getApiData("/api/profile/profilesyoumayknow");
    },
  });
 
  useEffect(async() => {
    // console.log("websitePagesContent",websitePagesContent)
    if(props?.friendshipTab){ 
       setFriendshipTab(props?.friendshipTab) 
        }   
    setForceRerender(!forceRerender)
  }, [profile]);
 

  function FriendshipListItem(FriendshipListItemprops) {
    const [friendshipType, setFriendshipType] = React.useState("");
    const [friendshipItem, setFriendshipItem] = React.useState(null);
    useEffect(() => {
      // console.log("FriendshipListItem", FriendshipListItemprops.friendshipItem);

      // if (FriendshipListItemprops.friendshipItem && myFriendships) {
        if (FriendshipListItemprops.friendshipItem) {
        let friendshipItemR = FriendshipListItemprops.friendshipItem;
        setFriendshipItem(friendshipItemR);
        let fT = ""//CommonServices.checkFriendship(friendshipItemR.id, myFriendships);
       
            if (friendshipItemR?.iAmFollowing  && friendshipItemR?.myFollower) {
              fT = "friend";
            } else if (friendshipItemR?.myFollower) {
              fT = "follower";
            } else if (friendshipItemR?.iAmFollowing) {
              fT = "following";
            }
        if (fT) {
          if ((friendshipItemR.id ? true : false)) {
            // console.log("fT",fT)
            setFriendshipType(fT);
          }
        }
      }
    }, [FriendshipListItemprops]);

    let res = <></>;
    try {
      // let friendshipType = CommonServices.checkFriendship(friendshipItem.id, myFriendships);
      // console.log("friendshipType", friendshipType);

      res = (
        <>
          {friendshipItem && (
            <ListItem
              sx={{ pt: 2 }}
              secondaryAction={
                friendshipType == "friend" ? (
                  <Chip
                    sx={{ px: "5px" }}
                    icon={<Send />}
                    onClick={() => {
                      // CommonApiServices.unfollow(friendshipItem.id)
                      // setFriendshipType("")
                      // console.log("mmmmmmmmmmm", following);
                    }}
                    // label={"Follow"}
                    label={"Message"}
                  />
                ) : friendshipType == "follower" ? (
                  <Chip
                    sx={{ px: "2px" }}
                    color={"info"}
                    icon={<AddCircle />}
                    onClick={() => {
                      CommonApiServices.follow(friendshipItem.id);
                      // friendshipType=!following;
                      setFriendshipType("friend");
                    }}
                    label={"Follow Back"}
                  />
                ) : friendshipType == "following" ? (
                  <Chip
                    sx={{ px: "2px" }}
                    icon={<Logout />}
                    onClick={() => {
                      CommonApiServices.unfollow(friendshipItem.id);
                      setFriendshipType("");
                    }}
                    // label={"Follow"}
                    label={"Unfollow"}
                  />
                ) : (
                  <Chip
                    sx={{ px: "15px" }}
                    color={"info"}
                    icon={<AddCircle />}
                    onClick={() => {
                      CommonApiServices.follow(friendshipItem.id);
                      setFriendshipType("following");
                    }}
                    // label={"Follow"}
                    label={"Follow"}
                  />
                )
              }
            >
              <ListItemAvatar
                onClick={() => {
                  navigate("/" + friendshipItem.userName);
                  props?.onClose;
                }}
              >
                {friendshipItem.profileImageUrl != "" && friendshipItem.profileImageUrl != null ? (
                  <Avatar src={friendshipItem.profileImageUrl}></Avatar>
                ) : (
                  <Avatar>
                    <Person />
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                onClick={() => {
                  navigate("/" + friendshipItem.userName);
                  props?.onClose;
                }}
                primary={<>
                  <MKTypography sx={{my:0}} variant="body2">
                    {CommonServices.getProfileName(friendshipItem)}
                  </MKTypography>
                  {/* */}
                </>}
                secondary={ <MKTypography variant="caption">{friendshipItem.userName}</MKTypography>}
              />
            </ListItem>
          )}
        </>
      );
    } catch (error) {
      console.log("FriendshipListItem errr", error);
    }
    return res;
  }


 

  return (
    <>

{(profile ? true : false) && (
        <>
          <>
            <AppBar position="static" sx={{ width: "100%", bgcolor: "background.paper" }}>
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }} 
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {CommonServices.getProfileName(profile)}
                </Typography>
              </Toolbar>
              <Divider sx={{ my: "0rem" }} />
            </AppBar>

            <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
              <Tabs
                value={friendshipTab}
                onChange={(e, v) => {
                  setFriendshipTab(v);
                  // props.changeFriendshipTab(v);
                }}
                centered
              >
                <Tab label={profile.followingCount + " Following"} value={"followings"} />
                <Tab label={profile.followersCount + " Followers"} value={"followers"} />
                <Tab label="Suggestions" value={"suggestions"} />
              </Tabs>
            </Box>
          </>
        </>
      )}
      <Box sx={{ px:2, width: "100%", bgcolor: "background.paper" }}>
        
      {(friendshipTab == "followers" && followers && followers.length ? true : false) && (
        <List>
          {followers?.map((friendshipItem) => {
            // (follower.Profile)&&
            if (friendshipItem.ProfileFollowers) {
              return <FriendshipListItem friendshipItem={friendshipItem} />;
            }
          })}
        </List>
      )}

      {(friendshipTab == "followings" && followings && followings.length ? true : false) && (
        <List> 

          {followings?.map((friendshipItem) => {
            // (follower.Profile)&&
            if (friendshipItem?.ProfileFollowers) {
              return <FriendshipListItem friendshipItem={friendshipItem} />;
            }
          })}
        </List>
      )}

      {(friendshipTab == "suggestions" && profilesYouMayKnow && profilesYouMayKnow.length ? true : false) && (
        <List> 

          {profilesYouMayKnow?.map((friendshipItem) => {
            // (follower.Profile)&&
            if (friendshipItem) {
              return <FriendshipListItem friendshipItem={friendshipItem} />;
            }
          })}
        </List>
      )}
      </Box> 
      {forceRerender} 
    </>
  );
}

export default Friendship;
