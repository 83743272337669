/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import queryString from "query-string";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
// Images
import { cities, subCities, specificAreas } from "Api-master-datas/masterDatas.js";

import ReactGA from "react-ga4";
import profilePicture from "assets/images/bruce-mars.jpg";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import Card from "@mui/material/Card";
import React, { useEffect } from "react";
import { ArrowDropDown, ArrowDropDownCircleRounded, Edit } from "@mui/icons-material";
import MKInput from "components/MKInput";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { HttpService } from "Api-Services/httpService";
import FileUploadComp from "components/common-components/fileUploadComp";
import { isMobile } from "react-device-detect";
import { UseAuth } from "Api-Services/useAuth";
import MKEditor from "components/MKEditor";
import { makeStyles } from "@mui/styles";
import { CommonServices } from "Api-Services/commonServices";
import SwitchMyProfilesBox from "pages/UserProfile/Sections/SwitchMyProfilesBox";

const useStyles1  = makeStyles((theme) => ({
  root: {
    "& .css-9igssw-MuiDialogContent-root": {
      padding: "5px",
    },
    "& .ql-toolbar.ql-snow ": {
      padding: "0px",
    },
  },
  dropzoneClass: {
    // maxHeight: "124px  !important",
    minHeight: "5px !important",
    "& .css-f8f0pk-MuiSvgIcon-root ": {
      height: "30px",
      width: "30px",
    },
    "& .css-1g96e5k-MuiTypography-root": {
      fontSize: "0.9rem",
      fontWeight: "700",
      marginTop: "4px",
      marginBottom: "2px",
    },
  },
  MKEditor: {
    "& .ql-editor ": {
      minHeight: "120px",
    },
  },
}));

function SwitchProfile(props) {
  const styles1=useStyles1()
  const [currentData, updateContext] = useCurrentUser();
  const { myCurrentProfile, myProfiles } = currentData;
   const [forceRerender, setForceRerender] = React.useState(false);
 

  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: location.pathname, title: "JobSeeker Profile" });
    setForceRerender(!forceRerender);
  }, [myProfiles,myCurrentProfile]);
 
 

  return (
    <> 
    {forceRerender}
    <SwitchMyProfilesBox onClose={() => props?.onClose} />   
    </>
  );
}

export default SwitchProfile;
