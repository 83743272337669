/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import queryString from "query-string";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
// Images
import { cities, subCities, specificAreas } from "Api-master-datas/masterDatas.js";

import ReactGA from "react-ga4"; 
import { useCurrentUser } from "Api-contexts/currentUserContext";
import Card from "@mui/material/Card";
import React, { useEffect } from "react";
import {   Edit } from "@mui/icons-material";
import MKInput from "components/MKInput";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { HttpService } from "Api-Services/httpService";
import FileUploadComp from "components/common-components/fileUploadComp";
import { isMobile } from "react-device-detect";
import { UseAuth } from "Api-Services/useAuth";
import MKEditor from "components/MKEditor";
import { makeStyles } from "@mui/styles";
import { CommonServices } from "Api-Services/commonServices";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import SelectYourJobCatagory from "./JobSetting/Components/SelectFieldOfStodies";

const useStyles1  = makeStyles((theme) => ({
  root: {
    "& .css-9igssw-MuiDialogContent-root": {
      padding: "5px",
    },
    "& .ql-toolbar.ql-snow ": {
      padding: "0px",
    },
  },
  dropzoneClass: {
    // maxHeight: "124px  !important",
    minHeight: "5px !important",
    "& .css-f8f0pk-MuiSvgIcon-root ": {
      height: "30px",
      width: "30px",
    },
    "& .css-1g96e5k-MuiTypography-root": {
      fontSize: "0.9rem",
      fontWeight: "700",
      marginTop: "4px",
      marginBottom: "2px",
    },
  },
  MKEditor: {
    "& .ql-editor ": {
      minHeight: "120px",
    },
  },
}));

function JobSetting(props) {
  const styles1=useStyles1()
  
  const queryClient = useQueryClient()
  const [currentData, updateContext] = useCurrentUser();
  const { myCurrentProfile ,isUserLoggedIn   } = currentData;
  const [expanded, setExpanded] = React.useState(false); 
  const [loadingSave, setLoadingSave] = React.useState(false); 
  const [tab, setTab] = React.useState("general"); 

  const [profileSectionIndex, setProfileSectionIndex] = React.useState(0);
  const [forceRerender, setForceRerender] = React.useState(false);
  const [dropzoneFiles, setDropzoneFiles] = React.useState([]);
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [dialogData,setDialogData] = React.useState(null);
  const [newWorkExperianceData, setNewWorkExperianceData] = React.useState({
    description: "",
    expectingSalaryPerHour: null,
    expectingSalaryPerMonth: null,
    isAvailable: true,
    jobId: null,
    workExperianceInMonth: null,
    skillsOrSubjects: [],
  });

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "JobSeeker Profile" });
    setForceRerender(!forceRerender);
  }, [isUserLoggedIn, myCurrentProfile]);

  useEffect(() => {
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch.tab) {
        setProfileSectionIndex(locationSearch.tab);
      }
    }
  }, [location.search, location.pathName]);
 
  
  // mynotifications
  const { data: jobSettings, isFetched :jobSettingsFetched  } = useQuery({
    queryKey: ["/api/profilesetting/jobsetting/my" ],
    queryFn: async () => { 
      // console.log("getMyChats", )  
      let mm= await HttpService.getApiData("/api/profilesetting/jobsetting/my" );  
      return mm
    },
    // initialData:{}
  });

  let {data:jobs} = useQuery({
    queryKey: ["/api/job/all"],
    queryFn: async () => {
      let resJc= await HttpService.getApiData("/api/job/all");
      return resJc
    }, 
  });
  
  let {data:jobCategories} = useQuery({
    queryKey: ["/api/job/jobcategory/all"],
    queryFn: async () => { 
      let resJc= await HttpService.getApiData("/api/job/jobcategory/all");
      return resJc
    }, 
  });
  
  // const {mutate:jobSettingsMutation} = useMutation({
  //   mutationFn:updatePrivacySettings,
  //   onSettled: () => { 
  //     // console.log("send message")
  //     queryClient.invalidateQueries({ 
  //        queryKey: ["/api/profilesetting/jobsetting/all"],
  //     })
  //   },
  // })

  const postDocuments = async (documentName, relativePath, data, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    documentsResponse = await HttpService.postService(relativePath, data, additionalData || {}, "");

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true; 
    } else {
      responseD.record = documentsResponse.data;
    }
    return responseD;
  };

  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = returnField ? "" : {};

    try {
      if (searchValue) {
        if (dataArray && searchField && searchValue) {
          if (dataArray.length > 0) {
            let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
            if (filteredLevels.length) {
              res = filteredLevels;
              if (returnRows == "single") {
                let row = filteredLevels[0];
                res = row;
                if (returnField) {
                  let rowField = row[returnField];
                  res = rowField;
                }
              }
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    // console.log("filtered city  ", res);
    return res;
  };

  const handleNewWorkExperianceData = async (field, value) => {
    try {
      let data = newWorkExperianceData;
      if (field && value) {
        data[field] = value;
        setNewWorkExperianceData(data);
        setForceRerender(!forceRerender);
      }
    } catch (error) {}
  };

  const addNewWorkExperiance = async () => {
    // console.log("data", dataD,parentData, dataParentName,fieldType);
    // return
    try {
      let data = newWorkExperianceData;
      let relativePath = "/api/employmentjobseekerworkexperiance/new";
      let refreshVariable = "currentProfile";
      if (data) {
        data.jobSeekerId = jobSettings?.id;

        if (data.skillsOrSubjects) {
          let skils = "";
          if (data.skillsOrSubjects.length) {
            data.skillsOrSubjects.forEach((element) => {
              skils = skils + (skils ? "," : "") + element;
            });
          }
          data.skillsOrSubjects = skils;
        }
      }

      if (data.jobSeekerId) {
        if (data) {
          // setLoadingSave(true);
          let changeUserProfileRes = await postDocuments("changeUserInfo", relativePath, data);
          //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
          // setLoadingSave(false);

          if (changeUserProfileRes.success) {
            setExpanded(false);
            setOpenedDialog("");
            // updateContext(refreshVariable);
          }
        }
      }
    } catch (error) {}
  };

  const updatemyCurrentProfile = async (dataD, parentData, dataParentName, fieldType) => {
    console.log("data", dataD,parentData, dataParentName,fieldType);
    // return
    try {
      let id;
      let relativePath;
      let refreshVariable = "";
      let value;
      let data = {  };
      if (dataD) {
        if (dataD.value) {
          value = dataD.value;
        }
      }
 
      console.log("    value 1", value);
      if (dataParentName == "profile") {
        // id = jobSettings?.id;
        relativePath = "/api/profilesetting/jobsetting/edit";
      }
      if (dataParentName == "workExperiance") {
        id = parentData.id;
        data["id"]=id
        relativePath = "/api/employmentjobseekerworkexperiance/edit";
        refreshVariable = "currentProfile";
      } else if (dataParentName == "myCurrentProfile") {
        id = jobSettings?.id;
        data["id"]=id
        relativePath = "/api/profilesetting/jobsetting/edit";
      }  
      
      console.log("    relativePath && id 1",  relativePath , jobSettings);
      if (relativePath ) {
        if ("fileUrl" == fieldType) {
          if (dataD.value) {
            let filesD = await uploadFiles(dataD.value);
            if (filesD) {
              if (filesD.length) {
                if (filesD.length > 0) {
                  value = filesD[0];
                }
              }
            }
          }
        }
        if ("imageUrl" == fieldType) {
          if (dataD.value) {
            let filesD = await uploadFiles(dataD.value);
            if (filesD) {
              if (filesD.length) {
                if (filesD.length > 0) {
                  value = filesD[0];
                }
              }
            }
          }
        }
          console.log("  data value", value);
        if (value) {
          data[dataD.fieldName] = value;
          console.log("HttpService data", data);

          // let toastId = toast.loading("Updating Profile data...",{
          //   position: toast.POSITION.BOTTOM_LEFT
          // })
          setLoadingSave(true);
          let changeUserProfileRes = await HttpService?.postService( relativePath, data, {});
          //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
          setLoadingSave(false);

          if (changeUserProfileRes?.success) {
            setExpanded(false);
            queryClient.invalidateQueries({ 
              queryKey: ["/api/profilesetting/jobsetting/my"],
          })
          }
        }
      }
    } catch (error) {
      console.log("error", error);

    }
  };
 
  
  const updateJobSetting = async (dataD) => {
    console.log("data", dataD );
    // return
    try { 
      let relativePath = "/api/profilesetting/jobsetting/edit";
        if (dataD) {
          let data  = dataD; 
          // let toastId = toast.loading("Updating Profile data...",{
          //   position: toast.POSITION.BOTTOM_LEFT
          // })
          setLoadingSave(true);
          let changeUserProfileRes = await HttpService?.postService( relativePath, data, {});
          //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
          setLoadingSave(false);
          if (changeUserProfileRes?.success) {
            setExpanded(false);
            queryClient.invalidateQueries({ 
              queryKey: ["/api/profilesetting/jobsetting/my"],
          })
            // updateContext(refreshVariable);
          }
        }
      
    } catch (error) {
      console.log("error", error);
    }
  };
 
 

  const addJobSeeking = async (dataD)=>{
    // addjobhiring
    let data={}
    let relativePath = "/api/profilesetting/jobsetting/addjobseeking";
    if (1) {
      let jobsID=[]
      dataD?.jobs?.forEach(element => {
        if(element?.id)(
          jobsID.push(element?.id)
        )
      });
      data["jobIds"] = jobsID;
      console.log("HttpService data", data);
      // let toastId = toast.loading("Updating Profile data...",{
      //   position: toast.POSITION.BOTTOM_LEFT
      // })
      setLoadingSave(true);
      let changeUserProfileRes = await HttpService?.postService(relativePath, data,{});
      //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
      setLoadingSave(false);

      if (changeUserProfileRes.success) {
        setExpanded(false);
          queryClient.invalidateQueries({ 
            queryKey: ["/api/profilesetting/jobsetting/my"],
        })
        // updateContext(refreshVariable);
      }
    }
  }
  
  const addJobHiring = async (dataD)=>{
    // addjobhiring
    let data={}
    let relativePath = "/api/profilesetting/jobsetting/addjobhiring";
    if (1) {
      let jobsID=[]
      dataD?.jobs?.forEach(element => {
        if(element?.id)(
          jobsID.push(element?.id)
        )
      });
      data["jobIds"] = jobsID;
      console.log("HttpService data", data);
      // let toastId = toast.loading("Updating Profile data...",{
      //   position: toast.POSITION.BOTTOM_LEFT
      // })
      setLoadingSave(true);
      let changeUserProfileRes = await HttpService?.postService(relativePath, data,{});
      //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
      setLoadingSave(false);

      if (changeUserProfileRes.success) {
        setExpanded(false);
        // updateContext(refreshVariable);
          queryClient.invalidateQueries({ 
            queryKey: ["/api/profilesetting/jobsetting/my"],
        })
      }
    }
  }

  const removeJobSeeking = async (ids)=>{
    // addjobhiring
    let data={}
    let relativePath = "/api/profilesetting/jobsetting/removejobseeking";
    if (1) { 
      data["jobIds"] = ids;
      console.log("HttpService data", data);
      // let toastId = toast.loading("Updating Profile data...",{
      //   position: toast.POSITION.BOTTOM_LEFT
      // })
      setLoadingSave(true);
      let changeUserProfileRes = await HttpService?.postService(relativePath, data,{});
      //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
      setLoadingSave(false);

      if (changeUserProfileRes.success) {
        setExpanded(false);
        // updateContext(refreshVariable);
          queryClient.invalidateQueries({ 
            queryKey: ["/api/profilesetting/jobsetting/my"],
        })
      }
    }
  }

  const removeJobHiring = async (ids)=>{
    // addjobhiring
    let data={}
    let relativePath = "/api/profilesetting/jobsetting/removejobhiring";
    if (1) { 
      data["jobIds"] = ids;
      console.log("HttpService data", data);
      // let toastId = toast.loading("Updating Profile data...",{
      //   position: toast.POSITION.BOTTOM_LEFT
      // })
      setLoadingSave(true);
      let changeUserProfileRes = await HttpService?.postService(relativePath, data,{});
      //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
      setLoadingSave(false);

      if (changeUserProfileRes.success) {
        setExpanded(false);
        // updateContext(refreshVariable);
          queryClient.invalidateQueries({ 
            queryKey: ["/api/profilesetting/jobsetting/my"],
        })
      }
    }
  }
  
  const editInputComponent = (
    parentData,
    parentFieldName,
    fieldName,
    fieldLabel,
    valueD,
    fieldType,
    options,
    optionsSelectedField,
    optionsSelectedLable,
    optionsGroupedBy
  ) => {
    // const editInputComponent = (fieldName, fieldLabel, valueD, fieldType, options, selectedField) => {
    // console.log("fieldName",fieldName, valueD);
    let res = <></>;
    let inputComp = <></>;
    let value = valueD;
    let viewMore =false;
    try {
      if (fieldType == "textEditor") {
        viewMore=true
        inputComp = (
          <>
            {" "}
            {/* <TextField
                id="standard-multiline-static"
                label={fieldLabel}
                defaultValue={value}
                onChange={(e) => {                 
                   console.log("aaa", e.target.value);

                  value = e.target.value;
                }} 
                variant="standard"
              /> */}
              
        <MKBox spacing="2" mb={1} className={styles1.MKEditor}>
          {" "}
          <MKEditor
          
          defaultValue={value}
            onChange={(e) => {
              // console.log("eee", e);
              // setPostMessage(e);
                value = e
            }}
          />
        </MKBox>
            {/* <MKInput
            fullWidth
              placeholder="Enter text"
              label={fieldLabel}
              multiline
              defaultValue={value}
              rows={4}
              onChange={(e) => {
                // console.log("aaa", e.target.value);
                value = e.target.value;
              }}
              InputLabelProps={{ shrink: true }}
              
            /> */}
          </>
        );
      }
      if (fieldType == "textArea") {
        viewMore=true
        inputComp = (
          <>
            {" "} 
               
            <MKInput
            fullWidth
              placeholder="Enter text"
              label={fieldLabel}
              multiline
              defaultValue={value}
              rows={4}
              onChange={(e) => {
                // console.log("aaa", e.target.value);
                value = e.target.value;
              }}
              InputLabelProps={{ shrink: true }}
              
            />
          </>
        );
      }
      if (fieldType == "textField" || fieldType == "email" || fieldType == "phone") {
        inputComp = (
          <>
            {" "}
            {value ? (
              <MKInput
                placeholder="Enter text"
                label={fieldLabel}
                defaultValue={value}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            ) : (
              <MKInput
                placeholder="Enter text"
                label={fieldLabel}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}
          </>
        );
      }
      if (fieldType == "number") {
        inputComp = (
          <>
            {" "}
            {value ? (
              <MKInput
                placeholder="Enter Number"
                label={fieldLabel}
                // defaultValue={value}
                inputProps={{ type: "number" }}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e?.target?.value;
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            ) : (
              <MKInput
                placeholder="Enter Number"
                label={fieldLabel}
                inputProps={{ type: "number" }}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}
          </>
        );
      } else if (fieldType == "radio") {
        inputComp = (
          <>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">{fieldLabel}</FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                defaultValue={value}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
              >
                {options && (
                  <>
                    {options.length ? (
                      <>
                        {options.map((option) => (
                          <FormControlLabel
                            value={optionsSelectedField ? option[optionsSelectedField] : option}
                            control={<Radio />}
                            label={optionsSelectedField ? option[optionsSelectedField] : option}
                          />
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </RadioGroup>
            </FormControl>
          </>
        );
      } else if (fieldType == "fileUrl") {
        // let currentIcon={ c:input.value }
        inputComp = (
          <>
            <FormControl variant="outlined" fullWidth>
              {/* <span style={{ marginRight: 10,  fontSize: 18,}}> 
                <i className={"fab "+input.value } />
            </span>  */}
            </FormControl>
            {/* <GridItem xs={12} sm={12} md={12}> */}
            <Divider variant="middle" />
            <h4 divider> {fieldLabel} </h4>
            <div className="container">
              <div className="row">
                <div className="col s12">
                  <FileUploadComp
                    filesLimit={1}
                    initialFiles={[value]}
                    handleDropZoneFiles={(files) => {
                      value = files;
                    }}
                  />
                  {/* <h1>mmm</h1> */}
                  {/* <ServerPhotoGallery fileType="document"  filesLimit={1} initialFiles={[value]} 
                  uplodedImages={(data)=>{
                        if(data){
                          if(data.length){
                               value =data[0]
                            // onInputChange(data[0],input)
                          }
                        } 
                  }}  
                            /> */}
                </div>
              </div>
            </div>
            {/* </GridItem>  */}
          </>
        );
      } else if (fieldType == "imageUrl") {
        // let currentIcon={ c:input.value }
        inputComp = (
          <>
            <FormControl variant="outlined" fullWidth>
              {/* <span style={{ marginRight: 10,  fontSize: 18,}}> 
                <i className={"fab "+input.value } />
            </span>  */}
            </FormControl>
            {/* <GridItem xs={12} sm={12} md={12}> */}
            <Divider variant="middle" />
            <h4 divider> {fieldLabel} </h4>
            <div className="container">
              <div className="row">
                <div className="col s12">
                  <FileUploadComp
                    filesLimit={1}
                    acceptedFiles={["image/*"]}
                    initialFiles={[value]}
                    handleDropZoneFiles={(files) => {
                      value = files;
                    }}
                  />
                  {/* <h1>mmm</h1> */}
                  {/* <ServerPhotoGallery fileType="document"  filesLimit={1} initialFiles={[value]} 
                  uplodedImages={(data)=>{
                        if(data){
                          if(data.length){
                               value =data[0]
                            // onInputChange(data[0],input)
                          }
                        } 
                  }}  
                            /> */}
                </div>
              </div>
            </div>
            {/* </GridItem>  */}
          </>
        );
      } else if (fieldType == "boolean") {
        inputComp = (
          <>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">{fieldLabel}</FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                defaultValue={value ? true : false}
                onChange={(e) => {
                  // console.log("aaa", e.target.value);
                  value = e.target.value;
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </>
        );
      } else if (fieldType == "selectOption") {
        inputComp = (
          <>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">{fieldLabel}</FormLabel>
              {/* <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  defaultValue={value?true:false}
                  onChange={(e) => {
                    // console.log("aaa", e.target.value);
                    value = e.target.value;
                  }}
                > 
                            <FormControlLabel
                              value={ true }
                              control={<Radio />}
                              label= "true" 
                            /> 
                            <FormControlLabel
                              value={ false }
                              control={<Radio />}
                              label= "false" 
                            /> 
                </RadioGroup> */}
              {options && (
                <Autocomplete
                  fullWidth
                  variant="standard"
                  sx={{ width: 400 }}
                  onChange={(e, val) => {
                    if (val) {
                      if (val) {
                        // console.log("val,id",val.id);
                        value = val;
                        // console.log("value",value);
                      }
                    }
                  }}
                  options={options}
                  // defaultValue={options?(optionsSelectedField?value[optionsSelectedLable]:value):""}
                  getOptionLabel={(option) =>
                    optionsSelectedLable ? option[optionsSelectedLable] : option
                  }
                  groupBy={(option) => (optionsGroupedBy ? option[optionsGroupedBy] : null)}
                  renderInput={(params) => <MKInput {...params} variant="standard" />}
                />
              )}
            </FormControl>
          </>
        );
      } else if (fieldType == "chipsText") {
        viewMore=true
        let chipNewText = "";
        let chipsData = [];
        if (value) {
          if (value.length) {
            chipsData = value.split(",");
          }
        }

        inputComp = (
          <>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">{fieldLabel}</FormLabel>

              {/* <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  // options={top100Films.map((option) => option.title)}
                  defaultValue={chipsData}
                  // value={chipsData}
                  freeSolo   
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => {
                    let newValue=""
                    console.log("params",params)
                  return<> 
                      <Input
                      
                      {...params}
                      value={newValue}
                      id="outlined-adornment-password" 
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            // onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                          <Visibility /> 
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    /> 
                    
                    </> }
                  }
                />  */}

              <Autocomplete
                fullWidth
                // sx={{ width: 400 }}
                multiple
                id="tags-filled"
                options={[]}
                // defaultValue={chipsData}
                value={chipsData}
                // defaultValue={newWorkExperianceData.skillsOrSubjects}
                onChange={(e, data) => {
                  // handleNewWorkExperianceData("skillsOrSubjects",data)
                  let skills = "";
                  if (data.length) {
                    data.forEach((element) => {
                      skills = skills + (skills ? "," : "") + element;
                    });
                  }
                  value = skills;
                }}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Skills Or Subjects"
                    placeholder="Add Skill"
                  />
                )}
              />
            </FormControl>
          </>
        );
      }

      let accordionTitle = "value not assigned";
      if (fieldType == "fileUrl") {
        accordionTitle = (
          <Button
            href={value}
            target="_blank"
            onClick={() => {
              // setDocumentUrl(value);
              // setOpenedDialog("documentviewer")
            }}
            color="primary"
            round
            size="sm"
          >
            open file
          </Button>
        );
      } else if (fieldType == "imageUrl") {
        accordionTitle = <Avatar src={value}></Avatar>;
      } else if (fieldType == "chipsText") {
        viewMore=true
        let chipsData = [];
        if (value) {
          if (value.length) {
            chipsData = value.split(",");
          }
        }
        accordionTitle = (
          <>
            {chipsData.map((chipValue) => (
              <Chip label={chipValue} variant="outlined" />
            ))}
          </>
        );
      } else if (fieldType == "chip") {
        accordionTitle = (
          <>
            {value.map((chipValue) => (
              <Chip
                label={optionsSelectedLable ? chipValue[optionsSelectedLable] : chipValue}
                variant="outlined"
              />
            ))}
          </>
        );
      } else if (fieldType == "boolean") {
        accordionTitle = <>{value ? "Yes" : "No"}</>;
      }else if (fieldType == "number") {
        accordionTitle = <>{value}</>;
      }
      //  else if (fieldType == "selectOption") {

      //   accordionTitle = (
      //     <>
      //       {value?getEducationLevelById(value, "single","title"):"Not selected" }
      //     </>
      //   );
      // }
      else {
        if (value) {
          accordionTitle = optionsSelectedLable
            ? value[optionsSelectedLable]
              ? value[optionsSelectedLable]
              : ""
            :CommonServices?.parseText(value);
            // viewMore=accordionTitle?(accordionTitle.length)?((accordionTitle.length>20)?true:false):false:false;
          // accordionTitle = CommonServices.truncateStringDefualt(accordionTitle, 15);
        }
      }
      // console.log("accordionTitle",accordionTitle)
// {expanded === fieldName} 
      res = (
        <>
          <Accordion expanded={true} >
            <AccordionSummary
              expandIcon={ <Edit 
              onClick={()=>{
                  setExpanded((expanded==fieldName) ? false:fieldName);
              }} /> }
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <MKTypography
                sx={{ width:  viewMore?"100%":"43%", flexShrink: 0 }}
                variant="body2"
                color="text"
                fontWeight="regular"
                pl={1}
              >
                {fieldLabel}{" "}
              </MKTypography>
            { (!viewMore)&& <MKTypography
                sx={{ color: "text.secondary" }}
                variant="body2"
                color="text"
                fontWeight="regular"
                pl={1}
              >
                <strong>{accordionTitle}</strong>{" "}
              </MKTypography>}
              {/* <Typography sx={{ width: "43%", flexShrink: 0 }}>{fieldLabel}</Typography> */}
              {/* <Typography sx={{ color: "text.secondary" }}><strong>{accordionTitle}</strong></Typography> */}
            </AccordionSummary> 
            <Divider  variant="middle" sx={{my:0}}/>
          { (expanded === fieldName)?<AccordionDetails>
              <Grid container alignItems="center">
                <Grid py={1} item xs={12} lg={12}>
                  {inputComp}
                </Grid>
                <Grid py={1} item xs={12} lg={12}>
                  <MKBox px={4}>
                    <Stack direction="row" spacing={2}>
                      <Box sx={{ m: 1, position: "relative" }}>
                        <Button
                          variant="contained"
                          color="success"
                          disabled={loadingSave}
                          onClick={() => {
                            let dataDD = {};
                            if (fieldType == "chip") {
                              let valuesD = "";
                              value.forEach((val) => {
                                valuesD =
                                  valuesD +
                                  (optionsSelectedField ? val[optionsSelectedField] : val) +
                                  ",";
                              });
                              dataDD = {
                                value: valuesD,
                                fieldName: fieldName,
                              };
                            } else {
                              dataDD = {
                                value: optionsSelectedField ? value[optionsSelectedField] : value,
                                fieldName: fieldName,
                              };
                            }
                            updatemyCurrentProfile(dataDD, parentData, parentFieldName, fieldType);
                            console.log("value", value);
                          }}
                        >
                          Save
                        </Button>

                        {loadingSave && (
                          <CircularProgress
                            size={24}
                            sx={{
                              // color: green[500],
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                          />
                        )}
                      </Box>

                      <Button
                        color="secondary"
                        onClick={() => {
                          setExpanded(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </MKBox>
                </Grid>
              </Grid>
            </AccordionDetails>:((viewMore)&& <AccordionDetails>
               <Grid container alignItems="center">
                  <MKTypography
                sx={{ color: "text.secondary" }}
                variant="body2"
                color="text"
                fontWeight="regular"
                pl={1}
              >
                <strong>{accordionTitle}</strong>{" "}
              </MKTypography>
              </Grid> 
            </AccordionDetails>) }
          </Accordion> 
        </>
      );
    } catch (error) {
      // console.log("inp comp", error);
    }
    return res;
  };

  const uploadFiles = async (filesD, filePathD) => {
    // console.log("uploadFiles",filesD,filePathD);
    let res = [];
    let dropzoneTempImages1 = filesD || dropzoneFiles;
    try {
      let filePath = "./public/files/users/user_25/new-folder3";
      const formData = new FormData();
      _.filter(dropzoneTempImages1, (file) => {
        // formData.append("folderPath", uploadingFolderPath);
        formData.append("folderPath", filePath);
        formData.append("file", file);
        return file;
      });

      let getHeaderAndURLRes = await HttpService.getHeaderAndURL();
      let baseUrl = getHeaderAndURLRes.baseUrl;
      let additionalData = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        // onUploadProgress: progressEvent => {
        //   if(progressEvent){
        //     // console.log("progressEvent", Math.round((progressEvent.loaded * 100) / progressEvent.total))
        //       setuploadPercentage(parseInt(
        //       Math.round((progressEvent.loaded * 100) / progressEvent.total)
        //         ))
        //         }
        // }
      };

      // console.log("formData  ",formData);
      let uploadFileRes = await postDocuments(
        "uploadFile",
        "/api/file/photos",
        formData,
        additionalData
      );
      if (uploadFileRes) {
        if (uploadFileRes.record) {
          if (uploadFileRes.record.length) {
            uploadFileRes.record.forEach((imgFile) => {
              // var destination=imgFile.destination.replace('./public',"");
              var destination = imgFile.destination.replace("./public", "/public");
              var filename = "/" + imgFile.filename;
              var pathN = baseUrl + destination + filename;
              var imgpath = pathN;
              res.push(imgpath);
            });
          }
        }
      }
      // console.log("uploadFileRes res",res);
      // await axios.post(baseUrl+"/api/file/photos", formData,{additionalData})
      // .then(function (response) {
      //   let imgs=[]
      //   // console.log("file  dropzoneval, uploaded",dropzoneTempImages1,response.data);
      //   response.data.forEach(imgFile => {
      //     var destination=imgFile.destination.replace('./public',"");
      //     var filename="/"+imgFile.filename
      //     var pathN=baseUrl+destination+filename;
      //     var imgpath=pathN
      //     imgs.push(imgpath)
      //   });
      //   // props.uplodedImages(imgs,props.uploadingImageType);
      //     // setuploadedFiles([...uploadedFiles, ...imageFiles])
      // })
      // .catch(function (error) {
      // });
    } catch (err) {
      // console.log("uploadFiles err",err);
    }
    return res;
  };
  

  return (
    <>  
        <>
          {myCurrentProfile && (
            <>
            {/* {(tab=="selectYourJobCatagory")?
            <SelectYourJobCatagory  selectedjobs={jobSettings?.JobsHiring||[]} jobs={jobs} jobCategories={jobCategories}  done={()=>{}} />:<>
               */}
               <Tabs
              value={tab}
              onChange={(e,v)=>{setTab(v)}}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab value="general" label="General" />
             {(jobSettings?.isEmployer||jobSettings?.isEmploymentAgency)&& <Tab value="employer" label="Employer" />}
             {(jobSettings?.isJobSeeker)&&<Tab value="jobSeeker" label="Job Seeker" />}
            </Tabs>
             {(tab=="general")&&
              <Grid container item xs={12} pt={2} justifyContent="center">
                {/* <Card sx={{ width: "100%" }}> */}
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={12}>
                      {/* <MKBox py={1} px={3}>
                        <MKTypography variant="h6" mb={1}>
                          Job Informations
                        </MKTypography>
                      </MKBox> */}
                   
                      <MKBox py={{ sm: 0, md: 1 }} px={{ sm: 0, md: 3 }}>   
                     <MKTypography
                          variant="h6"
                          mt={2}
                          style={{ color: "darkgreen" }}
                          // mb={2}
                          mx={2}
                        >
                         Which describe you in baally
                        </MKTypography>

                    <FormControl component="fieldset" variant="standard"> 
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch 
                                defaultChecked={jobSettings?.isEmployer} 
                                onChange={(e)=>{
                                  let data={isEmployer:e?.target?.checked}
                                  updateJobSetting(data)
                                }} />
                              }
                              label="Employer"
                            />
                            <FormControlLabel
                              control={
                                <Switch 
                                defaultChecked={jobSettings?.isEmploymentAgency} 
                                onChange={(e)=>{
                                  let data={isEmploymentAgency:e?.target?.checked}
                                  updateJobSetting(data)
                                }}
                                />
                              }
                              label="Employment agency"
                            />
                            <FormControlLabel
                              control={
                                <Switch 
                                defaultChecked={jobSettings?.isJobSeeker} 
                                onChange={(e)=>{
                                  let data={isJobSeeker:e?.target?.checked}
                                  updateJobSetting(data)
                                }} />
                              }
                              label="Job Seeker"
                            />
                          </FormGroup>
                          {/* <FormHelperText>Be careful</FormHelperText> */}
                        </FormControl>
                        <MKTypography
                          variant="h6"
                          mt={2}
                          style={{ color: "darkgreen" }}
                          // mb={2}
                          mx={2}
                        >
                        Job Profile
                        </MKTypography>
                        {/* educationalDocumentAttachementUrl */}
                        
                        {editInputComponent(
                          jobSettings,
                          "profile",
                          "displayName",
                          "Display Name",
                          jobSettings?.displayName,
                          "textField",
                          {minLength: 2,maxLength: 80}
                        )}  
                        {editInputComponent(
                          jobSettings,
                          "profile",
                          "profileImageUrl",
                          "Display Images",
                          jobSettings?.profileImageUrl,
                          "imageUrl"
                        )} 
                        {editInputComponent(
                          jobSettings,
                          "profile",
                          "coverImageUrl",
                          "Cover Image",
                          jobSettings?.coverImageUrl,
                          "imageUrl"
                        )}  
                        {editInputComponent(
                          jobSettings,
                          "profile",
                          "title",
                          "Title",
                          jobSettings?.title,
                          "textField",
                          {minLength: 5,maxLength: 100}
                        )}
                        {editInputComponent(
                          jobSettings,
                          "profile",
                          "email",
                          "Contact Email",
                          jobSettings?.email,
                          "email",
                          {minLength: 2,maxLength: 80}
                        )}
                        {editInputComponent(
                          jobSettings,
                          "profile",
                          "phone",
                          "Contact Phone",
                          jobSettings?.phone,
                          "phone",
                          {maxLength: 20}
                        )}  
                        {editInputComponent(
                          jobSettings,
                          "profile",
                          "description",
                          "Description about you",
                          jobSettings?.description,
                          "textEditor",
                          {minLength: 20,maxLength: 300}
                        )}

 
                      </MKBox>
                    </Grid>
                  </Grid>
                {/* </Card> */}
              </Grid>}
 
              { ((tab=="employer")&&(jobSettings?.isEmployer||jobSettings?.isEmploymentAgency))&&<Grid xs={12} justifyContent="center" py={3} mx="auto">
                <Card sx={{ width: "100%" }}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={8}>
                      <MKBox pt={3} px={4}>
                        <MKTypography variant="h4" mb={1}>
                          Employer Details
                        </MKTypography>
                      </MKBox>
                    </Grid>
                  {/* </Grid>

                  <Grid container alignItems="center"> */}
                    <Grid item xs={12} lg={12}>
                      <MKBox py={{ sm: 0, md: 3 }} px={{ sm: 0, md: 3 }}>

                      <Paper
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 2,
                            my: 2,
                          }}
                          component="ul"
                        >
                            <ListItem
                              secondaryAction={
                                <Button edge="end"  onClick={()=>{
                                  setOpenedDialog("addJob")
                                  setDialogData({
                                    title:"add new job",
                                    content:{
                                      jobAs:"JobsHiring",
                                      selectedjobs:jobSettings?.JobsHiring
                                    }
                                  })
                                  setForceRerender(!forceRerender)
                                  }} aria-label="delete">
                                  Add job
                                </Button>
                              }
                              > 
                                <ListItemText
                                 primary ={<MKTypography
                                  variant="h6" 
                                  style={{ color: "dark" }} 
                                > Hiring job catagories   </MKTypography>}
                                 secondary ={jobSettings?.JobsHiring?.length?jobSettings?.JobsHiring?.map((data) => { 
                                    return (
                                        <Chip  sx={{mb:0.5, mr:0.5}}
                                          // icon={icon}
                                          label={data?.title}
                                          onDelete={()=>{removeJobHiring([data?.id])}}
                                        />
                                    ); 
                                  }):"No job Added"}
                                />
                              </ListItem>
                          
                        </Paper> 
                    </MKBox>
                    </Grid>
                    </Grid>
                    </Card>
                    </Grid>}



             { ((tab=="jobSeeker")&&(jobSettings?.isJobSeeker))&&<Grid xs={12} justifyContent="center" py={3} mx="auto">
                <Card sx={{ width: "100%" }}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={8}>
                      <MKBox pt={3} px={4}>
                        <MKTypography variant="h4" mb={1}>
                          Job Seeker Details
                        </MKTypography>
                      </MKBox>
                    </Grid>
                  {/* </Grid>

                  <Grid container alignItems="center"> */}
                    <Grid item xs={12} lg={12}>
                      <MKBox py={{ sm: 0, md: 3 }} px={{ sm: 0, md: 3 }}>





                        {editInputComponent(
                          jobSettings,
                          "profile",
                          "isAvailable",
                          "Available to be hired?",
                          jobSettings?.isAvailable,
                          "boolean"
                        )} 
                        
                        <Paper
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 2,
                            my: 2,
                          }}
                          component="ul"
                        >
                            <ListItem
                              secondaryAction={
                                <Button edge="end"    onClick={()=>{
                                  setOpenedDialog("addJob")
                                  setDialogData({
                                    title:"add new job",
                                    content:{
                                      jobAs:"JobsSeeking",
                                      selectedjobs:jobSettings?.JobsSeeking
                                    }
                                  })
                                  setForceRerender(!forceRerender)
                                  }} aria-label="delete">
                                  Add Job
                                </Button>
                              }
                              > 
                                <ListItemText
                                 primary ={<MKTypography
                                  variant="h6" 
                                  style={{ color: "dark" }} 
                                >Seeking job catagories    </MKTypography>}
                                 secondary ={jobSettings?.JobsSeeking?.length?jobSettings?.JobsSeeking?.map((data) => { 
                                    return (
                                        <Chip sx={{mb:0.5, mr:0.5}}
                                          // icon={icon}
                                          label={data?.title}
                                          onDelete={()=>{removeJobSeeking([data?.id])}}
                                          // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                                        />
                                    ); 
                                  }):"No job Added"}
                                />
                              </ListItem>
                          
                        </Paper>
                        
                        <MKTypography
                          variant="h6"
                          mt={2}
                          style={{ color: "darkgreen" }}
                          mb={2}
                          mx={2}
                        >
                          Expecting Salary | Benefit
                        </MKTypography>
                        {editInputComponent(
                          jobSettings,
                          "profile",
                          "expectingSalaryPerHour",
                          "Salary Per Hour",
                          jobSettings?.expectingSalaryPerHour,
                          "number"
                        )}
                        {editInputComponent(
                          jobSettings,
                          "profile",
                          "expectingSalaryCurrency",
                          "Currency",
                          jobSettings?.expectingSalaryCurrency,
                          "textField",
                          {maxLength: 50}
                        )}
                        {editInputComponent(
                          jobSettings,
                          "profile",
                          "expectingSalaryPerHourConvertedInUSD",
                          "Salary Per Hour In USD",
                          jobSettings?.expectingSalaryPerHourConvertedInUSD,
                          "number"
                        )}
                        

                        {/* <MKTypography
                          variant="h6"
                          mt={2}
                          style={{ color: "darkgreen" }}
                          mb={2}
                          mx={2}
                        >
                          Address you want to work
                        </MKTypography>
                        {editInputComponent(
                          jobSettings,
                          "profile",
                          "country",
                          "Country",
                          jobSettings?.country,
                          "textField",
                          {minLength: 2,maxLength: 50}
                        )}
                        {editInputComponent(
                          jobSettings,
                          "profile",
                          "city",
                          "City",
                          // cities ? getCityById(currentProfile.city, "single") : "",
                          cities
                            ? getDataFromArray(
                                cities,
                                "TOWN_NAME",
                                jobSettings?.city,
                                "single",
                                "TOWN_NAME"
                              )
                            : "",
                          "selectOption",
                          cities,
                          "TOWN_NAME",
                          "TOWN_NAME",
                          "REGION"
                        )}
                        {jobSettings?.city && (
                          <>
                            {jobSettings?.city.toLowerCase() == "addis ababa" && (
                              <>
                                {subCities && (
                                  <>
                                    {editInputComponent(
                                      jobSettings,
                                      "profile",
                                      "subCity",
                                      "Sub City",
                                      getDataFromArray(
                                        subCities,
                                        "title",
                                        jobSettings?.subCity,
                                        "single"
                                      ),
                                      "selectOption",
                                      subCities,
                                      "title",
                                      "title"
                                    )}
                                  </>
                                )}
                                {specificAreas && (
                                  <>
                                    {editInputComponent(
                                      jobSettings,
                                      "profile",
                                      "specificArea",
                                      "Specific Area",
                                      getDataFromArray(
                                        specificAreas,
                                        "title",
                                        jobSettings?.specificArea,
                                        "single"
                                      ),
                                      "selectOption",
                                      specificAreas,
                                      "title",
                                      "title"
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )} */}

                      </MKBox>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>}
            </>  
          )}
 
        </> 
      {forceRerender}

      <Dialog
        open={openedDialog == "addWorkExperiance"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        <DialogTitle>Add Profession and Work Experiance</DialogTitle>
        <DialogContent style={{ paddingTop: "15px" }}>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}

          {jobs && (
            <Autocomplete
              fullWidth
              // sx={{ width: 400 }}
              onChange={(e, val) => {
                if (val) {
                  // console.log("val",val);
                  //  value=val;
                  handleNewWorkExperianceData("jobId", val.id);
                  // console.log("value",value);
                }
              }}
              options={jobs}
              // defaultValue={options?(optionsSelectedField?value[optionsSelectedLable]:value):""}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <MKInput {...params} label="Profession or Job" variant="outlined" />
              )}
            />
          )}

          <TextField
            autoFocus
            margin="dense"
            id="expectingSalaryPerMonth"
            defaultValue={newWorkExperianceData.expectingSalaryPerMonth}
            label="Expecting Salary (Per Month)"
            type="number"
            onChange={(e) => {
              handleNewWorkExperianceData("expectingSalaryPerMonth", e.target.value);
            }}
            fullWidth
            variant="outlined"
          />
          <TextField
            autoFocus
            margin="dense"
            id="workExperianceInMonth"
            defaultValue={newWorkExperianceData.workExperianceInMonth}
            onChange={(e) => {
              handleNewWorkExperianceData("workExperianceInMonth", e.target.value);
            }}
            label="Work Experiance (In Month)"
            type="number"
            fullWidth
            variant="outlined"
          />

          <Autocomplete
            multiple
            id="tags-filled"
            options={[]}
            // defaultValue={[top100Films[13].title]}
            // defaultValue={newWorkExperianceData.skillsOrSubjects}
            onChange={(e, data) => {
              handleNewWorkExperianceData("skillsOrSubjects", data);
            }}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Skills Or Subjects"
                placeholder="Add Skill"
              />
            )}
          />

          <TextField
            autoFocus
            margin="dense"
            multiline
            rows={4}
            id="Describe"
            defaultValue={newWorkExperianceData.description}
            onChange={(e) => {
              handleNewWorkExperianceData("description", e.target.value);
            }}
            label="More Describtion (about your skills) "
            // type="number"
            fullWidth
            variant="outlined"
          />
          {/* newWorkExperianceData */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={()=>{setOpenedDialog("")}}>Cancel</Button> */}
          <MKButton
            color="success"
            // variant="text"
            // size="small"
            disabled={!newWorkExperianceData.jobId}
            onClick={() => {
              addNewWorkExperiance();
            }}
          >
            <strong> Save </strong>
          </MKButton>
        </DialogActions>
      </Dialog>
      <Dialog
          // fullWidth
          fullScreen={isMobile}
        open={openedDialog == "addJob"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {dialogData && (
          <>
            {dialogData?.title && <DialogTitle>{dialogData?.title}</DialogTitle>}
            {dialogData?.content && (
              <DialogContent>
                {/* <DialogContentText>{"moreText.content"}</DialogContentText> */}
                <SelectYourJobCatagory  
                selectedjobs={dialogData?.content?.selectedjobs||[]}
                addNewJobs={(newJ)=>{
                    let data={ 
                      jobs:newJ
                    }
                    if(dialogData?.content?.jobAs=="JobsSeeking"){
                      addJobSeeking(data)
                    }
                    else{
                      addJobHiring(data)
                    }

                }}
                removeJobs={(jobIds)=>{ 
                  if(dialogData?.content?.jobAs=="JobsSeeking"){
                    removeJobSeeking(jobIds)
                  }
                  else{
                    removeJobHiring(jobIds)
                  }
                }} 
                jobs={jobs} jobCategories={jobCategories}  done={()=>{}} /> 
           
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{moreText.title}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{moreText.content}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default JobSetting;
