import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Button,
  Card,
  Dialog,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  SwipeableDrawer,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import {
  AddCircleOutline,
  ArrowBack,
  ArrowDropDownCircleOutlined,
  ArrowForwardIosRounded,
  CheckCircleRounded,
  ContactMail,
  ContactPhone,
  Delete,
  Details,
  Directions,
  Drafts,
  GroupWorkSharp,
  History,
  Inbox,
  Login,
  Logout,
  Mail,
  MenuRounded,
  Person,
  QuestionAnswer,
  RefreshRounded,
  Rule,
  Search,
  SettingsApplications,
  WomanOutlined,
  Work,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices";

import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import { DataManagementService } from "Api-Services/dataManagementService";
import { CommonApiServices } from "Api-Services/commonApiServices";
 
function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
 
export default function SearchBoxContent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpened, setDrawerOpened] = React.useState(false); 
  const [forceRerender, setForceRerender] = React.useState(false);
  const [keywordSearchResult, setKeywordSearchResult] = React.useState([]);
  const [jobSearchHistories, setJobSearchHistories] = React.useState(["mesfin", "tutor"]);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const [searchKeyword, setSearchKeyword] = React.useState(props.searchKeyword);

  const [currentData] = useCurrentUser();
  const {
    currentUser, 
  } = currentData;
 
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
 
  React.useEffect(() => {
    let historiesD = [];

    let jobSearchHistoriesLocalD =
      DataManagementService.getLocalstorageDataSecured("jobSearchHistories");

    if (jobSearchHistoriesLocalD) {
      if (jobSearchHistoriesLocalD.length) {
        historiesD = jobSearchHistoriesLocalD;
      }
    }

    setJobSearchHistories(historiesD);
  }, [currentUser]);

  const searchByKeyword = async (searchKeywordData) => {
    console.log("searchByKeyword", searchKeywordData);
    let searchKeywordDataDD = searchKeywordData || searchKeyword;
    try {
      let pathSearch;
      if (searchKeywordDataDD) {
        // jobSearchData = jobSearchFieldsandValue;
        pathSearch = { keyword: searchKeywordDataDD };
        pathSearch = CommonServices.encryptLocalData(pathSearch);
        let path = "/pages/jobs?search=" + pathSearch; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
        updateHistory(searchKeywordDataDD, "add");
        // setDrawerOpened(false);
        props?.onClose()
        setSearchKeyword(searchKeywordDataDD);
        setForceRerender(!forceRerender);
        goToPage(path);
      }
    } catch (error) {
      console.log("errr", error);
    }
  };

  const searchProfile = async (searchproKeyword) => {
    console.log("searchByKeyword", searchproKeyword);
    let res=[]
    let searchKeywordDataDD = searchproKeyword || searchKeyword;
    try { 
      if (searchKeywordDataDD) { 
      let serachDropdownData= await CommonApiServices.searchProfileByKeyword(searchKeywordDataDD)
      if(serachDropdownData){
      if(serachDropdownData,length){
        res=serachDropdownData
      }
      }
      console.log("serachDropdownData", serachDropdownData);
      }
    } catch (error) {
      console.log("errr", error);
    }
    setKeywordSearchResult(res)
    setForceRerender(!forceRerender)
  };

  const goToPage = (page) => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate(page);
  };

  const updateHistory = (historyKeyWord, action) => {
    let jobSearchHistoriesD = [];
    if (jobSearchHistories.length) {
      jobSearchHistoriesD = jobSearchHistories;
    }
    let removElemIdx = jobSearchHistoriesD.indexOf(historyKeyWord);
    if (removElemIdx > -1) {
      jobSearchHistoriesD.splice(removElemIdx, 1); // 2nd parameter means remove one item only
    }

    if (action == "add") {
      jobSearchHistoriesD.unshift(historyKeyWord);
    }

    //update
    setJobSearchHistories(jobSearchHistoriesD);
    setForceRerender(!forceRerender);
    DataManagementService.setLocalstorageDataSecured(jobSearchHistoriesD, "jobSearchHistories");
  };
  const navigate = useNavigate();
  const mobileMenuId = "primary-search-account-menu-mobile";
  return (
    <>
        <Box>
          <AppBar
            color="white"
            sx={{ mt: 3, position: "relative" }}
            //  position="fixed"
          >
            <Toolbar variant="dense" sx={{ p: 0 }}>
              <Box sx={{ display: { xs: "flex" } }}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  // onClick={handleMobileMenuOpen}
                  onClick={() => {
                    props?.onClose()
                    // setDrawerOpened(false);
                  }}
                  color="inherit"
                >
                  <ArrowBack />
                </IconButton>
              </Box>

              <form>
                <Paper
                  // component="form"
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <InputBase
                    fullWidth
                    startAdornment={<SearchIcon sx={{ mr: 1 }} />}
                    autoFocus={true}
                    sx={{ ml: 1, pr: 1, flex: 1, backgroundColor: "aliceblue" }}
                    placeholder="Search"
                    value={searchKeyword}
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                      searchProfile(e.target.value)
                    }}
                    onKeyDown={(event) => {
                      if (event.keyCode) {
                        if (event.keyCode === 13) {
                          searchByKeyword();
                        }
                      }
                    }}
                    type="search"
                    inputProps={{ "aria-label": "search google maps" }}
                  />
                  <Button
                    sx={{ p: "10px" }}
                    disabled={!searchKeyword}
                    onClick={() => {
                      searchByKeyword();
                    }}
                    // endIcon={<SearchIcon/>}
                  >
                    Search
                  </Button>
                </Paper>
              </form>
            </Toolbar>
          </AppBar>

          <List dense={true} sx={{ pr: 2, mt: 3 }}>
            
          {keywordSearchResult.map((resultData) => {
            if(resultData.firstName||resultData.profileName||resultData.userName){
            return  <ListItem
                disablePadding
                // secondaryAction={
                //   <IconButton
                //     edge="end"
                //     aria-label="delete"
                //     onClick={() => {
                //       updateHistory(jobSearchHistory, "remove");
                //     }}
                //   >
                //     <AddCircleOutline />
                //   </IconButton>
                // }
              >
                <ListItemButton
                  onClick={() => {
                    props?.onClose()
                    navigate("/"+resultData.userName);
                  }}
                >
                  <ListItemIcon>
                    <Search />
                  </ListItemIcon>
                  <ListItemText primary={resultData.firstName?(resultData.firstName+" "+resultData.lastName):resultData.profileName} />
                </ListItemButton>
              </ListItem>
             }})}
            {jobSearchHistories.map((jobSearchHistory) => (
              <ListItem
                disablePadding
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      updateHistory(jobSearchHistory, "remove");
                    }}
                  >
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemButton
                  onClick={() => {
                    searchByKeyword(jobSearchHistory);
                  }}
                >
                  <ListItemIcon>
                    <History />
                  </ListItemIcon>
                  <ListItemText primary={jobSearchHistory} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box> 
 

      {forceRerender}
    </>
  );
}
 
