import React, { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Container, Grid, TextField, Autocomplete, Card, CircularProgress, ImageListItem, ImageList, Divider, Button, ListItemButton, ListItemIcon, ListItemText, Typography, List, ListItem, ImageListItemBar, IconButton, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import MKButton from 'components/MKButton';
import MKTypography from 'components/MKTypography';
import { HttpService } from 'Api-Services/httpService';
import { useCurrentUser } from 'Api-contexts/currentUserContext';
import PortfoliosList from './portfoliosList';
import { DropzoneArea } from 'mui-file-dropzone';
import MKBox from 'components/MKBox';
import { isMobile } from 'react-device-detect';
import imageCompression from "browser-image-compression";
import { makeStyles } from '@mui/styles';
import { Link, useNavigate } from 'react-router-dom';
import { CommonServices } from 'Api-Services/commonServices';
import { PhotoProvider,  PhotoSlider,  PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { Close, Delete } from '@mui/icons-material';
import { defaultStyles, FileIcon } from 'react-file-icon';
import TruncateMarkup from 'react-truncate-markup';
const postStylesDropzone = makeStyles((theme) => ({
  root: {
    "& .css-9igssw-MuiDialogContent-root": {
      padding: "5px",
    },
    "& .ql-toolbar.ql-snow ": {
      padding: "0px",
    },
  },
  dropzoneClassThumbnail: {
    // maxHeight: "124px  !important",
    minHeight: "5px !important",
    "& .css-f8f0pk-MuiSvgIcon-root ": {
      height: "30px",
      width: "30px",
    },
    "& .css-1g96e5k-MuiTypography-root": {
      fontSize: "0.9rem",
      fontWeight: "700",
      marginTop: "4px",
      marginBottom: "2px",
    },
  },
  dropzoneClass: {
    // maxHeight: "124px  !important",
    minHeight: "5px !important",
    "& .css-f8f0pk-MuiSvgIcon-root ": {
      height: "30px",
      width: "30px",
    },
    "& .css-1g96e5k-MuiTypography-root": {
      fontSize: "0.9rem",
      fontWeight: "700",
      marginTop: "4px",
      marginBottom: "2px",
    },
  },
  storyDropzoneClass: {
    // maxHeight: "124px  !important",
    minHeight: "5px !important",
    "& .css-f8f0pk-MuiSvgIcon-root ": {
      height: "130px",
      width: "30px",
    },
    "& .css-1g96e5k-MuiTypography-root": {
      fontSize: "0.9rem",
      fontWeight: "700",
      marginTop: "4px",
      marginBottom: "2px",
    },
  },
  postTextArea: {
    "& .ql-editor ": {
      minHeight: "120px",
    },
  },
  storyTextArea: {
    "& .ql-editor ": {
      minHeight: "60px",
    },
  },
}));
  
function Portfolio() {
  const [currentData, updateContext] = useCurrentUser();
  const { myCurrentProfile } = currentData; 
  const navigate =useNavigate()
  const [addOrEditMode, setAddOrEditMode] = useState(false);
  const [editingPortfolioData, setEditingPortfolioData] = useState(null);
  const [forceRerender, setForceRerender] = useState(false);

  const { data: jobs } = useQuery({
    queryKey: ['/api/job/all'],
    queryFn: () => HttpService.getApiData('/api/job/all'),
    initialData: [],
  });

  const { data: cities } = useQuery({
    queryKey: ['/api/masterdata/city/all'],
    queryFn: () => HttpService.getApiData('/api/masterdata/city/all'),
    initialData: [],
  });

  const onEditClicked = (Portfolio) => {
    if (Portfolio) {
      setEditingPortfolioData(Portfolio);
      setForceRerender(!forceRerender);
      setAddOrEditMode(true);
    }
  };

  return (
    <Container sx={{ my: 5 }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item sx={{ mb: 2 }}>
          <MKTypography variant="h4">Your Portfolio</MKTypography>
        </Grid>
        <Grid item>

          {addOrEditMode ? (
            <MKButton onClick={() => setAddOrEditMode(false)}>Back to Lists</MKButton>
          ) : (<>
            <MKButton sx={{mr:2}} onClick={() => navigate(-1)}>Back </MKButton>
            <MKButton onClick={() => {
              setEditingPortfolioData(null);
              setAddOrEditMode(true);
            }}>
              Add New
            </MKButton>
            </>
          )}
        </Grid>
      </Grid>

      {!addOrEditMode ? (
        <PortfoliosList onEditClicked={onEditClicked} profileId={myCurrentProfile?.id} />
      ) : (
        <AddOrEditPortfolio
          jobs={jobs}
          cities={cities}
          editingPortfolioData={editingPortfolioData}
          setEditingPortfolioData={setEditingPortfolioData}
          forceRerender={forceRerender}
          setForceRerender={setForceRerender}
          myCurrentProfile={myCurrentProfile}
          setAddOrEditMode={setAddOrEditMode}
        />
      )}
    </Container>
  );
}

function AddOrEditPortfolio({
  jobs,
  cities,
  editingPortfolioData,
  setEditingPortfolioData,
  forceRerender,
  setForceRerender,
  myCurrentProfile,
  setAddOrEditMode
}) {
  const queryClient=useQueryClient()
  const postStyles = postStylesDropzone();
  const [jobId, setJobId] = useState(editingPortfolioData?.JobId || null);
  const [thumbnailAttachment, setThumbnailAttachment ] = React.useState([]); 
  const [attachments, setAttachments] = React.useState([]);

  const [employerName, setEmployerName] = useState(editingPortfolioData?.employerName || '');
  const [title, setTitle] = useState(editingPortfolioData?.title || '');
  const [description, setDescription] = useState(editingPortfolioData?.description || '');
  const [startDate, setStartDate] = useState(editingPortfolioData?.startDate || '');
  const [endDate, setEndDate] = useState(editingPortfolioData?.endDate || '');
  const [cityId, setCityId] = useState(editingPortfolioData?.Address?.CityId || null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [photoSliderIndex, setPhotoSliderIndex] = useState(0);
  const [photoSliderVisible, setPhotoSliderVisible] = useState(false);

  const todayDate = new Date().toISOString().split('T')[0];

  const [currentAttachment,setCurrentAttachment] = useState(null);
  const [openedDialog,setOpenedDialog] = useState(false);

  let {data:portfolio} = useQuery({
    queryKey: ["/api/portfolio/find/"+editingPortfolioData?.id],
    queryFn: async () => {
      return await HttpService.getApiData("/api/portfolio/find/"+editingPortfolioData?.id);
    },
    initialData:editingPortfolioData
  });
 
  const onDeleteAttachment= async(itemData)=>{
    await HttpService.getApiData("/api/portfolio/"+portfolio?.id+"/attachment/"+itemData?.id+"/remove");
    queryClient.invalidateQueries( ["/api/portfolio/find/"+portfolio?.id]);
  }
   
  const compressImage = async (files) => { 
    let compressedFiles = [];
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 2520,
      useWebWorker: true,
      fileType: "image/jpeg",
    };

    try { 
      for (const file of files) {
        if (file.type.startsWith('image/')) {
              const compressedFile = await imageCompression(file, options);
              compressedFiles.push(compressedFile);
        }
        else{
              compressedFiles.push(file);
        }
      }
    } catch (err) {
      console.error("Image compression error", err);
    }
    return compressedFiles;
  };

  const handleSave = async () => {
    if (!description || !employerName || !title) {
      setError("Please fill in all required fields.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const savingPortfolio = {
        employerName,
        title,
        description,
        startDate,
        endDate,
        id: portfolio?.id || undefined,
      };

      const address = {
        CityId: cityId,
        id: portfolio?.Address?.id || undefined,
        PortfolioId: portfolio?.id || undefined,
      };

      let editPath='/api/portfolio/edit/'+portfolio?.id
      const relativePath = portfolio?.id
        ? editPath
        : '/api/portfolio/new';

      const compressedThumbnailFile  = await compressImage(thumbnailAttachment);
      const compressedFiles = await compressImage(attachments);

      const formData = new FormData();
      formData.append('portfolio', JSON.stringify(savingPortfolio));
      formData.append('address', JSON.stringify(address));
 
      compressedThumbnailFile.forEach(fileThumbnail => { 
        formData.append('thumbnail', fileThumbnail, fileThumbnail.name);
      });

      compressedFiles.forEach(file => {
        formData.append("file", file, file.name);
      });

      await HttpService.postApiData(relativePath, formData);

      setEditingPortfolioData(null);
      setJobId(null);
      setEmployerName('');
      setTitle('');
      setStartDate('');
      setEndDate('');
      setCityId(null);
      setAddOrEditMode(false);

      // Optionally, trigger a re-render to update the UI after saving
      setForceRerender(!forceRerender);
    } catch (error) {
      console.error('Error saving portfolio:', error);
      setError('Failed to save portfolio. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setEditingPortfolioData(null);
    setJobId(null);
    setEmployerName('');
    setTitle('');
    setStartDate('');
    setEndDate('');
    setCityId(null);
    setAddOrEditMode(false);
  };

  return (<>
    <Card>
      <Container>
        <Grid container sx={{ my: 2 }} justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <MKTypography variant="h6">{portfolio?"Edit ":"Add New "} Portfolio</MKTypography>
          </Grid>
          <Grid item>
            <MKButton
              variant="outlined"
              color="success"
              onClick={handleSave}
              disabled={loading}
              startIcon={loading ? <CircularProgress size={24} /> : null}
            >
              {loading ? '' : 'Save'}
            </MKButton>
            <MKButton onClick={handleCancel} disabled={loading}>Cancel</MKButton>
          </Grid>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <MKTypography color="error">{error}</MKTypography>
          </Grid>
        )}
        <Grid container spacing={2} sx={{ my: 2 }}>
          
         <Grid item sm={12} lg={8}> 
            <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item xs={12} lg={12}>
                <TextField
                  fullWidth
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  label="Project Title "
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  fullWidth
                  value={employerName}
                  onChange={(e) => setEmployerName(e.target.value)}
                  label="Employer / Company"
                />
              </Grid>
              {/* <Grid item sm={12} lg={6}>
                <Autocomplete
                  fullWidth
                  id="job"
                  value={jobs?.find((job) => job.id === jobId) || null}
                  onChange={(e, val) => setJobId(val?.id || null)}
                  options={jobs || []}
                  getOptionLabel={(option) => option.title || ""}
                  renderInput={(params) => <TextField {...params} label="Skill or Job" />}
                />
              </Grid> */}

              <Grid item xs={12} lg={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  label="Description"
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  id="addressCity"
                  value={cities?.find((city) => city.id === cityId) || null}
                  onChange={(e, val) => setCityId(val?.id || null)}
                  options={cities || []}
                  groupBy={(option) => option.REGION || ""}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => <TextField {...params} label="City / Town" />}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="Start Date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: todayDate,
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="End Date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: todayDate,
                  }}
                />
              </Grid>
            </Grid>
         </Grid>
         <Grid item xs={12} lg={4}>  
         <Grid container spacing={2} sx={{ my: 2 }}>
         <Grid item sx={{ mb: 5 }} xs={12} lg={12}> 
          <MKBox spacing="2" mb={1}>
            <DropzoneArea
              showAlerts={["error", "info"]}
              showPreviews
              filesLimit={1}
              maxFileSize={25000000}
              showPreviewsInDropzone={false}
              dropzoneClass={postStyles.dropzoneClassThumbnail}
              acceptedFiles={["image/*"]}
              dropzoneText={  "Add Thumbnail Photo  " }
              onChange={(e) => setThumbnailAttachment(e.length ? e : [])}
            /> 
          </MKBox>
        </Grid>
         <Grid item xs={12} lg={12}> 
            <MKBox spacing="2" mb={1}>
              <DropzoneArea
                showAlerts={["error", "info"]}
                showPreviews
                filesLimit={5}
                maxFileSize={50000000}
                showPreviewsInDropzone={false}
                dropzoneClass={postStyles.dropzoneClass}
                acceptedFiles={[
                  "image/*",          // All image types
                  ".pdf",             // PDF files
                  // ".doc", ".docx",    // Microsoft Word documents
                  // ".xls", ".xlsx",    // Microsoft Excel documents
                  // ".ppt", ".pptx",    // Microsoft PowerPoint presentations
                  // ".txt",             // Text files
                  // ".rtf"              // Rich Text Format files
                ]}
                dropzoneText={"Add Associated PDF / Image Files Max(10 Files ,5 MB)"}
                onChange={(e) => setAttachments(e.length ? e : [])}
              /> 
            </MKBox>
            
      <MKBox   sx={{ p: 2 }}>
      {portfolio?.Attachments && portfolio?.Attachments?.length > 0 && (
          <div>
        
        {/* List document attachments */}
      
            <Divider sx={{ mt: 2, mb: 1 }} />
        <MKTypography variant="subtitle2" color="text.secondary" sx={{ my : 2 }}>
        document Attachments:
            </MKTypography>
            <List>
              {portfolio?.Attachments?.map((attachment, index) =>(!attachment.fileType.startsWith('image/'))&& (
                <ListItem  sx={{maxWidth:248}} 
                 secondaryAction={ (myCurrentProfile?.id==portfolio?.ProfileId)?
                  <>{isMobile?
                 <Delete color="warning"   onClick={()=>{ 
                  // deletefile()
                  setCurrentAttachment(attachment)
                  setOpenedDialog("deleteAttachment")
                  }}/>:
                <Button color="primary" onClick={()=>{ 
                  // deletefile()
                  setCurrentAttachment(attachment)
                  setOpenedDialog("deleteAttachment")
                  }} size="small">Delete </Button> 
                 }</>:null}
                  key={attachment.id}>
                  <ListItemIcon  component={Link} to={attachment?.url} target="blank" sx={{ width: "8px",marginBottom:"2px", marginRight: "3px"}}>
                  <FileIcon radius={1} extension={attachment?.title?(CommonServices?.getExtension(attachment?.title)):"docx"} {...defaultStyles.docx} /> 
                  </ListItemIcon>
                  <ListItemText
                 
                  
                   primary={
                    <TruncateMarkup
                         lines={2}
                         // onTruncate={(wasTruncated) => setIsTruncated(wasTruncated)}
                       >
                    <Typography variant='body2' component={Link} to={attachment?.url} target="blank">{attachment?.title?attachment?.title:('Document '+ (index+1)) }</Typography> 
                    </TruncateMarkup> } />
                </ListItem>
              ))}
            </List>     
            <Divider sx={{ mt: 2, mb: 1 }} />
            <MKTypography variant="subtitle2" color="text.secondary" sx={{ my : 2 }}>
              Image Attachments:
            </MKTypography>
            
            <PhotoSlider
                        images={CommonServices?.getPostAttachments({attachments:portfolio?.Attachments})?.map((item) => ({
                          src: item,
                          key: item,
                        }))}
                        visible={photoSliderVisible}
                        onClose={() => {
                          setPhotoSliderVisible(false);
                          // navigateComp(-1)
                        }}
                        index={photoSliderIndex}
                        onIndexChange={setPhotoSliderIndex}
                      />     
    <PhotoProvider>
            {portfolio?.Attachments?.length > 0 && (
          <ImageList variant="masonry" cols={3} gap={8}>
            {portfolio?.Attachments?.map((attachment,index) =>((attachment?.fileType?.startsWith('image/')))&& (
           
              <ImageListItem key={attachment.id}>
                  {(myCurrentProfile?.id==portfolio?.ProfileId)?<ImageListItemBar
                    sx={{ maxWidth:248,
                      background:
                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                        'rgba(0,0,0,0.3) 40%, rgba(0,0,0,0) 60%)',
                    }}
                  title={attachment?.title?attachment?.title:("attachment "+index+1)}
                  position="below"
                  actionIcon={
                    <IconButton
                      sx={{ color: 'white' }}
                      // aria-label={`star ${item.title}`}

                      onClick={()=>{
                        setCurrentAttachment(attachment)
                        setOpenedDialog("deleteAttachment")
                      }}
                    >
                      <Delete sx={{ color: 'red' }}/>
                    </IconButton>
                  }
                  actionPosition="right"
                />:null}
                <img onClick={()=>{
                     setPhotoSliderIndex(index);
                     setPhotoSliderVisible(true);
                }}
                srcSet={`${CommonServices?.getPostAttachments({ attachments: [attachment] })[0]}?w=348&fit=crop&auto=format&dpr=2 2x`}
                src={`${CommonServices?.getPostAttachments({ attachments: [attachment] })[0]}?w=348&fit=crop&auto=format`}
               
                  alt={attachment?.title || "Attachment"}
                  loading="lazy"
                />
              </ImageListItem> 
            ))}
          </ImageList>
        )}
        </PhotoProvider> 

          </div>
        )}
      </MKBox> 
        </Grid>
        </Grid>
         </Grid>
        </Grid>
      </Container>
    </Card>
    
    <Dialog  
        onClose={()=>setOpenedDialog(false)}
        aria-labelledby="customized-dialog-title"
        open={openedDialog=="deleteAttachment"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                  {"Delete Attachment"} 
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={()=>setOpenedDialog(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers> 
          
          {"Do you want to delete the attachment?"}
           </DialogContent>
        <DialogActions> 
          <MKButton variant="outlined" color="primary"  onClick={()=>{
            onDeleteAttachment(currentAttachment)
            setOpenedDialog(false)}}>
          Yes
          </MKButton> 
          <Button onClick={()=>{ setOpenedDialog(false) }} size="small">No </Button>  
        </DialogActions>
      </Dialog>
  </>);
}

export default Portfolio;
