// @mui material components
// Material Kit 2 PRO React components

// @mui material components
import Grid from "@mui/material/Grid";
import queryString from "query-string";

import PropTypes from "prop-types";

import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKDatePicker from "components/MKDatePicker";
import MKButton from "components/MKButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect } from "react";

import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Card } from "@mui/material";
import {
  ArrowForwardIosSharp,
  ExpandLess,
  ExpandMore,
  Inbox,
  NoteAdd,
  Receipt,
  Send,
  VerifiedUser,
  Work,
} from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { HttpService } from "Api-Services/httpService";
import { CommonServices } from "Api-Services/commonServices";
var groupArray = require("group-array");

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderBottom: "darkgreen solid",
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, px: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SingleJobProposals() {
  // console.log("cities", cities);

  const navigateComp = useNavigate();
  const params = useParams();
  // console.log("cities", cities);

  const [currentData, updateContext] = useCurrentUser();
  const { myJobApplications, jobs, agencyData } = currentData;

  const [agencyJobs, setAgencyJobs] = React.useState(null);
  const [jobsToChoose, setJobsToChoose] = React.useState(jobs);
  const [expanded, setExpanded] = React.useState("");
  const [proposalsGroup, setProposalsGroup] = React.useState([]);

  const [forceRerender, setForceRerender] = React.useState(false);
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [currentProposal, setCurrentProposal] = React.useState(null);
  const [value, setValue] = React.useState("All");

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  let { data: jobRecievedProposals } = useQuery({
    queryKey: ["/api/job/" + params?.jobId + "/recievedproposals"],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/job/" + params?.jobId + "/recievedproposals");
    },
    // initialData:props?.post
  });

  useEffect(() => {
    try {
      let propsGroup = groupArray(
        jobRecievedProposals?.EmploymentApplicationRequests,
        "statusName"
      );
      propsGroup = Object.entries(propsGroup);
      setProposalsGroup(propsGroup);
      console.log("propsGroup  ", propsGroup);
    } catch (error) {
      console.log("propsGroup error", error);
    }
    setForceRerender(!forceRerender);
  }, [jobRecievedProposals]);

  useEffect(() => {
    // console.log("myJobApplications", myJobApplications);
    // console.log(" myJobSeekerInformation", myJobSeekerInformation);
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch) {
        if (locationSearch.search) {
          searchByKeyword(locationSearch.search);
        }
      }
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    setForceRerender(!forceRerender);
  }, [myJobApplications]);

  useEffect(() => {
    // console.log("agencyData",agencyData)
    let filterdJobs = [];
    if (agencyData) {
      if (agencyData.jobsId) {
        let allagencyjobs = agencyData.jobsId.split(",");
        filterdJobs = jobs.filter((jobD) => {
          let res = false;
          allagencyjobs.forEach((element) => {
            element = parseInt(element);
            if (element) {
              if (element == jobD.id) {
                res = true;
              }
            }
          });
          return res;
        });
        setAgencyJobs(filterdJobs);
        setJobsToChoose(filterdJobs);
      }
    }
    setForceRerender(!forceRerender);
  }, [agencyData, jobs]);

  const parseDate = (dt) => {
    let d = new Date(dt);
    let m = d.getMonth() + 1;
    let dateD = m + "/" + d.getDate() + "/" + d.getFullYear();
    return dateD;
  };

  const truncateString = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          let btn = lableName ? (
            <Button
              variant="text"
              onClick={() => {
                setMoreText({ content: str, title: lableName });
                setOpenedDialog("moreText");
                setForceRerender(!forceRerender);
              }}
            >
              {"View more"}
            </Button>
          ) : null;
          let txt = str.slice(0, num) + "...";
          res = (
            <>
              {parse(txt)} {btn}
            </>
          );
        } else {
          res = <>{parse(res)}</>;
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };

  function ProposalsComp (props3) { 
    const [proposalsData, setProposalsData] = React.useState(props3?.data);
    const [showPhone, setShowPhone] = React.useState(false);
    
    return (
      <>
        <List dense sx={{ width: "100%" }}>
          {proposalsData?.map((proposal) => (
            <Box sx={{ width: "100%", bgcolor: "background.paper", m: 0 }}>
              <ListItemButton
                onClick={() => setExpanded(expanded === proposal.id ? false : proposal?.id)}
              >
                <ListItemIcon onClick={() => navigateComp("/" + proposal?.Profile?.userName)}>
                  <Avatar src={proposal?.Profile?.profileImageUrl} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    CommonServices.getProfileName(proposal?.Profile) + " | " + proposal?.statusName
                  }
                  secondary={
                    <>
                      {truncateString(proposal?.description, 65)}
                      <MKTypography
                        display="block"
                        variant="button"
                        fontWeight="regular"
                        // color="text"
                        mb={1}
                      >
                        Applied date: {parseDate(proposal.createdAt)}
                      </MKTypography>
                    </>
                  }
                />{" "}
                {expanded === proposal.id ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={expanded === proposal.id} timeout="auto" unmountOnExit>
                <Box sx={{ borderTop: 1, borderColor: "divider", p: 2 }}>
                
                  <MKButton
                    sx={{ mr: 2 }}
                    color="primary"
                    size="small"
                    variant="outlined"
                    // variant={Link}
                    // disabled={loading || !professionTitle}
                    // color="success"

                    onClick={() => {
                      let data = {
                        dialogId: "ProfileDialogBox",
                        data: { profile: proposal?.Profile },
                      };
                      updateContext("currentGlobalDialogBoxData", data);
                    }}
                  >
                    {"Profile"}
                  </MKButton>
                  <MKButton 
                    // component={Link}
                    href={"tel:"+ proposal?.Profile?.phone}
                    sx={{ mr: 2 }}
                    color="primary"
                    size="small"
                    variant="outlined"
                    // variant={Link}
                    // disabled={loading || !professionTitle}
                    // color="success"

                    onClick={() => { 
                      setShowPhone(!showPhone)
                    }}
                  >
                    {showPhone?"09333333":"Call"}
                  </MKButton>
                  <MKButton
                    sx={{ mr: 2 }}
                    color="primary"
                    size="small"
                    variant="outlined"
                    // variant={Link}
                    // disabled={loading || !professionTitle}
                    // color="success"

                    // onClick={() => {
                    //   let data = {
                    //     dialogId: "ProfileDialogBox",
                    //     data: { profile: proposal?.Profile },
                    //   };
                    //   updateContext("currentGlobalDialogBoxData", data);
                    // }}
                  >
                    {"Message"}
                  </MKButton>
                </Box>
                <Box sx={{ borderTop: 1, borderColor: "divider", p: 2 }}>
                  <MKTypography variant="h6" color="text">
                    Cover Letter:
                  </MKTypography>
                  <MKTypography variant="body1" fontWeight="light" color="text">
                    {parse(proposal.description)}
                  </MKTypography>
                    <Button 
                    color="primary"
                    size="small"
                    // variant="outlined"
                    onClick={() => {
                      setCurrentProposal(proposal);
                      setOpenedDialog("viewProposal");
                      setForceRerender(!forceRerender);
                    }}
                  >
                    {"Read More"}
                  </Button>
                  </Box>
                <Box sx={{ borderTop: 1, borderColor: "divider", p: 2 }}>
                  <MKButton
                    sx={{ mr: 2 }}
                    color="success"
                    size="small"
                    variant="contained"
                    // onClick={() => {
                    // }}
                  >
                    {"Accept"}
                  </MKButton>
                  <MKButton
                    sx={{ mr: 2 }}
                    variant="contained"
                    color="warning"
                    size="small"
                    // variant={Link}
                    // disabled={loading || !professionTitle}
                    // color="danger"

                    // onClick={() => {
                    // }}
                  >
                    Decline
                  </MKButton>
                </Box>
              </Collapse>
              <Divider variant="inset" component="li" />
            </Box>
          ))}
        </List>
      </>
    );
  };

  return (
    <MKBox component="section" py={1}>
      <MKBox component="section">
        <>
          <>
            <Grid container>
              <Grid item xs={12} sx={{ mt: 2 }} justifyContent={"flex-end"}>
                <MKTypography variant="h3" opacity={0.4}>
                  {"Job Proposals"}
                </MKTypography>
                <Divider />
                <MKTypography ml={2} variant="h6" opacity={0.8}>
                  {jobRecievedProposals?.title}
                </MKTypography>
                <Typography
                  ml={2}
                  onClick={() => {
                    let data = {
                      dialogId: "PostDetailsDialogBox",
                      data: { post: jobRecievedProposals?.Post },
                    };
                    updateContext("currentGlobalDialogBoxData", data);
                  }}
                  color={"primary"}
                  variant="caption"
                >
                  View job post
                </Typography>
              </Grid>
            </Grid>

            <Box sx={{ width: "100%", my: 2 }}>
              <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label={"All"} value={"All"} icon={<Work />} />
                {proposalsGroup.map((tabD) => (
                  <Tab label={tabD[0]} value={tabD[0]} icon={<Work />} />
                ))}
              </Tabs>
            </Box>

            <Box>
              {value == "All" && (
                <>
                  {jobRecievedProposals && (
                    <div style={{ marginBottom: "10px" }}>
                      {<ProposalsComp data={jobRecievedProposals?.EmploymentApplicationRequests}/>}
                    </div>
                  )}
                </>
              )}
              {proposalsGroup.map((PGroup) => (
                <>
                  {PGroup[0] == value && (
                    <>
                      {PGroup?.length > 1 && (
                        <div style={{ marginBottom: "10px" }}>{<ProposalsComp data={PGroup[1]} />}</div>
                      )}
                    </>
                  )}
                </>
              ))}
            </Box>

            {/* </Card> */}
            <Dialog
              open={openedDialog == "moreText"}
              onClose={() => {
                setOpenedDialog("");
              }}
            >
              {moreText && (
                <>
                  {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
                  {moreText.content && (
                    <DialogContent>
                      <DialogContentText>{parse(moreText.content)}</DialogContentText>
                    </DialogContent>
                  )}
                </>
              )}
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenedDialog("");
                  }}
                >
                  close
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openedDialog == "viewProposal"}
              onClose={() => {
                setOpenedDialog("");
              }}
            >
              {currentProposal && (
                <>
                  {currentProposal.title && (
                    <DialogTitle>{parse(currentProposal.title)}</DialogTitle>
                  )}
                  {currentProposal.description && (
                    <DialogContent>
                      <DialogContentText>{parse(currentProposal.description)}</DialogContentText>
                    </DialogContent>
                  )}
                </>
              )}
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenedDialog("");
                  }}
                >
                  close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        </>
      </MKBox>
      {forceRerender}
    </MKBox>
  );
}

export default SingleJobProposals;
