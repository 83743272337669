// @mui material components
import Container from "@mui/material/Container";
import React, { useEffect } from "react"; 
// Images
// import bgImage from "assets/images/bg-rental.jpeg";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { 
  Avatar, 
  Box, 
  Chip,  
  List,
  ListItem,
  ListItemAvatar, 
  ListItemText,  
} from "@mui/material"; 
import {
  AddCircle, 
  Logout,
  Person,
  Send, 
} from "@mui/icons-material"; 
import { makeStyles } from "@mui/styles"; 
import { CommonServices } from "Api-Services/commonServices";
import { CommonApiServices } from "Api-Services/commonApiServices";
import MKAvatar from "components/MKAvatar";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { HttpService } from "Api-Services/httpService";
import MKTypography from "components/MKTypography";
import { InView } from "react-intersection-observer";

const postStylesDropzone = makeStyles((theme) => ({
  root: {
    "& .css-9igssw-MuiDialogContent-root": {
      padding: "5px",
    },
    "& .ql-toolbar.ql-snow ": {
      padding: "0px",
    },
  },
  dropzoneClass: {
    // maxHeight: "124px  !important",
    minHeight: "5px !important",
    "& .css-f8f0pk-MuiSvgIcon-root ": {
      height: "30px",
      width: "30px",
    },
    "& .css-1g96e5k-MuiTypography-root": {
      fontSize: "0.9rem",
      fontWeight: "700",
      marginTop: "4px",
      marginBottom: "2px",
    },
  },
  postTextArea: {
    "& .ql-editor ": {
      minHeight: "120px",
    },
  },
}));

// {"& .css-1agvk75" :{
//   minHeight: "250px"}}
function ProfileLists(props) {
  // profile={myCurrentProfile} friendshipTab={friendshipTab}
  const postStyles = postStylesDropzone();
  const [currentData,updateContext] = useCurrentUser();
  const navigate= useNavigate();
  const params= useParams();
  const { myFriendships, currentUser , myCurrentProfile} = currentData; 
  const [nextPageLoaded, setNextPageLoaded] = React.useState(false);
  const [forceRerender, setForceRerender] = React.useState(true);
  const [profiles, setProfiles] = React.useState([]);
 
 
  // useEffect(async() => {
  //   // console.log("websitePagesContent",websitePagesContent)
  //   if(props?.profiles){ 
  //     setProfiles(props?.profiles) 
  //       }   
  //   setForceRerender(!forceRerender)
  // }, [props?.profiles]);
 

  const checkAndGoToLoginOrRegisterBox=()=>{
    if(myCurrentProfile){
      return true
    }
    else{
      let data = {
        dialogId: "SwitchProfileDialogBox",
        data: {},
      };
      updateContext("currentGlobalDialogBoxData", data);
    return false
    }
  }

  function FriendshipListItem(FriendshipListItemprops) {
    const [friendshipType, setFriendshipType] = React.useState("");
    const [friendshipItem, setFriendshipItem] = React.useState(FriendshipListItemprops?.friendshipItem);
    useEffect(() => {
      // console.log("FriendshipListItem", FriendshipListItemprops.friendshipItem);

      // if (FriendshipListItemprops.friendshipItem && myFriendships) {
        if (FriendshipListItemprops.friendshipItem) {
        let friendshipItemR = FriendshipListItemprops.friendshipItem;
        setFriendshipItem(friendshipItemR);
        let fT = ""//CommonServices.checkFriendship(friendshipItemR.id, myFriendships);
       
            if (friendshipItemR?.iAmFollowing  && friendshipItemR?.myFollower) {
              fT = "friend";
            } else if (friendshipItemR?.myFollower) {
              fT = "follower";
            } else if (friendshipItemR?.iAmFollowing) {
              fT = "following";
            }
        if (fT) {
          if ((friendshipItemR.id ? true : false)) {
            // console.log("fT",fT)
            setFriendshipType(fT);
          }
        }
      }
    }, [FriendshipListItemprops?.friendshipItem]);

    let res = <></>;
    try {
      // let friendshipType = CommonServices.checkFriendship(friendshipItem.id, myFriendships);
      // console.log("friendshipType", friendshipType);

      res = ((friendshipItem)?(
            <ListItem key={friendshipItem.id}
              sx={{ pt: 2 }}
              secondaryAction={
                friendshipType == "friend" ? (
                  <Chip
                    sx={{ px: "5px" }}
                    icon={<Send />}
                    onClick={() => {
                      // CommonApiServices.unfollow(friendshipItem.id)
                      // setFriendshipType("")
                      // console.log("mmmmmmmmmmm", following);
                    }}
                    // label={"Follow"}
                    label={"Message"}
                  />
                ) : friendshipType == "follower" ? (
                  <Chip
                    sx={{ px: "2px" }}
                    color={"info"}
                    icon={<AddCircle />}
                    onClick={() => {
                      
                      if(!checkAndGoToLoginOrRegisterBox())return
                      CommonApiServices.follow(friendshipItem.id);
                      // friendshipType=!following;
                      setFriendshipType("friend");
                    }}
                    label={"Follow Back"}
                  />
                ) : friendshipType == "following" ? (
                  <Chip
                    sx={{ px: "2px" }}
                    icon={<Logout />}
                    onClick={() => {
                      CommonApiServices.unfollow(friendshipItem.id);
                      setFriendshipType("");
                    }}
                    // label={"Follow"}
                    label={"Unfollow"}
                  />
                ) : (
                  <Chip
                    sx={{ px: "15px" }}
                    color={"info"}
                    icon={<AddCircle />}
                    onClick={() => {
                      CommonApiServices.follow(friendshipItem.id);
                      setFriendshipType("following");
                    }}
                    // label={"Follow"}
                    label={"Follow"}
                  />
                )
              }
            >
              <ListItemAvatar
                onClick={() => {
                  navigate("/" + friendshipItem.userName);
                  props?.onClose;
                }}
              >
                {friendshipItem.profileImageUrl != "" && friendshipItem.profileImageUrl != null ? (
                  <Avatar src={friendshipItem.profileImageUrl}></Avatar>
                ) : (
                  <Avatar>
                    <Person />
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                onClick={() => {
                  navigate("/" + friendshipItem.userName);
                  props?.onClose;
                }}
                primary={<>
                  <MKTypography sx={{my:0}} variant="body2">
                    {CommonServices.getProfileName(friendshipItem)}
                  </MKTypography>
                  {/* */}
                </>}
                secondary={ <MKTypography variant="caption">{friendshipItem.userName}</MKTypography>}
              />
            </ListItem> 
      ):null)
    } catch (error) {
      console.log("FriendshipListItem errr", error);
    }
    return res;
  }

  return (<Box sx={{ px:2, width: "100%", bgcolor: "background.paper" }}>
       {/* <ul>
        {props?.profiles?.map(item => (
          <li  > 
              {item.firstName||"--"} 
          </li>
        ))}
      </ul>   */}

      {props?.profiles?.length ? (
        <List>  
          {props?.profiles?.map((friendshipItem,index) => {
            // (follower.Profile)&& 
        //     <li  > 
        //     {friendshipItem.firstName||"--"} 
        // </li>
            // <ListItem key={friendshipItem?.id} >
              
            //   <ListItemText 
            //     primary={friendshipItem?.firstName}  />
            //   </ListItem>
            if (friendshipItem) {
              return <FriendshipListItem friendshipItem={friendshipItem}  />  ;
            }
          })}
        </List>
      ):null}  
    </Box>
  );
}

export default ProfileLists;
