// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { HttpService } from "./httpService";

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBOUKgb9Bpob0mzvf9Qi73CSZcb5mEOZNQ",
  authDomain: "baally-9be90.firebaseapp.com",
  projectId: "baally-9be90",
  storageBucket: "baally-9be90.appspot.com",
  messagingSenderId: "404122210468",
  appId: "1:404122210468:web:2b624468d580ea70929631",
  measurementId: "G-RY8ZJ1DHWM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
// const analytics = getAnalytics(app);


// Request permission to send notifications and generate token
export const requestPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      
      // Get FCM token
      const token = await getToken(messaging, { vapidKey: 'BEUxwl_cGC1pnRMrB1sLuiz8rK-VBI_cv8Etvb_9Z2efMqWvZk8ktv3d774KpPnB58G50uG3HzKSn3sPFG6HF08' });
      if (token) {
        console.log('FCM Token:', token);
        await HttpService.postApiData("/api/notification/pushnotification/subscribe",{fcmToken:token})

        // Send the token to your server or save it for later use
      } else {
        console.log('Failed to get FCM token.');
      }
    } else {
      console.log('Notification permission denied.');
    }
  } catch (error) {
    console.error('Error getting permission or token:', error);
  }
};
