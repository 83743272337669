import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Container, Grid, TextField, Autocomplete, Card, CircularProgress } from '@mui/material';
import MKButton from 'components/MKButton';
import MKTypography from 'components/MKTypography';
import { HttpService } from 'Api-Services/httpService';
import { useCurrentUser } from 'Api-contexts/currentUserContext';
import ProfessionsList from './professionList';

function Profession() {
  const [currentData, updateContext] = useCurrentUser();
  const { myCurrentProfile } = currentData;
  const [addOrEditMode, setAddOrEditMode] = useState(false);
  const [editingProfessionData, setEditingProfessionData] = useState(null);
  const [forceRerender, setForceRerender] = useState(false);

  const { data: jobs } = useQuery({
    queryKey: ['/api/job/all'],
    queryFn: () => HttpService.getApiData('/api/job/all'),
    initialData: [],
  });

  const { data: cities } = useQuery({
    queryKey: ['/api/masterdata/city/all'],
    queryFn: () => HttpService.getApiData('/api/masterdata/city/all'),
    initialData: [],
  });

  const editProfession = (Profession) => {
    if (Profession) {
      setEditingProfessionData(Profession);
      setForceRerender(!forceRerender);
      setAddOrEditMode(true);
    }
  };

  return (
    <Container sx={{ my: 5 }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item sx={{ mb: 2 }}>
          <MKTypography variant="h4">Your Work History</MKTypography>
        </Grid>
        <Grid item>
          {addOrEditMode ? (
            <MKButton onClick={() => setAddOrEditMode(false)}>Back to Lists</MKButton>
          ) : (
            <MKButton onClick={() => {
              setEditingProfessionData(null);
              setAddOrEditMode(true);
            }}>
              Add New
            </MKButton>
          )}
        </Grid>
      </Grid>

      {!addOrEditMode ? (
        <ProfessionsList editProfession={editProfession} profileId={myCurrentProfile?.id} />
      ) : (
        <AddOrEditProfession
          jobs={jobs}
          cities={cities}
          editingProfessionData={editingProfessionData}
          setEditingProfessionData={setEditingProfessionData}
          forceRerender={forceRerender}
          setForceRerender={setForceRerender}
          myCurrentProfile={myCurrentProfile}
          setAddOrEditMode={setAddOrEditMode}
        />
      )}
    </Container>
  );
}

function AddOrEditProfession({
  jobs,
  cities,
  editingProfessionData,
  setEditingProfessionData,
  forceRerender,
  setForceRerender,
  myCurrentProfile,
  setAddOrEditMode
}) {
  const [jobId, setJobId] = useState(editingProfessionData?.JobId || null);
  const [employerName, setEmployerName] = useState(editingProfessionData?.employerName || '');
  const [jobTitle, setJobTitle] = useState(editingProfessionData?.jobTitle || '');
  const [description, setDescription] = useState(editingProfessionData?.description || '');
  const [startDate, setStartDate] = useState(editingProfessionData?.startDate || '');
  const [endDate, setEndDate] = useState(editingProfessionData?.endDate || '');
  const [cityId, setCityId] = useState(editingProfessionData?.CityId || null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const todayDate = new Date().toISOString().split('T')[0];

  const handleSave = async () => {
    if (!jobId || !employerName || !jobTitle) {
      // Add your validation messages here
      console.log('Error saving work history: 3')

      return;
    }
    console.log('Error saving work history: 2')

    setLoading(true);
    setError(null);

    try {
      const Profession = {
        JobId:jobId,
        employerName,
        jobTitle,
        description,
        startDate,
        endDate,
        id: editingProfessionData?.id || undefined,
      };
      console.log('Error saving work history: 1')

      const address = {
        CityId:cityId,
        id: editingProfessionData?.Address?.id || undefined,
        ProfessionId: editingProfessionData?.id || undefined,
      };

      const data = { Profession, address };

      const relativePath = editingProfessionData?.id
        ? '/api/Profession/edit'
        : '/api/Profession/new';

      await HttpService.postApiData(relativePath, data);

      // Reset form
      setEditingProfessionData(null);
      setJobId(null);
      setEmployerName('');
      setJobTitle('');
      setStartDate('');
      setEndDate('');
      setCityId(null);
      setAddOrEditMode(false);
    } catch (error) {
      console.error('Error saving work history:', error);
      setError('Failed to save work history. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setEditingProfessionData(null);
    setJobId(null);
    setEmployerName('');
    setJobTitle('');
    setStartDate('');
    setEndDate('');
    setCityId(null);
    setAddOrEditMode(false);
  };

  return (
    <Card>
      <Container>
        <Grid container sx={{ my: 2 }} justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <MKTypography variant="h6">{editingProfessionData?"Edit ":"Add New "} Work History</MKTypography>
          </Grid>
          <Grid item>
            <MKButton
              variant="outlined"
              color="success"
              onClick={handleSave}
              disabled={loading}
              startIcon={loading ? <CircularProgress size={24} /> : null}
            >
              {loading ? '' : 'Save'}
            </MKButton>
            <MKButton onClick={handleCancel} disabled={loading}>Cancel</MKButton>
          </Grid>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <MKTypography color="error">{error}</MKTypography>
          </Grid>
        )}
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item sm={12} lg={12}>
            <TextField
              fullWidth
              value={employerName}
              onChange={(e) => setEmployerName(e.target.value)}
              label="Employer / Company"
            />
          </Grid>
          

         <Grid item sm={12} lg={12}>
            <TextField
              fullWidth
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              label="Job Title / Position"
            />
          </Grid>
          <Grid item sm={12} lg={6}>
            <Autocomplete
              fullWidth
              id="job"
              value={jobs?.find((job) => job.id === jobId) || null}
              onChange={(e, val) => setJobId(val?.id || null)}
              options={jobs || []}
              getOptionLabel={(option) => option.title || ""}
              renderInput={(params) => <TextField {...params} label="Skill or Job" />}
            />
          </Grid>

          <Grid item sm={12} lg={12}>
            <TextField
              fullWidth
              multiline
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              label="Description"
            />
          </Grid>

          <Grid item sm={12}>
            <Autocomplete
              fullWidth
              id="addressCity"
              value={cities?.find((city) => city.id === cityId) || null}
              onChange={(e, val) => setCityId(val?.id || null)}
              options={cities || []}
              groupBy={(option) => option.REGION || ""}
              getOptionLabel={(option) => option.name || ""}
              renderInput={(params) => <TextField {...params} label="City / Town" />}
            />
          </Grid>

          <Grid item sm={12} lg={6}>
            <TextField
              fullWidth
              type="date"
              label="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: todayDate,
              }}
            />
          </Grid>

          <Grid item sm={12} lg={6}>
            <TextField
              fullWidth
              type="date"
              label="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: todayDate,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
}

export default Profession;
