 

import { useEffect, useState } from "react";

import imageCompression from "browser-image-compression";
// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
 

// NewProduct page components
// import ProductInfo from "layouts/ecommerce/products/new-product/components/ProductInfo";
// import Media from "layouts/ecommerce/products/new-product/components/Media";
// import Socials from "layouts/ecommerce/products/new-product/components/Socials";
// import Pricing from "layouts/ecommerce/products/new-product/components/Pricing"; 
import Benefits from "./components/Benefits"; 
// import VacancyFewDetail from "../vacancy-detail/vacancyFewDetail";
import { Autocomplete, Box, Checkbox, CircularProgress, FormControlLabel, FormGroup, LinearProgress, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { HttpService } from "Api-Services/httpService";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import MKInput from "components/MKInput";
import { isMobile } from "react-device-detect";
import { useQuery } from "@tanstack/react-query";
import MKEditor from "components/MKEditor";
import {
  cities,
  subCities,
  specificAreas,
} from "Api-master-datas/masterDatas.js";

function getSteps() {
  return ["1. Basic Info", "2. Listing Info", "3. Vehicle "];
}


function CreateVehicle() {
  
  const [currentContextData, updateContext  ] = useCurrentUser();  
  const { myCurrentProfile}  = currentContextData;  
 

  const [loading, setLoading] = useState(false);
  const [vacancySubmittedResponse, setVacancySubmittedResponse] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isCurrentSectionValid, setIsCurrentSectionValid] = useState(false);
  const [vacancyData, setVacancyData] = useState({});
  const [post, setPost] = useState(null);
  const [marketPlaceListing, setMarketPlaceListing] = useState(null);
  const [marketPlaceVehicle, setMarketPlaceVehicle] = useState(null);
  const [isReviewSection, setIsReviewSection] = useState(false);
  const [forceRerender, setForceRerender] = useState(false);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

   const [postSectionValues, setPostSectionValues] = useState(null); 

   const [listingSectionValues, setListingSectionValues] = useState(
     null);

   const [vehicleSectionValues, setVehicleSectionValues] = useState(
     null);

     let {data:marketPlaceListingPersonTypes} = useQuery({
      queryKey: ["/api/marketplace/marketplacelistingpersontype/all/"],
      queryFn: async () => {
        // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
        // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
        return await HttpService.getApiData("/api/marketplace/marketplacelistingpersontype/all/");
      }, 
    });
  
    let {data:marketPlaceListingConditions} = useQuery({
      queryKey: ["/api/marketplace/marketplacelistingcondition/all/"],
      queryFn: async () => {
        // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
        // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
        return await HttpService.getApiData("/api/marketplace/marketplacelistingcondition/all/");
      }, 
    });
  
    let {data:fuelTypes} = useQuery({
      queryKey: ["/api/marketplace/fueltype/all/"],
      queryFn: async () => {
        // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
        // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
        return await HttpService.getApiData("/api/marketplace/fueltype/all/");
      }, 
    });
    let {data:vehicleTransmissions} = useQuery({
      queryKey: ["/api/marketplace/vehicletransmission/all/"],
      queryFn: async () => {
        // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
        // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
        return await HttpService.getApiData("/api/marketplace/vehicletransmission/all/");
      }, 
    });
    let {data:vehicleMakes} = useQuery({
      queryKey: ["/api/marketplace/vehiclemake/all/"],
      queryFn: async () => {
        // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
        // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
        return await HttpService.getApiData("/api/marketplace/vehiclemake/all/");
      }, 
    });
    let {data:vehicleTypes} = useQuery({
      queryKey: ["/api/marketplace/vehicletype/all/"],
      queryFn: async () => {
        // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
        // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
        return await HttpService.getApiData("/api/marketplace/vehicletype/all/");
      }, 
    });
   

  useEffect( async () => {
    let postId=253
    let postData=null
    let marketPlaceListingData=null
    let marketPlaceVehicleData=null
    setLoading(true)
    // if(false){
      if(postId){
    let apiData={
      id:postId,
      PostTypeId:4,
      isDraft:true
    }
    let relativePath = "/api/post/getdraft";
    let postDataRes= await postDocuments("postvacancy",relativePath,apiData)
      if(postDataRes?.record){
        postData=postDataRes?.record
      }

    }
    else{ 
    let apiData={
     post:{ 
        PostTypeId:4,
        isDraft:true
      },
     marketPlaceListing:{ 
         MarketPlaceListingTypeId:3, 
       },
  }
    let relativePath = "/api/post/createdraft/marketplace";
      let postDataRes= await postDocuments("postvacancy",relativePath,apiData)
      if(postDataRes?.record){
        postData=postDataRes?.record
      }
    }


    if(postData){
      setPostSectionValues(
        {
          title:{value:postData?.title||"",required:true},
          message:{value:postData?.message||"",required:false} ,    
          // jobLocationTypeId:{value:1,required:false},//inPerson//remote//ontheroad
          // country: {value:"",required:false},
          city: {value:postData?.city||"Addis Ababa",required:false},
          subCity: {value:postData?.subCity||"Any",required:false},
          specificArea: {value:postData?.specificArea||"Any",required:false},   
          // isExpired:{value:postData?.isExpired||false,required:false},   
          PostTypeId:{value:postData?.PostTypeId||3,required:false},  
          PostPrivacyId:{value:postData?.PostPrivacyId||1,required:false},  
        });

        // market listing
        setListingSectionValues(
          { 
            
            PostId: {value:marketPlaceListingData?.PostId,required:false}, 
            price:  {value:marketPlaceListingData?.price,required:false}, 
            currency: {value:marketPlaceListingData?.currency,required:false}, 
            paymentDuration: {value:marketPlaceListingData?.duration,required:false},  
            // contactPhone1: {value:marketPlaceListingData?.contactPhone1,required:false}, 
            // contactPhone2: {value:marketPlaceListingData?.contactPhone2,required:false}, 
            isNegotiable:{value:marketPlaceListingData?.isNegotiable,required:false}, 
            isForRent: {value:marketPlaceListingData?.isForRent,required:false}, 
            isForSell: {value:marketPlaceListingData?.isForSell,required:false}, 
            serviceCharge:{value:marketPlaceListingData?.serviceCharge,required:false}, 
            MarketPlaceListingTypeId: {value:marketPlaceListingData?.MarketPlaceListingTypeId,required:false}, 
            MarketPlaceListingPersonTypeId: {value:marketPlaceListingData?.MarketPlaceListingPersonTypeId,required:false}, 
            MarketPlaceListingConditionId:{value:marketPlaceListingData?.MarketPlaceListingConditionId,required:false}, 

 
        })

        /// market vehicle
        setVehicleSectionValues(
          { 
            seats: {value:null,required:false},
            yearOfManufacture:{value:null,required:false},
            engineSize: {value:null,required:false},
            numberOfCylinders:{value:null,required:false},
            capacity:{value:null,required:false},
            length:{value:null,required:false},
            width:{value:null,required:false},
            height:{value:null,required:false},
            numberOfTires: {value:null,required:false},
            horsePower: {value:null,required:false},
            model: {value:null,required:false},
            color:{value:null,required:false},
            interiorColor:{value:null,required:false},
            isPossibleToExchange:{value:null,required:false}, 

            VehicleTypeId:{value:marketPlaceListingData?.VehicleTypeId,required:false}, 
            VehicleMakeId:{value:marketPlaceListingData?.VehicleMakeId,required:false}, 
            VehicleTransmissionId:{value:marketPlaceListingData?.VehicleTransmissionId,required:false}, 
            FuelTypeId:{value:marketPlaceListingData?.FuelTypeId,required:false}, 
          }); 
    } 
    setForceRerender(!forceRerender)
    setLoading(false)
    console.log("post", post);

  }, []);

  const handleNext = () =>{
      console.log("active step",activeStep)
     setActiveStep(activeStep + 1)

    };

  const handleBack = () =>{ setActiveStep(activeStep - 1)};

  const handleReview = () => {
    let vacancyDataD={basicInfo:{},requirements:{},benefits:{}}
  
    let basicInfoD= Object.entries(postSectionValues)  
    let basicInfoD2={}
    basicInfoD.forEach(element => {
     if(element.length){
      basicInfoD2[element[0]]=element[1].value
     }
    });
    vacancyDataD.basicInfo=basicInfoD2

    let requirementsD= Object.entries(listingSectionValues)  
    let requirementsD2={}
    requirementsD.forEach(element => {
     if(element.length){
      requirementsD2[element[0]]=element[1].value
     }
    });
    vacancyDataD.requirements=requirementsD2
    
    let benefitsD= Object.entries(vehicleSectionValues)  
    let benefitsD2={}
    benefitsD.forEach(element => {
      
    // console.log("  vacancyDataD element",   element)
     if(element.length){
      benefitsD2[element[0]]=element[1].value  
     }
    });
    // console.log("  vacancyDataD benefitsD2",   benefitsD2)
    vacancyDataD.benefits=benefitsD2
    
    let locationD= Object.entries(locationSectionValues)  
    let locationD2={}
    locationD.forEach(element => {
     if(element.length){
      locationD2[element[0]]=element[1].value
     }
    });
    vacancyDataD.location=locationD2
    
    let applicationD= Object.entries(applicationSectionValues)  
    let applicationD2={}
    applicationD.forEach(element => {
     if(element.length){
      applicationD2[element[0]]=element[1].value
     }
    });
    vacancyDataD.application=applicationD2
    // console.log("  vacancyDataD",   vacancyDataD)

      setVacancyData(vacancyDataD)
      setLoading(false)
      setVacancySubmittedResponse(null)
      setIsReviewSection(true)
  };

  const handleSubmit = async(postData) => {
    try {
      if(postData){
        if(postData.id){

    let vacancyDataD= Object.entries(vacancyData)  
    let data={
      "PostId":postData.id, 
    }

    vacancyDataD.forEach(element => {
     if(element.length){
      if(element[1]){
          let vacancyDataDProperties=Object.entries(element[1])  
           vacancyDataDProperties.forEach(elementP => {
            if(element.length){
            // console.log("  elementP",   elementP[0]," : ",elementP[1])
            data[elementP[0]]=elementP[1]
            }
          })
      } 
     }
    });

    // console.log("  myBusinessEmploymentAgency",   myBusinessEmploymentAgency)
    // data.employmentAgencyId=myBusinessEmploymentAgency.id
    // data.employmentAgencyFullName=myBusinessEmploymentAgency.fullName||myBusinessEmploymentAgency.brandName
    // data.employmentAgencyEmail=myBusinessEmploymentAgency.contactEmail
    // data.employmentAgencyPhone=myBusinessEmploymentAgency.phone1||myBusinessEmploymentAgency.phone2

    // vacancyDataD.application=applicationD2
    // console.log("  data",   data)
    // return
    let relativePath = "/api/employmentjobvacancy/new";
    setLoading(true)
    let postvacancyRes= await postDocuments("postvacancy",relativePath,data)
    setLoading(false)
    setVacancySubmittedResponse(postvacancyRes)
      // setVacancyData(vacancyDataD)
      // setLoading(false)
      // setVacancySubmittedResponse(null)
      // setIsReviewSection(true)
      
        }
      }
    } catch (error) {
      
    }
    
  };

  const postNewJob = async() => {
    try { 
      if(myCurrentProfile){ 
          if(myCurrentProfile.id){
      
    let data={
      title :"Job Opening", 
      message :"Job Opening", 
      isExpired:false,  
      PostTypeId:3, 
      PostPrivacyId:1,  
    }
   
    setLoading(true)
    let newpostRes= await submitPost(data)
    if(newpostRes.success){
      if(newpostRes.record){
       let postData=newpostRes.record
        handleSubmit(postData)
    }
  }
    setLoading(false) 
      console.log("newpostRes",newpostRes)
      // setLoading(false)
      // setVacancySubmittedResponse(null)
      // setIsReviewSection(true)
    }
  } 
    } catch (error) {
      
      console.log("newpostRes error",error)
    }
    
  };
  
  const submitPost = async (postData) => {
    let res = {};
    try { 
      let relativePath = "/api/post/new"; 
      let additionalData = {}; 
      let data=postData  
      let postFeedRes = await postDocuments("uploadFile", relativePath, data, additionalData);
      if (postFeedRes) {
        if (postFeedRes.success) { 
            res=postFeedRes 
        }
      }
      console.log("uploadFiles  ", postFeedRes, res);
      // props?.onClose();
    } catch (err) {
      console.log("uploadFiles err", err);
    }
    return res;
  };

  const submitAttachmentPost = async (postData) => {
    let res = {};
    try {
      let isPostWithAttachment = false;
      let relativePath = "/api/post/new";
      let filesData =[]// postAttachments || [];
      let filesDataNew = [];
      let additionalData = {}; 
      let data=postData
      const formData = new FormData();

      // return
      const options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        fileType: "image/jpeg",
      };
      if (filesData&&false) {
        if (filesData.length) {
          relativePath = "/api/post/newwithattachment";
          isPostWithAttachment = true;
          for (let index = 0; index < filesData.length; index++) {
            const imageFile = filesData[index];
            console.log("img imageFile", imageFile);
            // imageFile.name="mesfin.jpg"
            const compressedFile = await imageCompression(imageFile, options);
            // const compressedFile = imageFile;
            filesDataNew.push(compressedFile);
          }

          console.log("img filesData", filesData);
          console.log("img filesDataNew", filesDataNew);
          let filePath = "./public/files/post_files";
          const aa = _.filter(filesDataNew, (file) => {
            formData.append("file", file);
            return file;
          });

          let postDataRes = Object.entries(postData);
          postDataRes.forEach((element) => {
            formData.append(element[0], element[1]);
          });
          formData.append("fileTypeExtension", ".jpg");
          formData.append("folderPath", filePath);

          additionalData = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            // onUploadProgress: progressEvent => {
            //   if(progressEvent){
            //     // console.log("progressEvent", Math.round((progressEvent.loaded * 100) / progressEvent.total))
            //       setuploadPercentage(parseInt(
            //       Math.round((progressEvent.loaded * 100) / progressEvent.total)
            //         ))
            //         }
            // }
          };
        }
      }
      let getHeaderAndURLRes = await HttpService.getHeaderAndURL();
      let baseUrl = getHeaderAndURLRes.baseUrl;

      console.log("img chng", formData, additionalData);
       data = isPostWithAttachment ? formData : postData;
      let postFeedRes = await postDocuments("uploadFile", relativePath, data, additionalData);
      if (postFeedRes) {
        if (postFeedRes.success) { 
            res=postFeedRes 
        }
      }
      console.log("uploadFiles  ", postFeedRes, res);
      // props?.onClose();
    } catch (err) {
      console.log("uploadFiles err", err);
    }
    return res;
  };

  
  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false }; 

    documentsResponse = await HttpService.postService(
      relativePath,
      data,
      {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    } else {
      responseD.message = documentsResponse.message;
 
    }  
    return responseD; 
  };


  const validateCurrentSection = (values) =>
  {
    // setIsCurrentSectionValid(isValid)
    let isValidSec=true
    let valuesEnt= Object.entries(values)   
     valuesEnt.forEach(element => {
      if(element.length){
       let elementD=element[1]||{}
       if(elementD.required){
        if(elementD.value==""||elementD.value==null){
           isValidSec=false
          }
       }
      }
     });
    //  console.log("  isValidSec",   isValidSec)
     setIsCurrentSectionValid(isValidSec)

  }

  const changeSectionValues = (field,values) =>
  {
    // console.log("values index",field,values)
    if("postSectionValues"==field){
      setPostSectionValues(values)
    } 
    if("listingSectionValues"==field){
      setListingSectionValues(values)
    } 
    if("vehicleSectionValues"==field){
      setVehicleSectionValues(values)
    }  
    validateCurrentSection(values)
    // setForceRerender(!forceRerender)
  }
  
  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = null;
    // console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = row[returnField];
                res = rowField;
              }
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };

  const getStepContent=(stepIndex)=> { 
    switch (stepIndex) {//stepIndex
      case 0:
        return postSectionValues?<BasicInfo getDataFromArray={getDataFromArray} validateSection={validateCurrentSection} sectionValues={postSectionValues} changeSectionValues={changeSectionValues}/>:null;
      case 1:
        return listingSectionValues? <Requirements getDataFromArray={getDataFromArray} validateSection={validateCurrentSection} sectionValues={listingSectionValues} changeSectionValues={changeSectionValues} />:null;
      case 2:
        return vehicleSectionValues? <MarketPlaceVehicleComp getDataFromArray={getDataFromArray} validateSection={validateCurrentSection} sectionValues={vehicleSectionValues} changeSectionValues={changeSectionValues} />:null;
      default:
        return null;
    }
  }
  
  
function Requirements(props) { 
  const [forceRerender, setForceRerender] = useState(false);
  const [sectionValues, setSectionValues] = useState(props.sectionValues);
   
  useEffect(() => {
    console.log("props.sectionValues", props.sectionValues)
    props.validateSection(props.sectionValues)
  }, []);

  const changeInputValue = (field,value) =>
  {
    let sectionValuesD=sectionValues
    sectionValuesD[field].value=value
    setSectionValues(sectionValuesD)
    setForceRerender(!forceRerender)
    props.changeSectionValues("listingSectionValues",sectionValuesD)
  }

  return (<>
  {forceRerender}
  <MKBox>


    <MKTypography variant="h5">Listing Info</MKTypography>
     <>
    <MKBox mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}> 
          <TextField fullWidth type="number" label="price"
             defaultValue={sectionValues?.price?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("price",e.target?.value )
                }
              }}  placeholder="99.00" />
        </Grid>
        <Grid item xs={12} sm={12}  md={6} sx={{ mt: 2 }}> 
          <Autocomplete defaultValue={sectionValues?.currency?.value}
            onChange={(e,val)=>{
              if(val){   
                  changeInputValue("currency", val)
                } 
            }}
            options={["ETB", "USD","BTC", "CNY", "EUR", "GBP", "INR"]}
            renderInput={(params) => <MKInput label={"Currency"} {...params}  />}
          />
        </Grid>
        <Grid item xs={12} sm={12}  md={6} sx={{ mt: 2 }}> 
        <Autocomplete 
            defaultValue={sectionValues?.paymentDuration?.value}
            onChange={(e,val)=>{
              if(val){   
                  changeInputValue("paymentDuration", val)
                } 
            }}
            options={["Hourly", "Daily","Weekly", "Monthly", "Annaul",]}
            renderInput={(params) => <MKInput label={"Payment Duration "} {...params}   />}
          /> 
        </Grid> 
        <Grid item xs={12} sm={12}  md={6} sx={{ mt: 2 }}> 
        <Autocomplete  
            defaultValue={props.getDataFromArray(
              marketPlaceListingConditions,
                      "id",
                      sectionValues?.MarketPlaceListingConditionId?.value,
                      "single"
                    )}
            onChange={(e,val)=>{
              if(val){   
                  changeInputValue("MarketPlaceListingConditionId", val)
                } 
            }}
            options={marketPlaceListingConditions}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => <MKInput label={"Condition"} {...params}   />}
          /> 
        </Grid> 
        <Grid item xs={12} sm={12}  md={6} sx={{ mt: 2 }}> 
        <Autocomplete  
            defaultValue={props.getDataFromArray(
              marketPlaceListingPersonTypes,
                      "id",
                      sectionValues?.MarketPlaceListingPersonTypeId?.value,
                      "single"
                    )}
            onChange={(e,val)=>{
              if(val){   
                  changeInputValue("MarketPlaceListingPersonTypeId", val)
                } 
            }}
            options={marketPlaceListingPersonTypes}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => <MKInput label={"Person Types"} {...params}   />}
          /> 
        </Grid> 
        <Grid item xs={12} sm={12}  md={6} sx={{ mt: 2 }}>
          <FormGroup>
          <FormControlLabel control={<Checkbox checked={sectionValues?.isForRent?.value} onChange={(e,v)=>  changeInputValue("isForRent", v)}/>} label="For Rent" />
          <FormControlLabel control={<Checkbox checked={sectionValues?.isForSell?.value}  onChange={(e,v)=>  changeInputValue("isForSell",v)}/>} label="For Sell" />
          <FormControlLabel control={<Checkbox checked={sectionValues?.isNegotiable?.value}  onChange={(e,v)=>  changeInputValue("isNegotiable", v)}/>} label="Negotiable" />
          <FormControlLabel control={<Checkbox checked={sectionValues?.serviceCharge?.value}  onChange={(e,v)=>  changeInputValue("serviceCharge",v)}/>} label="Service Charge" />
        </FormGroup>
        </Grid>
      </Grid>
    </MKBox>
        </>
  </MKBox></>
);
}


function BasicInfo(props) {
  const [forceRerender, setForceRerender] = useState(false);
  const [sectionValues, setSectionValues] = useState(props.sectionValues);
  const [currentData, updateContext] = useCurrentUser();
  const {  jobs  } =
    currentData; 
  useEffect(() => {
    console.log("props.sectionValues", jobs);
    props.validateSection(props.sectionValues);
  }, []);

  const changeInputValue = (field, value) => {
    let sectionValuesD = sectionValues;
    sectionValuesD[field].value = value;
    setSectionValues(sectionValuesD);
    setForceRerender(!forceRerender);
    props.changeSectionValues("postSectionValues", sectionValuesD);
  };

  return (
    <>
      {forceRerender}
      <MKBox>
        <MKTypography variant="h5">Basic Information</MKTypography>
        <MKBox mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              {/* <MKBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MKTypography component="label" variant="button" fontWeight="regular" color="text">
                  Title&nbsp;&nbsp;
                  {sectionValues.title.required && (
                    <MKTypography variant="caption" color="text">
                      (required)
                    </MKTypography>
                  )}
                </MKTypography>
              </MKBox> */}
              <TextField fullWidth
                type="text"
                label={"Title"}
                defaultValue={sectionValues.title.value}
                onChange={(e) => {
                  if (e) {
                    changeInputValue("title", e.target.value);
                  }
                }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
            <TextField  type="text" label="Weight" />
          </Grid> */}
          </Grid>
        </MKBox>
        <MKBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MKBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MKTypography component="label" variant="button" fontWeight="regular" color="text">
                  Short Description&nbsp;&nbsp;
                  {sectionValues.message.required && (
                    <MKTypography variant="caption" color="text">
                      (required)
                    </MKTypography>
                  )}
                </MKTypography>
              </MKBox>
              <MKEditor
                value={sectionValues.message.value}
                //  onChange={setDescription}
                onChange={(e) => {
                  if (e) {
                    changeInputValue("message", e);
                  }
                }}
              />
            </Grid> 
 
          </Grid>
        </MKBox>
        <MKBox mt={2}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={3}>
            <TextField  type="text" label="Salary" placeholder="99.00" />
          </Grid> */}
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            {/* <Autocomplete
              defaultValue="ETB"
              options={cities}
              renderInput={(params) => <MKInput {...params} variant="standard" />}
            /> */}
            
            {/* <MKBox mb={2} display="inline-block">
                <MKTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  City  &nbsp;&nbsp;
               {(sectionValues.city.required)&& <MKTypography variant="caption" color="text">
                  (required)
                </MKTypography>}
                </MKTypography>
              </MKBox> */}

          {(cities)&& <Autocomplete
                  fullWidth
                  id="optionsListComp"
                  // sx={{ width: 400 }}
                  groupBy={(option) =>  option["REGION"] } 
                  onChange={(e, val) => {
                    if (val) {
                    if(val.TOWN_NAME){  
                      changeInputValue("city", val.TOWN_NAME)
                    }
                    }
                  }}
                  value={props.getDataFromArray(
                    cities,
                    "TOWN_NAME",
                    sectionValues.city.value,
                    "single"
                  )}
                  // "TOWN_NAME",
                  // "REGION"
                  options={cities} 
                  // defaultValue={options?(optionsSelectedField? value :{}):{}}
                  // defaultValue="ETB"
                  getOptionLabel={(option) =>  option["TOWN_NAME"] 
                  }
                  renderInput={(params) => (
                    <MKInput {...params}  label="City"   id="optionsList"/>
                  )}
                />}



          </Grid>
         { (sectionValues.city.value=="Addis Ababa")&&<>
          <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
            {/* <Autocomplete
              defaultValue="ETB"
              options={cities}
              renderInput={(params) => <MKInput {...params} variant="standard" />}
            /> */}

           {/* <MKBox mb={2} display="inline-block">
                <MKTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  SubCity &nbsp;&nbsp;
               {(sectionValues.subCity.required)&& <MKTypography variant="caption" color="text">
                  (required)
                </MKTypography>}
                </MKTypography>
              </MKBox> */}

          {(subCities)&& <Autocomplete
                  fullWidth
                  id="optionsListComp"
                  // sx={{ width: 400 }}
                  // groupBy={(option) =>  option["REGION"] } 
                  value={props.getDataFromArray(
                    subCities,
                    "title",
                    sectionValues.subCity.value,
                    "single"
                  )}
                  onChange={(e, val) => {
                    if (val) {
                    if(val.title){  
                      changeInputValue("subCity", val.title)
                    }
                    }
                  }}
                  
                  // "TOWN_NAME",
                  // "REGION"
                  options={subCities} 
                  // defaultValue={options?(optionsSelectedField? value :{}):{}}
                  // defaultValue="ETB"
                  getOptionLabel={(option) =>  option["title"] 
                  }
                  renderInput={(params) => (
                    <MKInput {...params} label="Sub-city"   id="optionsList"/>
                  )}
                />}



          </Grid>
          <Grid item xs={12} sm={6} sx={{ mt: 2 }}>  
        {/* <MKBox mb={2} display="inline-block">
                <MKTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Specific Area&nbsp;&nbsp;
               {(sectionValues.specificArea.required)&& <MKTypography variant="caption" color="text">
                  (required)
                </MKTypography>}
                </MKTypography>
              </MKBox> */}
              {(specificAreas)&& <Autocomplete
                  fullWidth
                  id="optionsListComp"
                  // sx={{ width: 400 }}
                  // groupBy={(option) =>  option["REGION"] } 
                  value={props.getDataFromArray(
                    specificAreas,
                    "title",
                    sectionValues.specificArea.value,
                    "single"
                  )}
                  onChange={(e, val) => {
                    if (val) {
                    if(val.title){  
                      changeInputValue("specificArea", val.title)
                    }
                    }
                  }}
                  
                  // "TOWN_NAME",
                  // "REGION"
                  options={specificAreas} 
                  // defaultValue={options?(optionsSelectedField? value :{}):{}}
                  // defaultValue="ETB"
                  getOptionLabel={(option) =>  option["title"] 
                  }
                  renderInput={(params) => (
                    <MKInput {...params}  label="Specific Area"   id="optionsList"/>
                  )}
                />}
            {/* <TextField  type="text" label="SKU" placeholder="71283476591" /> */}
          </Grid>
          </>}
        </Grid>
      </MKBox>
      </MKBox>
    </>
  );
}

function MarketPlaceVehicleComp(props) { 
  const [forceRerender, setForceRerender] = useState(false);
  const [sectionValues, setSectionValues] = useState(props.sectionValues);
   
  useEffect(() => {
    console.log("props.sectionValues", props.sectionValues)
    props.validateSection(props.sectionValues)
  }, []);

  const changeInputValue = (field,value) =>
  {
    let sectionValuesD=sectionValues
    sectionValuesD[field].value=value
    setSectionValues(sectionValuesD)
    setForceRerender(!forceRerender)
    props.changeSectionValues("listingSectionValues",sectionValuesD)
  }

  return (<>
  {forceRerender}
  <MKBox>


    <MKTypography variant="h5"> Vehicle Info</MKTypography>
     <>
    <MKBox mt={3}>
      <Grid container spacing={3}>
         
         <Grid item xs={12} sm={12}  md={6} > 
         <Autocomplete  
             onChange={(e,val)=>{
               if(val){   
                   changeInputValue("VehicleTypeId", val)
                 } 
             }}
             defaultValue={props.getDataFromArray(
               vehicleTypes,
                       "id",
                       sectionValues?.VehicleTypeId?.value,
                       "single"
                     )}
             options={vehicleTypes}
             getOptionLabel={(option) => option?.title}
             renderInput={(params) => <MKInput label={"Vehicle Type "} {...params}   />}
           /> 
         </Grid> 
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Year Of Manufacture"
             defaultValue={sectionValues?.yearOfManufacture?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("yearOfManufacture",e.target?.value )
                }
              }}   />
        </Grid>
         <Grid item xs={12} sm={12}  md={6}  > 
         <Autocomplete 
             defaultValue={props.getDataFromArray(
               vehicleMakes,
                       "id",
                       sectionValues?.VehicleMakeId?.value,
                       "single"
                     )}
             onChange={(e,val)=>{
               if(val){   
                   changeInputValue("VehicleMakeId", val)
                 } 
             }}
             options={vehicleMakes}
             getOptionLabel={(option) => option.title}
             renderInput={(params) => <MKInput label={"Vehicle Make "} {...params}   />}
           /> 
         </Grid> 
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Model"
             defaultValue={sectionValues?.model?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("model",e.target?.value )
                }
              }}   />
        </Grid>
         <Grid item xs={12} sm={12}  md={6} > 
         <Autocomplete 
             defaultValue={props.getDataFromArray(
               fuelTypes,
                       "id",
                       sectionValues?.FuelTypeId?.value,
                       "single"
                     )}
             onChange={(e,val)=>{
               if(val){   
                   changeInputValue("FuelTypeId", val)
                 } 
             }}
             options={fuelTypes}
             getOptionLabel={(option) => option.title}
             renderInput={(params) => <MKInput label={"Fuel Type "} {...params}   />}
           /> 
         </Grid> 
         <Grid item xs={12} sm={12}  md={6}  > 
         <Autocomplete 
             defaultValue={props.getDataFromArray(
               vehicleTransmissions,
                       "id",
                       sectionValues?.VehicleTransmissionId?.value,
                       "single"
                     )}
             onChange={(e,val)=>{
               if(val){   
                   changeInputValue("VehicleTransmissionId", val)
                 } 
             }}
             options={vehicleTransmissions}
             getOptionLabel={(option) => option.title}
             renderInput={(params) => <MKInput label={"Vehicle Transmission "} {...params}   />}
           /> 
         </Grid> 
        <Grid item xs={12} sm={12}  md={6} > 
          <Autocomplete defaultValue={sectionValues?.color?.value}  sx={{p:0,m:0}} 
        
            onChange={(e,val)=>{
              if(val){   
                  changeInputValue("color", val)
                } 
            }}
            options={["white", "black","green", "other" ]}
            renderInput={(params) => <MKInput label={"Color"} {...params}  />}
          />
        </Grid>
        <Grid item xs={12} sm={12}  md={6} > 
          <Autocomplete defaultValue={sectionValues?.interiorColor?.value}  
            onChange={(e,val)=>{
              if(val){   
                  changeInputValue("interiorColor", val)
                } 
            }}
            options={["white", "black","green", "other" ]}
            renderInput={(params) => <MKInput label={"Interior Color"} {...params}  />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Number Of Seats"
             defaultValue={sectionValues?.seats?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("seats",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Number Of Tires"
             defaultValue={sectionValues?.numberOfTires?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("numberOfTires",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Number Of Cylinders"
             defaultValue={sectionValues?.numberOfCylinders?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("numberOfCylinders",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Engine Size"
             defaultValue={sectionValues?.engineSize?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("engineSize",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Capacity"
             defaultValue={sectionValues?.capacity?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("capacity",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Length"
             defaultValue={sectionValues?.length?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("length",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Width"
             defaultValue={sectionValues?.width?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("width",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Height"
             defaultValue={sectionValues?.height?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("height",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Horse Power"
             defaultValue={sectionValues?.horsePower?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("horsePower",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12}  md={6} sx={{ mt: 2 }}>
          <FormGroup>
          <FormControlLabel control={<Checkbox checked={sectionValues?.isPossibleToExchange?.value} onChange={(e,v)=>  changeInputValue("isPossibleToExchange", v)}/>} label="is Possible To Exchange" />
            </FormGroup>
        </Grid>
      </Grid>
    </MKBox>
        </>
  </MKBox></>
);
}


  return ( 
      <MKBox mx={1}  mb={9}>
        <Grid container justifyContent="center"> 
        {(!loading&&(vacancySubmittedResponse==null))&&  <Grid item xs={12} lg={10}>
            <MKBox my={3} textAlign="center">
              <MKBox mb={1}>
                <MKTypography variant="h3" fontWeight="bold">
                  Add New Vehicle
                </MKTypography>
              </MKBox>
              <MKTypography variant="h5" fontWeight="regular" color="secondary">
                This information will describe more about the Vehicle.
              </MKTypography>
            </MKBox>
             {!isReviewSection?
            <Card> <div>
              <MKBox mt={-3} mb={3} mx={2}>
               { isMobile? null:<Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>
                         {label}  </StepLabel>
                    </Step>
                  ))}
                </Stepper>}
              </MKBox>
              <MKBox p={2}>
                <MKBox>
                  {getStepContent(activeStep)}
                  <MKBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <MKBox />
                    ) : (
                      <MKButton variant="gradient" color="light" onClick={handleBack}>
                        back
                      </MKButton>
                    )}
                    <MKButton
                      disabled={!isCurrentSectionValid}
                      variant="gradient"
                      color="dark"
                      onClick={!isLastStep ? handleNext : handleReview}
                    >
                      {isLastStep ? "Finish and Review" : "next"}
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox></div> 
               </Card>:<>
              {
              <>
              {/* <VacancyFewDetail 
              vacancyData={vacancyData}
              // vacancyData={{basicInfo:postSectionValues,requirements:listingSectionValues,benefits:vehicleSectionValues,location:locationSectionValues,application:applicationSectionValues}}
              /> */}
             
              <MKBox p={2}>
                <MKBox> 
                  <MKBox mt={3} width="100%" display="flex" justifyContent="space-between">
                      <MKButton variant="gradient" color="light" onClick={()=>setIsReviewSection(false)}>
                        Edit
                      </MKButton> 
                    <MKButton
                      // disabled={!isCurrentSectionValid}
                      variant="gradient"
                      color="success"
                      onClick={!isLastStep ? handleNext : postNewJob}
                    >
                      {"Submit"}
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox> </>}
          </>}
       
          </Grid>}

          {loading&&<Grid item xs={12} lg={10}>
            <MKBox p={2}>
                <MKBox> 
                <MKBox mb={1}>
              </MKBox>
              {/* <MKTypography variant="h5" fontWeight="regular" color="secondary">
                This information will describe more about the Vacancy.
              </MKTypography> */}
                  <MKBox mb={1} width="100%" display="flex" justifyContent="center">
                       <LinearProgress />
                       <CircularProgress />
                   </MKBox>
                  <MKBox mt={3} width="100%" display="flex" justifyContent="center">
                      <MKBox mb={1} width="100%" display="flex" justifyContent="center">
                        <MKTypography variant="h5" >
                          Submitting The Vacancy
                        </MKTypography>
                      </MKBox> 
                  </MKBox>
                </MKBox>
              </MKBox> 
            </Grid>}
            
          { vacancySubmittedResponse&&<Grid item xs={12} lg={10}>
            <MKBox p={2}>
                <MKBox>  
              {/* <MKTypography variant="h5" fontWeight="regular" color="secondary">
                This information will describe more about the Vacancy.
              </MKTypography> */} 
                  <MKBox mt={3} width="100%" display="flex" justifyContent="center">
                      <MKBox mb={1} width="100%" display="flex" justifyContent="center">
                        <MKTypography variant="h5" >
                          {vacancySubmittedResponse.message}
                        </MKTypography>
                      </MKBox> 
                  </MKBox>
 
                  <MKBox p={2}>
                  <MKBox mt={3}   width="100%" display="flex" justifyContent="center">
                     {!vacancySubmittedResponse.success&&
                       <MKButton variant="gradient" color="light" onClick={handleReview}>
                        Back To Review
                      </MKButton> }
                    {vacancySubmittedResponse.success&&<MKButton
                      // disabled={!isCurrentSectionValid}
                      variant="gradient"
                      color="success"
                       
                        size={"small"}
                        to={"/Employment/Job-Vacancies"}
                        component={Link}  
                      // onClick={!isLastStep ? handleNext : handleReview}
                    >
                      {"Go To Vacancy List"}
                    </MKButton>}
                  </MKBox> 
                </MKBox> 

                </MKBox>
              </MKBox> 
            </Grid>}

          {/* set vacancySubmittedResponse */}


        </Grid> 
{forceRerender}
      </MKBox> 
  );
}

export default CreateVehicle;
