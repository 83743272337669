 



  import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import {
  BottomNavigationAction,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  Divider,
  Fab,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Skeleton,
  SwipeableDrawer,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { UseAuth } from "Api-Services/useAuth";
import { ContactMail, Home, Label, Navigation, Phone, QuestionAnswer, Rule } from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices";

import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import { DataManagementService } from "Api-Services/dataManagementService";
import { green, grey } from "@mui/material/colors";
 
import { isMobile } from "react-device-detect";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
 
export default function Find() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpened, setDrawerOpened] = React.useState(false);
  const [drawer2Opened, setDrawer2Opened] = React.useState(false);
  const [forceRerender, setForceRerender] = React.useState(false);
  const [jobSearchHistories, setJobSearchHistories] = React.useState(["mesfin", "tutor"]);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const [currentData] = useCurrentUser();
  const { currentUser ,myCurrentProfile } =  currentData;
  const sideBarContent = [
    {
      title: "Posts",
      items: [
        {
          title: "Market Place",
          link: "/Market-Place",
          icon: "local_grocery_store",
        },
        {
          title: "Jobs",
          link: "/jobs",
          icon: "work",
        },
        {
          title: "Properties",
          link: "/Market-Place",
          icon: "apartment",
        },
        {
          title: "Events",
          link: "/Events",
          icon: "event",
        },
        {
          title: "Explore",
          link: "/Explore",
          icon: "explore",
        },
      ],
    },
    // {
    //   title: "Apps",
    //   items: [
    //     {
    //       title: "Dating",
    //       link: "/Dating",
    //       icon: "volunteer_activism",
    //     },
    //     {
    //       title: "Vote",
    //       link: "/Vote",
    //       icon: "ballot",
    //     },
    //   ],
    // },
    {
      title: "Accounts",
      items: [
        {
          title: "Freelancers",
          link: "/Freelancers",
          icon: "badge",
        },
        {
          title: "Employers",
          // title: "Employment Agencies",
          link: "/jobs",
          icon: "handshake",
        },
        {
          title: "Shops",
          link: "/Market-Place",
          icon: "local_grocery_store",
        },
        // {
        //   title: "Popular Busineses",
        //   link: "/Popular-Busineses",
        //   icon: "local_convenience_store",
        // },
        {
          title: "Find Friends",
          link: "/contacts/"+myCurrentProfile?.userName+"/suggestions",
          icon: "person_add",
        },
      ],
    },
    {
      title: "Baally",
      items: [
        {
          title: "Contact Us",
          link: "/pages/support/contact-us",
          icon: "contact_mail",
        },
        // {
        //   title:"About Us",
        //   link:"",
        //   icon:""
        // },
        {
          title: "FAQ",
          link: "/pages/AskQuestion",
          icon: "question_answer",
        },
        {
          title: "Privacy Policy",
          link: "/pages/privacy-policy",
          icon: "rule",
        },
      ],
    },
  ];
 
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
 

  React.useEffect(() => {
    setDrawerOpened(false);
    setDrawer2Opened(false);
  }, [location.pathname]);

  React.useEffect(() => {
    let historiesD = [];

    let jobSearchHistoriesLocalD =
      DataManagementService.getLocalstorageDataSecured("jobSearchHistories");

    if (jobSearchHistoriesLocalD) {
      if (jobSearchHistoriesLocalD.length) {
        historiesD = jobSearchHistoriesLocalD;
      }
    }

    setJobSearchHistories(historiesD);
  }, [currentUser]);
 
  const goToPage = (page) => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate(page);
  };
 
  const navigate = useNavigate();

  const drawer = ( 
      <> 
          {(sideBarContent ? (sideBarContent.length ? true : false) : false) && (
            <>
              {sideBarContent.map((sideBarTopic) => (
                <MKBox sx={{width:"100%", px:2,pt:1}} 
                >
                  <MKTypography variant="h6" > {sideBarTopic.title}</MKTypography>
                   <Grid container spacing={2}>
                  {(sideBarTopic.items ? (sideBarTopic.items.length ? true : false) : false) &&
                    sideBarTopic.items.map((sideBarItem) => (
                        
                      <Grid item xs={6} >  
                      <Paper  onClick={() => {
                            goToPage(sideBarItem.link);
                          }} sx={{ m: 0 ,cursor:"pointer"  ,p:0.5, px:1,height:"auto"}}  elevation={2}>       
                          {/* <Fab variant="extended"  sx={{ m: 0.5  ,p:0.5, px:1,height:"auto"}}
                         > */}
                            {/* <Navigation sx={{ mr: 1 }} /> */}
                            {(
                                  sideBarItem.icon ? (sideBarItem.icon != "" ? true : false) : false
                                ) ? (
                                  <Icon  color="success"   sx={{ fontSize: 40 ,mr: 1 }}>{sideBarItem.icon}</Icon>
                                ) : (
                                  <Label sx={{ mr: 1 }} />
                                )}
                      <MKTypography variant="h6" sx={{color:"gray"}}  >
                            {sideBarItem.title} </MKTypography>
                          {/* </Fab> */}
                          </Paper> 
                    </Grid>       
                    ))} 

                    </Grid>
                  <Divider sx={{ my: 1 }} variant="middle" />
                </MKBox>
              ))}
            </>
          )} 
      </> 
  ); 
  return (
    <Grid container justifyContent="space-between" alignItems="flex-start">
        
        <Grid 
            item
            sm={12}
            xs={12}
            md={4}
            lg={3.5}
            xl={2.3}
            display={{ xs: "12", sm: "12", md: "flex" }}
            >
          </Grid>
        <Grid item 
        // xs={12} lg={(isMobile||((props?.post)))?12:8}
        
        xs={12} sm={12} md={8} lg={5}  
        // sx={{mt:isMobile?0:2}}
        
        >  
        <MKBox sx={{ flexGrow: 1, mb:isMobile?0:7 }}>
          {drawer}
          {forceRerender}
        </MKBox> 
        </Grid>
        <Grid
            item
            sm={12}
            xs={12}
            md={4}
            lg={3.5}
            xl={2.3}
            display={{ xs: "12", sm: "12", md: "flex" }}>
          
        </Grid>
      </Grid>
  );
}

Find.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
