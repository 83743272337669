// @mui material components
import Container from "@mui/material/Container";
import React, { useEffect } from "react";
import imageCompression from "browser-image-compression";
// Images
// import bgImage from "assets/images/bg-rental.jpeg";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import {
  AppBar,
  Avatar,
  Box,
  Chip,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { AddCircle, ArrowBack, Logout, Person, Send } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { CommonServices } from "Api-Services/commonServices";
import { CommonApiServices } from "Api-Services/commonApiServices";
import {  useNavigate } from "react-router-dom";
import MySideBarContent from "examples/SideBarContent/MySideBarContent";
import GeneralSideBarContent from "examples/SideBarContent/GeneralSideBarContent";
import MKButton from "components/MKButton";
import { UseAuth } from "Api-Services/useAuth";
import { isMobile } from "react-device-detect";
import SingleFeedPost from "pages/Posts/FeedPosts/SingleFeedPost";

function PostComments(props) {
  // profile={myCurrentProfile} friendshipTab={friendshipTab}
  const navigate=useNavigate()
  const [currentData] = useCurrentUser();
  const { myFriendships, isUserLoggedIn,myCurrentProfile } = currentData;
  const [forceRerender, setForceRerender] = React.useState(true);
  const [friendshipTab, setFriendshipTab] = React.useState("followings"); 
  const [currentProfile, setCurrentProfile] = React.useState(null);
  const [post, setPost] = React.useState(null);

  useEffect(async () => {
    let profileR = null; 
    if (props.currentGlobalDialogBoxData) {
        console.log("props.currentGlobalDialogBoxData", props.currentGlobalDialogBoxData);
      if (props.currentGlobalDialogBoxData.data) {
        if (props.currentGlobalDialogBoxData.data.post) {
       console.log("props.currentGlobalDialogBoxData post", props.currentGlobalDialogBoxData.data.post);
         setPost(props.currentGlobalDialogBoxData.data.post);
      }
    }
    }
    if (props.currentProfile) {
      profileR = props.currentProfile;
    }
    setCurrentProfile(profileR);
    setForceRerender(!forceRerender);   
  }, [props.currentProfile]);
 

  return (
    <>

<Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {
                props?.onClose();
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Comments
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
          </Toolbar>
        </AppBar>
      </Box>

    <DialogContent sx={{ minHeight:isMobile?"300px": "460px" , p: "0px",m:"0px"}}>

      
   {post&& <SingleFeedPost post={post} componentViewType={1}/>}
      {/* // <div className={postStyles.root}>  */}
      {/* </Dialog>  */}
      {/* </div> */}
    </DialogContent>
      {forceRerender}
  </> );
}

export default PostComments;
