import { 
  Container, 
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { HttpService } from "Api-Services/httpService";
import FilterPosts from "pages/Posts/Sections/filterPosts";
import PostsHeader from "pages/Posts/Sections/PostsHeader";
import * as React from "react";
import { isMobile } from "react-device-detect";  
export default function UserPosts(props) { 
  const [forceRerender, setForceRerender] = React.useState(false);
  const [filteredPostTypes, setFilteredPostTypes] = React.useState([]);
  const [currentData, updateCurrentUser] = useCurrentUser();
  const [currentProfile, setCurrentProfile] = React.useState(props.currentProfile);
  const { isUserLoggedIn,myCurrentProfile } = currentData;
  const [filter, setFilter] = React.useState({}); 

  //////
  
  const { data: postTypes } = useQuery({
    queryKey: ["/api/post/posttype/all"],
    queryFn: async () => {
      return await HttpService.getApiData("/api/post/posttype/all");
    },
  });

  React.useEffect(() => { 
    try {
      let postTypeIdsD=[]
      let id ="null";
      if (props?.currentProfile) {
         id=props?.currentProfile?.id;
      }   
      postTypes?.forEach(element => {
        if(id!=myCurrentProfile?.id){
          if(element?.displayOnHomeCategory){
            postTypeIdsD.push(element.id)
          }
        }
        else{
          postTypeIdsD.push(element.id)
        }
      });
      setFilteredPostTypes(postTypeIdsD)
      let filterD=filter||{}
      filterD["postFilter"]={postTypeIds:postTypeIdsD} 
      filterD["profileFilter"]={id:id} 
      setFilter(filterD)
      setForceRerender(!forceRerender);
    } catch (error) {}
  }, [postTypes, props.currentProfile,myCurrentProfile, isUserLoggedIn]);

  React.useEffect(async () => {
    let id ="null";
    let filterD=filter||{}
    if (props?.currentProfile) {
       id=props?.currentProfile?.id;
    }   
    filterD["profileFilter"]={id:id} 
    setFilter(filterD)
    setForceRerender(!forceRerender);
   }, [props.currentProfile, isUserLoggedIn]);
 
  const onFiltersChange = async (filterData) => {
    setFilter(filterData)
    setForceRerender(!forceRerender);
  };

  return (
    <>
      <Container sx={{ px: "2px" }}>
        
     {filteredPostTypes?.length? <PostsHeader place={"profile"} showCreatePost={props?.currentProfile?.id==myCurrentProfile?.id} allPostTypeIds={filteredPostTypes} filter={filter} onFiltersChange={onFiltersChange}/>:null}
     {filter?  <FilterPosts filter={filter}/>:null }  
      </Container> 
      {forceRerender}
    </>
  );
}
