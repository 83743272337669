// @mui material components
// Material Kit 2 PRO React components

// @mui material components
import Grid from "@mui/material/Grid";
import queryString from "query-string";

import PropTypes from "prop-types";

import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKDatePicker from "components/MKDatePicker";
import MKButton from "components/MKButton";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect } from "react";

import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Card } from "@mui/material";
import { ArrowForwardIosSharp, Inbox, NoteAdd, Receipt, Send, VerifiedUser, Work } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { HttpService } from "Api-Services/httpService";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderBottom: "darkgreen solid",
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, px: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Proposals() {
  // console.log("cities", cities);

  const navigateComp = useNavigate();
  // console.log("cities", cities);

  const [currentData,updateContext] = useCurrentUser();
  const { myJobApplications, jobs, agencyData } = currentData;

  const [agencyJobs, setAgencyJobs] = React.useState(null);
  const [jobsToChoose, setJobsToChoose] = React.useState(jobs);
  const [expanded, setExpanded] = React.useState("");

  const [forceRerender, setForceRerender] = React.useState(false);
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const viewDetails = (id) => {
    let path = "/pages/jobs/job-details?id=" + id; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
    navigateComp(path);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  let {data:recievedProposals} = useQuery({
    queryKey: ["/api/employmentapplicationrequest/recievedproposals"],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/employmentapplicationrequest/recievedproposals");
    },
    // initialData:props?.post
  });

  
  let {data:sentProposals} = useQuery({
    queryKey: ["/api/employmentapplicationrequest/sentproposals"],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/employmentapplicationrequest/sentproposals");
    },
    // initialData:props?.post
  });
  
  let {data:myJobPosts} = useQuery({
    queryKey: ["/api/job/myjobposts"],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/job/myjobposts");
    },
    // initialData:props?.post
  });

  useEffect(() => {
    // console.log("myJobApplications", myJobApplications);
    // console.log(" myJobSeekerInformation", myJobSeekerInformation);
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch) {
        if (locationSearch.search) {
          searchByKeyword(locationSearch.search);
        }
      }
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    setForceRerender(!forceRerender);
  }, [myJobApplications]);

  useEffect(() => {
    // console.log("agencyData",agencyData)
    let filterdJobs = [];
    if (agencyData) {
      if (agencyData.jobsId) {
        let allagencyjobs = agencyData.jobsId.split(",");
        filterdJobs = jobs.filter((jobD) => {
          let res = false;
          allagencyjobs.forEach((element) => {
            element = parseInt(element);
            if (element) {
              if (element == jobD.id) {
                res = true;
              }
            }
          });
          return res;
        });
        setAgencyJobs(filterdJobs);
        setJobsToChoose(filterdJobs);
      }
    }
    setForceRerender(!forceRerender);
  }, [agencyData, jobs]);

  const parseDate = (dt) => {
    let d = new Date(dt);
    let m = d.getMonth() + 1;
    let dateD = m + "/" + d.getDate() + "/" + d.getFullYear();
    return dateD;
  };

  const truncateString = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          let btn = lableName ? (
            <Button
              variant="text"
              onClick={() => {
                setMoreText({ content: str, title: lableName });
                setOpenedDialog("moreText");
                setForceRerender(!forceRerender);
              }}
            >
              {"View more"}
            </Button>
          ) : null;
          let txt = str.slice(0, num) + "...";
          res = (
            <>
              {parse(txt)} {btn}
            </>
          );
        } else {
          res = <>{parse(res)}</>;
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };

  function SentProposalsComp() {
    try {
      return (
        <>
          {sentProposals && (
            <div style={{ marginBottom: "10px" }}>
              {sentProposals.map(
                (jobVacancy) =>
                  jobVacancy.EmploymentJobVacancy && (
                    <div style={{ marginBottom: "10px" }}>
                      <Accordion
                        expanded={expanded === jobVacancy.id}
                        onChange={handleChange(jobVacancy.id)}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          // expandIcon={<ExpandMoreOutlined/>}
                        >
                          {/* <MKBox component="section"> */}
                          {/* <Container> */}
                          <Grid container>
                            <Grid item xs={12} lg={9} sx={{ mt: 2 }}>
                              <Grid
                                container
                                spacing={{ xs: 0, lg: 0 }}
                                // sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}
                              >
                                <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                                  <MKTypography
                                    display="block"
                                    variant="h6"
                                    fontWeight="bold"
                                    // color="text"
                                    mb={1}
                                  >
                                    {truncateString(jobVacancy.EmploymentJobVacancy.title, 65)}
                                  </MKTypography>
                                  <MKTypography
                                    display="block"
                                    variant="button"
                                    fontWeight="regular"
                                    // color="text"
                                    mb={1}
                                  >
                                    Applied date: {parseDate(jobVacancy.createdAt)}
                                  </MKTypography>
                                </Grid>

                                <Grid item xs={12} lg={4} sx={{ mt: 2 }}>
                                  <MKTypography
                                    display="block"
                                    variant="button"
                                    fontWeight="regular"
                                    color="text"
                                    mb={1}
                                  >
                                    Status
                                  </MKTypography>
                                  <MKTypography
                                    display="block"
                                    variant="button"
                                    fontWeight="regular"
                                    // color="text"
                                    mb={1}
                                  >
                                    {jobVacancy.statusName}
                                  </MKTypography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                              {/* <MKButton
                          variant="gradient"
                          disabled={loading || !professionTitle}
                          color="success"
                          onClick={() => {
                            search();
                          }}
                          fullWidth
                        > 
                          Apply Now
                        </MKButton> */}
                              <Stack direction="row" m={1} spacing={2}>
                                <MKButton
                                  size="small"
                                  variant="outlined"
                                  // disabled={loading || !professionTitle}
                                  color="success" 
                                  
                                  onClick={() => {
                                    let data = {
                                      dialogId: "PostDetailsDialogBox",
                                      data: { post: jobVacancy },
                                    };
                                    updateContext("currentGlobalDialogBoxData", data);
                                  }}
                                  fullWidth
                                >
                                  View Job Details
                                </MKButton>
                              </Stack>
                              <Stack direction="row" m={1} spacing={2}>
                                <MKButton
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                  // disabled={loading || !professionTitle}
                                  color="primary"
                                              
                                  onClick={() => {
                                    let data = {
                                      dialogId: "PostDetailsDialogBox",
                                      data: { profile: jobVacancy?.Profile },
                                    };
                                    updateContext("currentGlobalDialogBoxData", data);
                                  }} 
                                   
                                >
                                  Contact Employer
                                </MKButton>
                              </Stack>
                            </Grid>

                            {/* <Grid item xs={12} lg={3} sx={{ mt: 4 }}>
                      <Box sx={{ m: 1, position: "relative" }}>
                        <MKButton
                          variant="gradient"
                          disabled={loading || !professionTitle}
                          color="info"
                          onClick={() => {
                            search();
                          }}
                          fullWidth
                        > 
                          search
                        </MKButton>
                        {loading && (
                          <CircularProgress
                            size={24}
                            sx={{ 
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                          />
                        )}
                      </Box>
                    </Grid> */}
                          </Grid>
                          {/* </Container> */}
                          {/* </MKBox> */}
                        </AccordionSummary>
                        <AccordionDetails>
                          <MKBox ml={4}>
                            <MKTypography variant="h6" color="text">
                              Your Comment:
                            </MKTypography>
                            <MKTypography variant="body1" fontWeight="light" color="text">
                              {parse(jobVacancy.description)}
                            </MKTypography>
                            {/* <Button
                              size="small"
                              // variant={Link}
                              // disabled={loading || !professionTitle}
                              // color="success"
                              onClick={() => {
                                viewDetails(jobVacancy.employmentJobVacancyId);
                              }}
                            >
                              Job Details
                            </Button> */}
                          </MKBox>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  )
              )}
            </div>
          )}
        </>
      );
    } catch (error) {
      console.log("error", error);
      return <></>;
    }
  };
 
  function MyJobPostsComp(){
    try {
      return (
        <>
          {myJobPosts && (
            <div style={{ marginBottom: "10px" }}>
              {myJobPosts?.Posts?.map(
                (jobVacancy) =>
                  jobVacancy.EmploymentJobVacancy && (
                    <div >
                      <Accordion
                        expanded={expanded === jobVacancy.id}
                        onChange={handleChange(jobVacancy.id)}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          // expandIcon={<ExpandMoreOutlined/>}
                        >
                          {/* <MKBox component="section"> */}
                          {/* <Container> */}
                          <Grid container>
                            <Grid item xs={12} lg={9} >
                              <Grid
                                container
                                spacing={{ xs: 0, lg: 0 }}
                                // sx={{ pt: 2, pb: 3, px: 2, mx: "auto" }}
                              >
                                <Grid item xs={12} lg={6} >
                                  <MKTypography
                                    display="block"
                                    variant="h6"
                                    fontWeight="bold"
                                    // color="text"
                                    mb={1}
                                  >
                                    {truncateString(jobVacancy.EmploymentJobVacancy.title, 65)}
                                  </MKTypography>
                                  <MKTypography
                                    display="block"
                                    variant="caption"
                                    fontWeight="regular"
                                    // color="text"
                                    mb={1}
                                  >
                                    Posted date: {parseDate(jobVacancy.createdAt)}
                                  </MKTypography>
                                  <MKTypography
                                    display="block"
                                    variant="caption"
                                    fontWeight="regular"
                                    // color="text"
                                    mb={1}
                                  >
                                    Dead Line: {parseDate(jobVacancy?.EmploymentJobVacancy?.deadLineDateTime)}
                                  </MKTypography>
                                </Grid>

                                <Grid item xs={12} lg={4}  >
                                  <MKTypography
                                    display="block"
                                    variant="button"
                                    fontWeight="regular"
                                    color="text"
                                    mb={1}
                                  >
                                    Status
                                  </MKTypography>
                                  <MKTypography
                                    display="block"
                                    variant="button"
                                    fontWeight="regular"
                                    // color="text"
                                    mb={1}
                                  >
                                    {jobVacancy?.isJobClosed?"Closed":"Running"}
                                  </MKTypography>
                                  <MKTypography
                                    display="block"
                                    variant="button"
                                    fontWeight="regular"
                                    // color="text"
                                    mb={1}
                                  >
                                    {jobVacancy?.EmploymentJobVacancy?.EmploymentApplicationRequests?.length+" Proposal(s)"}
                                  </MKTypography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} lg={3} >
                              
                              <Stack direction="row" >
                                <Button
                                  fullWidth
                                  size="small"
                                  // variant="outlined"
                                  // disabled={loading || !professionTitle}
                                  color="primary"
                                  onClick={() => {
                                    
                                      let path
                                      path="/profile/jobs/"+jobVacancy?.EmploymentJobVacancy?.id+"/proposals"; 
                                      navigateComp(path);
                                  }}
                                >
                                  View Proposals
                                </Button>
                              </Stack>
                              <Stack direction="row"  >
                                <Button
                                  size="small"
                                  // variant="outlined"
                                  // disabled={loading || !professionTitle}
                                  color="primary"
                                  onClick={() => { 
                                    let data = {
                                      dialogId: "PostDetailsDialogBox",
                                      data: { post: jobVacancy  },
                                    };
                                    updateContext("currentGlobalDialogBoxData", data);
                                  }}
                                  fullWidth
                                >
                                  View Job Details
                                </Button>
                              </Stack>
                            </Grid>
 
                          </Grid>
                          {/* </Container> */}
                          {/* </MKBox> */}
                        </AccordionSummary>
                        <AccordionDetails>
                          <MKBox ml={4}>
                            <MKTypography variant="h6" color="text">
                               title:
                            </MKTypography>
                            <MKTypography variant="body1" fontWeight="light" color="text">
                              {parse(jobVacancy.title)}
                            </MKTypography>
                            {/* <Button
                              size="small"
                              // variant={Link}
                              // disabled={loading || !professionTitle}
                              // color="success"
                              onClick={() => {
                                viewDetails(jobVacancy.employmentJobVacancyId);
                              }}
                            >
                              Job Details
                            </Button> */}
                          </MKBox>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  )
              )}
            </div>
          )}
        </>
      );
    } catch (error) {
      console.log("error", error);
      return <></>;
    }
  };

  return (
    <Container >
      <MKBox component="section" >
        <>
          <>
            <Grid container mb={2}>
              <Grid item xs={12} lg={5} sx={{ mt: 2 }} justifyContent={"flex-end"}>
                <MKTypography variant="h3" opacity={0.4}>
                  {"Job Proposals"}
                </MKTypography>
              </Grid>
            </Grid> 

            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                  <Tab label="Sent Proposals" {...a11yProps(1)} icon={<Work />} />
                  <Tab label="Recieved Proposals" {...a11yProps(0)} icon={<Work />} />
                </Tabs>
              </Box>

              <CustomTabPanel value={value} index={0}>
               <SentProposalsComp />  
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <MyJobPostsComp /> 
              </CustomTabPanel>
            </Box>

            {/* </Card> */}

            <Dialog
              open={openedDialog == "moreText"}
              onClose={() => {
                setOpenedDialog("");
              }}
            >
              {moreText && (
                <>
                  {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
                  {moreText.content && (
                    <DialogContent>
                      <DialogContentText>{parse(moreText.content)}</DialogContentText>
                    </DialogContent>
                  )}
                </>
              )}
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenedDialog("");
                  }}
                >
                  close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        </>
      </MKBox>
      {forceRerender}
    </Container>
  );
}

export default Proposals;
