// @mui material components
import Container from "@mui/material/Container";
import React, { useEffect } from "react";
import imageCompression from "browser-image-compression";
// Images
// import bgImage from "assets/images/bg-rental.jpeg";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import {
  AppBar, 
  Box, 
  Divider, 
  IconButton,  
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material"; 
import { 
  ArrowBack, 
} from "@mui/icons-material";   
import { CommonServices } from "Api-Services/commonServices";  
// {"& .css-1agvk75" :{
//   minHeight: "250px"}}
function SwitchProfileHeader(props) {
  // profile={myCurrentProfile} friendshipTab={friendshipTab}
  const [forceRerender, setForceRerender] = React.useState(true);
  const [friendshipTab, setFriendshipTab] = React.useState("followings");

  useEffect(async() => {
    let friendshipTabR="following"
    let profileR=null 

    if(props.friendshipTab){
      friendshipTabR=props.friendshipTab
        } 
    setFriendshipTab(friendshipTabR) 
    setForceRerender(!forceRerender) 
    
    // console.log("FriendshipHeader profileR",profileR)
  }, [props.currentProfile, props.friendshipTab]);


  return (
    <>  
    <Container  >
      <AppBar position="static" sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              props?.onClose();
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
           Account / Profile
          </Typography>  
        </Toolbar>
        <Divider sx={{ my: "0rem" }} />
      </AppBar> 
      
      {/* <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Tabs value={friendshipTab} onChange={(e,v)=>{ 
        setFriendshipTab(v)
        props.changeFriendshipTab(v)}} centered>
        <Tab label="Following" value={"followings"}  />
        <Tab label="Followers"  value={"followers"} />
        <Tab label="Suggestions"  value={"suggestions"} />
      </Tabs>
    </Box> */}
      </Container>  
      {forceRerender} 
    </>
  );
}

export default SwitchProfileHeader;
