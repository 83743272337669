 

import parse from "html-react-parser"; 
import MKTypography from "components/MKTypography";
import React, { useState, useEffect, useRef } from "react";

// Rental page components
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { Link, useNavigate, useParams } from "react-router-dom";
// Direct React component imports
import { defaultStyles, FileIcon } from 'react-file-icon';
import { 
  Button,
  Card,
  CardActions,
  CardContent, 
  CardMedia,
  Chip, 
  Box,   
  Grid, 
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  Divider,
  ListItemButton,
  ImageListItemBar,
} from "@mui/material";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
 
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { CommonServices } from "Api-Services/commonServices";
import { HttpService } from "Api-Services/httpService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { InView } from "react-intersection-observer";
import { makeStyles } from "@mui/styles"; 
import MKButton from "components/MKButton";
import TruncateMarkup from "react-truncate-markup";
import { Close, Delete, Description, Details, Edit } from "@mui/icons-material"; 
import { PhotoProvider,  PhotoSlider,  PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { isMobile } from "react-device-detect";
import Avatar from "assets/theme/components/avatar";
// import SwiperSlide from 'swiper/react';

// Define the custom styles using makeStyles
const useStyles = makeStyles(() => ({
  swiperButtonPrev: {
    color: "black",
    bottom: "150px",
    top: "auto",
    transform: "translateY(0)",
    width: "30px",
    height: "30px",
    left: "-54px", // Adjust the position of the left arrow
  },
  swiperButtonNext: {
    color: "black",
    bottom: "150px",
    top: "auto",
    transform: "translateY(0)",
    width: "30px",
    height: "30px",
    right: "-54px", // Adjust the position of the right arrow
  },
}));


const PortfolioItem = (props) => {
  const myCurrentProfile=props?.myCurrentProfile
  const queryClient=useQueryClient()
  
  const [photoSliderVisible,setPhotoSliderVisible] = useState(false);
  const [photoSliderIndex,setPhotoSliderIndex] = useState(0);
  const [currentAttachment,setCurrentAttachment] = useState(null);
  const [openedDialog,setOpenedDialog] = useState(false);

  let {data:portfolio} = useQuery({
    queryKey: ["/api/portfolio/find/"+props?.portfolio?.id],
    queryFn: async () => {
      return await HttpService.getApiData("/api/portfolio/find/"+props?.portfolio?.id);
    },
    initialData:props?.portfolio
  });
 
  const onDeleteAttachment= async(itemData)=>{
    await HttpService.getApiData("/api/portfolio/"+props?.portfolio?.id+"/attachment/"+itemData?.id+"/remove");
    queryClient.invalidateQueries( ["/api/portfolio/find/"+props?.portfolio?.id]);
  }
   

  return (<>
    <Card sx={{ mb: 2, minHeight:  isMobile?"100%":"calc(-2rem + 100vh)", }}>
    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
      <Grid item  xs={12} lg={6} sx={{ mb: 2 }}>
        <Card sx={{ mb: 2 , 
           }}>
          {portfolio?.Thumbnail && (  
            <CardMedia
              component="img"
              alt={portfolio?.title || 'Thumbnail Image'}
              height="160"
              image={portfolio?.Thumbnail
                ? CommonServices?.getPostAttachments({ attachments: [portfolio?.Thumbnail], thumbnail: true })[0]
                : ""}
            /> 
          )}
          <CardContent> 
              <MKTypography variant="body2">
                {portfolio?.employerName || ""}
              </MKTypography> 
              <MKTypography variant="subtitle1">
                {portfolio?.title ? "  " + portfolio?.title : ""}
              </MKTypography> 
    
              <MKTypography variant="subtitle2" color="text.secondary">
                {portfolio?.Address?.Country?.name || ""}
                {portfolio?.Address?.City?.name ? " - " + portfolio?.Address?.City?.name : ""}
                <small style={{ color: "grey" }}>
                  {" " + (CommonServices?.parseDate(portfolio?.startDate) || "") + "-" + (CommonServices?.parseDate(portfolio?.endDate) || "")}
                </small>
              </MKTypography> 
    
              <MKTypography variant="subtitle2" color="text.secondary">
                {portfolio?.description}
              </MKTypography> 
          </CardContent>
{/* 
          <CardActions> 
            {myCurrentProfile?.id === portfolio?.ProfileId && (
              <Button
                size="small"
                onClick={() => {
                  if (onEditClicked) {
                    onEditClicked(portfolio);
                  } else {
                    navigate("/profile/portfolio");
                  }
                }}
              >
                Edit
              </Button>
            )}
          </CardActions> */}
        </Card>
      </Grid> 
      <Grid item xs={12} lg={6} sx={{ p: 2 }}>
      {portfolio?.Attachments && portfolio?.Attachments?.length > 0 && (
          <div>
        
        {/* List document attachments */}
      
            <Divider sx={{ mt: 2, mb: 1 }} />
        <MKTypography variant="subtitle2" color="text.secondary" sx={{ my : 2 }}>
        document Attachments:
            </MKTypography>
            <List>
              {portfolio?.Attachments?.map((attachment, index) =>(!attachment.fileType.startsWith('image/'))&& (
                <ListItem
                 secondaryAction={ (myCurrentProfile?.id==portfolio?.ProfileId)?
                  <>{isMobile?
                 <Delete color="warning"  onClick={()=>{ 
                  // deletefile()
                setCurrentAttachment(attachment)
                setOpenedDialog("deleteAttachment")
                  }} />:
                <Button color="primary" onClick={()=>{ 
                  // deletefile()
                  setCurrentAttachment(attachment)
                  setOpenedDialog("deleteAttachment")
                  }} size="small">Delete </Button> 
                 }</>:null}
                  key={attachment.id}>
                {/* <ListItemButton  > */}
                  <ListItemIcon  component={Link} to={attachment?.url} target="blank" sx={{ width: "18px", marginBottom:"1px", marginRight: "1px"}}>
                     <FileIcon radius={2} extension={attachment?.title?(CommonServices?.getExtension(attachment?.title)):"docx"} {...defaultStyles.docx} /> 
                  </ListItemIcon>
                  <ListItemText 
                   primary={
                    <TruncateMarkup
                         lines={2}
                         // onTruncate={(wasTruncated) => setIsTruncated(wasTruncated)}
                       >
                    <Typography  component={Link} to={attachment?.url} target="blank">{attachment?.title?attachment?.title:('Document '+ (index+1)) }</Typography> 
                    </TruncateMarkup> }
               />
                  {/* </ListItemButton> */}
                </ListItem>
              ))}
            </List>     
            <Divider sx={{ mt: 2, mb: 1 }} />
            <MKTypography variant="subtitle2" color="text.secondary" sx={{ my : 2 }}>
              Image Attachments:
            </MKTypography>
            

            <PhotoSlider
                        images={CommonServices?.getPostAttachments({attachments:portfolio?.Attachments})?.map((item) => ({
                          src: item,
                          key: item,
                        }))}
                        visible={photoSliderVisible}
                        onClose={() => {
                          setPhotoSliderVisible(false);
                          // navigateComp(-1)
                        }}
                        index={photoSliderIndex}
                        onIndexChange={setPhotoSliderIndex}
                      />            {portfolio?.Attachments?.length > 0 && (
          <ImageList variant="masonry" cols={3} gap={8}>
            {portfolio.Attachments.map((attachment,index) =>((attachment.fileType.startsWith('image/')))&& (
          //  <PhotoView  src={CommonServices?.getPostAttachments({ attachments: [attachment] })[0]}
          //        >
              <ImageListItem key={attachment.id}>
              {(myCurrentProfile?.id==portfolio?.ProfileId)?<ImageListItemBar
              sx={{ maxWidth:248,
                background:
                  'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                  'rgba(0,0,0,0.3) 40%, rgba(0,0,0,0) 60%)',
              }}
              title={attachment?.title?attachment?.title:("attachment "+index+1)}
              position="below"
              actionIcon={
                <IconButton
                  sx={{ color: 'white' }}
                  aria-label={`${attachment?.title}`}

                  onClick={()=>{
                    setCurrentAttachment(attachment)
                    setOpenedDialog("deleteAttachment")
                  }}
                >
                  <Delete sx={{ color: 'red' }}/>
                </IconButton>
              }
              actionPosition="right"
            />:null}
                <img  onClick={()=>{
                     setPhotoSliderIndex(index);
                     setPhotoSliderVisible(true);
                }}
                  srcSet={`${CommonServices?.getPostAttachments({ attachments: [attachment] })[0]}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  src={`${CommonServices?.getPostAttachments({ attachments: [attachment] })[0]}?w=248&fit=crop&auto=format`}
                  // src={CommonServices?.getPostAttachments({ attachments: [attachment] })[0]}
                  alt={attachment?.title || "Attachment"}
                  loading="lazy"
                />
              </ImageListItem>
                // {/* </PhotoView> */}
            ))}
          </ImageList>
        )}
 
          </div>
        )}
      </Grid>  
   </Grid>  
    </Card>

    <Dialog  
        onClose={()=>setOpenedDialog(false)}
        aria-labelledby="customized-dialog-title"
        open={openedDialog=="deleteAttachment"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                  {"Delete Attachment"} 
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={()=>setOpenedDialog(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers> 
          
          {"Do you want to delete the attachment?"}
           </DialogContent>
        <DialogActions> 
          <MKButton variant="outlined" color="primary"  onClick={()=>{
            onDeleteAttachment(currentAttachment)
            setOpenedDialog(false)}}>
          Yes
          </MKButton> 
          <Button onClick={()=>{ setOpenedDialog(false) }} size="small">No </Button>  
        </DialogActions>
      </Dialog>
      

  </>);
};


function PortfoliosList(props) {
 const queryClient=useQueryClient()
  const classes = useStyles(); // Use the defined styles
  const [currentData,updateContext] = useCurrentUser();
  const [forceRerender,setForceRerender] = useState(false);
  const [openedDialog,setOpenedDialog] = useState(false);
  const [currentPortfolio,setCurrentPortfolio] = useState(null);
  const {   myCurrentProfile } = currentData;
  const navigate = useNavigate();
  
  let {data:Portfolios} = useQuery({
    queryKey: ["/api/portfolio/getbyprofileid/"+props?.profileId],
    queryFn: async () => {
      return await HttpService.getApiData("/api/portfolio/getbyprofileid/"+props?.profileId);
    },
  });
 
  const onDeleteListItem= async(itemData)=>{
    await HttpService.getApiData("/api/portfolio/delete/"+itemData?.id);
    queryClient.invalidateQueries( ["/api/portfolio/getbyprofileid/"+props?.profileId]);
  }
  
  
return <>  
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item sx={{ mb: 2 }}>
          <MKTypography variant="subtitle2">{Portfolios?.length||"No "} Portfolios</MKTypography>
        </Grid> 
      </Grid>  
        <Grid container spacing={1}  direction="row"  alignItems="flex-start">
            {Portfolios?.map((portfolio,index)=>(props?.isOverview?(index<2):true)&& 
            <Grid item xs={props?.isOverview?6:12} lg={6} sx={{ mb: 2 }}>
              <Card >
          <CardMedia
            component="img"
            alt={"portfolio "+(index+1)}
            height="160"
            image={portfolio?.Thumbnail?CommonServices?.getPostAttachments({attachments:[portfolio?.Thumbnail],thumbnail:true})[0]:""}
          />
          <CardContent> 
           
               <TruncateMarkup
                    lines={props?.isOverview?2:15}
                    // onTruncate={(wasTruncated) => setIsTruncated(wasTruncated)}
                  >
                    <MKTypography variant="body2"> 
                   { portfolio?.title?"  "+portfolio?.title+"":""}

                    </MKTypography> 
                </TruncateMarkup>
                {props?.isOverview?null:<>
               <TruncateMarkup
                    lines={props?.isOverview?1:15}
                    // onTruncate={(wasTruncated) => setIsTruncated(wasTruncated)}
                  > 
                    <MKTypography variant="body2">
                    { portfolio?.employerName?portfolio?.employerName:""}
                      </MKTypography>
                </TruncateMarkup>
                <TruncateMarkup
                    lines={props?.isOverview?1:5}
                    // onTruncate={(wasTruncated) => setIsTruncated(wasTruncated)}
                  >
                 <MKTypography variant="subtitle2" color="text.secondary">
                   { portfolio?.Address?.Country?.name?portfolio?.Address?.Country?.name:""}
                   { portfolio?.Address?.City?.name?" - "+portfolio?.Address?.City?.name:""}
                   {<small style={{color:"grey"}} > {" "+(CommonServices?.parseDate(portfolio?.startDate)||"")+"-"+(CommonServices?.parseDate(portfolio?.endDate)||"" )} </small>}
                  </MKTypography> 
                 </TruncateMarkup>
                    {/* <TruncateMarkup
                    lines={1}
                    // onTruncate={(wasTruncated) => setIsTruncated(wasTruncated)}
                  > */}
                  <MKTypography variant="body2" color="text.secondary">
                   { portfolio?.description} 
                   </MKTypography>
                  {/* </TruncateMarkup> */}
                  </>}
          </CardContent>
          <CardActions>
           <Button onClick={()=>{
            setCurrentPortfolio(portfolio)
            setOpenedDialog("portfolioDetails")
            setForceRerender(!forceRerender)
            }} size="small">View </Button>  
          {(myCurrentProfile?.id==props?.profileId)?<>
                <Button
                  size={"small"}
                  onClick={() => {
                    try {
                    if(props?.onEditClicked){
                      props?.onEditClicked(portfolio) 
                    }
                    else navigate("/profile/portfolio");
                    } catch (error) {
                    }
                  }} 
                >
                  Edit 
                </Button> 
                </>:null
              }
          </CardActions>
              </Card>
            </Grid> )}
       </Grid> 

       <Dialog fullScreen
        onClose={()=>setOpenedDialog(false)}
        aria-labelledby="customized-dialog-title"
        open={openedDialog=="portfolioDetails"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                  {currentPortfolio?.title} 
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={()=>setOpenedDialog(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers> 
          {currentPortfolio?<PortfolioItem myCurrentProfile={myCurrentProfile}  portfolio={currentPortfolio}/>:null}
        </DialogContent>
        <DialogActions>
          
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item sx={{ mb: 2 }}>

        {(myCurrentProfile?.id==props?.profileId)?<>
        <Button
                  size={"small"}
                  onClick={() => {
                    try {
                    if(props?.onEditClicked){
                      props?.onEditClicked(currentPortfolio) 
                    }
                    else navigate("/profile/portfolio");
                    } catch (error) {
                    }
                  }} 
                >
                  Edit 
                </Button> 
          <Button onClick={()=>{ 
            setOpenedDialog("deletePortfolio") 
            }} size="small">Delete </Button> 
            </>:null}
        </Grid>

        <Grid item sx={{ mb: 2 }}>
          <MKButton variant="outlined" color="primary"  onClick={()=>setOpenedDialog(false)}>
            close
          </MKButton>
        </Grid>
        </Grid>
        </DialogActions>
      </Dialog>
      
      <Dialog  
        onClose={()=>setOpenedDialog("portfolioDetails")}
        aria-labelledby="customized-dialog-title"
        open={openedDialog=="deletePortfolio"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                  {"Delete portfolio"} 
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={()=>setOpenedDialog(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers> 
          {"Do you want to Delete the portfolio?"}
          </DialogContent>
        <DialogActions>
           
          <MKButton color="primary"  onClick={()=>{
            onDeleteListItem(currentPortfolio) 
            setOpenedDialog(false)}}>
           Confirm Delete
          </MKButton>  
          <MKButton variant="outlined"   color="primary"  onClick={()=>{
            setOpenedDialog("portfolioDetails") }}>
           Cancel
          </MKButton>  
        </DialogActions>
      </Dialog>
      {forceRerender}
  </>
}

export default PortfoliosList;
