// This optional code is used to register a service worker.
// register() is not called by default.

import { HttpService } from "Api-Services/httpService";

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA
/////////////////
// change back

// line 35, if block

//////////
//// YOUTUBE LINK TUTORIAL 
//https://www.youtube.com/watch?v=lie7_DVxNBY&list=PL8p2I9GklV46NFHdQMFBjXvxwVqtJpa2N&index=5

const applicationServerPublicKey= 'BMZ0LwG4OIVjL6ryZMkysQoOHAO_4xjCU0eicI7nVWJlzlL-WjvFZXHNq0em5y8mvC70tXkAv-Q39EfNiRnW-5o' 
const applicationServerPrivateKey= '_hh2wv7FdLNjndMnOsZSuc_PXC7icXHDEUyJXhr7kbc'  /////this has to be hidden
const pushButton = document.querySelector('.js-push-btn');

let isSubscribed = false;
let swRegistration = null;


const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );
  
  export function register(config) {
    // console.log("register running");
    if ('serviceWorker' in navigator) {
      // if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // console.log("serviceWorker in navigator running");

      // The URL constructor is available in all browsers that support SW.
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
      if (publicUrl.origin !== window.location.origin) {
        // Our service worker won't work if PUBLIC_URL is on a different origin
        // from what our page is served on. This might happen if a CDN is used to
        // serve assets; see https://github.com/facebook/create-react-app/issues/2374
        return;
      }
  
      window.addEventListener('load', () => {
        // console.log("load running");
        const swUrl_Local = `service-worker_Local.js`;
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
        if (isLocalhost) {
          // console.log("isLocalhost running"); 
          // This is running on localhost. Let's check if a service worker still exists or not.
          checkValidServiceWorker(swUrl_Local, config);
          // registerValidSW(swUrl, config);
  
          // Add some additional logging to localhost, pointing developers to the
          // service worker/PWA documentation.
          navigator.serviceWorker.ready.then(() => {
            // console.log(
            //   'This web app is being served cache-first by a service ' +
            //     'worker. To learn more, visit https://cra.link/PWA'
            // );
          });
        } else {
          // Is not localhost. Just register service worker
          registerValidSW(swUrl, config);
        }
      });
    }
  }
  
  function registerValidSW(swUrl, config) {
    // console.log("registerValidSW running"); 
    navigator.serviceWorker
      .register(swUrl)
      .then((registration) => 
        {
    // console.log("registration running"); 
              swRegistration = registration;
              initializeUI();

        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // At this point, the updated precached content has been fetched,
                // but the previous service worker will still serve the older
                // content until all client tabs are closed.
                // console.log(
                //   'New content is available and will be used when all ' +
                //     'tabs for this page are closed. See https://cra.link/PWA.'
                // );
  
                // Execute callback
                if (config && config.onUpdate) {
                  config.onUpdate(registration);
                }
              } else {
                // At this point, everything has been precached.
                // It's the perfect time to display a
                // "Content is cached for offline use." message.
                // console.log('Content is cached for offline use.');
  
                // Execute callback
                if (config && config.onSuccess) {
                  config.onSuccess(registration);
                }
              }
            }
          };
        };
      })
      .catch((error) => {
        console.error('Error during service worker registration:', error);
      });
  }
  
  function checkValidServiceWorker(swUrl, config) {
    // console.log('checkValidServiceWorker.');
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl, {
      headers: { 'Service-Worker': 'script' },
    })
      .then((response) => {
        // console.log('checkValidServiceWorker  fetch(swUrl.');

        // Ensure service worker exists, and that we really are getting a JS file.
        const contentType = response.headers.get('content-type');
        if (
          response.status === 404 ||
          (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // console.log('checkValidServiceWorker  response.status === 404 ');

          // No service worker found. Probably a different app. Reload the page.
          navigator.serviceWorker.ready.then((registration) => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          // Service worker found. Proceed as normal.
          registerValidSW(swUrl, config);
        }
      })
      .catch(() => {
        // console.log('No internet connection found. App is running in offline mode.');
      });
  }
  
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          registration.unregister();
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }

 

  ////////////////////
  ////////////////////
  ////////////////////
  ////////////////////
 // Set the initial subscription value
    
function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function updateBtn() {
  // console.log("Notification.permission ",Notification.permission )
  if (Notification.permission === 'denied') {
    // pushButton.textContent = 'Push Messaging Blocked.';
    // pushButton.disabled = true;
    updateSubscriptionOnServer(null);
    return;
  }

  if (isSubscribed) {
    // pushButton.textContent = 'Disable Push Messaging';
  } else {
    // pushButton.textContent = 'Enable Push Messaging';
  }

  // pushButton.disabled = false;
}

async function updateSubscriptionOnServer(subscription,old_subscription) {
  // TODO: Send subscription to application servers
    let data= JSON.stringify(subscription)
    let data_old_subscription= JSON.stringify(old_subscription)
  // console.log("subscription",data) 
  // console.log("old_subscription",data_old_subscription) 
  const subscriptionJson = document.querySelector('.js-subscription-json');
  const subscriptionDetails =
    document.querySelector('.js-subscription-details');

    // await HttpService.postApiData("/api/notification/pushnotification/subscribe",{subscription:data})
  // if (subscription) {
  //   subscriptionJson.textContent = JSON.stringify(subscription);
  //   subscriptionDetails.classList?.remove('is-invisible');
  // } else {
  //   subscriptionDetails.classList?.add('is-invisible');
  // }
}

function subscribeUser() {
  // // console.log('subscribeUser running.');

  // const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
  // swRegistration.pushManager.subscribe({
  //   userVisibleOnly: true,
  //   applicationServerKey: applicationServerKey
  // })
  // .then(function(subscription) { 
  //   // console.log('subscribe subscription', subscription);
  //   updateSubscriptionOnServer(subscription); 
  //   isSubscribed = true;

  //   updateBtn();
  // })
  // .catch(function(err) {
  //   // console.log('Failed to subscribe the user: ', err);
  //   updateBtn();
  // });
}

function unsubscribeUser() {
  // swRegistration.pushManager.getSubscription()
  // .then(function(subscription) {
  //   // console.log('getSubscription subscription', subscription);
  //   if (subscription) {
  //     return subscription.unsubscribe();
  //   }
  // })
  // .catch(function(error) {
  //   // console.log('Error unsubscribing', error);
  // })
  // .then(function(subscription) {
  //   // updateSubscriptionOnServer(null,subscription);
  //   // console.log('User is unsubscribed., subscription', subscription);
  //   isSubscribed = false;
  //   updateBtn();
  // });
}

function initializeUI() {
  // pushButton.addEventListener('click', function() {
  //   pushButton.disabled = true;
    // if (isSubscribed) {
    //   unsubscribeUser();
    // } else {
    //   subscribeUser();
    // }
  // });

  // try {
    // console.log("initializeUI running"); 
          
  // Set the initial subscription value
  // swRegistration.pushManager.getSubscription()
  // .then(function(subscription) {
  //   // console.log("getSubscription running",subscription); 

  //   isSubscribed = !(subscription === null);

  //   updateSubscriptionOnServer(subscription);

  //   if (isSubscribed) {
  //     // console.log('User IS subscribed.');
  //   } else {
  //     // console.log('User is NOT subscribed.');
  //   }

  //   updateBtn();
  // }) 
  //       } catch (error) {
          
  //   // console.log("error running on registerValidSW",error); 
  //       }
}

document.addEventListener("visibilitychange", (e) => {
         
  // use the document's hidden property to check if the current tab is active!
    
      //  console.log("browser tab e",e)
 if (e.target.hidden) {
  // console.log("--------self browser tab is hidden </br>") 
  } else {
      //  console.log("--------self Browser tab is again active! </br>")
  }
});
 



  ////////////////////
  ////////////////////
  ////////////////////
  ////////////////////
  