import { Box, CircularProgress, LinearProgress } from "@mui/material";
import JobApplication from "pages/Search/SearchJobs/Job/JobApplication";
import Contracts from "pages/UserProfile/Components/Contracts/Contracts";
import Proposals from "pages/UserProfile/Components/Proposals/Proposals";
import SingleJobProposals from "pages/UserProfile/Components/Proposals/SingleJobProposals";
import { lazy, Suspense } from "react";

// Pages
const SingleArticle = lazy(() => import("pages/Support/HelpCenter/sections/SingleArticle"));
const AskQuestion = lazy(() => import("pages/Support/HelpCenter/sections/AskQuestion"));
const JobSeekerDetails = lazy(() =>
  import("pages/Search/SearchEmployee/components/JobSeeker/jobSeekerDetails")
);
const SignInBasicPage = lazy(() => import("layouts/authentication/sign-in/basic"));
const SignUpCoverPage = lazy(() => import("layouts/authentication/sign-up/cover"));
const ResetPasswordPage = lazy(() => import("layouts/authentication/reset-password/cover"));
const SendHiringRequest = lazy(() =>
  import("pages/Search/SearchEmployee/components/Employee/SendHiringRequest")
);
const EmployeeDetails = lazy(() =>
  import("pages/Search/SearchEmployee/components/Employee/EmployeeDetails")
);
const JobDetails = lazy(() => import("pages/Search/SearchJobs/Job/JobDetails"));
const Contact = lazy(() => import("pages/LandingPages/Extreme/sections/Contact"));
const SearchEmployee = lazy(() => import("pages/Search/SearchEmployee"));
const SearchJob = lazy(() => import("pages/Search/SearchJobs"));

const MyProfile = lazy(() => import("pages/UserProfile/myProfile"));
const CreateNewProfile = lazy(() => import("pages/UserProfile/Components/CreateProfile"));
const CreateJobPost = lazy(() => import("pages/Posts/Post/CreateJobPost"));
const UserProfile = lazy(() => import("pages/UserProfile"));
const JoinAgency = lazy(() => import("pages/Agencies/components/JoinAgency"));
const AgencyRegistrationFeePayment = lazy(() =>
  import("pages/Agencies/components/PayAgency/registrationFeePayment")
);
const SearchAgency = lazy(() => import("pages/Agencies"));
const AgencyProfile = lazy(() => import("pages/Agencies/Agency/agencyProfile"));
const PrivacyPolicy = lazy(() => import("pages/LandingPages/Extreme/sections/privacyPolicy"));
const MyApplications = lazy(() =>
  import("pages/LandingPages/Extreme/components/User/MyApplications")
);

function Loading() {
  return (
    <Box sx={{ width: "100%" ,m:5}}>
      <CircularProgress />
    </Box>
  );
}

const customRoutes = [
  {
    name: "Create New Profile",
    route: "/profile/create-new-profile",

    component: (
      <Suspense fallback={<Loading />}>
        <CreateNewProfile />
      </Suspense>
    ),
  },
  {
    name: "Article",
    route: "/pages/Article",

    component: (
      <Suspense fallback={<Loading />}>
        <SingleArticle />
      </Suspense>
    ),
  },

  {
    name: "Employee Details",
    route: "/pages/employee-details",

    component: (
      <Suspense fallback={<Loading />}>
        <EmployeeDetails />
      </Suspense>
    ),
  },
  {
    // isAllowedForAgent: true,
    name: "Employees",
    route: "/Freelancers",

    component: (
      <Suspense fallback={<Loading />}>
        <SearchEmployee />
      </Suspense>
    ),
  },
  {
    // isAllowedForAgent: true,
    name: "Find-Friends",
    route: "/Find-Friends",

    component: (
      <Suspense fallback={<Loading />}>
        <SearchEmployee />
      </Suspense>
    ),
  },

  {
    // isAllowedForAgent: true,
    name: "Jobs",
    route: "/pages/jobs",

    component: (
      <Suspense fallback={<Loading />}>
        <SearchJob />
      </Suspense>
    ),
  },
  ///////////////////////
  {
    // isAllowedForAgent: true,
    name: "Create Job Post",
    route: "/pages/posts/create-job-post",

    component: (
      <Suspense fallback={<Loading />}>
        <CreateJobPost />
      </Suspense>
    ),
  },

  {
    // isAllowedForAgent: true,
    name: "Jobs",
    route: "/pages/agencies",

    component: (
      <Suspense fallback={<Loading />}>
        <SearchAgency />
      </Suspense>
    ),
  },
  {
    // isAllowedForAgent: true,
    name: "contact us",
    route: "/pages/support/contact-us",

    component: (
      <Suspense fallback={<Loading />}>
        <Contact />
      </Suspense>
    ),
  },

  // {
  //   name: "Job Details",
  //   route: "/pages/Job-Details",
  //
  // component: (
  //   <Suspense fallback={<Loading/>}>
  //     <JobDetails />
  //   </Suspense>
  // ),
  // },

  {
    name: "JobSeeker-Details",
    route: "/pages/JobSeeker-Details",

    component: (
      <Suspense fallback={<Loading />}>
        <JobSeekerDetails />
      </Suspense>
    ),
  },
  {
    name: "my-applications",
    route: "/profile/jobs/proposals",

    component: (
      <Suspense fallback={<Loading />}>
        <Proposals />
      </Suspense>
    ),
  },
  {
    name: "my-applications",
    route: "/profile/jobs/proposals",

    component: (
      <Suspense fallback={<Loading />}>
        <Proposals />
      </Suspense>
    ),
  },
  {
    name: "my-applications",
    route: "/profile/jobs/:jobId/proposals",

    component: (
      <Suspense fallback={<Loading />}>
        <SingleJobProposals />
      </Suspense>
    ),
  },
  {
    name: "my-applications",
    route: "/profile/jobs/contracts", 
    component: (
      <Suspense fallback={<Loading />}>
        <Contracts/>
      </Suspense>
    ),
  },

  {
    isAllowedForAgent: true,
    name: "Job Details",
    route: "/pages/jobs/job-details",

    component: (
      <Suspense fallback={<Loading />}>
        <JobDetails />
      </Suspense>
    ),
  },
  {
    name: "Agents",
    route: "/agents",

    component: (
      <Suspense fallback={<Loading />}>
        <SearchEmployee />
      </Suspense>
    ),
  },

  {
    name: "Send Tutor Request",
    route: "/pages/SendTutorRequest",

    component: (
      <Suspense fallback={<Loading />}>
        <SendHiringRequest />
      </Suspense>
    ),
  },
  {
    name: "Ask Question",
    route: "/pages/AskQuestion",

    component: (
      <Suspense fallback={<Loading />}>
        <AskQuestion />
      </Suspense>
    ),
  },
  {
    name: "Login",
    route: "/login",

    component: (
      <Suspense fallback={<Loading />}>
        <SignInBasicPage />
      </Suspense>
    ),
  },
  {
    name: "Register",
    route: "/register",

    component: (
      <Suspense fallback={<Loading />}>
        <SignUpCoverPage />
      </Suspense>
    ),
  },
  {
    name: "Privacy Policy",
    route: "/pages/privacy-policy",

    component: (
      <Suspense fallback={<Loading />}>
        <PrivacyPolicy />
      </Suspense>
    ),
  },
  {
    name: "Agency Profile",
    route: "/pages/agencies/agency",

    component: (
      <Suspense fallback={<Loading />}>
        <AgencyProfile />
      </Suspense>
    ),
  },
  {
    name: "Join Agency",
    route: "/pages/agencies/join-agency",

    component: (
      <Suspense fallback={<Loading />}>
        <JoinAgency />
      </Suspense>
    ),
  },
  {
    name: "Agency Registration Fee Payment",
    route: "/pages/agencies/Agency-Registration-Fee-Payment",

    component: (
      <Suspense fallback={<Loading />}>
        <AgencyRegistrationFeePayment />
      </Suspense>
    ),
  },
  {
    name: "reset password",
    route: "/reset-password",

    component: (
      <Suspense fallback={<Loading />}>
        <ResetPasswordPage />
      </Suspense>
    ),
  },
  {
    name: "My Profile",
    route: "/profile",

    component: (
      <Suspense fallback={<Loading />}>
        <MyProfile />
      </Suspense>
    ),
  },
  {
    name: "job application",
    route: "/jobs/:jobId/apply",
    component: (
      <Suspense fallback={<Loading />}>
        <JobApplication />
      </Suspense>
    ),
  },
];

export default customRoutes;
