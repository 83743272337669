// @mui material components
import FbImageLibrary from "react-fb-image-grid";
import "./botstrap.css";
import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect } from "react";
import { PhotoProvider, PhotoSlider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

import { ShareSocial } from "react-share-social";
import {
  Avatar,
  Button,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  Typography,
  Grid,
  List,
  ListItem,
  Divider,
  ListItemAvatar,
  ListItemText,
  Paper,
  IconButton,
  InputBase,
  Toolbar,
  CardMedia,
  Menu,
  MenuItem,
  ListItemIcon,
  Box,
  Tabs,
  Tab,
  AppBar,
  Stack,
  Badge, 
} from "@mui/material";
import {
  AddCircle,
  Analytics,
  ArrowBack,
  Block,
  Bookmark,
  BookmarkBorder,
  Call,
  Cancel,
  ChatBubbleOutline,
  Comment,
  ContactMail,
  Delete,
  Directions,
  Edit,
  Favorite,
  FavoriteBorder,
  LockClockOutlined,
  Logout,
  Person,
  PersonAdd,
  Photo,
  PlaceRounded,
  Repeat,
  Reply,
  Report,
  Send,
  Share,
  VolumeMute,
} from "@mui/icons-material";

import MoreVert from "@mui/icons-material/MoreVert";
import ReactTimeago from "react-timeago";
import TruncateMarkup from "react-truncate-markup";
import { CommonServices } from "Api-Services/commonServices";
import { isMobile } from "react-device-detect";
import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from "@tanstack/react-query";
import { CommonApiServices } from "Api-Services/commonApiServices";
import { blue, green, pink, red } from "@mui/material/colors";
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import JobPostDetails from "./postDetails/JobPostDetails";
import ArticlePostDetails from "./postDetails/ArticlePostDetails";
import { InView, useInView } from "react-intersection-observer";
import { HttpService } from "Api-Services/httpService";
import { makeStyles } from "@mui/styles";
import MKButton from "components/MKButton";
import InfiniteScroll from "react-infinite-scroller";

var approx = require('approximate-number');
const postStylesDropzone = makeStyles((theme) => ({
  root: {
    "& .css-9igssw-MuiDialogContent-root": {
      padding: "5px",
    },
    "& .ql-toolbar.ql-snow ": {
      padding: "0px",
    },
  },
  dropzoneClass: {
    // maxHeight: "124px  !important",
    minHeight: "5px !important",
    "& .css-f8f0pk-MuiSvgIcon-root ": {
      height: "30px",
      width: "30px",
    },
    "& .css-1g96e5k-MuiTypography-root": {
      fontSize: "0.9rem",
      fontWeight: "700",
      marginTop: "4px",
      marginBottom: "2px",
    },
  },
  postTextArea: {
    "& .ck-editor__editable": {minHeight: "100px"}
    // "& .ql-editor ": {
    //   minHeight: "120px",
    // },
  },
}));



// import required modules
function PostCard(props) {
  const postStyles = postStylesDropzone();
  const navigateComp = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams();
  const [currentData, updateContext] = useCurrentUser();
  const { myCurrentProfile, isUserLoggedIn ,
    employmentJobLocationTypes,employmentTypes ,myFriendships } = currentData;
  const [showMore, setShowMore] = React.useState(false);
  const [openedDialog, setOpenedDialog] = React.useState(""); 
  const [postPath, setPostPath] = React.useState("/");
  const [repostMessage, setRepostMessage] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });

  const [photoSliderVisible, setPhotoSliderVisible] = React.useState(false);
  const [photoSliderIndex, setPhotoSliderIndex] = React.useState(0);  
  const [commentFocused, setCommentFocused] = React.useState(false);
  
  const [iReacted, setIReacted] = React.useState(false);
  const [iBookmarked, setIBookmarked] = React.useState(false); 
  const [iAmFollowing, setIAmFollowing] = React.useState(false);
  const [iViewed, setIViewed] = React.useState(false);
  const [iGotImpression, setIGotImpression] = React.useState(false);

  const [commentsCount, setCommentsCount] = React.useState(0);
  const [reactionsCount, setReactionsCount] = React.useState(0);
  const [repostsCount, setRepostsCount] = React.useState(0);
  const [sharesCount, setSharesCount] = React.useState(0);
  const [bookmarksCount, setBookmarksCount] = React.useState(0);
  const [viewsCount, setViewsCount] = React.useState(0);
  const [impressionsCount, setImpressionsCount] = React.useState(0);
  

  const { ref:targetRef, inView:isVisible } = useInView();
    
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorE2);
  window.addEventListener("popstate", (event) => {
    setPhotoSliderVisible(false);
    setOpenedDialog(false);
  });
 
  const handleDialogOpen=(name)=>{
    setOpenedDialog(name);
    if(!openedDialog){
      let path;
      if(post){
        path =  "/" + post?.Profile?.userName +"/posts/" +post?.id;
        window.history.pushState(null, "Job Details", path);   
      }
    }
  }
 
  const handleDialogClose=()=>{
    setOpenedDialog(false);
    setCommentFocused(false)
    let path;
    if(post){
      navigateComp(-1) 
    }
  }

   
  let postId = null;
  if (props?.post?.id) {
    postId = props?.post?.id;
  } else {
    postId = params?.postId;
  }


  let { data: post } = useQuery({
    queryKey: ["/api/post/find/" + postId],
    queryFn: async () => {
      let postD=null
      try {
        // console.log("  props ------------------------------------------", props);
        if (props?.post) {
          postD=props?.post
        }
        else{   
          postD = await HttpService.getApiData("/api/post/find/" + postId);
        }
        if(postD){ 
          let path;
          path = window.location.hostname + "/" +  postD?.Profile?.userName +  "/posts/" + postD?.id;
          setPostPath(path) 
        }
        
      } catch (error) {
        // console.log("post error",error)
      }
      
      setIAmFollowing(postD?.Profile?.iAmFollowing)
      return postD
    },
    initialData: {},
  });
 
  useEffect(() => { 
    try {
        if(post){ 
          setIReacted(post?.iReacted)
          setIViewed(post?.iViewed)
          setIBookmarked(post?.iBookmarked)

          setCommentsCount(post?.commentsCount)
          setReactionsCount(post?.reactionsCount)
          setRepostsCount(post?.repostsCount)
          setSharesCount(post?.sharesCount)
          setBookmarksCount(post?.bookmarksCount)
          setViewsCount(post?.viewsCount)
          setImpressionsCount(post?.impressionsCount)
           
        }
    } catch (error) {}
  }, [post]);
    
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); 
  };
  
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorE2(null);
  };

  const addImpression = async () => {
    try {
      // if(!(post?.iViewed||IViewed)){
        let data = {
          PostId: post.id, 
          EngagementAreaId: 1,
        };
        let addReactRes = await CommonApiServices.addImpression(data);
        if(addReactRes.success){
          setIGotImpression(true);
        }
      // }
    } catch (error) {}
  };
   
  const addView = async () => {
    try {
      // if(!(post?.iViewed||IViewed)){
        let data = {
          PostId: post.id, 
          EngagementAreaId: 1,
        };
        let addReactRes = await CommonApiServices.addView(data);
        if(addReactRes.success){
          setIViewed(true);
        }
      // }
    } catch (error) {}
  };
   
  const addReaction = async (reactionTypeId) => {
    if(!checkAndGoToLoginOrRegisterBox())return
    try {
    setIReacted(!iReacted);
    setReactionsCount((rs)=>rs+1);
      let data = {
        PostId: post?.id,
        ReactionTypeId: 1,
        EngagementAreaId: 1,
        ///////
         
        // EngagedProfileId: data?.EngagedProfileId||null, 
        ProfileId:post?.ProfileId
      };
      let addReactRes = await CommonApiServices.addReaction(data);
      if(addReactRes.success){
        setIReacted(true);
      }
    } catch (error) {}
  };
  
  const removeReaction = async (reactionTypeId) => {
    try {
      if(!checkAndGoToLoginOrRegisterBox())return
      setIReacted(!iReacted);
      setReactionsCount((rs)=>rs-1);
      let data = {
        // id:reactionId
        PostId: post.id,
        ReactionTypeId: reactionTypeId,
      };
      let removeReactRes = await CommonApiServices.removeReaction(data);
      if(removeReactRes.success){
        setIReacted(false);
      }
    } catch (error) {}
  };

  const addBookmark = async () => {
    if(!checkAndGoToLoginOrRegisterBox())return
    try {
    setIBookmarked(!iBookmarked);
    setBookmarksCount((cnt)=>cnt+1);
      let data = {
        PostId: post.id,
        // ReactionTypeId: 1,
        EngagementAreaId: 1,
        // EngagedProfileId: data?.EngagedProfileId||null, 
        ProfileId:post?.ProfileId
      };
      let addReactRes = await CommonApiServices.addBookmark(data);
      if(addReactRes.success){
        setIBookmarked(true);
      }
    } catch (error) {}
  };

  const removeBookmark = async () => {
    if(!checkAndGoToLoginOrRegisterBox())return
    setIBookmarked(!iBookmarked);
    try {
      setBookmarksCount((cnt)=>cnt-1);
      let data = {
        // id:reactionId
        PostId: post.id, 
      };
      let removeReactRes = await CommonApiServices.removeBookmark(data);  
    if(removeReactRes.success){
        setIBookmarked(false);
      }
    } catch (error) {}
  };
  
  const addShare = async (platformName) => {
    try {
      setSharesCount((cnt)=>cnt+1);
      let data = {
        PostId: post?.id, 
        EngagementAreaId: 1,
        platform: platformName,
        // EngagedProfileId: data?.EngagedProfileId||null, 
        // ProfileId:post?.ProfileId
      };
      let addReactRes = await CommonApiServices.addShare(data);
      if(addReactRes.success){
        // setIViewed(true);
      }
    } catch (error) {}
  };

  // Mutations
  const mutation = useMutation((newComment) => CommonApiServices.addComment(newComment), {
    onSuccess: () => {
      // Invalidate and refetch
      setCommentMessage("");
      queryClient.invalidateQueries({ queryKey: ["comments" + post?.id] });
    },
  });
 

  function FriendshipButton(FriendshipListItemprops) {
    const [friendshipType, setFriendshipType] = React.useState("");
    const [friendshipItem, setFriendshipItem] = React.useState(null);
    useEffect(() => {
      let friendshipItemR;
      try {
        if (FriendshipListItemprops.friendshipItem && myFriendships && myCurrentProfile) {
          friendshipItemR = FriendshipListItemprops.friendshipItem;
          if (myCurrentProfile.id != friendshipItemR.id) {
            setFriendshipItem(friendshipItemR);
            // console.log("friendshipItemR",friendshipItemR.id, myFriendships)
            let fT = CommonServices.checkFriendship(friendshipItemR.id, myFriendships);
            // console.log("fT",fT)
            if (fT) {
              if (myFriendships && (friendshipItemR.id ? true : false)) {
                // console.log("fT",fT)
                setFriendshipType(fT);
              }
            }
          } else {
            setFriendshipType("myself");
          }
        }
      } catch (error) {}
    }, [FriendshipListItemprops, myFriendships]);

    let res = <></>;
    try {
      res =
        friendshipType != "myself" ? (
          <>
            {friendshipItem && (
              <>
                {friendshipType == "friend" ? (
                  <Chip
                    sx={{ px: "5px" }}
                    icon={<Send />}
                    onClick={() => {
                      // CommonApiServices.unfollow(friendshipItem.id)
                      // setFriendshipType("")
                      // console.log("mmmmmmmmmmm", following);
                    }}
                    // label={"Follow"}
                    label={"Message"}
                  />
                ) : friendshipType == "follower" ? (
                  <Chip
                    sx={{ px: "2px" }}
                    color={"info"}
                    icon={<AddCircle />}
                    onClick={() => {
                      CommonApiServices.follow(friendshipItem.id);
                      // friendshipType=!following;
                      setFriendshipType("friend");
                    }}
                    label={"Follow Back"}
                  />
                ) : friendshipType == "following" ? (
                  <></>
                ) : (
                  <Chip
                    sx={{ px: "15px" }}
                    color={"info"}
                    icon={<AddCircle />}
                    onClick={() => {
                      CommonApiServices.follow(friendshipItem.id);
                      setFriendshipType("following");
                    }}
                    // label={"Follow"}
                    label={"Follow"}
                  />
                )}{" "}
              </>
            )}
          </>
        ) : (
          <></>
        );
    } catch (error) {
      // console.log("FriendshipListItem errr", error);
    }
    return res;
  }
  
 

  function Comments(commentProps) {
    let postId = commentProps.postId;
    const query = useQuery(
      ["comments" + postId],
      async () => await CommonApiServices.getPostComments(postId)
    );
    try {
    } catch (error) {
      // console.log("post card comments",error)
    }

      return (
        <>
         
            <MKBox display="flex" sx={{ p: 2 }} flexDirection="column">
              {!query.isFetched? (
                <h1>Loading</h1>
              ) : (query?.data?.length )? (
                <List sx={{ bgcolor: "background.paper" }}>
                  {query.data?.map(
                    (comment) =>
                      comment.message &&
                      comment.EngagerProfile && (
                        <>
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              <Avatar sx={{width:30,height:30}} alt="profile" src={comment.EngagerProfile.profileImageUrl} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ display: "inline" }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >{CommonServices?.getProfileName(comment.EngagerProfile)}
                                  </Typography>}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {parse(comment.message)}
                                  </Typography>
                                  {/* {" — I'll be in your neighborhood doing errands this…"} */}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" sx={{my:0,py:0}}/>{" "}
                        </>
                      )
                  )}
                </List>
              ) : (
                <MKTypography variant={"body2"}>Be the first to comment </MKTypography>
              )}
            </MKBox>
        </>
      );


  }


  function Messages() {
    let messages = []
    try {
      messages= post?.Chats[0]?.Messages
    } catch (error) {
      
    }
    
      return (
        <>
         
           { messages?.length?
            <MKBox display="flex" sx={{ p: 2 }} flexDirection="column"> 
             <List sx={{ bgcolor: "background.paper" }}>
                  {messages?.map(
                    (comment) =>
                      comment.Profile && (
                        <>
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              <Avatar sx={{width:30,height:30}} alt="profile" src={comment.Profile.profileImageUrl} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{ display: "inline" }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >{CommonServices?.getProfileName(comment.Profile)}
                                  </Typography>}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {parse(comment.content)}
                                  </Typography>
                                  {/* {" — I'll be in your neighborhood doing errands this…"} */}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" sx={{my:0,py:0}}/>{" "}
                        </>
                      )
                  )}
                </List>
            </MKBox>:null}
        </>
      );


  } 

  function Reactions(commentProps) {
    let postId = commentProps?.postId;


    const {
      data:reactions,
      fetchNextPage, 
      hasNextPage,
      isFetching,
      isFetchingNextPage,
      status,
      refetch 
      } = useInfiniteQuery({
      // queryKey: ["/api/profilefollowers/followings/"+myCurrentProfile?.id],
      queryKey: ["/api/reaction/getpostreactions/" + postId ],
      queryFn: async ({pageParam=1}) => { 
        if(postId){
        return await HttpService.getApiData("/api/reaction/getpostreactions/" + postId+"?pageSize="+12+"&page="+pageParam); 
        }
        else return null
       },
      getNextPageParam: (lastPage, pages) => {
        // If the last page has less than the page size, return undefined
        if (lastPage.length < 12) {
          return undefined;
        }
        // Otherwise, return the next page number
        return pages.length + 1;
      },    
      // getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
  
      })

    try {
      return (
        <>
          <DialogContent
            sx={{
              // minHeight: isMobile ? "300px" : "460px",
              p: "0px",
              m: "0px",
            }}
          >
            <MKBox display="flex" sx={{ p: 2 }} flexDirection="column">
            <InfiniteScroll
                  loadMore={() => { 
                    fetchNextPage()
                  }}
                  // hasMore={true}
                  hasMore={
                    hasNextPage&&
                    (reactions?.pages[reactions?.pages?.length-1]?.length>11)}
                  loader={"loading..."}
                >  
             {reactions?.pages?.length ? (
                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                  {reactions?.pages?.map((pageD)=><>
                  {pageD?.map((reaction) =>
                      reaction?.EngagerProfile && (
                        <>
                          <ListItem
                            alignItems="flex-start"
                            secondaryAction={<FriendshipButton friendshipItem={reaction.EngagerProfile} />}
                          >
                            <ListItemAvatar
                              onClick={() => {
                                navigateComp("/" + reaction.EngagerProfile.userName);
                                handleDialogClose();
                              }}
                            >
                              <Badge  badgeContent={
                      <IconButton 
                        sx={{mt:"-9px"}} color="error" aria-label="delete" size="medium">
                        <Favorite fontSize="inherit" />
                      </IconButton>} 
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  >
                              <>
                              {reaction.EngagerProfile?.profileImageUrl != "" &&
                              reaction.EngagerProfile?.profileImageUrl != null ? (
                                <Avatar src={reaction.EngagerProfile?.profileImageUrl}></Avatar>
                              ) : (
                                <Avatar>
                                  <Person />
                                </Avatar>
                              )}</></Badge>
                            </ListItemAvatar>
                            <ListItemText
                              onClick={() => {
                                navigateComp("/" + reaction.EngagerProfile?.userName);
                                handleDialogClose();
                              }}
                              primary={
                                <Typography variant="h6">
                                  {CommonServices.getProfileName(reaction?.EngagerProfile )}
                                </Typography>
                              }
                              secondary={  <Typography variant="body2" >{"@"+reaction?.EngagerProfile?.userName}</Typography>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />{" "}
                        </>
                      )
                  )}</>
                  )}
                </List>
              ) : (
                <MKTypography variant={"body2"}>Be the first to React </MKTypography>
              )}
              </InfiniteScroll>
            </MKBox>
          </DialogContent>
        </>
      );
    } catch (error) {}
  }

  const postMenuListsComp=()=>{
    return (<>
    
    <MenuItem  onClick={(e)=>{ 
        handleDialogOpen("sharePost") 
        handleClose(e)
        }}>
          <ListItemIcon>
            <Share fontSize="small" />
          </ListItemIcon>
          Share 
        </MenuItem>
        {(post?.Profile?.id==myCurrentProfile?.id)&&<>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          Edit post
        </MenuItem>
      <MenuItem onClick={(e)=>{ 
        handleDialogOpen("deletePost") 
        handleClose(e)
        }}>
        <ListItemIcon>
          <Delete fontSize="small" />
        </ListItemIcon>
        Delete post
      </MenuItem></>}
    </>)}

  const profileHeaderSection=()=>{
    //  setIAmFollowing(post?.Profile?.iAmFollowing)
    return (<>
    
    <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // TransitionComponent={Fade}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      > 
     
     {postMenuListsComp()}
       
      </Menu>
    {post.Profile && (
                  <CardHeader
                    avatar={<Badge  badgeContent={((myCurrentProfile?.id==post?.Profile?.id)||iAmFollowing)?null:
                      <IconButton 
                        onClick={() => {
                          if(!checkAndGoToLoginOrRegisterBox())return
                          CommonApiServices.follow(post?.Profile?.id);
                          setIAmFollowing(!iAmFollowing);
                        }} 
                        sx={{mt:"-9px"}} color="error" aria-label="delete" size="large">
                        <AddCircle fontSize="inherit" />
                      </IconButton>} 
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  >
                      <Avatar
                        // onClick={() => {
                        //   let data = {
                        //     dialogId: "ProfileDialogBox",
                        //     data: { profile: post?.Profile },
                        //   };
                        //   updateContext("currentGlobalDialogBoxData", data);
                        // }}
                         onClick={() => navigateComp("/" + post.Profile.userName)}
                        sx={{ width: 44, height: 44 }}
                        src={post.Profile.profileImageUrl}
                        aria-label="recipe"
                      />
                      </Badge>
                    }
                    action={
                      // props.componentViewType == 3 ? (
                      <MoreVert
                        id="fade-button"
                        aria-controls={open ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                      //  color="success"
                      //  fontSize="large"
                       />
                      // ) : null
                    }
                    title={
                      <TruncateMarkup lines={1}>
                        <MKTypography
                          variant="h6"
                          // onClick={() => navigateComp("/" + post.Profile.userName)}
                        >
                         <span 
                         onClick={() => navigateComp("/" + post.Profile.userName)}>
                          {CommonServices.getProfileName(post.Profile)} 
                          </span>
                        </MKTypography>
                      </TruncateMarkup>
                    }
                    subheader={
                      <Typography variant="body2" color="text.secondary">
                        <ReactTimeago date={post.createdAt} />

                        {/* <PeopleOutlineRounded /> {1461 + post.numberOfMembers} {" Members"} */}
                      </Typography>
                    }
                  />
                )}
    
    </>)
  }

  const checkAndGoToLoginOrRegisterBox=()=>{
    if(myCurrentProfile){
      return true
    }
    else{
      let data = {
        dialogId: "SwitchProfileDialogBox",
        data: {},
      };
      updateContext("currentGlobalDialogBoxData", data);
    return false
    }
  }

  function PostEngagementSection(){
     // console.log("sVisible",isVisible)


    return (<> 
                  <Box
                    sx={{ m: 1 }}
                    // sx={{ marginBottom: "-11px" }}
                    onClick={() => {
                      // viewDetails(post.id);
                    }}
                    // sx={{textAlign: "left",pl:2}}
                  >
                    {(post ? reactionsCount > 0 : false) && (
                      <Grid item xs={12} sx={{ py: 0, my: 0 }}>
                        <Typography
                          sx={{ py: 0, my: 0 }}
                          variant="caption"
                          onClick={() => {
                            handleDialogOpen("reactions");
                          }}
                        >
                         { reactionsCount? reactionsCount + " reactions ":" "}  
                         { viewsCount? viewsCount + " views":" "}  
                        </Typography>
                      </Grid>
                    )}
               
               {/* {myCurrentProfile &&   */}
                  <Box sx={{ width: '100%' }}>
                      <Tabs sx={{p:0}}
                        // onChange={handleChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                      >
                         <Tab  
                             icon={
                              iReacted ? (
                                <Favorite
                                  // sx={{
                                  //   color: pink[500],
                                  //   //  ,fontSize: 150
                                  // }}
                                 
                                  sx={{   color:red[500]}}
                                />
                              ) : (
                                <FavoriteBorder
                                
                                 
                                // sx={{    width: 20, height: 30}}
                                  // sx={{
                                  //   color: pink[500],
                                  //   //  ,fontSize: 150
                                  // }}
                                />
                                )
                              }   
                              onClick={() => {
                                if (iReacted) {
                                  removeReaction(1);
                                } else { 
                                  addReaction(1);
                                }
                              }}
                              label={ reactionsCount || ""} />
                     <Tab disabled={!post?.allowComment} icon={<ChatBubbleOutline />}  
                     
                              onClick={() => {
                                setCommentFocused(true)
                                handleDialogOpen("comments");
                              }}
                            // onClick={() => {
                            //   // setOpenedDialog("comments");
                            //   let data = {
                            //     dialogId: "PostDetailsDialogBox",
                            //     data: { post: post ,section:"comments"  },
                            //   };
                            //   updateContext("currentGlobalDialogBoxData", data);
                            // }}
                            label={ commentsCount || ""} />
                  <Tab icon={<Repeat />}  
                              onClick={(e)=>{ 
                                handleDialogOpen("repost") 
                                handleClose(e)
                                }}
                                label={ repostsCount ? repostsCount : ""}/>
                  <Tab icon={<Reply />}  
                              onClick={(e)=>{ 
                                handleDialogOpen("sharePost") 
                                handleClose(e)
                                }}
                                label={ sharesCount ? sharesCount : ""}/>
                    <Tab icon={iBookmarked?<Bookmark 
                                  sx={{   color:blue[500]}}/>:<BookmarkBorder />}  
                            
                            onClick={() => {
                              if (iBookmarked) { 
                                removeBookmark();
                              } else { 
                                addBookmark();
                              }
                            }}
                            label={ bookmarksCount ?  bookmarksCount : ""}/>
                </Tabs>
             </Box>
            {/* //  }  */}
                  </Box> 
    </>)
  }
  
  function PostDetailEngagementSection(){
    return (<>   
                  <Box sx={{ width: '100%' }}>
      <Grid sx={{p:0}}
        // onChange={handleChange}
        // textColor="secondary" 
        // indicatorColor="secondary"
        // aria-label="secondary tabs example"
       
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      > 
      {!(myCurrentProfile)&&<Grid item  >
        <MKButton
                     size="small"
                    //  disabled={employmentJobVacancy.isJobClosed}
                    variant="gradient"
                    color="success" 
                    sx={{ my: 2 }}
                    component={Link}
                    // variant="outlined"
                    // color="default"
                    to={"/login?redirectTo=/pages/jobs"}
                    // sx={{ color: ({ palette: { dark } }) => dark.main }}
                  >
                    Login and continue 
                  </MKButton> 
        </Grid>}
                 {(myCurrentProfile&&(post?.PostTypeId==3))&& <Grid item  >
                {(myCurrentProfile?.id==post?.Profile?.id )? <MKButton
                                    endIcon={<Send />}
                                    disabled={post?.EmploymentJobVacancy?.isJobClosed}
                                    // onClick={() => setOpenedDialog("sendRequest")}
                                    onClick={() => {
                                      // /1/new/21
                                      navigateComp("/profile/jobs/"+post?.EmploymentJobVacancy?.id+"/proposals");
                                    }}
                                    variant="gradient"
                                    color="secondary"
                                    size="small"
                                    sx={{ m: 1 }}
                                  >
                                   View Proposals
                                  </MKButton>
                    : applyComponent()}  
                   </Grid> }  
                { ((myCurrentProfile?.id!=post?.Profile?.id)&&(post?.allowMessage))?<Grid item  > 
                                    <MKButton
                                    endIcon={<Send />}
                                    disabled={post?.EmploymentJobVacancy?.isJobClosed}
                                    // onClick={() => setOpenedDialog("sendRequest")}
                                    onClick={() => {
                                      // /1/new/21
                                      navigateComp("/chats/2/new/"+post?.id+"");
                                    }}
                                    variant="gradient"
                                    color="secondary"
                                    size="small"
                                    sx={{ m: 1 }}
                                  >
                                   send message
                                  </MKButton>
                   </Grid> :null }
             
                   {( (myCurrentProfile?.id!=post?.Profile?.id)&&(post?.allowCall)&&(post?.Profile?.phone))?<Grid item > 
                                <MKButton
                                 component={Link}
                                 to={"tel:"+post?.Profile?.phone}
                                endIcon={<Call />}
                                disabled={post?.EmploymentJobVacancy?.isJobClosed} 
                                variant="gradient"
                                color="secondary"
                                size="small"
                                sx={{ m: 1 }}
                              >
                                Call
                              </MKButton>
                   </Grid> :null} 
                </Grid>
             </Box>  
    </>)
  }


  function CommentInput(){

    const [commentMessage, setCommentMessage] = React.useState("");
    const sendComment = async () => {
      if (post) {
        if (post?.id && commentMessage) {
          let data = {
            PostId: post?.id,
            CommentTypeId: 1,
            EngagementAreaId: 1,
            message: commentMessage,
            ProfileId:post?.ProfileId
          }; 
          mutation.mutate(data);
        }
      }
    };
    return (<>
    
    {myCurrentProfile && (
              <>
                <Paper
                  // component="form"
                  elevation={0}
                  square={false}
                  // component="form"
                  shadow={"none"}
                  sx={{
                    mx: 0,
                    mt: 0.5,
                    backgroundColor: "#f0f2f5",
                    // mr: "6px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    //  width: "min-content",
                  }}
                >
                  <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <Avatar sx={{width:30,height:30}} src={myCurrentProfile?.profileImageUrl} />
                  </IconButton>
                  <InputBase
                    fullWidth
                    // startAdornment={<SearchIcon sx={{ mr: 1 }} />}
                    autoFocus={commentFocused}
                    sx={{ ml: 1, pr: 1, flex: 1, backgroundColor: "aliceblue" }}
                    // sx={{ ml: 1, flex: 1 }}
                    placeholder="Write Comment"
                    inputProps={{ "aria-label": "Write Comment" }}
                    value={commentMessage}
                    onChange={(e) => {
                      setCommentMessage(e.target.value);
                    }}
                    onKeyDown={(event) => {
                      if (event.keyCode) {
                        if (event.keyCode === 13) {
                          sendComment();
                        }
                      }
                    }}
                    type="search"
                  />
                  <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                    <Edit />
                  </IconButton>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    onClick={() => {
                      sendComment();
                    }}
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                  >
                    <Send />
                  </IconButton>
                </Paper>
              </>
            )}
    
    </>)}

  const cardBody=()=>{

    return (<>
    
          {((post?.PostTypeId == 4)) && marketPlaceCardBody() }

               {((post?.PostTypeId == 8) ) && (<>   
                {profileHeaderSection()}
                  <Paper
                  onClick={() => {handleDialogOpen("postDetails")}} 
                    
                  // onClick={() => {
                  //     let data = {
                  //       dialogId: "PostDetailsDialogBox",
                  //       data: { post: post },
                  //     };
                  //     updateContext("currentGlobalDialogBoxData", data);
                  //   }}
                    sx={{ backgroundColor: "#eee", m: 0 }}
                    square={false}
                    elevation={2}
                  >
                    {post.Attachments?.length ? (
                      <CardMedia
                        sx={{ m: 0, height: 300 }}
                        image={post.Attachments[0]?.url}
                        title="green iguana"
                      />
                    ) : null}

                    <CardContent
                      sx={{ p: 2, pb: "1px" }}
                      onClick={() => {
                        // viewDetails(post.id);
                      }}
                      // sx={{textAlign: "left",pl:2}}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <Grid item xs={8} >
                          <TruncateMarkup lines={2}>
                            <MKTypography color={"text"} variant="h6">
                              {parse(post.title)}
                              {/* {parse(post.message)} */}
                            </MKTypography>
                          </TruncateMarkup>
                        </Grid>

                        <Grid item  xs={4}  sx={{textAlign:"right"}}>
                          <MKButton variant="outlined" color="primary" size="small">
                          { isMobile?"See": "See Descussion"}
                          </MKButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Paper> 
                {<PostEngagementSection />}
                </>
                )}

                {post?.PostTypeId == 7 && (
                  <>
                  {profileHeaderSection()}
                  <Paper
                  onClick={() => {handleDialogOpen("postDetails")}} 
                    // onClick={() => {
                    //   let data = {
                    //     dialogId: "PostDetailsDialogBox",
                    //     data: { post: post },
                    //   };
                    //   updateContext("currentGlobalDialogBoxData", data);
                    // }}
                    sx={{ backgroundColor: "#eee", m: 0 }}
                    square={false}
                    elevation={2}
                  >
                    {post.Attachments?.length ? (
                      <CardMedia
                        sx={{ m: 0, height: 300 }}
                        image={post.Attachments[0]?.url}
                        title="green iguana"
                      />
                    ) : null}

                    <CardContent
                      sx={{ p: 2, pb: "1px" }}
                      onClick={() => {
                        // viewDetails(post.id);
                      }}
                      // sx={{textAlign: "left",pl:2}}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <Grid item xs={8} >
                          <TruncateMarkup lines={2}>
                            <MKTypography color={"text"} variant="h6">
                              {parse(post.message)}
                            </MKTypography>
                          </TruncateMarkup>
                        </Grid>

                        <Grid item  xs={4}  sx={{textAlign:"right"}}>
                          <MKButton variant="outlined" color="primary" size="small">
                          { isMobile?"Read": "Read Article"}
                          </MKButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Paper> 
                  {<PostEngagementSection />}
                  </>
                )}

                {post?.PostTypeId == 1 ? (
                  <> 
                 {profileHeaderSection()}
                  <CardContent
                    sx={{ my: "1px", py: "1px" }}
                    onClick={() => {
                      // viewDetails(post.id);
                    }}
                    // sx={{textAlign: "left",pl:2}}
                  >
                    {post.message && (
                      <>
                        {showMore == "description" ? (
                          <>
                            <Typography variant="body2">{parse(post.message)}</Typography>
                            <Typography
                              color={"info"}
                              variant="caption"
                              onClick={() => setShowMore("")}
                              // component={Link}
                            >
                              {" see less"}
                            </Typography>
                          </>
                        ) : (
                          <TruncateMarkup
                            lines={
                              (post ? (post.Attachments ? true : false) : false) ? 3 : 8
                            }
                            ellipsis={
                              <span>
                                ...{" "}
                                <Typography
                                  color={"info"}
                                  variant="caption"
                                  onClick={() => setShowMore("description")}
                                  // component={Link}
                                >
                                  see more
                                </Typography>
                              </span>
                            }
                          >
                            <Typography variant="body2">{parse(post.message)}</Typography>
                          </TruncateMarkup>
                        )}
                      </>
                    )}
                  </CardContent> 
                  
                    {post.Attachments && props.componentViewType == 1 && (
                      <div className={"FbImageLibrary"}>
                        <PhotoSlider
                          images={CommonServices?.getPostAttachments({attachments:post?.Attachments})?.map((item) => ({
                            src: item,
                            key: item,
                          }))}
                          visible={photoSliderVisible}
                          onClose={() => {
                            setPhotoSliderVisible(false);
                            navigateComp(-1);
                          }}
                          index={photoSliderIndex}
                          onIndexChange={setPhotoSliderIndex}
                        />
                        <FbImageLibrary
                          onClickEach={({ src, index }) => {
                            let path = post?.Profile?.userName + "/posts/" + post?.id;
                            window.history.pushState(null, "Job Details", path);
                            setPhotoSliderIndex(index);
                            setPhotoSliderVisible(true);
                          }}
                          hideOverlay={true}
                          images={CommonServices?.getPostAttachments({attachments:post?.Attachments})}
                        />
                      </div>
                    )}
                    {<PostEngagementSection />} 
                  </>
                ): null}



                {post?.PostTypeId == 3 ? (
                  <>
                  {profileHeaderSection()}
                  <CardContent
                    sx={{ my: "1px", py: "1px" }}
                    onClick={() => {
                      // viewDetails(post.id);
                    }}
                    // sx={{textAlign: "left",pl:2}}
                  >{jobComponent(post)}
                    {/* <JobPostHighlight post={post} data={post?.EmploymentJobVacancy} /> */}
                  </CardContent>
                {<PostEngagementSection />} 
                  </>
                ) : null}

    </>)
  }
  
  const jobComponent = (postData) => {
    let jobVacancy = postData?.EmploymentJobVacancy; 
    let jobOverviewComponent = <></>;
    try {
      jobOverviewComponent = (
        <>
          {jobVacancy && (
            <MKBox
              sx={{ py: "1px", cursor: "pointer" }}
              onClick={() => {handleDialogOpen("postDetails")}} 
              // onClick={() => {
                
              // let data = {
              //   dialogId: "PostDetailsDialogBox",
              //   data: { post: postData  },
              // };
              // updateContext("currentGlobalDialogBoxData", data); 
              // }}
              // sx={{textAlign: "left",pl:2}}
            >
              <TruncateMarkup lines={2}>
                <MKTypography variant="h6">
                  {post?.title}
                  {/* {truncateString(jobVacancy.title, 65)} */}
                  </MKTypography>
              </TruncateMarkup>

              {post?.Address&& (
              <TruncateMarkup lines={1}>
                <Typography variant="body2" color="text.secondary">
                  <PlaceRounded />{" "}
                    <>
                      {post.Address?.specificArea}
                      {" , " + post.Address?.subCity}
                      {" , " + post.Address?.City?.name}
                    </>
                </Typography>
              </TruncateMarkup>
              )}
              <Typography variant="body2" color="text.secondary">
                <LockClockOutlined /> { jobVacancy.deadLineDateTime }
              </Typography>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Chip
                  sx={{ mr: "2px", my: "3px" }}
                  size="small"
                  color="success"
                  variant="outlined"
                  label={
                    jobVacancy.salary
                      ? approx(jobVacancy.salary) +
                        " " +
                        jobVacancy.salaryCurrency +
                        " (" +
                        jobVacancy.salaryDuration +
                        ")"
                      : ""
                  }
                />
                {jobVacancy.jobLocationTypeId ? (
                  <Chip
                    sx={{ mr: "2px", my: "3px" }}
                    size="small"
                    label={
                      getDataFromArray(
                        employmentJobLocationTypes,
                        "id",
                        jobVacancy.jobLocationTypeId,
                        "single",
                        "title"
                      ) || null
                    }
                  />
                ) : null}
                {jobVacancy.employmentTypeId ? (
                  <Chip
                    sx={{ mr: "2px", my: "3px" }}
                    size="small"
                    label={getDataFromArray(
                      employmentTypes,
                      "id",
                      jobVacancy.employmentTypeId,
                      "single",
                      "title"
                    )}
                  />
                ) : null}
              </Grid>
              
             {(jobVacancy?.employmentApplicationRequestsCount)? <TruncateMarkup lines={1}>
                <Typography variant="body2" color="text.secondary">
                  <Send />{" proposals:"}
                  {jobVacancy?.employmentApplicationRequestsCount}
                </Typography>
              </TruncateMarkup>:null}
            </MKBox>
          )}
        </>
      );
    } catch (error) {
      // console.log("eeeeeeeeeerrrrrr", error);
    }
    return jobOverviewComponent;
  };


  const marketPlaceCardBody=()=>{
    return (<>
     <Paper
                  onClick={() => {handleDialogOpen("postDetails")}} 
                    // onClick={() => {
                    //   let data = {
                    //     dialogId: "PostDetailsDialogBox",
                    //     data: { post: post },
                    //   };
                    //   updateContext("currentGlobalDialogBoxData", data);
                    // }}
                    sx={{ backgroundColor: "#eee", m: 0}}
                    square={false}
                    elevation={2}
                  >
                     {post.Attachments?.length ? (
                      <CardMedia
                        sx={{ m: 0, height: isMobile?150:200 }}
                        image={post.Attachments[0]?.url}
                        title="green iguana"
                      />
                    ) : null}
                      <MKBox
                        sx={{ py: "1px", cursor: "pointer",p:1  }}
                        onClick={() => {handleDialogOpen("postDetails")}} 
                        // onClick={() => {
                          
                        // let data = {
                        //   dialogId: "PostDetailsDialogBox",
                        //   data: { post: post  },
                        // };
                        // updateContext("currentGlobalDialogBoxData", data);
                        //   // let path
                        //     // if(params?.pageTab){ 
                        //     //  path="/post/"+jobVacancy?.Post?.id;
                        //     // }
                        //     // console.log("jobs location" ,path) 
                        //     // window.history.pushState(null, "Job Details", path); 
                        //     //  setOpenedDialog("jobDetails");

                        //   // viewDetails(jobVacancy.id, jobVacancy);
                        // }}
                        // sx={{textAlign: "left",pl:2}}
                      >
                       {post?.MarketPlaceListing?.price? <TruncateMarkup lines={1}>
                          <MKTypography variant="h6">
                            { post?.MarketPlaceListing?.currency+" "+ post?.MarketPlaceListing?.price }</MKTypography>
                        </TruncateMarkup>:""}
                        <TruncateMarkup lines={1}>
                          <MKTypography variant="body2">{ post?.title }</MKTypography>
                        </TruncateMarkup>
                        {post?.Address?(
                        <TruncateMarkup lines={1}>
                          <Typography variant="body2" color="text.secondary">
                            <PlaceRounded />{" "}
                              <>{post?.Address?.City?.name} 
                              { post?.Address?.subCity?" , " + post?.Address?.subCity:""} 
                              { post?.Address?.specificArea?" , " + post?.Address?.specificArea:""}
                              
                              </>
                          </Typography>
                        </TruncateMarkup>
                          ):""}
                        {/* 
                        <TruncateMarkup lines={1}> 
                        <Typography variant="body2" color="text.secondary">
                          <LockClockOutlined /> { "DateTime"}
                        </Typography>
                        </TruncateMarkup> */}
                        {/* <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                          <Chip
                            sx={{ mr: "2px", my: "3px" }}
                            size="small"
                            color="success"
                            variant="outlined"
                            label={ "salary" 
                            }
                          /> 
                            <Chip
                              sx={{ mr: "2px", my: "3px" }}
                              size="small"
                              label={  "single"   }
                            /> 
                            <Chip
                              sx={{ mr: "2px", my: "3px" }}
                              size="small"
                              label={ "title" }
                            /> 
                        </Grid> */}
                      </MKBox>
                  </Paper></>)
  }
  
  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = returnField ? "" : {};
    // console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = "";
                if (row[returnField]) {
                  rowField = row[returnField];
                }
                res = rowField;
              }
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };
  

  const postDetailsComp=()=>{
  // const handleClick = (event) => {
  //   setAnchorE2(event.currentTarget);
  // };
   
  // // const targetRef = useRef(null); 
  // const { ref:targetRef, inView:isVisible } = useInView();
    return <>
    <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorE2}
        open={open2}
        onClose={handleClose}
        // TransitionComponent={Fade}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      > 
      {postMenuListsComp()}
       
      </Menu>
    <Grid container justifyContent="space-between" alignItems="flex-start">
        
        <Grid 
            item
            sm={12}
            xs={12}
            md={4}
            lg={3.5}
            xl={2.3}
            display={{ xs: "12", sm: "12", md: "flex" }}
            >
          </Grid>
        <Grid item 
        // xs={12} lg={(isMobile||((props?.post)))?12:8}
        
        xs={12} sm={12} md={8} lg={5}  
        // sx={{mt:isMobile?0:2}}
        
        
        > 
        
         {/* {!props?.post && ( */}
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => {
                  // navigateComp(-1);
                  handleDialogClose();
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Post
              </Typography>
              {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
          </AppBar>
        {/* )} */}
      <Card
                 sx={{width: "100%"}}
                >
                  {post?.Profile && (
                    <CardHeader
                      avatar={<Badge  badgeContent={4} 
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                      >
                        <Avatar
                          onClick={() => navigateComp("/" + post?.Profile.userName)}
                          sx={{ width: 44, height: 44 }}
                          src={post?.Profile.profileImageUrl}
                          aria-label="recipe"
                        />
                        </Badge>
                      }
                      action={
                        // props.componentViewType == 3 ? (
                        <MoreVert
                        id="fade-button"
                        aria-controls={open2 ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open2 ? 'true' : undefined}
                        onClick={handleClick}
                        />
                        // ) : null
                      }
                      title={
                        <TruncateMarkup lines={1}>
                          <MKTypography
                            variant="h6"
                            onClick={() => navigateComp("/" + post?.Profile.userName)}
                          >
                            {CommonServices.getProfileName(post?.Profile)}
                          </MKTypography>
                        </TruncateMarkup>
                      }
                      subheader={
                        <Typography variant="body2" color="text.secondary">
                          <ReactTimeago date={post?.createdAt} />

                          {/* <PeopleOutlineRounded /> {1461 + jobVacancy.numberOfMembers} {" Members"} */}
                        </Typography>
                      }
                    />
                  )}
                    {post?.title && (
                  <CardContent
                    sx={{ my: "1px", py: "1px" }}
                    onClick={() => {
                      // viewDetails(jobVacancy.id);
                    }}
                    // sx={{textAlign: "left",pl:2}}
                  >
                      <>
                        {showMore == "description" ? (
                          <>
                            <Typography variant="body2">{parse(post?.title)}</Typography>
                            <Typography
                              color={"info"}
                              variant="caption"
                              onClick={() => setShowMore("")}
                              // component={Link}
                            >
                              {" see less"}
                            </Typography>
                          </>
                        ) : (
                          <TruncateMarkup
                            lines={(post ? (post?.Attachments ? true : false) : false) ? 3 : 8}
                            ellipsis={
                              <span>
                                ...{" "}
                                <Typography
                                  color={"info"}
                                  variant="caption"
                                  onClick={() => setShowMore("description")}
                                  // component={Link}
                                >
                                  see more
                                </Typography>
                              </span>
                            }
                          >
                            <Typography variant="body2">{parse(post?.title)}</Typography>
                          </TruncateMarkup>
                        )}
                      </>
                  </CardContent>
                    )}
                    {post?.message && (
                  <CardContent
                    sx={{ my: "1px", py: "1px" }}
                    onClick={() => {
                      // viewDetails(jobVacancy.id);
                    }}
                    // sx={{textAlign: "left",pl:2}}
                  >
                      <>
                        {showMore == "description" ? (
                          <>
                            <Typography variant="body2">{parse(post?.message)}</Typography>
                            <Typography
                              color={"info"}
                              variant="caption"
                              onClick={() => setShowMore("")}
                              // component={Link}
                            >
                              {" see less"}
                            </Typography>
                          </>
                        ) : (
                          <TruncateMarkup
                            lines={(post ? (post?.Attachments ? true : false) : false) ? 3 : 8}
                            ellipsis={
                              <span>
                                ...{" "}
                                <Typography
                                  color={"info"}
                                  variant="caption"
                                  onClick={() => setShowMore("description")}
                                  // component={Link}
                                >
                                  see more
                                </Typography>
                              </span>
                            }
                          >
                            <Typography variant="body2">{parse(post?.message)}</Typography>
                          </TruncateMarkup>
                        )}
                      </>
                  </CardContent>
                    )}

                  {post?.PostTypeId == 3 && (
                    <CardContent
                      sx={{ my: "1px", py: "1px" }}
                      onClick={() => {
                        // viewDetails(jobVacancy.id);
                      }}
                      // sx={{textAlign: "left",pl:2}}
                    >
                      <JobPostDetails
                        post={post}
                        employmentJobVacancy={post?.EmploymentJobVacancy}
                      />
                    </CardContent>
                  )}
                  {post?.PostTypeId == 7 && (
                    <CardContent
                      sx={{ my: "1px",mx:0, px:1.5,py: "1px" }}
                      onClick={() => {
                        // viewDetails(jobVacancy.id);
                      }}
                      // sx={{textAlign: "left",pl:2}}
                    >
                     {(post?.Articles?.length>0)?<ArticlePostDetails post={post} article={post?.Articles[0]} />:null}
                    </CardContent>
                  )}

                  {post?.Attachments && (
                    <>
                      <PhotoSlider
                        images={CommonServices?.getPostAttachments({attachments:post?.Attachments})?.map((item) => ({
                          src: item,
                          key: item,
                        }))}
                        visible={photoSliderVisible}
                        onClose={() => {
                          setPhotoSliderVisible(false);
                          // navigateComp(-1)
                        }}
                        index={photoSliderIndex}
                        onIndexChange={setPhotoSliderIndex}
                      />
                      <div className={"FbImageLibrary"}>
                        <FbImageLibrary
                          images={CommonServices?.getPostAttachments({attachments:post?.Attachments,thumbnail:true})}
                          onClickEach={({ src, index }) => {
                            // let path="/post/"+jobVacancy?.id;
                            // window.history.pushState(null, "Job Details", path);
                            setPhotoSliderIndex(index);
                            setPhotoSliderVisible(true);
                          }}
                          hideOverlay={true}
                        />
                      </div>
                    </>
                  )}
                  
                  {/* COmmments */}
                  {/* COmmments */}

                 {!isVisible&& <CardContent
                    // sx={{ marginBottom: "-11px" }}
                    onClick={() => {
                      // viewDetails(jobVacancy.id);
                    }}
                    // sx={{textAlign: "left",pl:2}}
                  >
                    {/* <Divider sx={{ my: 0 }} /> */}
 
                  {(post?.PostTypeId==3)&&  <Grid
                         
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                      >
                        <Grid item  > </Grid>  
                          <Grid item sx={{ position:"fixed",  bottom:props?.post?"30px":"60px",marginLeft:"-14px" }}>
                          <MKBox sx={{ }}>
                            <Stack     elevation={0} 
                                  direction="column"
                                  // justifyContent="flex-end"
                                  alignItems="center"
                                  spacing={0.5}
                                > 
                                    <PostDetailEngagementSection />
                               </Stack>
                           </MKBox>
                        </Grid>   
                     </Grid>  
                   }
{/* 
                    <Grid 
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                      >

                        <Grid item  >  </Grid>  
                          <Grid item sx={{ position:"fixed",  bottom:props?.post?"30px":"60px",marginRight:'-14px' }}>
                          <MKBox sx={{ }}>
                            <Stack     elevation={0} 
                                  direction="column"
                                  // justifyContent="flex-end"
                                  alignItems="center"
                                  spacing={0.5}
                                > 
                                 
                                <Avatar
                                  onClick={() => navigateComp("/" + post?.Profile?.userName)}
                                  sx={{ width: 34, height: 34, marginBottom:"15px" }}
                                  src={post?.Profile?.profileImageUrl}
                                  aria-label="recipe"
                                /> 
                                
                                 {false?<Favorite  
                                  sx={{ width: 30, height: 30, color:red[500]}}
                                    disabled={!(myCurrentProfile)}
                              onClick={() => {
                                // setIReacted(!iReacted);
                                // removeReaction(1);
                              }}  />:
                                  <Favorite  
                                  sx={{ width: 30, height: 30, color:"white" }}
                                  disabled={!(myCurrentProfile)}  onClick={() => {
                                // setIReacted(!iReacted);
                                // addReaction(1);
                              }}   />   
                                  }
                                <Typography variant="h6">{approx(post.reactionsCount||0) }</Typography>  

                                    <Comment 
                                       disabled={!(myCurrentProfile)}
                                      onClick={() => {
                                        var element = document.getElementById("comments");
                                            element.scrollIntoView();

                                        // targetRef?.scrollIntoView();
                                        // setOpenedDialog("comments");
                                        // let data = {
                                        //   dialogId: "PostCommentsDialogBox",
                                        //   data: { post: jobVacancy  },
                                        // };
                                        // updateCurrentUser("currentGlobalDialogBoxData", data);
                                      }} 
                                       sx={{ width: 28, height: 28,  color: blue[500] }} />  
                                   <Typography variant="h6">{approx(post.commentsCount||0) }</Typography>  

                                    <BookmarkBorder  disabled={!(myCurrentProfile)}    sx={{width: 30, height: 30, color: green[500] }} />  
                                    <Typography sx={{marginTop:"-4px"}}  variant="h6">{approx(post.commentsCount||0) }</Typography>  

                                    <Reply fontSize="large" sx={{width: 30, height: 30,  color: green[500] }} />  
                                   <Typography variant="h6">{approx(post.commentsCount||0) }</Typography>  
                                   
                               </Stack>
                           </MKBox>
                        </Grid>   
                     </Grid>   */}
                     
                  </CardContent> }
                     <Box sx={{ width: '100%' }}>   
                  
                     {/* {post?.PostTypeId == 3 && */}
                      <Box sx={{ mx:1}} ref={targetRef}>   
                    <PostDetailEngagementSection  />
                     </Box>
                     {/* } */}
                     
                {<PostEngagementSection />} 
                    {(post?.Chats?.length)?<>
                          <Divider sx={{ my: 2}} />
                          {/* {<CommentInput/>} */}
                          <Typography   id="comments" variant="h6" component="div" sx={{ mx:2,flexGrow: 1 }}>
                            Last Conversation 
                          </Typography>  
                          <Messages /> 
                    
                    </>:null}
                    <Divider sx={{ my: 2}} />
                    {<CommentInput/>}
                    <Typography   id="comments" variant="h6" component="div" sx={{ mx:2,flexGrow: 1 }}>
                      Comments 
                    </Typography> 

                      {post && (
                        <> 
                          <Comments  postId={post?.id} />
                        </>
                      )}

                   </Box> 

    </Card></Grid>
        <Grid
            item
            sm={12}
            xs={12}
            md={4}
            lg={3.5}
            xl={2.3}
            display={{ xs: "12", sm: "12", md: "flex" }}>
          
        </Grid>
      </Grid>
    </>
  }

  const applyComponent = () => {
    return ( 
            <>
              {isUserLoggedIn ? (
                <>
                  {myCurrentProfile ? ( 
                      <>
                        {/* {isJobAlreadyApplied(post?.EmploymentJobVacancy?.id) ? ( */}
                        {false ? (
                          <>
                            <MKButton
                            size="small"
                              disabled={post?.EmploymentJobVacancy?.isJobClosed}
                              // onClick={() => {
                              //   navigateComp("/profile/my-applications");
                              // }}
                              // variant="outlined"
                              // color="success" 
                              sx={{ m: 1 }}
                            >
                              Already Applied
                            </MKButton>
                            <MKButton
                            size="small"
                              endIcon={<ContactMail />}
                              disabled={post?.EmploymentJobVacancy?.isJobClosed}
                              onClick={() => {
                                navigateComp("/"+post?.Profile?.userName);
                              }}
                              variant="outlined"
                              color="primary" 
                              sx={{ m: 1 }}
                            >
                              Contact Employer
                            </MKButton>
                          </>
                        ) : (<>
                          {post?.EmploymentJobVacancy?.isJobClosed?
                            <MKTypography display="block" variant="button" color="warning" fontWeight="regular">
                              <strong> {"Job Closed"} </strong>
                            </MKTypography>
                           : 
                            <MKButton
                            endIcon={<Send />}
                            disabled={post?.EmploymentJobVacancy?.isJobClosed}
                            // onClick={() => setOpenedDialog("sendRequest")}
                            onClick={() => {
                              navigateComp("/jobs/"+post?.EmploymentJobVacancy?.id+"/apply");
                            }}
                            variant="gradient"
                            color="success"
                            size="small"
                            sx={{ m: 1 }}
                          >
                            Apply
                          </MKButton>}
                        </>)}
                      </> 
                  ) : (
                    <MKButton
                    size="small"
                      //  disabled={employmentJobVacancy.isJobClosed}
                      variant="gradient"
                      color="success" 
                      sx={{ my: 2 }}
                      onClick={() => {
                        let data = {
                          dialogId: "SwitchProfileDialogBox",
                          data: {},
                        };
                        updateContext("currentGlobalDialogBoxData", data);
                        //   setFriendshipTab("suggestions")
                        //  setDrawerOpened("freindship")
                      }}
                      // sx={{ color: ({ palette: { dark } }) => dark.main }}
                    >
                      Enter to profile to Apply
                    </MKButton>
                  )}
                </>
              ) : ( 
                  <MKButton
                     size="small"
                    //  disabled={employmentJobVacancy.isJobClosed}
                    variant="gradient"
                    color="success" 
                    sx={{ my: 2 }}
                    component={Link}
                    // variant="outlined"
                    // color="default"
                    to={"/login?redirectTo=/pages/jobs"}
                    // sx={{ color: ({ palette: { dark } }) => dark.main }}
                  >
                    Login and Apply 
                  </MKButton> 
              )}
            </> 
    );
  };

  return (
    <>
      {post ? 
        <>   
        
    <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // TransitionComponent={Fade}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      > 
       
      {postMenuListsComp()}
       
      </Menu>
      
  <InView as="div" onChange={(inView, entry) => {
    console.log("gggggggg",inView)
    if(inView&&!iGotImpression){addImpression()}}}>

  </InView>
            <MKBox display="flex" flexDirection="column">
              <Card onClick={()=>{  addView();}}
                sx={{ boxShadow: 0 }}
                //  sx={{ maxWidth: 345 }}
              > 

                  {props?.post?cardBody():
                  postDetailsComp()  }
 
              </Card>
            </MKBox> 
        </>: null}
 

      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          handleDialogClose();
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{parse(moreText.content)}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              handleDialogClose();
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openedDialog == "comments"}
        onClose={() => {
          handleDialogClose();
        }}
        scroll={"paper"}
        sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        maxWidth="sm"
        fullScreen={isMobile ? true : false}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              handleDialogClose();
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Comments
          </Typography>
        </Toolbar>
         <DialogContent
              sx={{
                // minHeight: isMobile ? "300px" : "460px",
                p: "0px",
                m: "0px",
              }}
            >

            {post && (
                      <>
                        <MKBox display="flex" flexDirection="column">
                          <Card onClick={()=>{  addView();}}
                            sx={{ boxShadow: 0 }}
                            //  sx={{ maxWidth: 345 }}
                          >   

                              {cardBody()}
                              {/* <PostDetailsComp/> */}
                        <Comments postId={post.id} />
            
                          </Card>
                        </MKBox>  
                      </>
                    )}


          </DialogContent>
          <DialogActions>
                    {<CommentInput/>}
            {/* </> */}
          </DialogActions>
        {/* <DialogActions sx={{ px: 0 }} shadow={"lg"}>
          <WriteComment />
        </DialogActions> */}
      </Dialog>


      <Dialog
        open={openedDialog == "postDetails"}
        onClose={() => {
          handleDialogClose();
        }}
        scroll={"paper"}
        sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        maxWidth="sm"
        fullScreen={true }
      >
       
        {post && (
          <>
            
                  {postDetailsComp()}  
          </>
        )}
        {/* <DialogActions sx={{ px: 0 }} shadow={"lg"}>
          <WriteComment />
        </DialogActions> */}
      </Dialog>

      <Dialog
        open={openedDialog == "reactions"}
        onClose={() => {
          handleDialogClose();
        }}
        scroll={"paper"}
        sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        maxWidth="xs"
        fullScreen={isMobile ? true : false}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              handleDialogClose();
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Reactions
          </Typography>
        </Toolbar>
        {post && (
          <>
            {/* <DialogTitle>Reactions</DialogTitle> */}
            <Reactions postId={post.id} />
          </>
        )}
        {/* <DialogActions sx={{ px: 0 }} shadow={"lg"}>
          <WriteComment />
        </DialogActions> */}
      </Dialog>

      <Dialog
        open={openedDialog == "deletePost"}
        onClose={() => {
          handleDialogClose();
        }}
        scroll={"paper"}
        sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        maxWidth="xs"
        // fullScreen={isMobile ? true : false}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              handleDialogClose();
            }}
          >
            <ArrowBack />
          </IconButton>
             <DialogTitle>Delete Post?</DialogTitle> 
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Delete Post
          </Typography> */}
        </Toolbar>
        {post && (
          <>
             <DialogContent> 
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                This can’t be undone and it will be removed from your profile, the timeline of any accounts that follow you, and from search results. 
            </Typography>
             </DialogContent>
          </>
        )}
        <DialogActions sx={{ px: 0 }} shadow={"lg"}>
          <MKButton color="primary" variant="contained" startIcon={<Delete />}  onClick={() => {
            CommonApiServices?.deletePost(post?.id)
              handleDialogClose();
            }}>Delete</MKButton>
          <MKButton startIcon={<Cancel />}  onClick={() => {
              handleDialogClose();
            }}>Cancel</MKButton>
           
        </DialogActions>
      </Dialog>

      <Dialog
        open={openedDialog == "sharePost"}
        onClose={() => {
          handleDialogClose();
        }}
        scroll={"paper"}
        sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        maxWidth="xs"
        // fullScreen={isMobile ? true : false}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => { 
              handleDialogClose();
            }}
          >
            <ArrowBack />
          </IconButton>
             <DialogTitle>Share Post?</DialogTitle> 
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Delete Post
          </Typography> */}
        </Toolbar>
        {post && (
          <>
             <DialogContent> 
            {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                This can’t be undone and it will be removed from your profile, the timeline of any accounts that follow you, and from search results. 
            </Typography> */}
            <MKBox sx={{ "& .makeStyles-copyContainer": { width: "250px !important" } }}>
                      <Divider />
                      <Grid 
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                          <Grid item>
                            <FacebookShareButton
                              url={postPath}
                              onClick={() => {
                                addShare("facebook")
                                handleDialogClose();
                              }}
                              className="Demo__some-network__share-button"
                            >
                              <FacebookIcon size={32} round />
                            </FacebookShareButton>
                          </Grid>
                          <Grid item>
                            <TelegramShareButton
                              onClick={() => {
                                addShare("telegram")
                                handleDialogClose();
                              }}
                               url={postPath}
                               className="Demo__some-network__share-button"
                            >
                              <TelegramIcon size={32} round />
                            </TelegramShareButton>
                        </Grid>
                          <Grid item>
                            <WhatsappShareButton
                              onClick={() => {
                                addShare("whatsapp")
                                handleDialogClose();
                              }}
                               url={postPath}
                               className="Demo__some-network__share-button"
                            >
                              <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </Grid>
                          <Grid item>
                            <TwitterShareButton
                              onClick={() => {
                                addShare("twitter")
                                handleDialogClose();
                              }}
                               url={postPath}
                               className="Demo__some-network__share-button"
                            >
                              <TwitterIcon size={32} round />
                            </TwitterShareButton>
                        </Grid>
                        </Grid>

                      {/* <ShareSocial
                        // title={"Share post"}
                        url={window.location.href}
                        // socialTypes={['facebook','twitter','reddit','linkedin']}
                        onSocialButtonClicked={() => {
                          // incrementField(post?.Employmentpost.id, "numberOfShares");
                        }}
                        socialTypes={[
                          "facebook",
                          "telegram",
                          "twitter",
                          "linkedin",
                          "whatsapp",
                          // "instapaper",
                        ]}
                      /> */}
                    </MKBox>
             </DialogContent>
          </>
        )}
        <DialogActions sx={{ px: 0 }} shadow={"lg"}>
          {/* <MKButton color="primary" variant="contained" startIcon={<Delete />}  onClick={() => {
            CommonApiServices?.deletePost(post?.id)
              handleDialogClose();
            }}>Delete</MKButton> */}
          <MKButton startIcon={<Cancel />}  onClick={() => {
              handleDialogClose();
            }}>Cancel</MKButton>
           
        </DialogActions>
      </Dialog>

      <Dialog
        open={openedDialog == "repost"}
        onClose={() => {
          handleDialogClose();
        }}
        scroll={"paper"}
        sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        maxWidth="xs"
        // fullScreen={isMobile ? true : false}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => { 
              handleDialogClose();
            }}
          >
            <ArrowBack />
          </IconButton>
             <DialogTitle>Repost?</DialogTitle> 
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Delete Post
          </Typography> */}
        </Toolbar>
        {post && (
          <>
             <DialogContent> 
            {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                This can’t be undone and it will be removed from your profile, the timeline of any accounts that follow you, and from search results. 
            </Typography> */}
            {/* <MKBox sx={{ "& .makeStyles-copyContainer": { width: "250px !important" } }}> 
             
              <MKBox m={isMobile?0:2} className={postStyles.postTextArea}> 
                <CKEditor
                        editor={ ClassicEditor }
                        // data="<p>Hello from CKEditor&nbsp;5!</p>"
                        onReady={ editor => {
                            // You can store the "editor" and use when it is needed.
                            // console.log( 'Editor is ready to use!', editor.getData() );
                        } }
                        onChange={ ( event,editor ) => {
                            // console.log( event );
                            setRepostMessage(editor?.getData());
                        } } 
                        config={{ placeholder:'Write comment...' }}
                    />
               </MKBox>
               </MKBox> */}
             </DialogContent>
          </>
        )}
        <DialogActions sx={{ px: 0 }} shadow={"lg"}>
          <MKButton color="success" variant="contained" startIcon={<Repeat />}  onClick={() => {
            
    if(!checkAndGoToLoginOrRegisterBox())return 
      let data = {
        PostId: post?.id,
        OriginalPostId: post?.id,
        // ReactionTypeId: 1,
        EngagementAreaId: 1,
        // EngagedProfileId: data?.EngagedProfileId||null, 
        ProfileId:post?.ProfileId
      };
            CommonApiServices?.repost(data)
              handleDialogClose();
            }}>Repost</MKButton>
          <MKButton startIcon={<Cancel />}  onClick={() => {
              handleDialogClose();
            }}>Cancel</MKButton>
           
        </DialogActions>
      </Dialog> 
    </>
  );
}

export default PostCard;
