import Grid from "@mui/material/Grid";
import { isMobile } from "react-device-detect";

import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// Images
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";

function ArticlePostDetails(props) {
  const [currentData, updateContext] = useCurrentUser();
  const { myJobApplications } = currentData;
  const [forceRerender, setForceRerender] = React.useState(false);
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [post, setPost] = React.useState(null);
  const [article, setArticle] = React.useState(null);

  useEffect(() => {
    if (props?.article) {
      setArticle(props?.article);
    }
    if (props?.post) {
      setPost(props?.post);
    }
    setForceRerender(!forceRerender);
    console.log("  article short", props);
  }, [props?.article]);

  return (
    <>
      {article?.body ? <MKBox>{parse(article.body)}</MKBox> : null}

      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{parse(moreText.content)}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
      {forceRerender}
    </>
  );
}

export default ArticlePostDetails;
