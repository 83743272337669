 

import parse from "html-react-parser";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";
import React from "react";

// Rental page components
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { Link, useNavigate, useParams } from "react-router-dom";
// Direct React component imports

import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";


import { CommonServices } from "Api-Services/commonServices";
import { HttpService } from "Api-Services/httpService";
import { useQuery } from "@tanstack/react-query";
import { makeStyles } from "@mui/styles"; 
// Define the custom styles using makeStyles
const useStyles = makeStyles(() => ({
  swiperButtonPrev: {
    color: "black",
    bottom: "150px",
    top: "auto",
    transform: "translateY(0)",
    width: "30px",
    height: "30px",
    left: "-54px", // Adjust the position of the left arrow
  },
  swiperButtonNext: {
    color: "black",
    bottom: "150px",
    top: "auto",
    transform: "translateY(0)",
    width: "30px",
    height: "30px",
    right: "-54px", // Adjust the position of the right arrow
  },
}));
function WorkHistoriesList(props) {

  const classes = useStyles(); // Use the defined styles
  const [currentData,updateContext] = useCurrentUser();
  const {   myCurrentProfile } = currentData;
  const navigate = useNavigate();
  
  let {data:workHistories} = useQuery({
    queryKey: ["/api/workhistory/find/"+props?.profileId],
    queryFn: async () => {
      return await HttpService.getApiData("/api/workhistory/find/"+props?.profileId);
    },
  });
 
  const checkAndGoToLoginOrRegisterBox=()=>{
    if(myCurrentProfile){
      return true
    }
    else{
      let data = {
        dialogId: "SwitchProfileDialogBox",
        data: {},
      };
      updateContext("currentGlobalDialogBoxData", data);
    return false
    }
  }
  
return <>  
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item sx={{ mb: 2 }}>
          <MKTypography variant="subtitle2">{workHistories?.length||"No "} Work Histories</MKTypography>
        </Grid> 
      </Grid> 
      <List> 
            {workHistories?.map((WorkHistory,index)=>(props?.isOverview?(index<2):true)&&<>
            <ListItem
              secondaryAction={(myCurrentProfile?.id==props?.profileId)?
                <Button
                  size={"small"}
                  onClick={() => {
                    try {
                    if(props?.editWorkHistory){
                      props?.editWorkHistory(WorkHistory) 
                    }
                    else navigate("/profile/Work-history");
                    } catch (error) {
                    }
                  }}
                  // variant={"outlined"}
                  // color="warning"
                >
                  Edit 
                </Button>:null
              }
              >
                {/* <ListItemIcon>
                  <School />
                </ListItemIcon> */}
                <ListItemText
                  secondary={<>
                    <MKTypography variant="subtitle2">
                   { WorkHistory?.Address?.Country?.name?WorkHistory?.Address?.Country?.name:""}
                   { WorkHistory?.Address?.City?.name?" - "+WorkHistory?.Address?.City?.name:""}
                   {<small style={{color:"grey"}} > {" "+(CommonServices?.parseDate(WorkHistory?.startDate)||"")+"-"+(CommonServices?.parseDate(WorkHistory?.endDate)||"" )} </small>}
                  </MKTypography></>}
                  primary={<>
                    <MKTypography variant="h6">
                    { WorkHistory?.employerName?WorkHistory?.employerName:""}
                      </MKTypography>
                    <MKTypography variant="subtitle1">
                   { WorkHistory?.Job?.title?WorkHistory?.Job?.title:""}
                   { WorkHistory?.jobTitle?" - "+WorkHistory?.jobTitle+"":""}

                    </MKTypography>
                  </>
                  }
                /> 
              </ListItem> 
              <Divider/>
                </>)}
        </List> 
  </>
}

export default WorkHistoriesList;
