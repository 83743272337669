// @mui material components
import Container from "@mui/material/Container";
import React, { useEffect } from "react";
import imageCompression from "browser-image-compression";
// Images
// import bgImage from "assets/images/bg-rental.jpeg";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar, 
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  ArrowBack,
  HistoryTwoTone,
  HomeWork,
  PostAdd,
  Send,
  ShoppingBag,
  Work,
} from "@mui/icons-material";
import MKButton from "components/MKButton";
import { isMobile } from "react-device-detect";
import MKEditor from "components/MKEditor";
import MKBox from "components/MKBox";
import { DropzoneArea } from "mui-file-dropzone";
import { makeStyles } from "@mui/styles";
import { HttpService } from "Api-Services/httpService";
import { CommonServices } from "Api-Services/commonServices";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const postStylesDropzone = makeStyles((theme) => ({
  root: {
    "& .css-9igssw-MuiDialogContent-root": {
      padding: "5px",
    },
    "& .ql-toolbar.ql-snow ": {
      padding: "0px",
    },
  },
  dropzoneClass: {
    // maxHeight: "124px  !important",
    minHeight: "5px !important",
    "& .css-f8f0pk-MuiSvgIcon-root ": {
      height: "30px",
      width: "30px",
    },
    "& .css-1g96e5k-MuiTypography-root": {
      fontSize: "0.9rem",
      fontWeight: "700",
      marginTop: "4px",
      marginBottom: "2px",
    },
  },
  postTextArea: {
    "& .ck-editor__editable": {minHeight: "500px"}
    // "& .ql-editor ": {
    //   minHeight: "120px",
    // },
  },
}));

// {"& .css-1agvk75" :{
//   minHeight: "250px"}}
function CreateAnArticle(props) {
  const postStyles = postStylesDropzone();
  const [currentData] = useCurrentUser();
  const navigate = useNavigate();
  const { myJobSeekerInformation, currentUser, postPrivacies } = currentData;
  const [postPrivacyId, setPostPrivacyId] = React.useState(1);
  const [postTypeId, setPostTypeId] = React.useState(7);
  const [postMessage, setPostMessage] = React.useState("");
  const [articleTitle, setArticleTitle] = React.useState("");
  const [articleBody, setArticleBody] = React.useState("");
  const [stepNumber, setStepNumber] = React.useState(1);
  const [postAttachments, setPostAttachments] = React.useState([]);

  useEffect(() => {}, []);

  const createPost = async () => {
    let res = [];
    try {
      let isPostWithAttachment = false;
      let relativePath = "/api/post/newarticle";
      let filesData = postAttachments || [];
      console.log("postAttachments", postAttachments);
      let filesDataNew = [];
      let additionalData = [];
      let postData = {
        // title :DataTypes.TEXT,
        // profileId :postMessage,
        message: postMessage,
        PostTypeId: postTypeId,
        PostPrivacyId: postPrivacyId,
         title: articleTitle,
         destination: postMessage,
         body: articleBody,
      };
      const formData = new FormData();

      // return
      const options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        fileType: "image/jpeg",
      };

      console.log("filesData", filesData);
      if (filesData) {
        if (filesData.length) {
          relativePath = "/api/post/newarticle";
          isPostWithAttachment = true;
          for (let index = 0; index < filesData.length; index++) {
            const imageFile = filesData[index];
            console.log("img imageFile", imageFile);
            // imageFile.name="mesfin.jpg"
            const compressedFile = await imageCompression(imageFile, options);
            // const compressedFile = imageFile;
            filesDataNew.push(compressedFile);
          }

          console.log("compressedFiles", filesDataNew);
          let filePath = "./public/files/post_files";
          const aa = _.filter(filesDataNew, (file) => {
            console.log("file", file);

            formData.append("file", file, file.name);
            formData.append("fileTypeExtension", ".jpg");
            formData.append("folderPath", filePath);
            return file;
          });

          let postDataRes = Object.entries(postData);
          postDataRes.forEach((element) => {
            formData.append(element[0], element[1]);
          });
          // formData.append("fileTypeExtension", ".jpg");
          // formData.append("folderPath", filePath);

          additionalData = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            // onUploadProgress: progressEvent => {
            //   if(progressEvent){
            //     // console.log("progressEvent", Math.round((progressEvent.loaded * 100) / progressEvent.total))
            //       setuploadPercentage(parseInt(
            //       Math.round((progressEvent.loaded * 100) / progressEvent.total)
            //         ))
            //         }
            // }
          };
        }
      }
      let getHeaderAndURLRes = await HttpService.getHeaderAndURL();
      let baseUrl = getHeaderAndURLRes.baseUrl;

      console.log("img chng", formData, additionalData);
      const data = isPostWithAttachment ? formData : postData;
      let postFeedRes = await postDocuments("uploadFile", relativePath, data, additionalData);
      if (postFeedRes) {
        if (postFeedRes.record) {
          if (postFeedRes.record.length) {
            postFeedRes.record.forEach((imgFile) => {
              // var destination=imgFile.destination.replace('./public',"");
              var destination = imgFile.destination.replace("./public", "/public");
              var filename = "/" + imgFile.filename;
              var pathN = baseUrl + destination + filename;
              var imgpath = pathN;
              res.push(imgpath);
            });
          }
        }
      }
      console.log("uploadFiles  ", postFeedRes, res);
      // props?.onClose();
    } catch (err) {
      console.log("uploadFiles err", err);
    }
    return res;
  };

  const handleFileChange = async (filesData) => {
    try {
      if (filesData) {
        if (filesData.length) {
          setPostAttachments(filesData);
        }
      }
    } catch (error) {
      console.log("handleFileChange err", error);
    }
  };

  const postDocuments = async (documentName, relativePath, data, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    documentsResponse = await HttpService.postService(relativePath, data, additionalData || {}, "");

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    } else {
      responseD.record = documentsResponse.data;
    }
    return responseD;
  };

  return (
    <div className={postStyles.root}>
      {/* <Dialog open={open} onClose={() => {
          setOpen(false);
        }}> */}

      {/* <Box sx={{ flexGrow: 1 }}> */}
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
             navigate(-1);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
           {!isMobile?" Create an ":""}Article
          </Typography>
        {(stepNumber > 1)&& <MKButton  sx={{mx:1}}
                size="small"
                disabled={!(stepNumber > 1)}
                color="success"
                onClick={() => {
                  setStepNumber(stepNumber - 1);
                }}
              >
                Back
              </MKButton>}

              {stepNumber == 2 && (
                <MKButton
                  size="small"
                  color="success"
                  disabled={!(articleBody && articleTitle && postAttachments?.length && postMessage)}
                  onClick={() => {
                    createPost();
                  }}
                  startIcon={<Send />}
                >
                  Post
                </MKButton>
              )} 
            {stepNumber == 1 && (
              <MKButton
                size="small"
                color="success"
                disabled={!(articleTitle && postAttachments?.length && postMessage)}
                onClick={() => {
                  setStepNumber(stepNumber + 1);
                }}
              >
                Next
              </MKButton>
            )}
        </Toolbar>
        <Divider sx={{ my: "0rem" }} />
      </AppBar>
      {/* </Box> */}
      {/* <DialogTitle>Create Post</DialogTitle> */}

      {/* <Container sx={{px:0,py:isMobile?"3px":"20px"}}>  */}
      {(currentUser && myJobSeekerInformation ? true : false) && (
        <List
          sx={{
            mx: isMobile ? "1px" : "12px",
            width: "100%",
            py: isMobile ? "8px" : "20px",
            px: "12px",
            bgcolor: "background.paper",
          }}
        >
          <ListItem
          // secondaryAction={
          //   <IconButton onClick={handleClickOpen} edge="end" aria-label="delete">
          //     <PostAdd />
          //   </IconButton>
          // }
          >
            <ListItemAvatar>
              <Avatar src={myJobSeekerInformation.profileImageUrl}></Avatar>
            </ListItemAvatar>
            <ListItemText
              // onClick={handleClickOpen}
              primary={CommonServices.getProfileName(myJobSeekerInformation)}
              // primary="What is on your mind?"
              secondary={
                (postPrivacies ? (postPrivacies.length ? true : false) : false) ? (
                  <FormControl sx={{}}>
                    <Select
                      value={1}
                      onChange={(e) => setPostPrivacyId(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {postPrivacies.map((privacyData) => (
                        <MenuItem value={privacyData.id}>{privacyData.title}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : null
              }
            />
          </ListItem>
        </List>
      )} 
        {/* </Container> */}
        {stepNumber < 2 ? (
          <>
            <MKBox spacing="2" mb={1} className={postStyles.postTextArea}>
              <TextField
                fullWidth
                onChange={(e) => {
                  setArticleTitle(e.target.value);
                }}
                id="outlined-basic"
                label="Article Title"
                variant="outlined"
              />
            </MKBox>
            <MKBox spacing="2" mb={1} className={postStyles.postTextArea}>
              <TextField
                fullWidth
                multiline
                rows={3}
                onChange={(e) => {
                  setPostMessage(e.target.value);
                }}
                id="outlined-basic"
                label="Short Description"
                variant="outlined"
              />
            </MKBox>
            <MKBox spacing="2" mb={1}>
              <DropzoneArea
                showAlerts={["error", "info"]}
                showPreviews
                filesLimit={1}
                maxFileSize={25000000}
                showPreviewsInDropzone={false}
                dropzoneClass={postStyles.dropzoneClass}
                acceptedFiles={["image/*"]}
                dropzoneText={isMobile ? "Add Cover Picture" : "Add Cover Picture"}
                // onChange={(files) => console.log("Files:", files)}
                onChange={(e) => {
                  handleFileChange(e);
                }}
              />
            </MKBox>
          </>
        ) : (
          <MKBox m={isMobile?0:2} className={postStyles.postTextArea}> 
            {" "}
            <CKEditor
                    editor={ ClassicEditor }
                    // data="<p>Hello from CKEditor&nbsp;5!</p>"
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor.getData() );
                    } }
                    onChange={ ( event,editor ) => {
                        // console.log( event );
                        setArticleBody(editor?.getData());
                    } }
                    onBlur={ ( event, editor ) => {
                        // console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        // console.log( 'Focus.', editor );
                    } }
                    config={{ placeholder:'Write your story here...' }}
                />
            {/* <MKEditor
              onChange={(e) => {
                console.log("eee", e);
                setArticleBody(e);
              }}
            /> */}
          </MKBox>
        )} 
       
      {/* </Dialog>  */}
    </div>
  );
}

export default CreateAnArticle;
