import { Apartment, Apps, CarRentalOutlined, Home, ShoppingCart, Work } from "@mui/icons-material";
import { Box, CircularProgress, LinearProgress } from "@mui/material";
import MarketPlace from "pages/Search/MarketPlace";
import { Suspense, lazy } from "react";

const ExtremeLandingPage = lazy(() => import("pages/LandingPages/Extreme"));
const SearchJob = lazy(() => import("pages/Search/SearchJobs"));
const SearchEmployee = lazy(() => import("pages/Search/SearchEmployee"));

function Loading() {
  return (
    <Box sx={{ width: "100%" ,m:5}}>
      <CircularProgress />
    </Box>
  );
}

const routes = [
  {
    isAllowedForAgent: true,
    name: "Home",
    route: "/home",
    icon: <Home />,
    component: (
      <Suspense fallback={<Loading />}>
        <ExtremeLandingPage />
      </Suspense>
    ),
  },

  {
    isAllowedForAgent: true,
    name: "Jobs",
    route: "/jobs",
    component: (
      <Suspense fallback={<Loading />}>
        <SearchJob />
      </Suspense>
    ),
    icon: <Work />,
  }, 
  {
    isAllowedForAgent: true,
    name: "Market Place",
    route: "/marketplace",
    icon: <ShoppingCart />, //"feed_outlined",
    component: (
      <Suspense fallback={<Loading />}>
        <MarketPlace />
      </Suspense>
    ),
  },
  {
    isAllowedForAgent: true,
    name: "Properties",
    route: "/properties",
    icon: <Apartment />, //"feed_outlined",
    component: (
      <Suspense fallback={<Loading />}>
        <ExtremeLandingPage />
      </Suspense>
    ),
  },
  {
    isAllowedForAgent: true,
    name: "Vehicle",
    route: "/vehicle",
    icon: <CarRentalOutlined />, //"feed_outlined",
    component: (
      <Suspense fallback={<Loading />}>
        <ExtremeLandingPage />
      </Suspense>
    ),
  },
  {
    isAllowedForAgent: true,
    name: "Find",
    route: "/search",
    icon: <Apps />,
    component: (
      <Suspense fallback={<Loading />}>
        <SearchEmployee />
      </Suspense>
    ),
  },
];

export default routes;
