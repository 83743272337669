// @mui material components
import Container from "@mui/material/Container";
import React, { useEffect } from "react";

// Images
// import bgImage from "assets/images/bg-rental.jpeg";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Link  } from "react-router-dom";
import {   ArrowDropDownCircle, PostAdd } from "@mui/icons-material";
import MKButton from "components/MKButton";
import { isMobile } from "react-device-detect"; 
import MKBox from "components/MKBox"; 
import "swiper/swiper.min.css";
import { FreeMode, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useQuery } from "@tanstack/react-query";
import { HttpService } from "Api-Services/httpService";
function PostsHeader(props) { 
  const [currentData,updateCurrentUser] = useCurrentUser();
  const { myCurrentProfile, myCurrentProfileLoading, isUserLoggedIn } = currentData;
  // const [open, setOpen] = React.useState(false);
  const [postTypeId, setPostTypeId] = React.useState(false);
  const [forceRerender, setForceRerender] = React.useState(false);
  const [filter, setFilter] = React.useState({});
 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setForceRerender(!forceRerender);
    // console.log("websitePagesContent",websitePagesContent)
  }, [isUserLoggedIn, myCurrentProfile]);

  useEffect(() => {
    if(props?.filter){
    setFilter(props?.filter)
    setForceRerender(!forceRerender);}
    // console.log("props?.allPostTypeIds",props?.allPostTypeIds)
  }, [props]);

  const { data: postTypes } = useQuery({
    queryKey: ["/api/post/posttype/all"],
    queryFn: async () => {
      return await HttpService.getApiData("/api/post/posttype/all");
    },
  });


  const goToListing = (filterData) => {
    try {
        // setAnchorEl(null);
        // handleMobileMenuClose();
        let filterD= filter
        if(filterData?.postTypeIds){
          filterD["postFilter"]={postTypeIds:filterData?.postTypeIds} 
        }
        if(filterData?.keyword){
          filterD["postFilter"]={keyword:filterData?.keyword} 
        } 
        // filter=JSON.stringify(filter)
        setFilter(filterD)
        props?.onFiltersChange(filterD)
        // searchString=searchString+"&filter="+filter
        // let path= "/home"+ searchString
        // navigate(path);
          
    } catch (error) {
      
    }
  };

  return (
    <>
    
      {forceRerender}
    { props?.showCreatePost? <>
      {myCurrentProfileLoading ? (
        <MKBox>
          <List
            sx={{
              width: "100%",
              py: isMobile ? "8px" : "15px",
              px: "12px",
              bgcolor: "background.paper",
            }}
          >
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="delete">
                  <Skeleton animation="wave" width={15} height={25} />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <Skeleton animation="wave" variant="circular" width={40} height={40} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Skeleton width="100%">
                    <Typography>.</Typography>
                  </Skeleton>
                }
                // primary="What is on your mind?"
                // secondary="Jan 9, 2014"
              />
            </ListItem>
          </List>
        </MKBox>
      ) : (
        myCurrentProfile && (
          <MKBox>
            <List
              sx={{
                width: "100%",
                py: isMobile ? "8px" : "12px",
                px: "12px",
                bgcolor: "background.paper",
              }}
            >
              <ListItem
                secondaryAction={
                  <IconButton 
                  onClick={() => {
                   let data = {
                     dialogId: "CreatePostDialogBox",
                     data: {},
                   }; // 
                   updateCurrentUser("currentGlobalDialogBoxData", data);
                   //   setFriendshipTab("suggestions")
                   //  setDrawerOpened("freindship")
                 }} edge="end" aria-label="delete">
                    <PostAdd />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar
                    component={Link}
                    to={"/" + myCurrentProfile.userName}
                    src={myCurrentProfile.profileImageUrl}
                  ></Avatar>
                </ListItemAvatar>
                <ListItemText
                  onClick={() => {
                   let data = {
                     dialogId: "CreatePostDialogBox",
                     data: {},
                   }; // 
                   updateCurrentUser("currentGlobalDialogBoxData", data);
                   //   setFriendshipTab("suggestions")
                   //  setDrawerOpened("freindship")
                 }}
                  primary={
                    <MKButton
                      style={{ backgroundColor: "rgba(240,242,245,1.0)" }} //gainsboro
                      fullWidth
                      variant="filled"
                      color="primary"
                    >
                      Create New Post
                    </MKButton>
                  }
                  // primary="What is on your mind?"
                  // secondary="Jan 9, 2014"
                />
              </ListItem>
            </List>
          </MKBox>
        )
      )}
     </>:null}

      
<MKBox sx={{ mt: 1,
                width: "100%",
                py: isMobile ? 1 :1,
                px:1,
                bgcolor: "background.paper",
              }}>
            
            <Grid container justifyContent="space-between">   
 
 <Grid item xs={11}  justifyContent="center">

            <MKBox  sx={{"& .swiper-slide":{width:"auto"}}}>
                <Swiper
                  // slidesPerView={4}
                  slidesPerView={'auto'}
                  spaceBetween={3} 
                  freeMode={true}
                  lazy={true}
                  // pagination={{
                  //   clickable: true,
                  // }}            
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  modules={[FreeMode, Pagination, Navigation]}
      
                  className="mySwiper" 
                >     <SwiperSlide 
                        onClick={() => { 
                          // navigate("/stories/" + JobSeeker?.id);
                        }}>
                        <MKButton
                            onClick={() => { 
                              setPostTypeId(false);
                              let data={
                                // listingTypeName:"search",
                                postTypeIds:props?.allPostTypeIds||[],
                                // keyword:keyword
                              };
                              goToListing(data); 
                            }}
                          style={{ 
                            color:(!postTypeId)?"white":"grey", //gainsboro
                            backgroundColor:(!postTypeId)?"rgb(105 110 102)":"rgba(240,242,245,1.0)" 
                          }} //gainsboro
                     
                          fullWidth
                          variant="filled"
                          color="success"
                        >
                          All
                        </MKButton>
                        </SwiperSlide> 
                      { postTypes?.map((postType)=>(props?.allPostTypeIds?.includes(postType?.id))&& <SwiperSlide > 
                        <MKButton
                            onClick={() => { 
                              setPostTypeId(postType?.id);
                              let data={
                                // listingTypeName:"search",
                                postTypeIds:[postType?.id],
                                // keyword:keyword
                              };
                              goToListing(data); 
                            }}
                          style={{ 
                            color:(postTypeId==postType?.id)?"white":"grey", //gainsboro
                            backgroundColor:(postTypeId==postType?.id)?"rgb(105 110 102)":"rgba(240,242,245,1.0)" }} //gainsboro
                          fullWidth
                          variant="filled"
                          color="primary"
                        >
                          {postType?.title}
                        </MKButton>
                        </SwiperSlide> )}
                      
                </Swiper>
              </MKBox> 
              </Grid>
 
              <Grid item xs={1} justifyContent="center">
                    <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <ArrowDropDownCircle/>
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        // maxHeight: ITEM_HEIGHT * 4.5,
                        // width: '20ch',
                      },
                    }}
                  >
{ postTypes?.map((postType)=>(props?.allPostTypeIds?.includes(postType?.id))&&
           <MenuItem key={postType} selected={postType?.id==postTypeId} 
                    onClick={(e)=>{
                        setPostTypeId(postType?.id);
                              let data={
                                // listingTypeName:"search",
                                postTypeIds:[postType?.id],
                                // keyword:keyword
                              };
                              goToListing(data); 
                              handleClose(e)
                        }}>
                        {postType?.title}
                      </MenuItem>
                    )}
                  </Menu>
                 </Grid>
             </Grid>
          </MKBox>
           
    </>
  );
}

export default PostsHeader;
