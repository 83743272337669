// @mui material components
import React, { useEffect } from "react";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { AppBar, Box, Dialog, DialogContent, Drawer, Grid, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import SideBarMenuHeader from "./Sections/SideBarMenuHeader";
import SideBarMenu from "./Sections/SideBarMenu";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import SideBarMenuContent from "examples/Navbars/DefaultNavbar/SideBarMenuContent";

// {"& .css-1agvk75" :{
//   minHeight: "250px"}}
function SideBarMenuDialogBox(props) {
  // profile={myCurrentProfile} friendshipTab={friendshipTab}
  const [currentData] = useCurrentUser();
  const navigate= useNavigate();
  const [forceRerender, setForceRerender] = React.useState(true); 
  const [currentProfile, setCurrentProfile] = React.useState(null);
   
  const { currentGlobalDialogBoxData} =  currentData; 
  const params = useParams(); 
  const [dialogOpened, setDialogOpened] = React.useState(false); 
 

  window.addEventListener("popstate", (event) => {setDialogOpened(false)})
  // window.addEventListener("pushstate", (event) => {console.log("pushstate")})
  
  useEffect(async () => { 
    if ( currentGlobalDialogBoxData?.dialogId == "SideBarMenuDialogBox") {
      let path
      path="/menu" ; 
      window.history.pushState(null, "Profile SideBarMenuDialogBox", path);  
      setDialogOpened(true)
      } 
  }, [ currentGlobalDialogBoxData]);

  useEffect(async () => { 
    if ( location.pathname != "/menu") { 
       setDialogOpened(false)
      } 
  }, [ location.pathname]);

  useEffect(async () => {
    let profileR = null;
    if (currentGlobalDialogBoxData) {
      if (currentGlobalDialogBoxData.data) {
        let dataDD = currentGlobalDialogBoxData.data;
        if (dataDD.profile) {
          profileR = dataDD.profile;
        }
        setCurrentProfile(profileR);
        setForceRerender(!forceRerender);
      }
    }
    // console.log("SideBarMenuDialogBox profileR", profileR);

    setForceRerender(!forceRerender);
  }, [currentGlobalDialogBoxData]);

  return (
    <>
      {((currentGlobalDialogBoxData ? (currentGlobalDialogBoxData.dialogId ? true : false) : false)
        ? true
        : false) && (
        <>
          {isMobile ? (
            <Dialog
              open={dialogOpened}
              scroll={"paper"}
              sx={{ "& .MuiDialog-paper": { width: "100%" } }} 
              onClose={() => {
                setDialogOpened(false) 
                navigate(-1)
              }}
              fullScreen={isMobile ? true : false}
            >
              {/* {currentGlobalDialogBoxData.data && (
            <> */}
             
           <AppBar position="static" sx={{ width: "100%", bgcolor: "background.paper" }}>
          <Toolbar>
           
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              setDialogOpened(false) 
              navigate(-1)
            }}
          >
            <ArrowBack />
          </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Menu
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
          </Toolbar>
        </AppBar> 
        <Grid container >
              <SideBarMenuContent
              onClose={() => {
                setDialogOpened(false) 
                navigate(-1)
              }}
                currentGlobalDialogBoxData={currentGlobalDialogBoxData}
                currentProfile={currentProfile}
              />
              </Grid>
              {/* </>
          )} */}
            </Dialog>
          ) : (
            <Drawer
              open={dialogOpened}
              // TransitionComponent={Transition}
              scroll={"paper"}
              anchor={"right"}
              sx={{ "& .MuiDialog-paper": { width: "100%" } }}
              // maxWidth="xs" 
              onClose={() => {
                setDialogOpened(false) 
                navigate(-1)
              }}
              fullScreen={isMobile ? true : false}
            >
              {/* {currentGlobalDialogBoxData.data && (
            <> */}
              <SideBarMenuContent
              onClose={() => {
                setDialogOpened(false) 
                navigate(-1)
              }}
                currentGlobalDialogBoxData={currentGlobalDialogBoxData}
                currentProfile={currentProfile}
              />
              {/* </>
          )} */}
            </Drawer>
          )}
        </>
      )}
      {forceRerender}
    </>
  );
}

export default SideBarMenuDialogBox;
