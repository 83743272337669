
import React, { useEffect } from "react";
import {
  AppBar,
  Avatar, 
  Box, 
  Button, 
  Card, 
  CardActions, 
  CardContent, 
  DialogActions, 
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  Apartment,
  ArrowBack,
  Article,
  CarRental,
  HistoryTwoTone,
  HomeWork,
  PostAdd,
  QuestionAnswer,
  Send,
  ShoppingBag,
  Work,
} from "@mui/icons-material";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import { makeStyles } from "@mui/styles";
const postStylesDropzone = makeStyles((theme) => ({
  root: {
    "& .css-9igssw-MuiDialogContent-root": {
      padding: "5px",
    },
    "& .ql-toolbar.ql-snow ": {
      padding: "0px",
    },
  },
  dropzoneClass: {
    // maxHeight: "124px  !important",
    minHeight: "5px !important",
    "& .css-f8f0pk-MuiSvgIcon-root ": {
      height: "30px",
      width: "30px",
    },
    "& .css-1g96e5k-MuiTypography-root": {
      fontSize: "0.9rem",
      fontWeight: "700",
      marginTop: "4px",
      marginBottom: "2px",
    },
  },
  postTextArea: {
    "& .ql-editor ": {
      minHeight: "120px",
    },
  },
}));

// {"& .css-1agvk75" :{
//   minHeight: "250px"}}
function CreateMarketPlace(props) {
  const postStyles = postStylesDropzone();

  useEffect(() => {
  }, []);


  return (
    <div className={postStyles.root}>
      {/* <Dialog open={open} onClose={() => {
          setOpen(false);
        }}> */}

      <Box sx={{ p:2}}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            // onClick={() => {
            //   props?.onClose();
            // }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Create Market Place
          </Typography>
          
        </Toolbar>
        <Divider sx={{ my: "0rem" }} />
      </AppBar>
      {/* </Box> */}

        <MKBox my={4}>
          <Typography variant="h6" my={2}>
           Choose listing type
          </Typography>

          <Grid
            container
            spacing={1}
            alignItems={"center"}
            // columns={6}
          >
          <Grid item sm={12} md={4}>
            <Card sx={{p:2, width: "100%" }}>
              <CardContent> 
                {/* <Typography variant="h5" component="div">
                   Classified Item
                </Typography>  */}
                <Typography variant="body2">
                   Create a single listing for one or more items 
                </Typography> 
              </CardContent>
              <CardActions>
               
              <MKButton
                variant="outlined"
                color="success"
                component={Link}
                to={"/Market-Place/create/1"}
                startIcon={<ShoppingBag sx={{ fontSize: "large" }} />}
                fullWidth
                style={{ backgroundColor: "rgba(240,242,245,1.0)" }}
              >
                {"Classified Item"}
              </MKButton>
              </CardActions>
            </Card>

            </Grid>
            <Grid item sm={12} md={4}>
            <Card sx={{p:2,  width: "100%"}}>
              <CardContent> 
                {/* <Typography variant="h5" component="div">
                   Classified Item
                </Typography>  */}
               
              <Typography variant="body2">
                List a house, apartment, land or other

                </Typography> 
              </CardContent>
              <CardActions>
              <MKButton 
                variant="outlined"
                color="success"
                component={Link}
                to={"/Market-Place/create/2"}
                startIcon={<Apartment sx={{ fontSize: "large" }} />}
                fullWidth
                style={{ backgroundColor: "rgba(240,242,245,1.0)" }}
              >
                {"Property"}
              </MKButton>
              </CardActions>
            </Card>

            </Grid>
            <Grid item sm={12} md={4}>
            <Card sx={{p:2,  width: "100%"}}>
              <CardContent> 
                {/* <Typography variant="h5" component="div">
                   Classified Item
                </Typography>  */}
                <Typography variant="body2">
                List car, truck or other type of vehicle
                </Typography> 
              </CardContent>
              <CardActions>
               
              <MKButton
                variant="outlined"
                color="success"
                component={Link}
                to={"/Market-Place/create/3"}
                startIcon={<CarRental sx={{ fontSize: "large" }} />}
                fullWidth
                style={{ backgroundColor: "rgba(240,242,245,1.0)" }}
              >
                {"Vehicle"}
              </MKButton>
              </CardActions>
            </Card>

            </Grid> 
          </Grid>
        </MKBox>
        </Box>
      {/* </Dialog>  */}
    </div>
  );
}

export default CreateMarketPlace;
