// @mui material components
import Container from "@mui/material/Container";
import React, { useEffect } from "react";
import imageCompression from "browser-image-compression";
// Images
// import bgImage from "assets/images/bg-rental.jpeg";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import {
  AppBar,
  Avatar,
  Box,
  Chip,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { AddCircle, ArrowBack, Logout, Person, Send } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { CommonServices } from "Api-Services/commonServices";
import { CommonApiServices } from "Api-Services/commonApiServices";
import {  useNavigate } from "react-router-dom";
import MySideBarContent from "examples/SideBarContent/MySideBarContent";
import GeneralSideBarContent from "examples/SideBarContent/GeneralSideBarContent";
import MKButton from "components/MKButton";
import { UseAuth } from "Api-Services/useAuth";
import { isMobile } from "react-device-detect";

function SideBarMenu(props) {
  // profile={myCurrentProfile} friendshipTab={friendshipTab}
  const navigate=useNavigate()
  const [currentData] = useCurrentUser();
  const { myFriendships, isUserLoggedIn,myCurrentProfile } = currentData;
  const [forceRerender, setForceRerender] = React.useState(true);
  const [friendshipTab, setFriendshipTab] = React.useState("followings");
  const [followers, setFollowers] = React.useState([]);
  const [followings, setFollowings] = React.useState([]);
  const [suggestions, setSuggestions] = React.useState([]);
  const [currentProfile, setCurrentProfile] = React.useState(null);

  useEffect(async () => {
    let profileR = null;
    let followersRes = [];
    let followingsRes = [];
    let freindshipsRes = null;

    // console.log("myCurrentProfile", myCurrentProfile);
    // console.log(" props.currentProfile", props.currentProfile);
    if (props.currentProfile) {
      profileR = props.currentProfile;
    }
    setCurrentProfile(profileR);
    setForceRerender(!forceRerender);
    if (profileR && myCurrentProfile) {
      if (myCurrentProfile.id == profileR.id) {
        freindshipsRes = myFriendships;
      }
      if (freindshipsRes==null) {
      freindshipsRes = (await CommonApiServices.freindships(profileR.id)) || {};
      }
      if (freindshipsRes) {
        if (freindshipsRes.followers) {
          followersRes = freindshipsRes.followers;
        }
        if (freindshipsRes.followings) {
          followingsRes = freindshipsRes.followings;
        }
      }
    }
    setFollowers(followersRes);
    setFollowings(followingsRes);
    setForceRerender(!forceRerender);
    // console.log("FriendshipList freindshipsRes", freindshipsRes);
    // console.log("FriendshipList profileR", profileR);
  }, [props.currentProfile]);

  useEffect(async () => {
    let friendshipTabR = "following";
    if (props.friendshipTab) {
      friendshipTabR = props.friendshipTab;
    }
    setFriendshipTab(friendshipTabR);
  }, [props.friendshipTab]);

  function FriendshipListItem(FriendshipListItemprops) {

    const [friendshipType, setFriendshipType] = React.useState(""); 
    const [friendshipItem, setFriendshipItem] = React.useState(null); 
    useEffect(() => { 
        if(FriendshipListItemprops.friendshipItem&&myFriendships){
          let friendshipItemR=FriendshipListItemprops.friendshipItem
          setFriendshipItem(friendshipItemR)
        let fT= CommonServices.checkFriendship(friendshipItemR.id, myFriendships)
        if(fT){ 
            if (myFriendships&&(friendshipItemR.id?true:false)) {   
              // console.log("fT",fT)
              setFriendshipType(fT);
            }  
          }
        }
    }, [FriendshipListItemprops,myFriendships]);



    let res = <></>;
    try { 
      // let friendshipType = CommonServices.checkFriendship(friendshipItem.id, myFriendships);
      // console.log("friendshipType", friendshipType);

      res = (
        <>
       {(friendshipItem)&& <ListItem
          sx={{ pt: 2 }}
          secondaryAction={
            friendshipType == "friend" ? (
              <Chip
                sx={{ px: "5px" }}
                icon={<Send />}
                onClick={() => {
                  // CommonApiServices.unfollow(friendshipItem.id)
                  // setFriendshipType("")
                  // console.log("mmmmmmmmmmm", following);
                }}
                // label={"Follow"}
                label={"Message"}
              />
            ) : friendshipType == "follower" ? (
              <Chip
                sx={{ px: "2px" }}
                color={"info"}
                icon={<AddCircle />}
                onClick={() => {
                  CommonApiServices.follow(friendshipItem.id)
                  // friendshipType=!following;
                  setFriendshipType("friend")
                }}
                label={"Follow Back"}
              />
            ) : friendshipType == "following" ? (
              <Chip
                sx={{ px: "2px" }} 
                icon={<Logout />}
                onClick={() => {
                  CommonApiServices.unfollow(friendshipItem.id)
                  setFriendshipType("")
                }}
                // label={"Follow"}
                label={"Unfollow"}
              />
            ) : (
              <Chip
                sx={{ px: "15px" }}
                color={"info"}
                icon={<AddCircle />}
                onClick={() => {
                  CommonApiServices.follow(friendshipItem.id)
                  setFriendshipType("following")
                }}
                // label={"Follow"}
                label={"Follow"}
              />
            )
          }
        >
          <ListItemAvatar 
            onClick={()=>{navigate("/"+friendshipItem.userName);
            props?.onClose()
            }}>
            {friendshipItem.profileImageUrl != "" && friendshipItem.profileImageUrl != null ? (
              <Avatar src={friendshipItem.profileImageUrl}></Avatar>
            ) : (
              <Avatar>
                <Person />
              </Avatar>
            )}
          </ListItemAvatar>
          <ListItemText
            onClick={()=>{navigate("/"+friendshipItem.userName);
            props?.onClose()}}
            primary={
              <Typography variant="h6">{CommonServices.getProfileName(friendshipItem)}</Typography>
            }
            secondary={<Typography variant="body2">{friendshipItem.userName}</Typography>}
          />
        </ListItem>}
        </>
      );
    } catch (error) {
      // console.log("FriendshipListItem errr", error);
    }
    return res;
  }

  return (
    <>


    {/* <DialogContent sx={{ minHeight:isMobile?"300px": "460px" , pb: "75px",}}> */}
      {/* // <div className={postStyles.root}>  */}
    
      <Grid item pb={12} xs={12} mx="auto"> 
          <MySideBarContent/> 
          <GeneralSideBarContent/>
          {(isUserLoggedIn) && (
            <>
              <Grid container item xs={12} justifyContent="center">
                <MKButton
                  fullWidth
                  color="secondary"
                  onClick={() => UseAuth.logout()}
                  startIcon={<Logout />}
                >
                  Log Out
                </MKButton>
              </Grid>
            </>
          )} 
      </Grid>
      {/* </Dialog>  */}
      {forceRerender}
      {/* </div> */}
    {/* </DialogContent> */}
  </> );
}

export default SideBarMenu;
