// @mui material components
import FbImageLibrary from "react-fb-image-grid";
import "./botstrap.css";
import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect } from "react";
import { HttpService } from "Api-Services/httpService";

import {
  Avatar,
  Button,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  Typography,
  Grid,
  List,
  ListItem,
  Divider,
  ListItemAvatar,
  ListItemText,
  Paper,
  IconButton,
  InputBase,
  Toolbar,
} from "@mui/material";
import {
  AddCircle,
  ArrowBack,
  Comment,
  Directions,
  Edit,
  Favorite,
  Logout,
  Person,
  Photo,
  Send,
} from "@mui/icons-material";

import { DataManagementService } from "Api-Services/dataManagementService";
import MoreVert from "@mui/icons-material/MoreVert";
import ReactTimeago from "react-timeago";
import TruncateMarkup from "react-truncate-markup";
import { CommonServices } from "Api-Services/commonServices";
import { isMobile } from "react-device-detect";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { CommonApiServices } from "Api-Services/commonApiServices";
import { pink } from "@mui/material/colors";
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';

// import required modules
function SingleFeedPost(props) {
  const navigateComp = useNavigate();
  const queryClient = useQueryClient();
  const [currentData, updateCurrentUser] = useCurrentUser();
  const { myCurrentProfile, myFriendships } = currentData;
  const [showMore, setShowMore] = React.useState(false);
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [post, setPost] = React.useState(null);
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });

  const viewDetails = (data) => {
    DataManagementService.setLocalstorageDataSecured(data, "agencyDetail");
  };

  useEffect(() => {
    // console.log("  props ------------------------------------------", props);
    if (props.post) {
      setPost(props.post);
    }
  }, [props.post]);

  // const [commentMessage, setCommentMessage] = React.useState("");
  // const postQuery = useQuery( ['comments'], async () => await CommonApiServices.getPostComments(postId) )
  // const sendComment = async () => {
  //   if (postId) {
  //     if (postId && commentMessage) {
  //       let data = {
  //         PostId: postId,
  //         CommentTypeId: 1,
  //         message: commentMessage,
  //       };
  //       mutation.mutate(data);
  //     }
  //   }
  // };

  // // Mutations
  // const mutation = useMutation((newComment) => CommonApiServices.addComment(newComment), {
  //   onSuccess: () => {
  //     // Invalidate and refetch
  //     setCommentMessage("");
  //     queryClient.invalidateQueries({ queryKey: ["comments"] });
  //   },
  // });

  const getPostImages = (attachmentsD) => {
    let urlsd = [];
    attachmentsD.forEach((element) => {
      urlsd.push(element.url);
    });
    return urlsd;
  };

  function FriendshipButton(FriendshipListItemprops) {
    const [friendshipType, setFriendshipType] = React.useState("");
    const [friendshipItem, setFriendshipItem] = React.useState(null);
    useEffect(() => {
      let friendshipItemR;
      try {
        if (FriendshipListItemprops.friendshipItem && myFriendships && myCurrentProfile) {
          friendshipItemR = FriendshipListItemprops.friendshipItem;
          if (myCurrentProfile.id != friendshipItemR.id) {
            setFriendshipItem(friendshipItemR);
            // console.log("friendshipItemR",friendshipItemR.id, myFriendships)
            let fT = CommonServices.checkFriendship(friendshipItemR.id, myFriendships);
            // console.log("fT",fT)
            if (fT) {
              if (myFriendships && (friendshipItemR.id ? true : false)) {
                // console.log("fT",fT)
                setFriendshipType(fT);
              }
            }
          } else {
            setFriendshipType("myself");
          }
        }
      } catch (error) {}
    }, [FriendshipListItemprops, myFriendships]);

    let res = <></>;
    try {
      res =
        friendshipType != "myself" ? (
          <>
            {friendshipItem && (
              <>
                {friendshipType == "friend" ? (
                  <Chip
                    sx={{ px: "5px" }}
                    icon={<Send />}
                    onClick={() => {
                      // CommonApiServices.unfollow(friendshipItem.id)
                      // setFriendshipType("")
                      // console.log("mmmmmmmmmmm", following);
                    }}
                    // label={"Follow"}
                    label={"Message"}
                  />
                ) : friendshipType == "follower" ? (
                  <Chip
                    sx={{ px: "2px" }}
                    color={"info"}
                    icon={<AddCircle />}
                    onClick={() => {
                      CommonApiServices.follow(friendshipItem.id);
                      // friendshipType=!following;
                      setFriendshipType("friend");
                    }}
                    label={"Follow Back"}
                  />
                ) : friendshipType == "following" ? (
                  <></>
                ) : (
                  <Chip
                    sx={{ px: "15px" }}
                    color={"info"}
                    icon={<AddCircle />}
                    onClick={() => {
                      CommonApiServices.follow(friendshipItem.id);
                      setFriendshipType("following");
                    }}
                    // label={"Follow"}
                    label={"Follow"}
                  />
                )}{" "}
              </>
            )}
          </>
        ) : (
          <></>
        );
    } catch (error) {
      console.log("FriendshipListItem errr", error);
    }
    return res;
  }

  function AgencyComponent(propsPost) {
    let jobOverviewComponent = <></>;
    // console.log("feedpost", jobVacancy);
    const [jobVacancy, setJobVacancy] = React.useState(propsPost.post);
    const [iReacted, setIReacted] = React.useState(propsPost.post.iReacted);
    // let iReacted=false
    const addReaction = async (reactionTypeId) => {
      try {
        let data = {
          PostId: jobVacancy.id,
          ReactionTypeId: 1,
          ReactionAreaId: 1,
        };
        let addReactRes = await CommonApiServices.addReaction(data);
        setIReacted(addReactRes.success);
      } catch (error) {}
    };

    const removeReaction = async (reactionTypeId) => {
      try {
        let data = {
          // id:reactionId
          PostId: jobVacancy.id,
          ReactionTypeId: reactionTypeId,
        };
        let removeReactRes = await CommonApiServices.removeReaction(data);
        setIReacted(removeReactRes.success ? false : true);
      } catch (error) {}
    };

    try {
      jobOverviewComponent = (
        <>
          {jobVacancy && (
            <MKBox display="flex" flexDirection="column">
              <Card
              //  sx={{ maxWidth: 345 }}
              >
                {jobVacancy.Profile && (
                  <CardHeader
                    avatar={
                      <Avatar
                        onClick={() => navigateComp("/" + jobVacancy.Profile.userName)}
                        sx={{ width: 44, height: 44 }}
                        src={jobVacancy.Profile.profileImageUrl}
                        aria-label="recipe"
                      />
                    }
                    action={
                      // props.componentViewType == 3 ? (
                      <MoreVert
                      //  color="success"
                      //  fontSize="large"
                      />
                      // ) : null
                    }
                    title={
                      <TruncateMarkup lines={1}>
                        <MKTypography
                          variant="h6"
                          onClick={() => navigateComp("/" + jobVacancy.Profile.userName)}
                        >
                          {CommonServices.getProfileName(jobVacancy.Profile)}
                        </MKTypography>
                      </TruncateMarkup>
                    }
                    subheader={
                      <Typography variant="body2" color="text.secondary">
                        <ReactTimeago date={jobVacancy.createdAt} />

                        {/* <PeopleOutlineRounded /> {1461 + jobVacancy.numberOfMembers} {" Members"} */}
                      </Typography>
                    }
                  />
                )}
                <CardContent
                  sx={{ my: "1px", py: "1px" }}
                  onClick={() => {
                    // viewDetails(jobVacancy.id);
                  }}
                  // sx={{textAlign: "left",pl:2}}
                >
                  {jobVacancy.message && (
                    <>
                      {showMore == "description" ? (
                        <>
                          <Typography variant="body2">{parse(jobVacancy.message)}</Typography>
                          <Typography
                            color={"info"}
                            variant="caption"
                            onClick={() => setShowMore("")}
                            // component={Link}
                          >
                            {" see less"}
                          </Typography>
                        </>
                      ) : (
                        <TruncateMarkup
                          lines={
                            (jobVacancy ? (jobVacancy.Attachments ? true : false) : false) ? 3 : 8
                          }
                          ellipsis={
                            <span>
                              ...{" "}
                              <Typography
                                color={"info"}
                                variant="caption"
                                onClick={() => setShowMore("description")}
                                // component={Link}
                              >
                                see more
                              </Typography>
                            </span>
                          }
                        >
                          <Typography variant="body2">{parse(jobVacancy.message)}</Typography>
                        </TruncateMarkup>
                      )}
                    </>
                  )}
                </CardContent>
                {jobVacancy.Attachments && props.componentViewType == 1 && (
                  <>
                    <div className={"FbImageLibrary"}>
                      <FbImageLibrary images={getPostImages(jobVacancy.Attachments)} />
                    </div>
                  </>
                )}
                {(props.componentViewType == 1 || props.componentViewType == 2) && (
                  <CardContent
                    // sx={{ marginBottom: "-11px" }}
                    onClick={() => {
                      // viewDetails(jobVacancy.id);
                    }}
                    // sx={{textAlign: "left",pl:2}}
                  >
                    {(jobVacancy ? jobVacancy.reactionsCount > 0 : false) && (
                      <Grid item xs={12} sx={{ py: 0, my: 0 }}>
                        <Typography
                          sx={{ py: 0, my: 0 }}
                          variant="caption"
                          onClick={() => {
                            setOpenedDialog("reactions");
                          }}
                        >
                          {jobVacancy.reactionsCount + " "} like
                        </Typography>
                      </Grid>
                    )}
                    {myCurrentProfile && (
                      <Grid
                        // sx={{ marginBottom: "-39px" }}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          {/* <Stack direction="row" m={1} spacing={2}> */}
                          {iReacted ? (
                            <Chip
                              variant="outlined"
                              sx={{
                                width: "100px",
                                // border: "0px"
                              }}
                              color={"error"}
                              icon={
                                <Favorite
                                  sx={{
                                    color: pink[500],
                                    //  ,fontSize: 150
                                  }}
                                />
                              }
                              fontSize="medium"
                              // icon={<Favorite />}
                              onClick={() => {
                                setIReacted(!iReacted);
                                removeReaction(1);
                              }}
                              label={jobVacancy.reactionsCount || ""}
                            />
                          ) : (
                            <Chip
                              sx={{ width: "100px", border: "0px" }}
                              icon={<Favorite sx={{ color: pink[500] }}  />}
                              // icon={<Favorite />}
                              onClick={() => {
                                setIReacted(!iReacted);
                                addReaction(1);
                              }}
                              label={jobVacancy.reactionsCount || ""}
                            />
                          )}
                          {/* </Stack> */}
                        </Grid>
                        <Grid item>
                          {/* <Stack direction="row" m={1} spacing={2}> */}{" "}
                          <Chip
                            sx={{ width: "100px" }}
                            icon={<Comment />}
                            // onClick={() => {
                            //   viewDetails(jobVacancy.id, jobVacancy);
                            // }}

                            onClick={() => {
                              setOpenedDialog("comments");
                              // let data = {
                              //   dialogId: "PostCommentsDialogBox",
                              //   data: { post: jobVacancy  },
                              // };
                              // updateCurrentUser("currentGlobalDialogBoxData", data);
                            }}
                            label={jobVacancy.commentsCount || ""}
                          />
                          {/* </Stack> */}
                        </Grid>
                        {/* <Grid item> 
                        <Chip
                          icon={<Reply />}
                          sx={{ width: "90px" }}
                          onClick={() => {
                            viewDetails(jobVacancy.id, jobVacancy);
                          }}
                          label="6"
                        />  </Grid> */}
                      </Grid>
                    )}
                  </CardContent>
                )}
              </Card>
            </MKBox>
          )}
        </>
      );
    } catch (error) {
      console.log(error, "z  agency errr short  ");
    }
    return jobOverviewComponent;
  }

  function Comments(commentProps) {
    let postId = commentProps.postId;
    const [commentMessage, setCommentMessage] = React.useState("");
    const query = useQuery(
      ["comments" + postId],
      async () => await CommonApiServices.getPostComments(postId)
    );
    const sendComment = async () => {
      if (postId) {
        if (postId && commentMessage) {
          let data = {
            PostId: postId,
            CommentTypeId: 1,
            CommentAreaId: 1,
            message: commentMessage,
          };
          mutation.mutate(data);
        }
      }
    };

    // Mutations
    const mutation = useMutation((newComment) => CommonApiServices.addComment(newComment), {
      onSuccess: () => {
        // Invalidate and refetch
        setCommentMessage("");
        queryClient.invalidateQueries({ queryKey: ["comments" + postId] });
      },
    });
    try {
      return (
        <>
          <DialogContent
            sx={{
              // minHeight: isMobile ? "300px" : "460px",
              p: "0px",
              m: "0px",
            }}
          >
            <MKBox display="flex" sx={{ p: 2 }} flexDirection="column">
              {query.isLoading ? (
                <h1>Loading</h1>
              ) : (query.data ? (query.data.length ? true : false) : false) ? (
                <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                  {query.data?.map(
                    (comment) =>
                      comment.message &&
                      comment.Profile && (
                        <>
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              <Avatar alt="profile" src={comment.Profile.profileImageUrl} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={CommonServices.getProfileName(comment.Profile)}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {comment.message}
                                  </Typography>
                                  {/* {" — I'll be in your neighborhood doing errands this…"} */}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />{" "}
                        </>
                      )
                  )}
                </List>
              ) : (
                <MKTypography variant={"body2"}>Be the first to comment </MKTypography>
              )}
            </MKBox>
          </DialogContent>
          <DialogActions>
            {myCurrentProfile && (
              <>
                <Paper
                  // component="form"
                  elevation={0}
                  square={false}
                  // component="form"
                  shadow={"none"}
                  sx={{
                    mx: 2,
                    mt: 0.5,
                    backgroundColor: "#f0f2f5",
                    // mr: "6px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    //  width: "min-content",
                  }}
                >
                  <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <Avatar size="sm" src={myCurrentProfile.profileImageUrl} />
                  </IconButton>
                  <InputBase
                    fullWidth
                    // startAdornment={<SearchIcon sx={{ mr: 1 }} />}
                    autoFocus={true}
                    sx={{ ml: 1, pr: 1, flex: 1, backgroundColor: "aliceblue" }}
                    // sx={{ ml: 1, flex: 1 }}
                    placeholder="Write Comment"
                    inputProps={{ "aria-label": "Write Comment" }}
                    value={commentMessage}
                    onChange={(e) => {
                      setCommentMessage(e.target.value);
                    }}
                    onKeyDown={(event) => {
                      if (event.keyCode) {
                        if (event.keyCode === 13) {
                          sendComment();
                        }
                      }
                    }}
                    type="search"
                  />
                  <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                    <Edit />
                  </IconButton>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    onClick={() => {
                      sendComment();
                    }}
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                  >
                    <Send />
                  </IconButton>
                </Paper>
              </>
            )}
            {/* </> */}
          </DialogActions>
        </>
      );
    } catch (error) {}
  }

  function Reactions(commentProps) {
    let postId = commentProps.postId;
    // Queries
    const query = useQuery(
      ["reactions" + postId],
      async () => await CommonApiServices.getPostReactions(postId)
    );
    // Mutations
    // const reactionsMutation = useMutation((newReaction) => CommonApiServices.addReaction(newReaction), {
    //   onSuccess: () => {
    //     // Invalidate and refetch
    //     queryClient.invalidateQueries({ queryKey: ["reactions"] });
    //   },
    // });
    try {
      return (
        <>
          <DialogContent
            sx={{
              // minHeight: isMobile ? "300px" : "460px",
              p: "0px",
              m: "0px",
            }}
          >
            <MKBox display="flex" sx={{ p: 2 }} flexDirection="column">
              {query.isLoading ? (
                <h1>Loading</h1>
              ) : (query.data ? (query.data.length ? true : false) : false) ? (
                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                  {query.data.map(
                    (reaction) =>
                      reaction.Profile && (
                        <>
                          <ListItem
                            alignItems="flex-start"
                            secondaryAction={<FriendshipButton friendshipItem={reaction.Profile} />}
                          >
                            <ListItemAvatar
                              onClick={() => {
                                navigateComp("/" + reaction.Profile.userName);
                                setOpenedDialog("");
                              }}
                            >
                              {reaction.Profile.profileImageUrl != "" &&
                              reaction.Profile.profileImageUrl != null ? (
                                <Avatar src={reaction.Profile.profileImageUrl}></Avatar>
                              ) : (
                                <Avatar>
                                  <Person />
                                </Avatar>
                              )}
                            </ListItemAvatar>
                            <ListItemText
                              onClick={() => {
                                navigateComp("/" + reaction.Profile.userName);
                                setOpenedDialog("");
                              }}
                              primary={
                                <Typography variant="h6">
                                  {CommonServices.getProfileName(reaction.Profile)}
                                </Typography>
                              }
                              secondary={
                                <Typography variant="body2">{reaction.Profile.userName}</Typography>
                              }
                            />
                          </ListItem>
                          <Divider variant="inset" component="li" />{" "}
                        </>
                      )
                  )}
                </List>
              ) : (
                <MKTypography variant={"body2"}>Be the first to React </MKTypography>
              )}
            </MKBox>
          </DialogContent>
        </>
      );
    } catch (error) {}
  }

  return (
    <>
      {post ? <AgencyComponent post={post} /> : null}

      {/* </Card> */}

      {/* {forceRenderer} */}

      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{parse(moreText.content)}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openedDialog == "comments"}
        onClose={() => {
          setOpenedDialog("");
        }}
        scroll={"paper"}
        sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        maxWidth="sm"
        fullScreen={isMobile ? true : false}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Comments
          </Typography>
        </Toolbar>
        {post && (
          <>
            {/* <DialogTitle>Comments</DialogTitle> */}
            <Comments postId={post.id} />
          </>
        )}
        {/* <DialogActions sx={{ px: 0 }} shadow={"lg"}>
          <WriteComment />
        </DialogActions> */}
      </Dialog>

      <Dialog
        open={openedDialog == "reactions"}
        onClose={() => {
          setOpenedDialog("");
        }}
        scroll={"paper"}
        sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        maxWidth="xs"
        fullScreen={isMobile ? true : false}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Reactions
          </Typography>
        </Toolbar>
        {post && (
          <>
            {/* <DialogTitle>Reactions</DialogTitle> */}
            <Reactions postId={post.id} />
          </>
        )}
        {/* <DialogActions sx={{ px: 0 }} shadow={"lg"}>
          <WriteComment />
        </DialogActions> */}
      </Dialog>

      {/* {forceRerender} */}
    </>
  );
}

export default SingleFeedPost;
