// @mui material components 
import React from "react"; 
import {
  AppBar, 
  DialogContent, 
  IconButton, 
  Toolbar,
  Typography,
} from "@mui/material";
import {   ArrowBack  } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import PostDetails from "pages/Posts/Sections/postDetails";

function PostDetailsModal(props) {   
  return (
    <>
      {/* <Box sx={{ flexGrow: 1 }}> */}
      <AppBar position="static" color="transparent">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              props?.onClose();
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Post
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
      {/* </Box> */} 
      <DialogContent sx={{ minHeight: isMobile ? "300px" : "460px", p: "0px", m: "0px" }}>
        <PostDetails post={props?.currentGlobalDialogBoxData?.data?.post} data={props?.currentGlobalDialogBoxData?.data} componentViewType={1} /> 
      </DialogContent> 
    </>
  );
}

export default PostDetailsModal;
