import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import queryString from "query-string";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography"; 
import ReactGA from "react-ga4";
import {  useNavigate, useParams } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import Card from "@mui/material/Card";
import React, { useEffect } from "react";
 
import { isMobile } from "react-device-detect";
import EducationalHistoriesList from "../Components/EducationalHistory/educationalHistoriesList";
import WorkHistoriesList from "../Components/WorkHistory/workHistoriesList";

import "swiper/swiper.min.css";
import { FreeMode, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";  
import JobInfo from "../Components/JobInfo/jobInfo";
import PortfoliosList from "../Components/Portfolio/portfoliosList";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { ArrowDropDownCircle, MoreVert } from "@mui/icons-material";

function AboutUser(props) {
  // isThisMyProfile={isThisMyProfile} currentProfile={currentProfile}
  const navigate = useNavigate();
  const param = useParams();
  const [currentData, updateContext] = useCurrentUser();
  const { currentUser  } = currentData;  
  const [forceRerender, setForceRerender] = React.useState(false);
  const [tab, setTab] = React.useState(param?.aboutTab||"overview");
 
  const aboutTabs=["overview","job-info","portfolio","work","education"]
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "JobSeeker Profile" });
    setForceRerender(!forceRerender);
  }, [currentUser, props.currentProfile,  ]);

  
  useEffect(() => {
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch.tab) {
        setProfileSectionIndex(locationSearch.tab);
      }
    }
  }, [location.search, location.pathName]);
  
  const onTabChange=(tabD)=>{
      setTab(tabD) 
      if(props?.currentProfile){
      let path= "/"+props?.currentProfile?.userName+"/about/"+tabD ;  
      window.history.pushState(null, "  aboutpage", path); 
    }
  }

  return (
    <MKBox>
      <Container sx={{ px: "2px" }}>
      {/* <Grid container item xs={12} justifyContent="center" mx="auto"> */}
            {props?.currentProfile && (
              <Grid spacing={1} container justifyContent="center">   
 
                  <Grid item xs={12}  justifyContent="center">
    
                  <MKBox sx={{ mt: 1,
                          width: "100%",
                          py: isMobile ? 1 :1,
                          px:1,
                          bgcolor: "background.paper",
                        }}>
                      
            
                  <Grid container justifyContent="space-between">   
 
                    <Grid item xs={11}  justifyContent="center">
    
                      <MKBox  sx={{"& .swiper-slide":{width:"auto"}}}>
                          <Swiper
                            // slidesPerView={4}
                            slidesPerView={'auto'}
                            spaceBetween={4} 
                            freeMode={true}
                            lazy={true}
                            // pagination={{
                            //   clickable: true,
                            // }}            
                            navigation={{
                              nextEl: ".swiper-button-next",
                              prevEl: ".swiper-button-prev",
                            }}
                            modules={[FreeMode, Pagination, Navigation]}
                
                            className="mySwiper" 
                          >    
                               {aboutTabs?.map((tabD)=> <SwiperSlide > 
                                  <MKButton
                                      onClick={() => { 
                                        onTabChange(tabD); 
                                      }}
                                    style={{ 
                                      color:(tab==tabD)?"white":"grey", //gainsboro
                                      backgroundColor:(tab==tabD)?"rgb(105 110 102)":"rgba(240,242,245,1.0)" 
                                      }} //gainsboro
                                    fullWidth
                                    variant="filled"
                                    color="primary"
                                  >
                                    {tabD}
                                  </MKButton>
                                  </SwiperSlide>  
                         )}
                                
                          </Swiper>
                        </MKBox> 
                    </Grid>
                    <Grid item xs={1} justifyContent="center">
                    <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ArrowDropDownCircle/>
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            // maxHeight: ITEM_HEIGHT * 4.5,
            // width: '20ch',
          },
        }}
      >
        {aboutTabs.map((tabD) => (
          <MenuItem key={tabD} selected={tabD === tab} onClick={(e)=>{
            onTabChange(tabD); 
            handleClose(e)}}>
            {tabD}
          </MenuItem>
        ))}
      </Menu>
  
      
                    </Grid>
                    </Grid>
                    </MKBox>
                  </Grid>
                  
                 {(tab=="overview"||tab=="portfolio")?<Grid container item xs={12} pt={3} justifyContent="center">
                    <Card sx={{ width: "100%" }}>
                      <Grid container alignItems="center">
                        <Grid p={1} item xs={12} lg={12}>
                          <MKBox py={1} px={2}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="flex-start"
                            >
                              
                              <MKTypography variant="h5">
                              {"Portfolio"} </MKTypography>
                              
                              {props.isThisMyProfile && (
                                <MKButton
                                  size={"small"}
                                  onClick={() => {
                                    navigate("/profile/portfolio");
                                  }}
                                  variant={"outlined"}
                                  color="warning"
                                >
                                  Manage
                                </MKButton>
                              )}
                            </Grid>
                          </MKBox>
                          <MKBox pb={{ sm: 0, md: 1 }} px={{ sm: 2, md: 3 }}>
                            <PortfoliosList isOverview={tab=="overview"} profileId={props?.currentProfile?.id}/>
                          </MKBox>
                          
                          {(tab=="overview")?<MKButton fullWidth
                                      onClick={() => { 
                                        onTabChange("portfolio"); 
                                      }}
                                    style={{ 
                                      color: "grey", //gainsboro
                                      backgroundColor: "rgba(240,242,245,1.0)" 
                                      }} //gainsboro
                                     
                                    variant="filled"
                                    color="primary"
                                  >
                                    {"View More"}
                                  </MKButton>:null}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>  :null}
                
                 {/* {(tab=="overview"||tab=="profession")? <Grid container item xs={12} pt={3} justifyContent="center">
                    <Card sx={{ width: "100%" }}>
                      <Grid container alignItems="center">
                        <Grid p={1} item xs={12} lg={12}>
                          <MKBox py={1} px={2}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="flex-start"
                            >
                              
                              <MKTypography variant="h5">
                              {"Professions / Talents"} </MKTypography>
                              
                              {props.isThisMyProfile && (
                                <MKButton
                                  size={"small"}
                                  onClick={() => {
                                    navigate("/profile/profession");
                                  }}
                                  variant={"outlined"}
                                  color="warning"
                                >
                                  Manage
                                </MKButton>
                              )}
                            </Grid>
                          </MKBox>
                          <MKBox pb={{ sm: 0, md: 1 }} px={{ sm: 2, md: 3 }}>
                            <ProfessionsList isOverview={tab=="overview"} profileId={props?.currentProfile?.id}/>
                          </MKBox>
                          {(tab=="overview")?<MKButton fullWidth
                                      onClick={() => { 
                                        onTabChange("profession"); 
                                      }}
                                    style={{ 
                                      color: "grey", //gainsboro
                                      backgroundColor: "rgba(240,242,245,1.0)" 
                                      }} //gainsboro
                                     
                                    variant="filled"
                                    color="primary"
                                  >
                                    {"View More"}
                                  </MKButton>:null}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid> :null} */}
                 
                 {(tab=="overview"||tab=="job-info")? <Grid container item xs={12} pt={3} justifyContent="center">
                    <Card sx={{ width: "100%" }}>
                      <Grid container alignItems="center">
                        <Grid p={1} item xs={12} lg={12}>
                          <MKBox py={1} px={2}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="flex-start"
                            >
                              
                              <MKTypography variant="h5">
                              {"Job Info / Professions / Talents"} </MKTypography>
                              
                              {props.isThisMyProfile && (
                                <MKButton
                                  size={"small"}
                                  onClick={() => {
                                    navigate("/Settings/Job");
                                  }}
                                  variant={"outlined"}
                                  color="warning"
                                >
                                  Manage
                                </MKButton>
                              )}
                            </Grid>
                          </MKBox>
                          <MKBox pb={{ sm: 0, md: 1 }} px={{ sm: 2, md: 3 }}>
                            <JobInfo isOverview={tab=="overview"} profileId={props?.currentProfile?.id}/>
                          </MKBox>
                          {(tab=="overview")?<MKButton fullWidth
                                      onClick={() => { 
                                        onTabChange("job-info"); 
                                      }}
                                    style={{ 
                                      color: "grey", //gainsboro
                                      backgroundColor: "rgba(240,242,245,1.0)" 
                                      }} //gainsboro
                                     
                                    variant="filled"
                                    color="primary"
                                  >
                                    {"View Details"}
                                  </MKButton>:null}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid> :null}
                 {(tab=="overview"||tab=="work")?
                  <Grid container item xs={12} pt={3} justifyContent="center">
                    <Card sx={{ width: "100%" }}>
                      <Grid container alignItems="center">
                        <Grid p={1} item xs={12} lg={12}>
                          <MKBox py={1} px={2}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="flex-start"
                            >
                              <MKTypography variant="h5">{"Work History"}</MKTypography>
                              
                              {props.isThisMyProfile && (
                                <MKButton
                                  size={"small"}
                                  onClick={() => {
                                    navigate("/profile/work-history");
                                  }}
                                  variant={"outlined"}
                                  color="warning"
                                >
                                  Manage
                                </MKButton>
                              )}
                            </Grid>
                          </MKBox>
                          <MKBox pb={{ sm: 0, md: 1 }} px={{ sm: 2, md: 3 }}>
                            <WorkHistoriesList  isOverview={tab=="overview"} profileId={props?.currentProfile?.id}/>
                          </MKBox>
                          {(tab=="overview")?<MKButton fullWidth
                                      onClick={() => { 
                                        onTabChange("work"); 
                                      }}
                                    style={{ 
                                      color: "grey", //gainsboro
                                      backgroundColor: "rgba(240,242,245,1.0)" 
                                      }} //gainsboro
                                     
                                    variant="filled"
                                    color="primary"
                                  >
                                    {"View More"}
                                  </MKButton>:null}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>:null}
 
                 {(tab=="overview"||tab=="education")? <Grid container item xs={12} pt={3} justifyContent="center">
                    <Card sx={{ width: "100%" }}>
                      <Grid container alignItems="center">
                        <Grid p={1} item xs={12} lg={12}>
                          <MKBox py={1} px={2}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="flex-start"
                            >
                              <MKTypography variant="h5" mb={1}>
                                {"Educational Information"}
                              </MKTypography>
                              {props.isThisMyProfile && (
                                <MKButton
                                  size={"small"}
                                  onClick={() => {
                                    navigate("/profile/educational-history");
                                  }}
                                  variant={"outlined"}
                                  color="warning"
                                >
                                  Manage
                                </MKButton>
                              )}
                            </Grid>
                          </MKBox>
                          <MKBox pb={{ sm: 0, md: 1 }} px={{ sm: 2, md: 3 }}>
                            <EducationalHistoriesList isOverview={tab=="overview"} profileId={props?.currentProfile?.id}/>
                          </MKBox>
                          {(tab=="overview")?<MKButton fullWidth
                                      onClick={() => { 
                                        onTabChange("education"); 
                                      }}
                                    style={{ 
                                      color: "grey", //gainsboro
                                      backgroundColor: "rgba(240,242,245,1.0)" 
                                      }} //gainsboro
                                     
                                    variant="filled"
                                    color="primary"
                                  >
                                    {"View More"}
                                  </MKButton>:null}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>:null }
              </Grid>
            )}
          {/* </Grid>   */}
      </Container>
      {forceRerender}
    </MKBox>
  );
}

export default AboutUser;
