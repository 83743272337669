 import React, { useState, useEffect, useMemo, useCallback } from "react";
import Stories from 'react-insta-stories';
import ReactTimeago from "react-timeago";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { CommonServices } from "Api-Services/commonServices";
import { useNavigate } from "react-router-dom";
import { CommonApiServices } from "Api-Services/commonApiServices";

function StoryPosts(props) { 
  const [stories, setStories] = useState([]);
  const navigate=useNavigate()
  const [storiesProfiles, setStoriesProfiles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [storyKey, setStoryKey] = useState(0);
  const [isPaused, setIsPaused] = useState(props?.isPaused || false);
  // const [activeProfileIndex, setActiveProfileIndex] = useState(0);  
  const [activeProfileIndex, setActiveProfileIndex] = useState(props.activeProfileIndex || 0);

  const [currentData,updateContext] = useCurrentUser();
  const {   myCurrentProfile } = currentData; 

  useEffect(() => {
    const initializeStories = () => {
      try {
        const { storiesProfile, storiesProfiles } = props;
        
        if (storiesProfile) {
          setStories(getConstructedStoriesProfile(storiesProfile));
        }
        
        if (storiesProfiles) {
          const allProfilesStories = storiesProfiles.pages.flatMap(profileGroup =>
            profileGroup.map(profile => getConstructedStoriesProfile(profile))
          );
          setStoriesProfiles(allProfilesStories);
          if (allProfilesStories.length) {
            setStories(allProfilesStories[activeProfileIndex] || allProfilesStories[0]);
          }
        }
      } catch (error) {
        // console.error("Error initializing stories:", error);
      }
    };

    initializeStories();
  }, [props?.storiesProfile, props?.storiesProfiles, props?.activeProfileIndex]);

  useEffect(() => {
    if (props.activeProfileIndex !== undefined) {
      setActiveProfileIndex(props.activeProfileIndex);
      setCurrentIndex(0); // Reset current index for the new profile
      if (storiesProfiles[props.activeProfileIndex]) {
        setStories(storiesProfiles[props.activeProfileIndex]);
      }
    }
  }, [props.activeProfileIndex, storiesProfiles]);


  const getConstructedStoriesProfile = useCallback((profile) => {
    if (!profile?.Posts?.length) return [];
    
    return profile.Posts.flatMap(post =>
      post.Attachments.map(attachment => ({
        url: attachment.url,
        duration: 5000,
        header: {
          heading: CommonServices?.getProfileName(profile),
          subheading: <ReactTimeago date={post.createdAt} />,
          profileImage: profile.profileImageUrl,         
          onProfileImageClick: () => navigate("/"), // Navigate on profile image click
          onHeadingClick: () => navigate("/") // Navigate on heading click

        },
        PostId:post?.id,
        caption: "post.caption" // Add the caption here

      }))
    );
  }, []);

  const handleStoryChange = useCallback((newIndex, story) => {
    setCurrentIndex(newIndex);
    console.error("handleStoryChange:");

    addView(story);
  }, []);


  const goToNextProfile = useCallback(() => {
    setIsPaused(true);
    setStoryKey(prevKey => prevKey + 1);
    setCurrentIndex(0);
    if (activeProfileIndex < storiesProfiles.length - 1) {
      setActiveProfileIndex(activeProfileIndex + 1);
      setStories(storiesProfiles[activeProfileIndex + 1]);
      setIsPaused(false);
    } else {
      props?.onAllStoriesEnd?.();      
      setIsPaused(true);
    }
  }, [activeProfileIndex, storiesProfiles, props]);

  const getStoriesByIndex = useCallback((index) => {
    return storiesProfiles[index] || [{ url: 'default-story-url' }];
  }, [storiesProfiles]);

  const CustomStory = ({ story }) => (
    <div className="custom-story">
      <img src={story.url} alt="Story" style={{ width: '100%', height: '100%' }} />
      <div className="custom-story-caption">{story.caption}</div>
    </div>
  );

  const addView = async (story) => {
    if(!myCurrentProfile){
      return true
    }
    console.log("story",story)
    try {
      // if(!(post?.iViewed||IViewed)){
        let data = {
          PostId: story.PostId, 
          EngagementAreaId: 1,
        };
        let addReactRes = await CommonApiServices.addView(data);
        if(addReactRes.success){
          // setIViewed(true);
        }
      // }
    } catch (error) {}
  };
   
  const checkAndGoToLoginOrRegisterBox=()=>{
    if(myCurrentProfile){
      return true
    }
    else{
      let data = {
        dialogId: "SwitchProfileDialogBox",
        data: {},
      };
      updateContext("currentGlobalDialogBoxData", data);
    return false
    }
  }

  return (
    <>
      <Stories
        stories={getStoriesByIndex(activeProfileIndex)}
        key={storyKey}
        // onAllStoriesEnd={goToNextProfile}
        // onStoriesEnd={(e) => {
        //   addView(e)
        // }}
        onStoryStart={(e,data) => { 
          addView(data)
        }}
        onAllStoriesEnd={() => {
          if (activeProfileIndex < storiesProfiles.length - 1) {
            goToNextProfile();
          } else {
            setIsPaused(true); // Pause when all profiles have ended
            if (props.onAllStoriesEnd) {
              props.onAllStoriesEnd();
            }
          }
        }}
        defaultInterval={5000}
        isPaused={isPaused}
        keyboardNavigation={true}
        width={"100%"}
        height="83vh"
        onStoryChange={handleStoryChange}        
        // customStory={CustomStory}

      />
    </>
  );
}

export default StoryPosts;