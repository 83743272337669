import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import {
  Avatar,
  AvatarGroup,
  Dialog,
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Skeleton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { UseAuth } from "Api-Services/useAuth";
import { ContactMail, History, Label, Logout, QuestionAnswer, Rule, Settings } from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices";

import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import { DataManagementService } from "Api-Services/dataManagementService";
import { green, grey } from "@mui/material/colors";
 
import { isMobile } from "react-device-detect";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
 
export default function SideBarMenuContent() {
  
  const navigate = useNavigate();
 
  const [currentData, updateCurrentUser] = useCurrentUser();
  const { 
    myCurrentProfile, 
    myProfiles, 
  } = currentData;
 

  const sideBarMenuContents = [
    {
      id: 1,
      // title: "Settings",
      items: [
        {
          title: "Settings",
          link: "/Settings",
          icon: "settings",
        },  
      ],
    },
    {
      id: 1,
      title: "Job Deals",
      items: [
        {
          title: "Contracts",
          link: "/profile/jobs/contracts",
          icon: "work_history",
        }, 
        {
          title: "Proposals",
          link: "/profile/jobs/proposals",
          icon: "event_note",
        },
      ],
    },
    { id:2,
      title: "Contacts",
      items: [
        {
          title: "Followings",
          // link: "/pages/support/contact-us",
          icon: "people",
          dialogData:{
            dialogId: "FriendshipDialogBox",
            data: { profile: myCurrentProfile, friendshipTab: "followings" },
          }
        },
        {
          title: "Followers",
          // link: "/pages/support/contact-us",
          icon: "people",
          dialogData:{
            dialogId: "FriendshipDialogBox",
            data: { profile: myCurrentProfile, friendshipTab: "followers" },
          }
        },
        // {
        //   title:"About Us",
        //   link:"",
        //   icon:""
        // },
        // {
        //   title: "Suggestions",
        //   // link: "/pages/AskQuestion",
        //   icon: "people",
        // },
      ],
    },
  ];

  const getProfileImage = () => {
    let res = "";
    try {
      if (myCurrentProfile) {
        if (myCurrentProfile.profileImageUrl) {
          res = myCurrentProfile.profileImageUrl;
        }
      }
    } catch (error) {}
    return res;
  };


  return (
    <Paper sx={{width:"100%"}}>
   <Grid container >
   <Grid   >
       <List 
                          sx={{width:"100%"}}>
          <ListItem disablePadding  onClick={() => navigate("/" + myCurrentProfile.userName)}>
            <ListItemButton>
              <ListItemIcon>
              <Avatar sx={{ width: 40, height: 40 }} src={getProfileImage()} />{" "}
              </ListItemIcon>
              <ListItemText primary={myCurrentProfile
                            ? CommonServices.getProfileName(myCurrentProfile)
                            : "Profile"}/>
            </ListItemButton>
          </ListItem>
          <Divider sx={{my:0, }}/> 
          <ListItem disablePadding 
                                    onClick={() => {
                                      let data = {
                                        dialogId: "SwitchProfileDialogBox",
                                        data: {},
                                      }
                                      updateCurrentUser("currentGlobalDialogBoxData", data);
                                      //   setFriendshipTab("suggestions")
                                      //  setDrawerOpened("freindship")
                                      }} >
            <ListItemButton>
              <ListItemIcon>
              <AvatarGroup total={24} max={4}>
                        {myProfiles?.map((ProfileD)=>(ProfileD?.id!=myCurrentProfile?.id)&&
                        <Avatar sx={{ width: 40, height: 40 }} src={ProfileD?.profileImageUrl} />) }
                        </AvatarGroup>
              </ListItemIcon>
              <ListItemText  
                                secondary={
                                  <Typography
                                    sx={{ fontWeight: "500", fontSize: "0.9rem" }}
                                    variant="h6"
                                  >Switch
                                  </Typography>
                                } />
            </ListItemButton>
          </ListItem> 
          <Divider sx={{ my: 0 }} variant="middle" />
           
       </List>
       
       {(sideBarMenuContents ? (sideBarMenuContents.length ? true : false) : false) && (
                <>
                  {sideBarMenuContents.map((sideBarTopic) => (
                    <List
                      subheader={sideBarTopic?.title?
                        <ListSubheader sx={{ backgroundColor: "inherit" }}>
                          {sideBarTopic?.title}
                        </ListSubheader>:null
                      }
                    >
                      {(sideBarTopic?.items ? (sideBarTopic?.items?.length ? true : false) : false) &&
                        sideBarTopic?.items.map((sideBarItem) => (
                          <ListItem
                            disablePadding
                            onClick={() => {
                              if(sideBarItem?.dialogData){ 
                                updateCurrentUser("currentGlobalDialogBoxData", sideBarItem?.dialogData); 
                              }
                              else if(sideBarItem?.link){
                              navigate(sideBarItem?.link);
                            }}}
                          >
                            <ListItemButton> 
                              <ListItemIcon sx={{ minWidth: "30px" }}>
                           <Avatar sx={{ width: 30, height: 30 }}  >
                                {(
                                  sideBarItem?.icon ? (sideBarItem?.icon != "" ? true : false) : false
                                ) ? (
                                  <Icon>{sideBarItem?.icon}</Icon>
                                ) : (
                                  <Label />
                                )}
                            </Avatar> 
                              </ListItemIcon>
                              <ListItemText
                                secondary={
                                  <Typography
                                    sx={{ fontWeight: "500", fontSize: "0.9rem" }}
                                    variant="h6"
                                  >
                                    {sideBarItem?.title}
                                  </Typography>
                                }
                              />
                              {/* /pages/support/contact-us */}
                            </ListItemButton>
                          </ListItem>
                        ))}

                      <Divider sx={{ my: 0 }} variant="middle" />
                    </List>
                  ))}
                </>
              )}
              
          <Divider  sx={{my:0, }}/> 
          <ListItem disablePadding  onClick={() =>{
             UseAuth.logout()
             props?.logout()}}>
            <ListItemButton>
              <ListItemIcon>
              <Avatar sx={{ width: 40, height: 40 }}  >
                            <Logout/>
                            </Avatar> 
                         
              </ListItemIcon>
              <ListItemText primary={"Logout" } />
            </ListItemButton>
          </ListItem>

   </Grid>
   </Grid>
   </Paper>
  );
}

SideBarMenuContent.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
