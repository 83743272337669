/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import parse from "html-react-parser";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React, { useState, useEffect, useRef } from "react";

// Rental page components
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { Link, useNavigate, useParams } from "react-router-dom";
// Direct React component imports

import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import "swiper/swiper.min.css"; 
import { 
  ArrowBack, 
} from "@mui/icons-material";
import { isMobile } from "react-device-detect"; 
import { HttpService } from "Api-Services/httpService";
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import ProfileLists from "./Components/ProfileLists";
import InfiniteScroll from "react-infinite-scroller";
import { CommonServices } from "Api-Services/commonServices";

function Contacts(props) {
  const queryClient = useQueryClient()
  const [currentData,updateContext] = useCurrentUser();
  const [tab, setTab] = React.useState("followers");
  const {  myCurrentProfile } = currentData;
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    if(params?.tab){
     setTab(params?.tab)
    }
  }, [ params?.tab ]);

  let userName=params?.userName||props?.profile?.userName
  let {data:profile} = useQuery({
    queryKey: ["/api/profile/getbyusername/"+userName],
    queryFn: async () => { 
      if(userName){
      return await HttpService.getApiData("/api/profile/getbyusername/"+userName); 
      }
      else return null
     },
    initialData:props?.profile||null
  });
  
  const loader = (
    <div key="loader" className="loader">
      Loading ...
    </div>
  );
 


  function SuggestionsBox() {     
  const loadMore=async ( {pageParam=1} )=>{ 
        console.log("pageParam",pageParam)
        // return []
      let relativePath="/api/profile/profilesyoumayknow?pageSize="+8+"&page="+pageParam
        let resD =  await HttpService.getService(relativePath,{});
        let ress=[] 
        resD=resD?.data 
        if(resD?.record ){ 
          if(resD?.record?.length){  
              ress=resD?.record     
        }}
        return ress
  }

  const {
    data:profilesYouMayKnows,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ["/api/profile/profilesyoumayknow"],
    queryFn: loadMore,
    // initialPageParam:1,
    getNextPageParam: (lastPage, pages) =>{ 
      console.log("pages", pages);
      // return lastPage.nextCursor
      let lastLen= 1
           lastLen=pages?.length+1
      
      return lastLen},
  })
 
   let profilesD=[] 
    try { 
      {profilesYouMayKnows.pages?.forEach(element => {
        element.forEach(element11 => {
          profilesD.push(element11)
        });
      }); 
     }
      console.log("profilesD", profilesD);
    } catch (error) {
      console.log("errrrrrrrrrrr", error);
    }
    return ( 
       <InfiniteScroll
          loadMore={() => fetchNextPage()}
          hasMore={true}
          loader={loader}
        >   
         <ProfileLists  profiles={profilesD} loadMore={()=>{}} />
       </InfiniteScroll> 
    );
  }
  
  function FollowersBox() {
  const [isEndOfList, setIsEndOfList] = React.useState(false);
    
  const loadMore=async ( {pageParam=1} )=>{ 
    console.log("pageParam",pageParam)
    if(isEndOfList) return []
    // return [] 
    let relativePath="/api/profilefollowers/followers/"+profile?.id+"?pageSize="+8+"&page="+pageParam
    let resD =  await HttpService.getService(relativePath,{});
    let ress=[] 
    resD=resD?.data 
    if(resD ){ 
      if(resD?.isEndOfList)setIsEndOfList(true)
      if(resD?.record?.length){  
          ress=resD?.record  
          ress= ress?.reverse()   
    }}
    return ress
}

const {
data:followers,
error,
fetchNextPage,
hasNextPage,
isFetching,
isFetchingNextPage,
status,
} = useInfiniteQuery({
queryKey: ["/api/profilefollowers/followers/"+profile?.id],
queryFn: loadMore,
// initialPageParam:1,
getNextPageParam: (lastPage, pages) =>{ 
  console.log("pages", pages);
  // return lastPage.nextCursor
  let lastLen= 1
       lastLen=pages?.length+1
  
  return lastLen},
})

let profilesD=[] 
try { 
  {followers.pages?.forEach(element => {
    element.forEach(element11 => {
      profilesD.push(element11)
    });
  }); 
 }
  console.log("profilesD", profilesD);
} catch (error) {
  console.log("errrrrrrrrrrr", error);
}
return ( 
   <InfiniteScroll
      loadMore={() => fetchNextPage()}
      hasMore={!isEndOfList}
      loader={loader}
    >   
     <ProfileLists  profiles={profilesD} loadMore={()=>{}} />
   </InfiniteScroll> 
);
  }

  function FollowingsBox() {
  
  const [isEndOfList, setIsEndOfList] = React.useState(false);
    
  const loadMore=async ( {pageParam=1} )=>{ 
    console.log("pageParam",pageParam)
    if(isEndOfList) return []
    // return [] 
    let relativePath="/api/profilefollowers/followings/"+profile?.id+"?pageSize="+8+"&page="+pageParam
    let resD =  await HttpService.getService(relativePath,{});
    let ress=[] 
    resD=resD?.data 
    if(resD ){ 
      if(resD?.isEndOfList)setIsEndOfList(true)
      if(resD?.record?.length){  
          ress=resD?.record  
          ress= ress?.reverse()   
    }}
    return ress
}

const {
data:followings,
error,
fetchNextPage,
hasNextPage,
isFetching,
isFetchingNextPage,
status,
} = useInfiniteQuery({
queryKey: ["/api/profilefollowers/followings/"+profile?.id],
queryFn: loadMore,
// initialPageParam:1,
getNextPageParam: (lastPage, pages) =>{ 
  console.log("pages", pages);
  // return lastPage.nextCursor
  let lastLen= 1
       lastLen=pages?.length+1
  
  return lastLen},
})

let profilesD=[] 
try { 
  {followings.pages?.forEach(element => {
    element.forEach(element11 => {
      profilesD.push(element11)
    });
  }); 
 }
  console.log("profilesD", profilesD);
} catch (error) {
  console.log("errrrrrrrrrrr", error);
}
return ( 
   <InfiniteScroll
      loadMore={() => fetchNextPage()}
      hasMore={!isEndOfList}
      loader={loader}
    >   
     <ProfileLists  profiles={profilesD} loadMore={()=>{}} />
   </InfiniteScroll> 
);
  }

  const loadingComp = () => {
    try {
      return (
        <>
          <Container sx={{ py: 3 }}>
            <Grid
              spacing={2}
              // sx={{ marginBottom: "-39px" }}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item xs={9}>
                <Skeleton width="40%">
                  <Typography>.</Typography>
                </Skeleton>
              </Grid>
              <Grid item xs={3}>
                <Skeleton width="50%">
                  <Typography>.</Typography>
                </Skeleton>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              {[...Array(isMobile ? 1 : 2)].map(() => (
                <Grid item xs={12}>
                  <Card sx={{ width: "100%" }}>
                    <CardHeader
                      avatar={
                        <Skeleton animation="wave" variant="circular" width={40} height={40} />
                      }
                      title={
                        <Skeleton
                          animation="wave"
                          height={10}
                          width="80%"
                          style={{ marginBottom: 6 }}
                        />
                      }
                      subheader={<Skeleton animation="wave" height={10} width="40%" />}
                    />
                    <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />

                    <CardContent>
                      <React.Fragment>
                        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={10} width="80%" />
                      </React.Fragment>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </>
      );
    } catch (error) {
      // console.log("loadingComp error", error);
      return <></>;
    }
  };
return   <>
            <AppBar position="static" sx={{ width: "100%", bgcolor: "background.paper" }}>
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }} 
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {/* {CommonServices.getProfileName(profile)} */}
                {"Contacts - "} {CommonServices?.getProfileName(profile)}
                </Typography>
              </Toolbar>
              <Divider sx={{ my: "0rem" }} />
            </AppBar> 
          <Grid container   sx={{ width: "100%", bgcolor: "background.paper" }}>
           
          <Grid item xs={(isMobile||props?.profile)?12:4}>
              { (isMobile||props?.profile)?<> 
                <Tabs 
                  // orientation={isMobile?"horizontal":"vertical"}
                  // variant="scrollable" 
                  // aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: 'divider' ,textAlign:"left"}}
                          value={tab}
                          onChange={(e, v) => {
                            setTab(v);
                            // props.changeFriendshipTab(v);
                          }}
                centered
              >
                <Tab label={(profile?.followersCount||"")  + " Followers"} value={"followers"} />
                <Tab label={(profile?.followingCount||"") + " Following"} value={"followings"} />
                {/* <Tab label={(myCurrentProfile?.friendsCount||"")  + " Friends"} value={"friends"} /> */}
                {/* <Tab label={profile.followersCount + " Followers"} value={"followers"} />*/}
                <Tab label="Suggestions" value={"suggestions"} /> 
              </Tabs>
              </>:<Tabs 
                  orientation={ "vertical"}
                  variant="scrollable" 
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: 'divider' ,textAlign:"left"}}
                          value={tab}
                          onChange={(e, v) => {
                            setTab(v);
                          }}
                centered
              >
                <Tab label={(profile?.followersCount||"")  + " Followers"} value={"followers"} />
                <Tab label={(profile?.followingCount||"") + " Following"} value={"followings"} />
                {/* <Tab label={(myCurrentProfile?.friendsCount||"")  + " Friends"} value={"friends"} /> */}
                {/* <Tab label={(myCurrentProfile?.followerRequestsCount||"")  + " Follower Requests"} value={"followerrequests"} /> */}
                <Tab label={"Suggestions"} value={"suggestions"} /> 
              </Tabs>}
            </Grid>
          <Grid item xs={12}  md={(isMobile||props?.profile)?12:4}>
     <>  
        <Grid container direction="row" sx={{p:1}} justifyContent="space-between" alignItems="flex-start">
          <Grid item>
           { (tab=="suggestions")? 
            <MKTypography variant={"h6"}>Profiles You May Know</MKTypography>
           :(tab=="followers")?
            <MKTypography variant={"h6"}>{profile?.followerCount}  Followers</MKTypography>
           :(tab=="followings")? 
            <MKTypography variant={"h6"}>{profile?.followingCount}  Following</MKTypography>
           :null}  
           </Grid> 
        </Grid>
              { (tab=="suggestions")?<SuggestionsBox  />:null}
              { (tab=="followers")?<FollowersBox  />:null}
              { (tab=="followings")?<FollowingsBox  />:null}
           </> 
          </Grid>
  </Grid> 
  </> 
}

export default Contacts;
