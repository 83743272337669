import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Avatar,
  Badge,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Icon,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader, 
  Paper,
  Skeleton,
  Stack,
  SwipeableDrawer,
  TextField,
  Toolbar,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { UseAuth } from "Api-Services/useAuth";
import { AccountCircle, Add, ArrowBack, ArrowDownward, ArrowDropDownCircleOutlined, CalendarViewMonth, Category, CategorySharp, ExpandLess, ExpandMore, Filter, Home, Inbox, Label, ListAlt, ListAltSharp, Menu, Person, PriceChange, Search, SearchRounded, Settings, Tune, ViewCompactAlt, ViewListSharp, Work } from "@mui/icons-material";
// import { MenuIcon } from "@mui/icons-material/Menu";
import { CommonServices } from "Api-Services/commonServices";

import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import { DataManagementService } from "Api-Services/dataManagementService";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import SwitchMyProfilesBox from "pages/UserProfile/Sections/SwitchMyProfilesBox";
import MKAvatar from "components/MKAvatar";
import Friendship from "pages/UserProfile/Components/Friendship/Friendship";
import { isMobile } from "react-device-detect";
import MKButton from "components/MKButton";
import TruncateMarkup from "react-truncate-markup";
import { useQuery } from "@tanstack/react-query";
import { HttpService } from "Api-Services/httpService";
import queryString from "query-string";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function JobsSideBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpened, setDrawerOpened] = React.useState(false);
  const [friendshipTab, setFriendshipTab] = React.useState("followers");
  const [drawer2Opened, setDrawer2Opened] = React.useState(false);
  const [forceRerender, setForceRerender] = React.useState(false); 
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [keyword, setKeyword] = React.useState("");
  const [listingFilter, setListingFilter] = React.useState({});
  const [postFilter, setPostFilter] = React.useState({});
  const [propertyFilter, setPropertyFilter] = React.useState({});
  const [vehicleFilter, setVehicleFilter] = React.useState({});
  const [filterAccordionExpanded, setFilterAccordionExpanded] = React.useState(false);
  const [categoryAccordionExpanded, setCategoryAccordionExpanded] = React.useState(false);
  const [searchBoxShowed, setSearchBoxShowed] = React.useState(false);
  const [openedCatagoryId, setOpenedCatagoryId] = React.useState(false);

  const [currentData, updateCurrentUser] = useCurrentUser();
  const {
    isUserLoggedIn, 
    myCurrentProfile,
    myCurrentUserLoading,
    myCurrentProfileLoading,
  } = currentData;
  
  
  const navigate = useNavigate();
  const params = useParams();
  const searchParams = queryString.parse(location?.search);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };


  React.useEffect(() => {
    try {
    let searchParamsD = queryString.parse(location?.search); 
    let filter=searchParamsD?.filter 
    if(filter?.length){
    filter=JSON.parse(filter )
    }
    // console.log("filter 111111",filter)
    // if(filter){
      // if(filter?.postFilter){
        setPostFilter(filter?.postFilter||{})
      // }
      // if(filter?.listingFilter){
        setListingFilter(filter?.listingFilter||{})
      // }
      // if(filter?.vehicleFilter){
        setVehicleFilter(filter?.vehicleFilter||{})
      // }
      // if(filter?.propertyFilter){
        setPropertyFilter(filter?.propertyFilter||{})
      // } 
    setForceRerender(!forceRerender)
    // } 
    } catch (error) {
      
    }
  }, [location?.pathname,params]);

  React.useEffect(() => {
    setDrawerOpened(false);
    setDrawer2Opened(false);
  }, [location.pathname]);
  
  React.useEffect(async () => {
    // setSearchBoxShowed(false)
    let postFilterData={}
    let marketPlaceListingFilterData={}
    let marketPlaceVehicleFilterData={}
    let marketPlacePropertyFilterData={}
    try {
      let searchParamsD = queryString.parse(location?.search); 
            // console.log("searchParamsD", searchParamsD);
            
      if(params?.listingTypeId){
          setCategoryAccordionExpanded(false)
          setFilterAccordionExpanded(true)}
      else{
        setCategoryAccordionExpanded(true)
        setFilterAccordionExpanded(false) 
      }
      if (searchParamsD||params) {  
            ////filter post
            if(searchParamsD?.keyword){
              postFilterData["keyword"]=searchParamsD?.keyword
            }
            if(params?.listingTypeId){ 
              if(!(params?.listingTypeId=="0"||params?.listingTypeId==0)){
              marketPlaceListingFilterData["MarketPlaceListingTypeId"]=params?.listingTypeId
              
            setSearchBoxShowed(false)
            }
            else{  
            setSearchBoxShowed(true)
            }
          }
           filterData={
            post:postFilterData,
            marketPlaceListing:marketPlaceListingFilterData,
            marketPlaceVehicle:marketPlaceVehicleFilterData,
            marketPlaceProperty:marketPlacePropertyFilterData,
           } 
        } 
      else{

      } 
        
    } catch (error) {
      
    }     
  }, [location?.search,params]);

  React.useEffect(() => {
    setForceRerender(!forceRerender);
  }, [myCurrentProfile, isUserLoggedIn]);
   
  const { data: listingTypes } = useQuery({
    queryKey: ["/api/marketplace/listingtype/all"],
    queryFn: async () => {
      return await HttpService.getApiData("/api/marketplace/listingtype/all");
    },
    initialData:[]
  });


  let {data:marketPlaceListingPersonTypes} = useQuery({
    queryKey: ["/api/marketplace/marketplacelistingpersontype/all/"],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/marketplace/marketplacelistingpersontype/all/");
    }, 
  });

  let {data:marketPlaceListingConditions} = useQuery({
    queryKey: ["/api/marketplace/marketplacelistingcondition/all/"],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/marketplace/marketplacelistingcondition/all/");
    }, 
  });
    
  // mynotifications
  const { data: jobSettings, isFetched :jobSettingsFetched  } = useQuery({
    queryKey: ["/api/profilesetting/jobsetting/my" ],
    queryFn: async () => { 
      // console.log("getMyChats", )  
      let mm= await HttpService.getApiData("/api/profilesetting/jobsetting/my" );  
      return mm
    },
    // initialData:{}
  });

  
  let {data:jobCategories} = useQuery({
    queryKey: ["/api/job/jobcategory/withvacancy"],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/job/jobcategory/withvacancy");
    }, 
  });
   

  const goToPage = (page) => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate(page);
  };

  const goToSearch = (filterData) => {
    try {
        setAnchorEl(null);
        handleMobileMenuClose();
        let searchString="?"
        let filter={}
        if(filterData?.keyword){
          filter["postFilter"]={keyword:filterData?.keyword} 
        } 
        filter=JSON.stringify(filter)
        searchString=searchString+"&filter="+filter
        let path= "/jobs/category/"+filterData?.listingTypeName+"/"+filterData?.listingTypeId+ searchString
        navigate(path);
          
    } catch (error) {
      
    }
  };
  
  const goToProfileJobPosts = ({id}) => {
    try {
        setAnchorEl(null);
        handleMobileMenuClose();
        let searchString="?"
        let filter={}
        if(id){
          filter["profileFilter"]={id:id}  
        }  
        filter=JSON.stringify(filter)
        searchString=searchString+"&filter="+filter
        let path= "/jobs/category/profile/"+id+ searchString
        navigate(path);
          
    } catch (error) {
      
    }
  };
  

  const goToCategory = (filterData) => {
    try {
        setAnchorEl(null);
        handleMobileMenuClose();
        let searchString="?"
        let categoryName=""
        let categoryId=""
        let filter={} 
        if(filterData?.filter=="jobFilter"){
          filter["jobFilter"]={id:filterData?.id} 
          categoryName=filterData?.title
          categoryId=filterData?.id
        }
        filter=JSON.stringify(filter)
        searchString=searchString+"&filter="+filter
        let path= "/jobs/category/"+categoryName+"/"+categoryId+ searchString
        navigate(path);
          
    } catch (error) {
      
    }
  };
  
  const applyFilter = (filteringEntity,field,value) => {
      try {
        let filteringEntityData={}
        let filter={
          postFilter:postFilter,
          listingFilter:listingFilter,
          propertyFilter :propertyFilter,
          vehicleFilter :vehicleFilter,
        }
        if(filteringEntity&&field){
          if(filteringEntity=="postFilter"){
            filteringEntityData=postFilter
            filteringEntityData[field]=value
            setPostFilter(filteringEntityData)
          }
          if(filteringEntity=="listingFilter"){
            filteringEntityData=listingFilter
            filteringEntityData[field]=value
            setListingFilter(filteringEntityData)
          }
          if(filteringEntity=="propertyFilter"){
            filteringEntityData=propertyFilter
            filteringEntityData[field]=value 
            setPostFilter(filteringEntityData) 
          }
          if(filteringEntity=="vehicleFilter"){
            filteringEntityData=vehicleFilter
            filteringEntityData[field]=value 
            setPostFilter(filteringEntityData)  
          }
          filter[filteringEntity]=filteringEntityData

              
          setAnchorEl(null);
          setForceRerender(!forceRerender)
          handleMobileMenuClose();
          if(!isMobile){
           navigateToFilters(filter)
          }
        }


      } catch (error) {
        
      }

  };

  const navigateToFilters=(filters)=>{
     try {
        let searchString="?" 
        let filterD
        if(filters){
          filterD= filters 
        }
        else{
          filterD={
            postFilter:postFilter,
            listingFilter:listingFilter,
            propertyFilter :propertyFilter,
            vehicleFilter :vehicleFilter,
          }
        }
        
        filterD=JSON.stringify(filterD)

        searchString=searchString+"&filter="+filterD
        let path= location?.pathname+""
        path= path+""+ searchString
        // console.log(path)
        navigate(path);   
     } catch (error) {
      
    }
  }
 
 
  function CreateListingComp(){
    return (<>
      <List sx={{width:"100%"}}
                        subheader={
                          <ListSubheader sx={{ backgroundColor: "inherit" }}>
                            {"Creat Listing"}
                          </ListSubheader>
                        }
                      >
                        { 
                          listingTypes.map((sideBarItem) => (
                            <ListItem fullWidth
                              disablePadding
                                            
                                component={Link}
                                to={"/Market-Place/create/"+sideBarItem?.id}
                            >
                              <ListItemButton>
                                <ListItemIcon sx={{ minWidth: "30px" }}>
                                  {(
                                    sideBarItem?.icon ? true : false
                                  ) ? (
                                    <Icon>{sideBarItem?.icon}</Icon>
                                  ) : (
                                    <Label />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  secondary={
                                    <Typography
                                      sx={{ fontWeight: "500", fontSize: "0.9rem" }}
                                      variant="h6"
                                    >
                                      {sideBarItem.title}
                                    </Typography>
                                  }
                                />
                                {/* /pages/support/contact-us */}
                              </ListItemButton>
                            </ListItem>
                          ))}
  
                        <Divider sx={{ my: 1 }} variant="middle" />
                      </List> 
    </>)}


  function FilterListingComp(){
    return (<>  
      <List  sx={{width:"100%"}}
                        // subheader={
                        //   <ListSubheader sx={{ backgroundColor: "inherit" }}>
                        //     {"Filter Listing "}
                        //   </ListSubheader>
                        // }
                        >
                            {/* <Divider/> */}
                            
                            <ListItem fullWidth
                              disablePadding 
                            >

                                <FormGroup  sx={{pl:2}}>
                                <FormControlLabel checked={listingFilter?.isForSell} control={<Checkbox   />} onChange={(e)=>{
                                  applyFilter("listingFilter","isForSell",e?.target?.checked)
                                }} label="Listings For Sell" />
                                <FormControlLabel  checked={listingFilter?.isForRent} onChange={(e)=>{
                                  applyFilter("listingFilter","isForRent",e?.target?.checked)
                                }} 
                                control={<Checkbox   />} label="Listings For Rent" />
                                 </FormGroup> 
                            </ListItem>  
                            <ListItem fullWidth
                              disablePadding
                              // onClick={() => {
                              //   goToPage(sideBarItem.link);
                              // }}
                            >       
                               <Accordion defaultExpanded sx={{
                               width: "100%",}} >
                            <AccordionSummary sx={{ 
                              '& .MuiAccordionSummary-content': {
                                marginLeft: "12px",
                                my:"2px"
                              }, 
                              flexDirection: 'row-reverse', 

                            }} 
                            expandIcon={<ExpandMore />}  aria-controls="panel1d-content" id="panel1d-header">
                              <Typography  sx={{ width: '70%', flexShrink: 0 }}>Price </Typography>
                           {  (listingFilter?.priceMin||listingFilter?.priceMax) &&
                           <Typography component={Button} 
                                onClick={()=>{
                                    applyFilter("listingFilter","priceMin",null)
                                    applyFilter("listingFilter","priceMax",null)
                                }}
                               variant="caption" >clear</Typography>}

                            </AccordionSummary>
                            <AccordionDetails sx={{py:0}}>
                             
                            <Grid
                                    noValidate
                                    autoComplete="off"
                                    component="form"
                                    container
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                  > 
                                <Grid item xs={5}>
                                <TextField
                                  id="outlined-controlled"
                                  label="min price"
                                  type="number"
                                  defaultValue={listingFilter?.priceMin}
                                  // label="Filled"
                                  //  variant="filled"
                                  // value={name}
                                  // onChange={(event) => {
                                  //   setName(event.target.value);
                                  // }}
                                   onBlur={(e)=>{
                                    if(e?.target?.value){
                                       applyFilter("listingFilter","priceMin",e?.target?.value)
                                      }
                                    }} 
                                /> </Grid>
                                
                                <Grid item xs={1}>
                                <MKTypography variant={"caption"}>to</MKTypography>
                                </Grid>
                                
                                <Grid item xs={5}>
                                <TextField
                                  id="outlined-uncontrolled"
                                  type="number"
                                  label="max price"
                                  defaultValue={listingFilter?.priceMax}
                                  onBlur={(e)=>{
                                    if(e?.target?.value){
                                      applyFilter("listingFilter","priceMax",e?.target?.value)
                                       }} } 
                                  // defaultValue="foo"
                                /></Grid>
                                </Grid> 
                            </AccordionDetails>
                               </Accordion> 
                            </ListItem>
                            <ListItem fullWidth
                              disablePadding
                              // onClick={() => {
                              //   goToPage(sideBarItem.link);
                              // }}
                            >       
                               <Accordion
                               defaultExpanded={listingFilter?.conditions?.length} sx={{
                               width: "100%",}} >
                            <AccordionSummary sx={{ 
                              '& .MuiAccordionSummary-content': {
                                marginLeft: "12px",
                                my:"2px"
                              }, 
                              flexDirection: 'row-reverse', 

                            }} 
                            expandIcon={<ExpandMore />}  aria-controls="panel1d-content" id="panel1d-header">
                              <Typography  sx={{ width: '70%', flexShrink: 0 }}>Condition </Typography>
                           {  (listingFilter?.conditions?.length) &&
                             <Typography component={Button} 
                                onClick={()=>{
                                    applyFilter("listingFilter","conditions",null) 
                                }}
                               variant="caption" >clear</Typography>}
                            </AccordionSummary>
                            <AccordionDetails  sx={{py:0}}>
                             
                            <FormGroup>
                             
                            {marketPlaceListingConditions?.map((lCondition)=>
                               <FormControlLabel 
                               checked={listingFilter?.conditions?.includes(lCondition?.id)}
                               onChange={(e)=>{
                                    let conditionsD=[]
                                    if(listingFilter?.conditions?.length){
                                      conditionsD=listingFilter?.conditions
                                    }
                                    if(e?.target?.checked){
                                      if(!(conditionsD?.includes(lCondition?.id))){
                                        conditionsD.push(lCondition?.id)
                                      } 
                                    }
                                    else{
                                      if((conditionsD?.includes(lCondition?.id))){ 
                                          let index = conditionsD?.indexOf(lCondition?.id); 
                                         conditionsD.splice(index, 1); 
                                      }  
                                    }
                                   applyFilter("listingFilter","conditions",conditionsD)
                                    }} 
                               control={<Checkbox   />} label={lCondition?.title} />
                                ) }   </FormGroup> 
                            </AccordionDetails>
                               </Accordion> 
                            </ListItem>
                            <ListItem fullWidth
                              disablePadding
                              // onClick={() => {
                              //   goToPage(sideBarItem.link);
                              // }}
                            >       
                               <Accordion 
                               defaultExpanded={listingFilter?.personTypes?.length} 
                               sx={{
                               width: "100%",}} >
                            <AccordionSummary sx={{ 
                              '& .MuiAccordionSummary-content': {
                                marginLeft: "12px",
                                my:"2px"
                              }, 
                              flexDirection: 'row-reverse', 

                            }} 
                            expandIcon={<ExpandMore />}  aria-controls="panel1d-content" id="panel1d-header">
                              <Typography  sx={{ width: '70%', flexShrink: 0 }}>Person type  </Typography>
                           {  (listingFilter?.personTypes?.length) &&
                             <Typography component={Button} 
                                onClick={()=>{
                                    applyFilter("listingFilter","personTypes",null) 
                                }}
                               variant="caption" >clear</Typography>}
                            </AccordionSummary>
                            <AccordionDetails  sx={{py:0}}>
                             
                            <FormGroup>
                               {marketPlaceListingPersonTypes?.map((ptype)=>
                               <FormControlLabel 
                               checked={listingFilter?.personTypes?.includes(ptype?.id)}
                               onChange={(e)=>{
                                    let personTypesD=[]
                                    if(listingFilter?.personTypes?.length){
                                      personTypesD=listingFilter?.personTypes 
                                    }
                                    if(e?.target?.checked){
                                      if(!(personTypesD?.includes(ptype?.id))){
                                        personTypesD.push(ptype?.id)
                                      } 
                                    }
                                    else{
                                      if((personTypesD?.includes(ptype?.id))){ 
                                          let index = personTypesD?.indexOf(ptype?.id); 
                                          personTypesD.splice(index, 1); 
                                      }  
                                    }
                                   applyFilter("listingFilter","personTypes",personTypesD)
                                    }} 
                               
                               
                               control={<Checkbox  />} label={ptype?.title} />
                                ) }  </FormGroup> 
                            </AccordionDetails>
                               </Accordion> 
                            </ListItem>
                            
    {/* isForRent: DataTypes.BOOLEAN,  
    isForSell: DataTypes.BOOLEAN,   */}
                          </List>
    </>)
  }
  function MyMenuComp(){
    return (<>  
        <MKBox  xs={12} p={0} justifyContent="left">
            <List dense>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText
                  primary={ <Typography 
                    onClick={()=>{navigate("/Settings/Job")} }
                   sx={{ fontWeight: "500", fontSize: "0.9rem" }}
                   variant="body2"
                 >
                   {"Job Settings" }
                 </Typography>}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <Work />
                  </ListItemIcon>
                  <ListItemText
                  primary={ <Typography onClick ={()=>{goToProfileJobPosts(myCurrentProfile)}}
                   sx={{ fontWeight: "500", fontSize: "0.9rem" }}
                   variant="body2"
                 >
                   {"My job posts" }
                 </Typography>}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ListAltSharp />
                  </ListItemIcon>
                  <ListItemText 
                  primary={ <Typography
                    
                    onClick={()=>{navigate("/profile/jobs/proposals")} } 
                   sx={{ fontWeight: "500", fontSize: "0.9rem" }}
                   variant="body2"
                 >
                   {"Proposals" }
                 </Typography>} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <CalendarViewMonth />
                  </ListItemIcon>
                  <ListItemText 
                  primary={ <Typography color="text"
                   onClick={()=>{navigate("/profile/jobs/contracts")} }
                   sx={{ fontWeight: "500", fontSize: "0.9rem" }}
                   variant="text"
                 >
                   {"Contracts" }
                 </Typography>} />
                </ListItemButton>
              </ListItem>
              {/* <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <Inbox />
                  </ListItemIcon>
                  <ListItemText 
                  primary={ <Typography component={Link} to={""}
                   sx={{ fontWeight: "500", fontSize: "0.9rem" }}
                   variant="body2"
                 >
                   {"Inbox" }
                 </Typography>} />
                </ListItemButton>
              </ListItem> */}
            </List>
            </MKBox>
    </>)
  }


  function FavoriteListingComp(){
    return (<>
    
    <List sx={{width:"100%"}}
                      // subheader={
                      //   <ListSubheader sx={{ backgroundColor: "inherit" }}>
                      //     {"Catagories"}
                      //   </ListSubheader>
                      // }
                    >
                      { 
                        jobSettings?.JobsSeeking?.map((jobCategory) => (<>
                      
                          
      <ListItemButton   onClick={() => {
                                    let data={
                                      filter:'jobFilter',
                                      title:jobCategory?.title,
                                      id:jobCategory?.id
                                    };
                                    goToCategory(data);
                                  }}>
        <ListItemIcon>  
                                    <Badge badgeContent={jobCategory?.EmploymentJobVacancies?.length} color="secondary">
                                      <Work />
                                      </Badge> 
        </ListItemIcon>
        <ListItemText primary={
                                  <Typography
                                    sx={{ fontWeight: "500", fontSize: "0.9rem" }}
                                    variant="body2"
                                  >
                                    {jobCategory.title}
                                  </Typography>
                                }/> 
      </ListItemButton> 
      
                        </>))}

                      <Divider sx={{ my: 1 }} variant="middle" />
                    </List> 
    </>)
  }

  function ListingTypesComp(){
    return (<>
    
    <List sx={{width:"100%"}}
                      // subheader={
                      //   <ListSubheader sx={{ backgroundColor: "inherit" }}>
                      //     {"Catagories"}
                      //   </ListSubheader>
                      // }
                    >
                      { 
                        jobCategories?.map((jobCategory) => (<>
                      
                          
      <ListItemButton  onClick={() => {
                              setOpenedCatagoryId((openedCatagoryId==jobCategory?.id)?false:jobCategory?.id)
                             
                            }}>
        <ListItemIcon>
        {/* <Badge badgeContent={4} color="secondary"> */}
                                <Avatar size="small">
                                  <Work />
                                </Avatar>
                                {/* </Badge> */}
        </ListItemIcon>
        <ListItemText primary={
                                  <Typography
                                    sx={{ fontWeight: "500", fontSize: "0.9rem" }}
                                    variant="body2"
                                  >
                                    {jobCategory.title}
                                  </Typography>
                                }/>
        {(openedCatagoryId==jobCategory?.id)?  <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
                          { ( openedCatagoryId==jobCategory?.id)&&
                           <>{jobCategory?.Jobs?.length?
                           <Collapse in={ openedCatagoryId==jobCategory?.id} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                 {jobCategory?.Jobs?.map((jobM)=>(jobM?.title)&& <ListItemButton 
                                   onClick={() => {
                                    let data={
                                      filter:'jobFilter',
                                      title:jobM?.title,
                                      id:jobM?.id
                                    };
                                    goToCategory(data);
                                  }}
                                 sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                    <Badge badgeContent={jobM?.EmploymentJobVacancies?.length} color="secondary">
                                      <Work />
                                      </Badge>
                                    </ListItemIcon>
                                    <ListItemText primary={
                                  <Typography
                                    sx={{ fontWeight: "500", fontSize: "0.9rem" }}
                                    variant="body2"
                                  >
                                    {jobM?.title}
                                  </Typography>} />
                                  </ListItemButton>)}
                                </List>
                              </Collapse>:null}</> }


                        </>))}

                      <Divider sx={{ my: 1 }} variant="middle" />
                    </List> 
    </>)
  }
  
  const searchBoxComp=()=> {
  return (<Paper
    // component="form"
    // sx={{
    //   //  p: "2px 4px",
    //   mb: 1,
    //   display: "flex",
    //   alignItems: "center",
    //   width: "100%",
    // }}

    square={false}
    elevation={0}
    shadow={"none"}
    sx={{
      mb: 1,
      // mx: 2,
      // mt: 0.5,
      backgroundColor: "#f0f2f5",
      // mr: "6px",
      display: "flex",
      alignItems: "center",
      // width: "158px",
      width: "100%",
    }}


  >
    {/* <IconButton sx={{ p: "10px" }} aria-label="menu">
      <Menu/>
    </IconButton> */}
    <InputBase
      sx={{ ml: 1, flex: 1 }}
      placeholder="Search "
      // value={searchKeyword}
      onKeyDown={(event) => {
        if (event.keyCode) {
          if (event.keyCode === 13) {
            // searchByKeyword();
             
              let data={
                listingTypeName:"search",
                listingTypeId:0,
                keyword:event.target.value
              };
              goToSearch(data); 
          }
        }
      }}
      onChange={(e) => {
        setKeyword(e.target.value);
        // console.log(e.target.value)
      }}
      inputProps={{ "aria-label": "search google maps" }}
    />
    <IconButton
      // disabled={!searchKeyword}
      onClick={() => {
        let data={
          listingTypeName:"search",
          listingTypeId:0,
          keyword:keyword
        };
        goToSearch(data); 
      }}
      type="button"
      sx={{ p: "10px" }}
      aria-label="search"
    >
      <Search />
    </IconButton>
  </Paper>) }


  return (
    <> 
      <> <Paper sx={{px:1,py:0.3, mb:2, mt:0.4, }} elevation={0}>
         <MKBox  sx={{ display:{xs: "flex", sm: "flex", md: "none" }}}>
           
      <AppBar position="static">
        {/* <Toolbar sx={{my:0 }}> */}
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <Category/>
          </IconButton> */}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
>     
           
            <Grid xs={7}>  
             {params?.listingTypeId? <Breadcrumbs sx={{my:0}} aria-label="breadcrumb">
                {/* <Link
                  underline="hover"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="inherit"
                  href="/Market-Place"
                > */}
                  {/*  */}
                <Button component={Link} size="sm" to="/jobs" startIcon={<ArrowBack sx={{ mr: 0.5 }}  />} 
                  // color="primary"
                >  {"Back"}
                </Button>
                {/* </Link>  */}
                <Typography variant="h4"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="text.primary"
                >  
                 {params?.listingTypeName}
                </Typography>
              </Breadcrumbs>:
                <MKTypography variant="h4" component="div" sx={{ flexGrow: 1 ,
                  mx:2,
                  //  borderBottom: 1,
                  //  borderColor: "divider"
                }} >
                  {params?.listingTypeName?params?.listingTypeName:"Jobs"}
                </MKTypography> } 
                </Grid>
 
                <Grid  xs={5}> 
           <IconButton 
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
             onClick= {() => setSearchBoxShowed(!searchBoxShowed) }
            // size={"small"}
            color="inherit"

          >
            <SearchRounded />
          </IconButton>  
   
          <MKButton
            size="small"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            // startIcon={<AccountCircle/>}
            onClick= {() => setDrawerOpened("mymenu")}
            color="primary"
          > Job Menu 
          </MKButton  > 
           </Grid>  
               {(searchBoxShowed)&& 
               <Grid  xs={12}>
                {searchBoxComp()}
                </Grid>}

          </Grid>
  
        {/* </Toolbar> */}
      </AppBar>
         </MKBox>
      {/* <Divider sx={{my:0.5}}/> */}
         <MKBox sx={{px:1, mb:1 , display:{xs: "flex", sm: "flex", md: "none" }}}>
        
    <Grid sx={{my:0.2}}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"  > 
      <Chip component={Link} to="/pages/posts/create-job-post" variant="outlined" color="primary" icon={<Add />} label="Create Vacancy"   />
      { ((params?.listingTypeId)||searchParams?.keyword)?<Chip   sx={{display:"none"}} onClick= {() => setDrawerOpened("filterListing")} icon={<Tune />}  label="Filter"  color="secondary" variant="outlined"  />
     :null} 
     <Chip  onClick= {() => setDrawerOpened("selectListingType")} icon={<ViewListSharp />}  color="success" label="Categories" variant="outlined"  />
    </Grid>
         </MKBox> 
         </Paper>
          <Card
          sx={{ display:{xs: "none", sm: "none", md: "flex" },
          width:{xl: "20%",lg:"28%", md: "33%" }
        }}
           item
            xs={12} 
           mx="auto"  
           style={{
            // color: "rgba(0, 0, 0, 0.87)",
            // transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            // boxShadow: "none",
            overflowY: "auto",
            // display: "flex",
            // flexDirection: "column",
            // flex:" 1 0 auto",
            // zIndex: "1200",
        position: isMobile?"relative": "fixed",
        // top: "0px",
        // outline: "0px",
        // left: "0px",
        // width: "18.625rem",
        // width: "18.625rem",
        // backgroundColor: "rgb(255, 255, 255)",
        height:  isMobile?"100%":"calc(-2rem + 100vh)",
            // margin: "1rem",
            // borderRadius: "0.75rem",
            // border: "none",
        }
        }
        > 
            <MKBox xs={12} p={1} justifyContent="left">
             {params?.listingTypeId? <Breadcrumbs sx={{my:0}} aria-label="breadcrumb">
                <Link
                  underline="hover"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="inherit"
                  href="/Jobs"
                >
                  {/*  */}
                <Button component={Link} to="/Jobs" size="small" startIcon={<Home sx={{ mr: 0.5 }}  />} 
                  // color="primary"
                >  {"Jobs"}
                </Button>
                </Link> 
                <Typography variant="caption"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="text.primary"
                >
                  
{/* <Icon sx={{ fontSize: 30 }}>add_circle</Icon> */}
                  <CategorySharp sx={{ mr: 0.5 }} fontSize="inherit" />
                 {params?.listingTypeName}
                </Typography>
              </Breadcrumbs>:null}
                <MKBox
            sx={{
              mt: 0,
              //  borderBottom: 1,
              //  borderColor: "divider"
            }}
          >
            <MKTypography variant="h4" 
            sx={{ 
              mx:2,
              //  borderBottom: 1,
              //  borderColor: "divider"
            }} >
              {params?.listingTypeName?params?.listingTypeName:"Jobs"}
            </MKTypography>
            {searchBoxComp()} 
            <MKButton  component={Link} to="/pages/posts/create-job-post"  color={"primary"} variant="outlined" startIcon={<Add/>} fullWidth>Create New </MKButton>
              </MKBox>
            </MKBox> 
            <MKBox  > 
{myCurrentProfile?<>
          <Accordion  
              defaultExpanded={true}
              // onChange={()=>setFilterAccordionExpanded(!filterAccordionExpanded)}
              >
                <AccordionSummary sx={{my:0}}
                  expandIcon={<ExpandMore/>}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                   
                  <Typography sx={{ width: '70%', flexShrink: 0,fontWeight: "500", fontSize: "0.9rem" }}
                   variant="body2" >Me </Typography>
                           {/* {  (postFilter||listingFilter||vehicleFilter||propertyFilter) &&
                             <Typography component={Button} 
                                onClick={()=>{
                                    applyFilter("listingFilter","conditions",null) 
                                }}
                               variant="caption" >clear</Typography>} */}
                </AccordionSummary>
                <AccordionDetails sx={{p:0 ,my:0}}>
           <MyMenuComp/>
            </AccordionDetails>
            </Accordion>
               {jobSettings?.JobsSeeking?.length?<Accordion  
              defaultExpanded={true}
              // onChange={()=>setFilterAccordionExpanded(!filterAccordionExpanded)}
              >
                <AccordionSummary sx={{m:0}}
                  expandIcon={<ExpandMore/>}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                   
                  <Typography sx={{ width: '70%', flexShrink: 0,fontWeight: "500", fontSize: "0.9rem" }}
                   variant="body2" >Favorites </Typography>
                           {/* {  (postFilter||listingFilter||vehicleFilter||propertyFilter) &&
                             <Typography component={Button} 
                                onClick={()=>{
                                    applyFilter("listingFilter","conditions",null) 
                                }}
                               variant="caption" >clear</Typography>} */}
                </AccordionSummary>
                <AccordionDetails sx={{p:0,m:0}}>
             {/* <MKBox  xs={12} p={1} justifyContent="left"> */}
              <FavoriteListingComp/>  
              {/* </MKBox> */}
                </AccordionDetails>
              </Accordion>:null}
</>:null}
              <Accordion
              //  sx={{display:"none"}}
               expanded={categoryAccordionExpanded}
               onChange={()=>setCategoryAccordionExpanded(!categoryAccordionExpanded)}
               >
                <AccordionSummary
                  expandIcon={<ExpandMore/>}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  
                  <Typography sx={{ width: '70%', flexShrink: 0,fontWeight: "500", fontSize: "0.9rem" }}
                   variant="body2" >Categories </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{p:0,m:0}}>
                    {(jobCategories ? (jobCategories?.length ? true : false) : false) && (
                    <MKBox xs={12} p={1} justifyContent="left">
                      <ListingTypesComp/> 
                    </MKBox>
                    )} 
                </AccordionDetails>
              </Accordion>
             { ((params?.listingTypeId)||searchParams?.keyword)?
              <Accordion  sx={{display:"none"}}
              expanded={filterAccordionExpanded}
              onChange={()=>setFilterAccordionExpanded(!filterAccordionExpanded)}
              >
                <AccordionSummary sx={{m:0}}
                  expandIcon={<ExpandMore/>}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                   
                  <Typography  sx={{ width: '70%', flexShrink: 0 }}>Filter </Typography>
                           {/* {  (postFilter||listingFilter||vehicleFilter||propertyFilter) &&
                             <Typography component={Button} 
                                onClick={()=>{
                                    applyFilter("listingFilter","conditions",null) 
                                }}
                               variant="caption" >clear</Typography>} */}
                </AccordionSummary>
                <AccordionDetails sx={{p:0, m:0}}>
             {/* <MKBox  xs={12} p={0} justifyContent="left"> */}
              <FilterListingComp/>  
              {/* </MKBox> */}
                </AccordionDetails>
              </Accordion>:null }


              </MKBox>
            </Card>   
      
      
      
      
      </> 

      {isMobile ? (
        <SwipeableDrawer
          // sx={{ zIndex: 1111111 }}
          // container={container}
          anchor="bottom"
          open={drawer2Opened == "switchAgency"}
          onClose={() => setDrawer2Opened(false)}
          swipeAreaWidth={150}
          disableSwipeToOpen={true}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <SwitchMyProfilesBox onClose={() => setDrawer2Opened(false)} />
        </SwipeableDrawer>
      ) : (
        <Dialog
          open={drawer2Opened == "switchAgency"}
          // TransitionComponent={Transition}
          scroll={"paper"}
          sx={{ "& .MuiDialog-paper": { width: "100%" } }}
          maxWidth="xs"
          onClose={() => setDrawer2Opened(false)}
          fullScreen={isMobile ? true : false}
        >
          <DialogTitle>Profiles </DialogTitle>
          <DialogContent
            dividers={true}
            sx={{ padding: "0rem", pb: "35px", minHeight: isMobile ? "300px" : "460px" }}
          >
            <SwitchMyProfilesBox onClose={() => setDrawer2Opened(false)} />
          </DialogContent>
          {/* </>
          )} */}
        </Dialog>
      )}

      <Dialog
        scroll={"paper"}
        sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        maxWidth="xs"
        open={drawerOpened == "freindship"}
        onClose={() => setDrawerOpened(false)}
        fullScreen={isMobile ? true : false}
      >
        <Friendship
          profile={myCurrentProfile}
          friendshipTab={friendshipTab}
          onClose={() => {
            setDrawerOpened(false);
          }}
        />
      </Dialog>
     { isMobile?
    
    <SwipeableDrawer
    // sx={{ zIndex: 1111111 }}
    // container={container}
    anchor="bottom"
    open={drawerOpened == "createListing"}
    onClose={() => setDrawerOpened(false)}
    swipeAreaWidth={150}
    disableSwipeToOpen={true}
    ModalProps={{
      keepMounted: true,
    }}
  >
       <CreateListingComp/> </SwipeableDrawer> : 
      <Dialog
        scroll={"paper"}
        sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        maxWidth="xs"
        open={drawerOpened == "createListing"}
        onClose={() => setDrawerOpened(false)}
        fullScreen={isMobile ? true : false}
      >
       <CreateListingComp/>
      </Dialog>
    
    }
      {forceRerender}

      
      <SwipeableDrawer
          // sx={{ zIndex: 1111111 }}
          // container={container}
          anchor="bottom"
          open={drawerOpened == "filterListing"}
          onClose={() => setDrawerOpened(false)}
          swipeAreaWidth={150}
          disableSwipeToOpen={true}
          ModalProps={{
            keepMounted: true,
          }}
        >  <Box sx={{ flexGrow: 1 ,mb:3}}>
        <AppBar position="static">
          <Toolbar> 
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Filter
            </Typography>
            <MKButton onClick={()=>{
              setDrawerOpened(false)
              navigateToFilters()}} variant="outlined" size="small" color="primary">Apply</MKButton>
          </Toolbar>
        </AppBar>
        <FilterListingComp/>
      </Box>
        </SwipeableDrawer>

      
      <SwipeableDrawer
          // sx={{ zIndex: 1111111 }}
          // container={container}
          anchor="bottom"
          open={drawerOpened == "mymenu"}
          onClose={() => setDrawerOpened(false)}
          swipeAreaWidth={150}
          disableSwipeToOpen={true}
          ModalProps={{
            keepMounted: true,
          }}
        >  <Box sx={{ flexGrow: 1 ,mb:3}}>
        <AppBar position="static">
          <Toolbar> 
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Job Menu
            </Typography>
            {/* <MKButton onClick={()=>{
              setDrawerOpened(false)
              navigateToFilters()}} variant="outlined" size="small" color="primary">Apply</MKButton> */}
          </Toolbar>
        </AppBar>
        <MyMenuComp/>
      </Box>
        </SwipeableDrawer>

      <SwipeableDrawer
          // sx={{ zIndex: 1111111 }}
          // container={container}
          anchor="bottom"
          open={drawerOpened == "selectListingType"}
          onClose={() => setDrawerOpened(false)}
          swipeAreaWidth={150}
          disableSwipeToOpen={true}
          ModalProps={{
            keepMounted: true,
          }}
        > 
        <FavoriteListingComp/>
        <ListingTypesComp/>
        </SwipeableDrawer>
    </>
  );
}

JobsSideBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
