import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import {
  Dialog,
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Skeleton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { UseAuth } from "Api-Services/useAuth";
import { ContactMail, Label, QuestionAnswer, Rule } from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices";

import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import { DataManagementService } from "Api-Services/dataManagementService";
import { green, grey } from "@mui/material/colors";
 
import { isMobile } from "react-device-detect";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
 
export default function GeneralSideBarContent() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpened, setDrawerOpened] = React.useState(false);
  const [drawer2Opened, setDrawer2Opened] = React.useState(false);
  const [forceRerender, setForceRerender] = React.useState(false);
  const [jobSearchHistories, setJobSearchHistories] = React.useState(["mesfin", "tutor"]);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const [currentData] = useCurrentUser();
  const { currentUser,myCurrentProfile  } =  currentData;
 
  const sideBarContent = [
    {
      title: "Posts",
      items: [
        {
          title: "Market Place",
          link: "/Market-Place",
          icon: "local_grocery_store",
        },
        {
          title: "Jobs",
          link: "/jobs",
          icon: "work",
        },
        {
          title: "Properties",
          link: "/Market-Place",
          icon: "apartment",
        },
        {
          title: "Events",
          link: "/Events",
          icon: "event",
        },
        {
          title: "Explore",
          link: "/Explore",
          icon: "explore",
        },
      ],
    },
    // {
    //   title: "Apps",
    //   items: [
    //     {
    //       title: "Dating",
    //       link: "/Dating",
    //       icon: "volunteer_activism",
    //     },
    //     {
    //       title: "Vote",
    //       link: "/Vote",
    //       icon: "ballot",
    //     },
    //   ],
    // },
    {
      title: "Accounts",
      items: [
        {
          title: "Freelancers",
          link: "/Freelancers",
          icon: "badge",
        },
        {
          title: "Employers",
          // title: "Employment Agencies",
          link: "/jobs",
          icon: "handshake",
        },
        {
          title: "Shops",
          link: "/Market-Place",
          icon: "local_grocery_store",
        },
        // {
        //   title: "Popular Busineses",
        //   link: "/Popular-Busineses",
        //   icon: "local_convenience_store",
        // },
        {
          title: "Find Friends",
          link: "/contacts/"+myCurrentProfile?.userName+"/suggestions",
          icon: "person_add",
        },
      ],
    },
    {
      title: "Baally",
      items: [
        {
          title: "Contact Us",
          link: "/pages/support/contact-us",
          icon: "contact_mail",
        },
        // {
        //   title:"About Us",
        //   link:"",
        //   icon:""
        // },
        {
          title: "FAQ",
          link: "/pages/AskQuestion",
          icon: "question_answer",
        },
        {
          title: "Privacy Policy",
          link: "/pages/privacy-policy",
          icon: "rule",
        },
      ],
    },
  ];
 
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
 

  React.useEffect(() => {
    setDrawerOpened(false);
    setDrawer2Opened(false);
  }, [location.pathname]);

  React.useEffect(() => {
    let historiesD = [];

    let jobSearchHistoriesLocalD =
      DataManagementService.getLocalstorageDataSecured("jobSearchHistories");

    if (jobSearchHistoriesLocalD) {
      if (jobSearchHistoriesLocalD.length) {
        historiesD = jobSearchHistoriesLocalD;
      }
    }

    setJobSearchHistories(historiesD);
  }, [currentUser]);
 
  const goToPage = (page) => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate(page);
  };
 
  const navigate = useNavigate();

  const drawer = ( 
      <> 
          {(sideBarContent ? (sideBarContent.length ? true : false) : false) && (
            <>
              {sideBarContent.map((sideBarTopic) => (
                <List sx={{width:"100%"}}
                  subheader={
                    <ListSubheader sx={{ backgroundColor: "inherit" }}>
                      {sideBarTopic.title}
                    </ListSubheader>
                  }
                >
                  {(sideBarTopic.items ? (sideBarTopic.items.length ? true : false) : false) &&
                    sideBarTopic.items.map((sideBarItem) => (
                      <ListItem
                        disablePadding
                        onClick={() => {
                          goToPage(sideBarItem.link);
                        }}
                      >
                        <ListItemButton>
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            {(
                              sideBarItem.icon ? (sideBarItem.icon != "" ? true : false) : false
                            ) ? (
                              <Icon>{sideBarItem.icon}</Icon>
                            ) : (
                              <Label />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography sx={{ minWidth: "30px", fontSize: "0.9rem" }} variant="h6">{sideBarItem.title}</Typography>
                            }
                          />
                          {/* /pages/support/contact-us */}
                        </ListItemButton>
                      </ListItem>
                    ))}

                  <Divider sx={{ my: 1 }} variant="middle" />
                </List>
              ))}
            </>
          )} 
      </> 
  );

  return (
    <Box sx={{ flexGrow: 1, mb:isMobile?0:7 }}>
      {drawer}
      {forceRerender}
    </Box>
  );
}

GeneralSideBarContent.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
