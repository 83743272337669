import { HttpService } from "./httpService";

var CryptoJS = require("crypto-js");
var encryptionSecretLocal = "employment1234";
export class ProfileApiServices {
  static follow = async (profileId) => {
    let res = { success: false, record: null, message: "" };
    try {
      let followRes = await this.getDocumentsAll("/api/follower/follow/" + profileId, {});
      if (followRes.success) {
        res.success = followRes.success;
      }
    } catch (err) {
      console.log("uploadFiles err", err);
    }
    return res;
  };

  static unfollow = async (profileId) => {
    let res = { success: false, record: null, message: "" };
    try {
      let followRes = await this.getDocumentsAll("/api/follower/unfollow/" + profileId, {});
      if (followRes.success) {
        res.success = followRes.success;
      }
    } catch (err) {
      console.log("uploadFiles err", err);
    }
    return res;
  };

  static getProfilePostsByPostField = async (ProfileId,postQueryData) => {
    let res =[];
    try {
      let data=postQueryData
      data.ProfileId=ProfileId
      let posts = await this.postDocuments("/api/post/getprofilepostsbypostfield", data);
      if (posts.success) {
        if (posts.record) {
          if (posts.record.length) {
        res= posts.record
      }
    }
  }
    } catch (err) {
      console.log("getProfilePostsByPostField err", err);
    }
    return res;
  };
 
  static getProfilesByField = async (data) => {
    let res =[];
    try { 
      let path = "/api/profile/getbyfield"; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
      let profiles = await this.postDocuments(path,data );
      if (profiles.success) {
        if (profiles.record) {
          if (profiles.record.length) {
        res= profiles.record
      }
    }
  }
    } catch (err) {
      console.log("serach profiles err", err);
    }
    return res;
  };

  static searchProfileByKeyword = async (searchproKeyword) => {
    let res =[];
    try { 
      let path = "/api/profile/searchbykeyword/" + searchproKeyword; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
      let profiles = await this.getDocumentsAll(path, {});
      if (profiles.success) {
        if (profiles.record) {
          if (profiles.record.length) {
        res= profiles.record
      }
    }
  }
    } catch (err) {
      console.log("serach profiles err", err);
    }
    return res;
  };

  static uploadAttachments = async (attachments, properties) => {
    // console.log("uploadFiles",filesD,filePathD);
    let res = [];
    let dropzoneTempImages1 = filesD || dropzoneFiles;
    try {
      let filePath = "./public/files/users/user_25/new-folder3";
      const formData = new FormData();
      _.filter(dropzoneTempImages1, (file) => {
        // formData.append("folderPath", uploadingFolderPath);
        formData.append("folderPath", filePath);
        formData.append("file", file);
        return file;
      });

      let getHeaderAndURLRes = await HttpService.getHeaderAndURL();
      let baseUrl = getHeaderAndURLRes.baseUrl;
      let additionalData = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        // onUploadProgress: progressEvent => {
        //   if(progressEvent){
        //     // console.log("progressEvent", Math.round((progressEvent.loaded * 100) / progressEvent.total))
        //       setuploadPercentage(parseInt(
        //       Math.round((progressEvent.loaded * 100) / progressEvent.total)
        //         ))
        //         }
        // }
      };

      let uploadFileRes = await postDocuments(
        "uploadFile",
        "/api/file/photos",
        formData,
        additionalData
      );
      if (uploadFileRes) {
        if (uploadFileRes.record) {
          if (uploadFileRes.record.length) {
            uploadFileRes.record.forEach((imgFile) => {
              // var destination=imgFile.destination.replace('./public',"");
              var destination = imgFile.destination.replace("./public", "/public");
              var filename = "/" + imgFile.filename;
              var pathN = baseUrl + destination + filename;
              var imgpath = pathN;
              res.push(imgpath);
            });
          }
        }
      }
      // console.log("uploadFileRes  ",uploadFileRes);
      // console.log("uploadFileRes res",res);
      // await axios.post(baseUrl+"/api/file/photos", formData,{additionalData})
      // .then(function (response) {
      //   let imgs=[]
      //   // console.log("file  dropzoneval, uploaded",dropzoneTempImages1,response.data);
      //   response.data.forEach(imgFile => {
      //     var destination=imgFile.destination.replace('./public',"");
      //     var filename="/"+imgFile.filename
      //     var pathN=baseUrl+destination+filename;
      //     var imgpath=pathN
      //     imgs.push(imgpath)
      //   });
      //   // props.uplodedImages(imgs,props.uploadingImageType);
      //     // setuploadedFiles([...uploadedFiles, ...imageFiles])
      // })
      // .catch(function (error) {
      // });
    } catch (err) {
      // console.log("uploadFiles err",err);
    }
    return res;
  };

  static getDocumentsAll = async (relativePath, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    }
    return responseD;
  };

  static postDocuments = async (relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    }
    return responseD;
  };
}
