// @mui material components
import React, { useEffect } from "react";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { 
  Dialog,
  DialogActions,
  DialogContent, 
} from "@mui/material";
import { isMobile } from "react-device-detect";
import EditProfile from "./Sections/SwitchProfile";
import EditProfileHeader from "./Sections/SwitchProfileHeader";
import { useNavigate } from "react-router-dom";

function SwitchProfileDialogBox(props) {
  const [currentData] = useCurrentUser();
  const [forceRerender, setForceRerender] = React.useState(true);
  const [currentGlobalDialogBoxData, setCurrentGlobalDialogBoxData] = React.useState(null);
  const [currentProfile, setCurrentProfile] = React.useState(null);
  const [friendshipTab, setFriendshipTab] = React.useState("followings");
  const [dialogOpened, setDialogOpened] = React.useState(false); 

  const navigate= useNavigate();
  

  window.addEventListener("popstate", (event) => {setDialogOpened(false)})
  // window.addEventListener("pushstate", (event) => {console.log("pushstate")})
  
  useEffect(async () => { 
    if ( currentGlobalDialogBoxData?.dialogId == "SwitchProfileDialogBox") {
      let path
      path="/profile/switch-profile" ; 
      window.history.pushState(null, "Profile switch-profile", path);  
      setDialogOpened(true)
      } 
  }, [ currentGlobalDialogBoxData]);

  useEffect(async () => { 
    if ( location.pathname != "/profile/switch-profile") { 
       setDialogOpened(false)
      } 
  }, [ location.pathname]);

  useEffect(async () => {
    let friendshipTabR = "following";
    let profileR = null;
    if (props.currentGlobalDialogBoxData) {
      // if (props.currentGlobalDialogBoxData.data) {
      setCurrentGlobalDialogBoxData(props.currentGlobalDialogBoxData);
      let dataDD = props.currentGlobalDialogBoxData.data;
      if (dataDD.friendshipTab) {
        friendshipTabR = dataDD.friendshipTab;
      }
      if (dataDD.profile) {
        profileR = dataDD.profile;
      }
      setFriendshipTab(friendshipTabR);
      setCurrentProfile(profileR);
      setForceRerender(!forceRerender);
      // }
    }
    // console.log("FriendshipDialogBox profileR", profileR);

    setForceRerender(!forceRerender);
  }, [props.currentGlobalDialogBoxData]);

  return (
    <>
      {(currentGlobalDialogBoxData
        ? currentGlobalDialogBoxData.dialogId
          ? true
          : false
        : false) && (
        <Dialog
           open={dialogOpened}
          // TransitionComponent={Transition}
          scroll={"paper"}
          sx={{ "& .MuiDialog-paper": { width: "100%" } }}
          maxWidth="xs" 
          onClose={() => {
            // setCurrentGlobalDialogBoxData(null)
            setDialogOpened(false) 
            navigate(-1)
          }}
          fullScreen={isMobile ? true : false}
        >
          {/* {currentGlobalDialogBoxData.data && (
            <> */}
          <EditProfileHeader
            onClose={() => {
              setDialogOpened(false) 
              navigate(-1)}}
            changeFriendshipTab={(taab) => {
              setFriendshipTab(taab);
            }}
            currentGlobalDialogBoxData={currentGlobalDialogBoxData}
            friendshipTab={friendshipTab}
          />
           
            <EditProfile currentGlobalDialogBoxData={currentGlobalDialogBoxData} />
          
          {/* </>
          )} */}
        </Dialog>
      )}
      {forceRerender}
    </>
  );
}

export default SwitchProfileDialogBox;
