 
import React, { useEffect } from "react";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { 
  Dialog,
  Drawer, 
} from "@mui/material"; 
import { isMobile } from "react-device-detect"; 
import { useNavigate, useParams } from "react-router-dom";
import Friendship from "pages/UserProfile/Components/Friendship/Friendship";
import Contacts from "../Contacts/Contacts";
  
function FriendshipDialogBox(props) {
  // profile={myCurrentProfile} friendshipTab={friendshipTab} 
  const navigate = useNavigate();
  const params = useParams();
  const [forceRerender, setForceRerender] = React.useState(true);  

  const [currentData,updateContext] = useCurrentUser();
  const {myCurrentProfile, currentGlobalDialogBoxData} =  currentData;
  // const {currentProfile, setCurrentProfile} =  React.useState(null); 
  const [dialogOpened, setDialogOpened] = React.useState(false); 
  window.addEventListener("popstate", (event) => {{
    setDialogOpened(null) 
  }})
  useEffect(async () => { 
    if ( currentGlobalDialogBoxData?.dialogId == "FriendshipDialogBox") {
      let path
      let tab=currentGlobalDialogBoxData?.data?.friendshipTab||""
      path="/contacts/"+currentGlobalDialogBoxData?.data?.profile?.userName+"/"+tab; 
      window.history.pushState(null, "Profile Details", path);  
       setDialogOpened(true) 
      } 
  }, [ currentGlobalDialogBoxData]);

  React.useEffect(() => {  
    if ( !(location?.pathname?.indexOf("/contacts")>-1)) { 
      // console.log("location.pathname ",params,location.pathname)
      setDialogOpened(false) 
    }  
}, [location.pathname]);

//   React.useEffect(() => {  
//     if ( !(params?.userName)) { 
//       setDialogOpened(false) 
//     }  
// }, [location.pathname]);

  return (
    <>
      {((currentGlobalDialogBoxData
        ? currentGlobalDialogBoxData.dialogId
          ? true
          : false
        : false) && myCurrentProfile
        ? true
        : false) && (<>
       {isMobile?<Dialog
        open={dialogOpened}
          // TransitionComponent={Transition}
          scroll={"paper"}
          sx={{ "& .MuiDialog-paper": { width: "100%" } }}
          maxWidth="xs"
          onClose={() => {
           setDialogOpened(false) 
           navigate(-1)
         }}
          fullScreen={isMobile ? true : false}
        > 
            <Contacts 
              onClose={() => {
              setDialogOpened(false) 
              navigate(-1)
            }}
              currentGlobalDialogBoxData={currentGlobalDialogBoxData}
              profile={currentGlobalDialogBoxData?.data?.profile}
              friendshipTab={currentGlobalDialogBoxData?.data?.friendshipTab}
            />
      
        </Dialog>:
            <Drawer
              open={dialogOpened}
              // TransitionComponent={Transition}
              scroll={"paper"}
              anchor={"right"}
              sx={{ "& .MuiDialog-paper": { width:"400px" },
                   "& .css-1160xiw-MuiPaper-root-MuiDrawer-paper":{ width:"400px" }, }}
              // maxWidth="xs" 
              onClose={() => {
                setDialogOpened(false) 
                navigate(-1)
              }}
              fullScreen={isMobile ? true : false}
            >
            <Contacts
              onClose={() => {
              setDialogOpened(false) 
              navigate(-1)
            }}
              currentGlobalDialogBoxData={currentGlobalDialogBoxData}
              profile={currentGlobalDialogBoxData?.data?.profile}
              friendshipTab={currentGlobalDialogBoxData?.data?.friendshipTab}
            />
              
              </Drawer>}</>
      )}
      {forceRerender}
    </>
  );
}

export default FriendshipDialogBox;
