/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import queryString from "query-string";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isMobile } from "react-device-detect";
import { ShareSocial } from "react-share-social";
import ReactGA from "react-ga4";

import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
// Images
import profilePicture from "assets/images/bruce-mars.jpg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import Card from "@mui/material/Card";
import React, { useEffect } from "react";
import { ArrowBack, ContactMail, Send, Work } from "@mui/icons-material";
import {
  AppBar,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { HttpService } from "Api-Services/httpService";
import MKEditor from "components/MKEditor";
import { DataManagementService } from "Api-Services/dataManagementService";
import { CommonServices } from "Api-Services/commonServices";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";

const useStyles1  = makeStyles((theme) => ({
  root: {
    // "& .css-9igssw-MuiDialogContent-root": {
    //   padding: "5px",
    // },
    // "& .ql-toolbar.ql-snow ": {
    //   padding: "0px",
    // },
  },  
  MKEditor: {
    "& .ql-editor ": {
      minHeight: "120px",
    },
  },
}));

function JobApplication(props) {
  const styles1=useStyles1()
  const navigateComp = useNavigate();
  const navigate = useNavigate();
  const params = useParams();
  
  const [currentData, updateContext] = useCurrentUser();
  const { 
    employmentSalaryDealTypes,
    myJobApplications,
    myCurrentProfile,
    agencyData,
    isAgentWebsite,
    isUserLoggedIn,
  } = currentData; 
  // const [employmentJobVacancy, setEmploymentJobVacancy] = React.useState(null);
  // const [employmentAgencyAndJobSeeker, setEmploymentAgencyAndJobSeeker] = React.useState(null);
  const [forceRerender, setForceRerender] = React.useState(false);

  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [sendRequestData, setSendRequestData] = React.useState({
    ProfileId: "",
    EmploymentJobVacancyId: "",
    description: "",
  });

  const {data:employmentJobVacancy} = useQuery({
    queryKey: ["/api/job/employmentjobvacancy/"+params?.jobId],
    queryFn: async () => {
      try {
       return await HttpService.getApiData("/api/job/employmentjobvacancy/"+params?.jobId);
      } catch (error) {
       console.log("employmentSalaryDealTypes error", error);
      }
    },
  });

  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
    // search()
  }, []);
 

  const incrementField = async (id, fieldName, updateBy) => {
    try {
      if (id && fieldName) {
        let relativePath = "/api/employmentjobvacancy/incrementfieldbyid";
        let data = {
          field: fieldName,
          id: id,
          updateBy: updateBy || 1,
        };
        // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
        let employmentJobVacancyRes = await postDocuments("contactus", relativePath, data);
        // console.log("vacancy incrementField", employmentJobVacancyRes);
      }
      // setLoading(false);
    } catch (error) {}
  };
 
  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    }

    // console.log(documentName, " responseD: ", responseD);
    // setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const truncateString = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          let btn = (
            <Button
              variant="text"
              onClick={() => {
                setMoreText({ content: str, title: lableName });
                setOpenedDialog("moreText");
                setForceRerender(!forceRerender);
              }}
            >
              {"View more"}
            </Button>
          );
          let txt = str.slice(0, num) + "...";
          res = (
            <>
              {parse(txt)} {btn}
            </>
          );
        } else {
          res = <>{parse(res)}</>;
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };

  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = returnField ? "" : {};

    try {
      if (searchValue) {
        if (dataArray && searchField && searchValue) {
          if (dataArray.length > 0) {
            let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
            if (filteredLevels.length) {
              res = filteredLevels;
              if (returnRows == "single") {
                let row = filteredLevels[0];
                res = row;
                if (returnField) {
                  let rowField = row[returnField];
                  res = rowField;
                }
              }
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    // console.log("filtered city  ", res);
    return res;
  };

  const isJobAlreadyApplied = (id) => {
    let res = false;
    if (myJobApplications && id) {
      if (myJobApplications.length) {
        for (let i = 0; i < myJobApplications.length; i++) {
          if (myJobApplications[i].EmploymentJobVacancyId == id) {
            return true;
          }
        }
      }
    }
    return res;
  };

  const handleSendRequestData = (field, value) => {
    let data = sendRequestData;
    if (field && value) {
      data[field] = value;
    }
    setSendRequestData(data);
    setForceRerender(!forceRerender);
  };

  const applyComponent = () => {
    return ( 
            <>
              {isUserLoggedIn ? (
                <>
                  {myCurrentProfile ? (
                    <>
                      <>
                        {isJobAlreadyApplied(employmentJobVacancy?.id) ? (
                          <>
                            <MKButton
                              disabled={employmentJobVacancy?.isJobClosed}
                              // onClick={() => {
                              //   navigateComp("/profile/my-applications");
                              // }}
                              // variant="outlined"
                              // color="success"
                              size="small"
                              sx={{ m: 1 }}
                            >
                              Already Applied
                            </MKButton>
                            <MKButton
                              endIcon={<ContactMail />}
                              disabled={employmentJobVacancy?.isJobClosed}
                              onClick={() => {
                                navigateComp("/"+employmentJobVacancy?.Post?.Profile?.userName);
                              }}
                              variant="outlined"
                              color="primary"
                              size="small"
                              sx={{ m: 1 }}
                            >
                              Contact Employer
                            </MKButton>
                          </>
                        ) : (<>
                          { employmentJobVacancy?.isJobClosed?
                            <MKTypography display="block" variant="button" color="warning" fontWeight="regular">
                              <strong> {"Job Closed"} </strong>
                            </MKTypography>
                           :
                           null}
                        </>)}
                      </>
                    </>
                  ) : (
                    <MKButton
                      //  disabled={employmentJobVacancy.isJobClosed}
                      variant="gradient"
                      color="success"
                      size="large"
                      sx={{ my: 2 }}
                      onClick={() => {
                        let data = {
                          dialogId: "SwitchProfileDialogBox",
                          data: {},
                        };
                        updateContext("currentGlobalDialogBoxData", data);
                        //   setFriendshipTab("suggestions")
                        //  setDrawerOpened("freindship")
                      }}
                      // sx={{ color: ({ palette: { dark } }) => dark.main }}
                    >
                      Enter to profile to Apply
                    </MKButton>
                  )}
                </>
              ) : (
                <>
                  <MKButton
                    //  disabled={employmentJobVacancy.isJobClosed}
                    variant="gradient"
                    color="success"
                    size="large"
                    sx={{ my: 2 }}
                    component={Link}
                    // variant="outlined"
                    // color="default"
                    to={"/login?redirectTo=/pages/jobs"}
                    // sx={{ color: ({ palette: { dark } }) => dark.main }}
                  >
                    Login and Apply
                  </MKButton>
                </>
              )}

              {agencyData && isAgentWebsite && (
                <MKTypography display="block" variant="button" color="text" fontWeight="regular">
                  Brought to you by <strong> {agencyData?.fullName}</strong>
                </MKTypography>
              )}

            </> 
    );
  };

  const sendRequest = async () => {
    let data = sendRequestData;

    try {
      let relativePath = "/api/employmentapplicationrequest/new";
      if (employmentJobVacancy) {
        if (employmentJobVacancy.id) {
          data.EmploymentJobVacancyId = employmentJobVacancy.id;
        }
      }
      if (myCurrentProfile) {
        if (myCurrentProfile.id) {
          data.ProfileId = myCurrentProfile.id;
        }
      }
      let label = "Job Title: " + employmentJobVacancy.title + " | ";

      if (myCurrentProfile) {
        if (myCurrentProfile.id) {
          label =
            label +
            " Appilicant : " +
            CommonServices.getProfileName(myCurrentProfile) +
            " (ID : " +
            myCurrentProfile.id +
            ")";
        }
      }

      // console.log("data", data);

      if (relativePath && data.ProfileId) {
        let toastId = toast.loading("Sending Request...", {
          position: toast.POSITION.BOTTOM_LEFT,
        });

        let changeUserProfileRes = await postDocuments("changeUserInfo", relativePath, data);
        toast.update(toastId, {
          render: changeUserProfileRes.message,
          type: changeUserProfileRes.success ? "success" : "warn",
          isLoading: false,
          autoClose: 5000,
        });

        if (changeUserProfileRes.success) {
          setOpenedDialog("");
          updateContext("myJobApplications");
          ReactGA.event({
            category: "job Application",
            action: "job Application sent",
            label: label, // optional
            value: employmentJobVacancy.id, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
          incrementField(data.EmploymentJobVacancyId, "numberOfApplications");
          let path = "/profile/my-applications"; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
          navigateComp(path);
        } else {
          ReactGA.event({
            category: category,
            action: "job Application failed",
            label: label, // optional
            value: employmentJobVacancy.id, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        }
      }
    } catch (error) {
      // console.log("error", error);
    }
  };

  const editInputComponent = (
    parentFieldName,
    fieldName,
    fieldLabel,
    valueD,
    fieldType,
    options,
    optionsSelectedField,
    optionsSelectedLable
  ) => {
    // const editInputComponent = (fieldName, fieldLabel, valueD, fieldType, options, selectedField) => {
    // console.log("fieldName", valueD);
    let res = <></>;
    let inputComp = <></>;
    let value = valueD;
    try {
      let accordionTitle = "-";
      if (fieldType == "fileUrl") {
        accordionTitle = (
          <Button
            href={value}
            target="_blank"
            onClick={() => {
              // setDocumentUrl(value);
              // setOpenedDialog("documentviewer")
            }}
            color="primary"
            round
            size="sm"
          >
            open file
          </Button>
        );
      } else if (fieldType == "chip" || fieldType == "chipText") {
        let chipsData = []; //value
        if (value) {
          if (typeof value == "string") {
            chipsData = value.split(",");
          } else if (value.length) {
            chipsData = value;
          }
        }
        accordionTitle = (
          <>
            <>
              {chipsData.map((chipValue) => (
                <Chip
                  label={optionsSelectedLable ? chipValue[optionsSelectedLable] : chipValue}
                  variant="outlined"
                />
              ))}
            </>
          </>
        );
      } else {
        if (value) {
          accordionTitle = optionsSelectedLable
            ? value[optionsSelectedLable]
              ? value[optionsSelectedLable]
              : ""
            : value;
        }
      }

      if (typeof accordionTitle == "string") {
        accordionTitle = truncateString(accordionTitle, 50);
      }
      res = (
        <>
          {/* <Accordion expanded={expanded === fieldName} onChange={handleChange(fieldName)}> */}
          <Accordion expanded={false}>
            <AccordionSummary
              // expandIcon={<Edit />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              {/* <MKTypography sx={{ width: "43%", flexShrink: 0 }} variant="body2" color="text" fontWeight="regular" pl={1}>
              {fieldLabel}  </MKTypography>
              <MKTypography sx={{ color: "text.secondary" }} variant="body2" color="text" fontWeight="regular" pl={1}>
              <strong>{accordionTitle}</strong> </MKTypography> */}

              <MKTypography
                sx={{ width: "43%", flexShrink: 0 }}
                variant="body2"
                color="text"
                fontWeight="regular"
                pl={1}
              >
                {fieldLabel} {isMobile ? " : " : ""}
              </MKTypography>
              <MKTypography
                sx={{ color: "text.secondary" }}
                variant="body2"
                color="text"
                fontWeight="regular"
                pl={1}
              >
                {" "}
                <strong> {accordionTitle}</strong>
              </MKTypography>
            </AccordionSummary>
          </Accordion>
        </>
      );
    } catch (error) {
      // console.log("inp comp", error);
    }
    return res;
  };
 
  return (
    <>
     
      <MKBox component="section" py={2}>
        {employmentJobVacancy && (
          <Container>
            <Grid container item xs={12} justifyContent="center" mx="auto">
              {employmentJobVacancy && (
                <Grid container justifyContent="left" py={isMobile?2:6}>
                  
                  <Grid item xs={(employmentJobVacancy?.Post ? (employmentJobVacancy?.Post?.Profile?true:false) : false)  ?  9 :  12}>
                    <MKTypography variant={isMobile?"h4":"h4"}>
                       Submit a proposal
                      </MKTypography>
                  </Grid>  
                </Grid>
              )}
 

              {employmentJobVacancy && (
                <Grid container item xs={12} py={2} justifyContent="center">
                  <Card sx={{ width: "100%" }}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} lg={12}>
                        <MKBox py={3} px={2}>
                          {/* <MKTypography variant="h3" mb={1}>
                          Personal Informations
                        </MKTypography> */}

                          <MKTypography
                            sx={{ color: "text.secondary" }}
                            variant="h5"
                            color="text"
                            fontWeight="regular"
                            pl={1}
                          >
                            <strong> Job Information</strong>
                          </MKTypography> 
                        </MKBox>
                        <MKBox pb={3} px={2}> 
                        <MKTypography >Title : {employmentJobVacancy?.title}</MKTypography>
                        </MKBox>

                        <MKBox> 

                          {editInputComponent(
                            "jobseeker",
                            "salaryDealTypeId",
                            "Salary Amount",

                            (employmentJobVacancy.salary ? employmentJobVacancy.salary + " " : "") +
                              " " +
                              (employmentJobVacancy.salaryCurrency
                                ? employmentJobVacancy.salaryCurrency + " "
                                : "") +
                              " " +
                              (employmentJobVacancy.salaryDuration
                                ? " (" + employmentJobVacancy.salaryDuration + ") "
                                : ""),
                            "textField"
                          )}

                          {editInputComponent(
                            "jobseeker",
                            "salaryDealTypeId",
                            "Salary Deal",
                            getDataFromArray(
                              employmentSalaryDealTypes,
                              "id",
                              employmentJobVacancy.salaryDealTypeId,
                              "single",
                              "title"
                            ),
                            "textField"
                          )} 

                          <MKTypography mt={5} mb={2} mx={2}>
                            Required
                          </MKTypography>
                          {editInputComponent(
                            "jobseeker",
                            "applicantGender",
                            "Gender",
                            employmentJobVacancy.applicantGender,
                            "textField"
                          )}
                          {editInputComponent(
                            "jobseeker",
                            "requiredNumberOfEmployees",
                            "Employees  ",
                            employmentJobVacancy.requiredNumberOfEmployees + " employee(s)",
                            "textField"
                          )}

                          {(employmentJobVacancy.minimumExperianceInYear != null ||
                            employmentJobVacancy.maximumExperianceInYear != null) && (
                            <>
                              {/* <MKTypography mt={5} mb={2} mx={2}>
                          Experiance
                        </MKTypography> */}
                              {editInputComponent(
                                "jobseeker",
                                "minimumExperianceInYear",
                                "Experiance",
                                (employmentJobVacancy.minimumExperianceInYear != null
                                  ? "starting from " +
                                    employmentJobVacancy.minimumExperianceInYear +
                                    " "
                                  : "") +
                                  (employmentJobVacancy.maximumExperianceInYear >
                                  employmentJobVacancy.minimumExperianceInYear
                                    ? " to " + employmentJobVacancy.maximumExperianceInYear
                                    : "") +
                                  " year(s) ",

                                "textField"
                              )}
                            </>
                          )} 
                        </MKBox>
                      </Grid>
                    </Grid> 
                    <Grid container alignItems="center">
                      <Grid item xs={12} lg={12}> 
                        <MKBox px={2}>
                          {employmentJobVacancy.description && (
                            <MKBox mb={4}>
                              <MKTypography 
                            sx={{ color: "text.secondary" }}
                            variant="h5"
                            color="text"
                             mt={2}>
                                Overview :
                              </MKTypography>
                              <MKBox pl={2}>{parse(employmentJobVacancy.description)}</MKBox>
                            </MKBox>
                          )}

                          <Divider /> 
                          
                          {employmentJobVacancy && (
                            <List dense> 
                              {myCurrentProfile && (
                                <ListItem disablePadding onClick={()=>navigateComp("/"+employmentJobVacancy?.Post?.Profile?.userName)}>
                                  <ListItemButton>
                                    <ListItemIcon>
                                      <Avatar
                                        src={employmentJobVacancy?.Post?.Profile?.profileImageUrl 
                                        }
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={CommonServices.getProfileName(employmentJobVacancy?.Post?.Profile) || "User"}
                                      secondary="Posted by"
                                    />
                                  </ListItemButton>
                                </ListItem>
                              )} 
                            </List>
                          )}
                          <Divider /> 
                        </MKBox>
                      </Grid>
                    </Grid>
                    
                    <Button
                             component={Link}
                             to={"/"+employmentJobVacancy?.Post?.Profile?.userName+"/posts/"+employmentJobVacancy?.Post?.id} 
                            size="small"
                            sx={{ my: 2 }}
                          >
                            View job post details
                          </Button>
                  </Card>
                </Grid>
              )}

              <Grid container item xs={12} pb={2} justifyContent="center">
                {applyComponent()}
              </Grid>

           { (!employmentJobVacancy?.isJobClosed)&&(myCurrentProfile)&&
             <Grid container item xs={12} pb={2} justifyContent="center">
                <Card sx={{ width: "100%" ,p:2}}>
                  <Grid container >
                    <Grid item xs={12} lg={12}>
                      <MKBox mt={2}  > 
  
                       <MKBox mt={2}> 
                       <MKTypography
                            sx={{ color: "text.secondary" }}
                            variant="h5"
                            color="text"
                            fontWeight="regular"
                            pl={1}
                          >
                            <strong>Cover Letter  </strong>
                          </MKTypography> 
                          </MKBox>
                          <MKBox mt={2}> 

                          {employmentJobVacancy && (
                            <List dense> 
                              {myCurrentProfile && (
                                <ListItem disablePadding>
                                  <ListItemButton>
                                    <ListItemIcon>
                                      <Avatar
                                        src={
                                          myCurrentProfile.profileImageUrl ? myCurrentProfile.profileImageUrl : ""
                                        }
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={CommonServices.getProfileName(myCurrentProfile) || "Job seeker"}
                                      secondary="Job seeker"
                                    />
                                  </ListItemButton>
                                </ListItem>
                              )} 
                            </List>
                          )}

                            <Divider />
                          </MKBox>

                          <MKBox mt={2}>
                            <Grid container>
                              <Grid item lg={12}>
                                <MKBox mb={2} mx={0.5} lineHeight={0}  >
                                  <MKTypography
                                    component="label"
                                    variant="button"
                                    fontWeight="regular"
                                    color="text"
                                  >
                                  {" Short description about you? ("}
                                    <MKTypography variant="caption" color="text">
                                      required
                                    </MKTypography>
                                  {")"}
                                  </MKTypography>
                                  
                                <MKBox  className={styles1.MKEditor}>
                                  <MKEditor
                                    value={sendRequestData.description}
                                    //  onChange={setDescription}
                                    onChange={(e) => {
                                      if (e) {
                                        handleSendRequestData("description", e);
                                      }
                                    }}
                                  /></MKBox>
                                </MKBox>
                              </Grid>
                            </Grid>
                          </MKBox>   
                          <MKButton
                            onClick={() => {
                              sendRequest();
                            }}
                            variant="gradient"
                            color="success"
                            size="small"
                            sx={{ my: 2 }}
                          >
                            Send Request
                          </MKButton>
                          <MKButton
                            onClick={() => {
                              navigateComp(-1);
                            }} 
                            size="small"
                            sx={{ my: 2 }}
                          >
                            Cancel
                          </MKButton>
                        


                      </MKBox>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>}
            </Grid>
          </Container>
        )}
      </MKBox>
 
    </>
  );
}

export default JobApplication;
