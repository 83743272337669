// @mui material components
import React, { useEffect } from "react";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import {
  AppBar,
  Box,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Slide,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import EditProfile from "./Sections/EditProfile";
import EditProfileHeader from "./Sections/editProfileHeader";
import { useNavigate } from "react-router-dom";

function EditProfileDialogBox(props) { 
  const [forceRerender, setForceRerender] = React.useState(true); 
  const navigate = useNavigate();

  const [currentProfile, setCurrentProfile] = React.useState(null);
  const [friendshipTab, setFriendshipTab] = React.useState("followings");
  
  const [currentData,updateContext] = useCurrentUser();
  const {myCurrentProfile, currentGlobalDialogBoxData} =  currentData;
  const [dialogOpened, setDialogOpened] = React.useState(false); 

  window.addEventListener("popstate", (event) => {setDialogOpened(false)})
  useEffect(async () => { 
    if ( currentGlobalDialogBoxData?.dialogId == "EditProfileDialogBox") {
      let path
      path="/profile/edit" ; 
      window.history.pushState(null, "  EditProfileDialogBox", path);  
      setDialogOpened(true) 
      } 
  }, [ currentGlobalDialogBoxData]);
 
  useEffect(async () => { 
    if ( location.pathname != "/profile/edit") { 
       setDialogOpened(false)
      } 
  }, [ location.pathname]);
  useEffect(async () => {
    let friendshipTabR = "following";
    let profileR = null;
    if (currentGlobalDialogBoxData) {
      // if (props.currentGlobalDialogBoxData.data) { 
      let dataDD = currentGlobalDialogBoxData.data;
      if (dataDD.friendshipTab) {
        friendshipTabR = dataDD.friendshipTab;
      }
      if (dataDD.profile) {
        profileR = dataDD.profile;
      }
      setFriendshipTab(friendshipTabR);
      setCurrentProfile(profileR);
      setForceRerender(!forceRerender);
      // }
    }
    // console.log("FriendshipDialogBox profileR", profileR);

    setForceRerender(!forceRerender);
  }, [currentGlobalDialogBoxData]);

  return (
    <>
      {(currentGlobalDialogBoxData
        ? currentGlobalDialogBoxData.dialogId
          ? true
          : false
        : false) && (
        <Dialog
          open={dialogOpened}
          // TransitionComponent={Transition}
          scroll={"paper"}
          sx={{ "& .MuiDialog-paper": { width: "100%" } }}
          maxWidth="xs"
          onClose={() => {
           setDialogOpened(false) 
           navigate(-1)
         }}
          fullScreen={isMobile ? true : false}
        >
          {/* {currentGlobalDialogBoxData.data && (
            <> */}
          <EditProfileHeader
          onClose={() => {
           setDialogOpened(false) 
           navigate(-1)
         }}
            changeFriendshipTab={(taab) => {
              setFriendshipTab(taab);
            }}
            currentGlobalDialogBoxData={currentGlobalDialogBoxData}
            friendshipTab={friendshipTab}
          />
          <DialogContent
            dividers={true}
            sx={{ padding: "0rem", pb: "35px", minHeight: isMobile ? "300px" : "460px" }}
          >
            <EditProfile currentGlobalDialogBoxData={currentGlobalDialogBoxData} />
          </DialogContent>
          {/* </>
          )} */}
        </Dialog>
      )}
      {forceRerender}
    </>
  );
}

export default EditProfileDialogBox;
