// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { cities, specificAreas, subCities, citiesOnly } from "Api-master-datas/masterDatas.js";
import queryString from "query-string";

import PropTypes from "prop-types";
import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
// import MKDatePicker from "components/MKDatePicker";
import MKButton from "components/MKButton";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect, useRef } from "react";
import { HttpService } from "Api-Services/httpService";

import ReactGA from "react-ga4";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Avatar,
  Box,
  Button,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, 
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Card, TextField } from "@mui/material";
import { ArrowForwardIosSharp, Favorite, Filter, ListSharp, MoreVert, PeopleOutlineRounded, PlaceRounded, Search, Tune, Update, ViewCompactAlt, ViewCompactSharp, ViewListSharp, ViewModuleSharp } from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices"; 
import { red } from "@mui/material/colors";  
import SingleFeedPost from "../FeedPosts/SingleFeedPost";
import PostCard from "./postCard";
// import AgencyShortInfoBox from "./components/agencyShortInfoBox";
 
 
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, px: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
 

function PostList(props ) {
  const [notSearchedYet, setNotSearchedYet] = React.useState(true);
  const navigateComp = useNavigate();
  const [currentData, updateContext] = useCurrentUser();

  const { 
    jobs,popularEmploymentAgencies,
    agencyData, 
  } = currentData; 
  
  const [agencyJobs, setAgencyJobs] = React.useState(null);
  const [jobsToChoose, setJobsToChoose] = React.useState(jobs);
  const [forceRenderer, setForceRenderer] = React.useState(false);
  const [searchExpanded, setSearchExpanded] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [searchResult, setSearchResult] = React.useState(null);

  const [forceRerender, setForceRerender] = React.useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [searchedKeyword, setSearchedKeyword] = React.useState("");
  const [isFirstTimeSearch, setIsFirstTimeSearch] = React.useState(true);
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [value, setValue] = React.useState(0);
  const [componentViewType, setComponentViewType] = React.useState(1);
  const [agencyList, setAgencyList] = React.useState([]);
 
  useEffect(() => { 
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch) {
        if (locationSearch.search) {
          searchByKeyword(locationSearch.search);
        }
      }
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (location.pathname.indexOf("job") > -1) {
      ReactGA.send({ hitType: "pageview", page: location.pathname, title: "job search" });
    }
  }, []);

  useEffect(() => {
    // console.log("value", props);
    if (value == 0) {
      setAgencyList(popularEmploymentAgencies);
    }
  }, [value, popularEmploymentAgencies 
  ]);

  useEffect(() => {
    // console.log("agencyData",agencyData)
    let filterdJobs = [];
    if (agencyData) {
      if (agencyData.jobsId) {
        let allagencyjobs = agencyData.jobsId.split(",");
        filterdJobs = jobs.filter((jobD) => {
          let res = false;
          allagencyjobs.forEach((element) => {
            element = parseInt(element);
            if (element) {
              if (element == jobD.id) {
                res = true;
              }
            }
          });
          return res;
        });
        setAgencyJobs(filterdJobs);
        setJobsToChoose(filterdJobs);
      }
    }
    setForceRenderer(!forceRenderer);
  }, [agencyData, jobs]);
 
  const searchByKeyword = async (searchD) => {
    let pathSearch;
    let searchKeywordD = "";
    try {
      if (searchD) {
        let pathSearchData = CommonServices.decryptLocalData(searchD);
        // console.log("pathSearchData", pathSearchData);
        searchKeywordD = pathSearchData.keyword;
        setSearchKeyword(searchKeywordD);
        // jobSeekerSearchData = pathSearchData.jobSeekerSearch;
        // jobSearchData = pathSearchData.jobSearch;
      } else {
        searchKeywordD = searchKeyword;
        if (searchKeyword) {
          // jobSearchData = jobSearchFieldsandValue;
          pathSearch = { keyword: searchKeywordD };
          pathSearch = CommonServices.encryptLocalData(pathSearch);
          let path = "/pages/jobs?search=" + pathSearch; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
          navigateComp(path);
        }
      }

      let data = {
        keyword: searchKeywordD,
      };

      if (agencyData.id) {
        data.employmentAgencyId = agencyData.id;
      }

      try {
        setIsFirstTimeSearch(false);
        // let relativePath = "/api/employmentagencyjobseeker/search";
        let relativePath = "/api/employmentjobvacancy/getmyagencyjobvacanciesbykeyword";

        setLoading(true);

        // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
        let contactUsRes = await postDocuments("contactus", relativePath, data, {});
        setSearchedKeyword(searchKeywordD);
        setLoading(false);
        // console.log("contactUsRes",contactUsRes)
        // toast.update(toastId, { render: contactUsRes.message, type: contactUsRes.success?"success":"warn", isLoading: false , autoClose: 5000});
        // contactUsRes.professionTitle = professionTitle || "";
        // contactUsRes.employees = null;
        let searchResultD = [];
        if (contactUsRes.success) {
          if (contactUsRes.record) {
            if (contactUsRes.record.length) {
              searchResultD = contactUsRes.record;
              setSearchExpanded(false);
            }
          }
        }

        if (contactUsRes.success) {
          ReactGA.event({
            category: "job search",
            action: "job search found",
            label: searchKeywordD, // optional
            value: contactUsRes.record ? contactUsRes.record.length : 0, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        } else {
          ReactGA.event({
            category: "job search",
            action: "job search not found",
            label: searchKeywordD, // optional
            value: 0, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        }
        setSearchResult(searchResultD);
        // handleSearchResult(contactUsRes);
        if (contactUsRes.success) {
          // toast.success(contactUsRes.message, {
          //   position: toast.POSITION.TOP_CENTER,
          // });
        } else {
          // toast.error(contactUsRes.message, {
          //   position: toast.POSITION.TOP_LEFT,
          // });
        }
      } catch (error) {
        // setLoading(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
 
  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    }

    // console.log(documentName, " responseD: ", responseD);
    // setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };
 
  

  return (
    <> 
      <Container sx={{ p: 0,mb:1 }}>   
         {/* <Card
              sx={{
                my: 1,
                p:1,
                backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
                  rgba(white.main, 0.8),
                backdropFilter: "saturate(200%) blur(30px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
                overflow: "hidden",
              }}
            >
        <Grid  
        spacing={2}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
           
          <Grid item  >               
                <Stack direction="row" spacing={3}>
                  <Tune/>
                </Stack>
                </Grid>  
              <Grid item >               
                <Stack direction="row" spacing={3}>
                  <ViewCompactAlt  color={(componentViewType==1)?"primary":""} onClick={()=>{setComponentViewType(1)}}/> 
                  <ViewModuleSharp color={(componentViewType==2)?"primary":""} onClick={()=>{setComponentViewType(2)}}/> 
               
                  <ViewListSharp color={(componentViewType==3)?"primary":""} onClick={()=>{setComponentViewType(3)}}/> 
                </Stack>
            </Grid>  
           </Grid>
           </Card>  */}
                { (props.posts?(props.posts.length?true:false):false)&&  
                 <Grid spacing={1} container direction="row"   >
            {props.posts.map((post) => {
                return (
                  <Grid item 
                   xl={props?.grid?.xl||props?.grid?.lg||props?.grid?.md||12}
                   lg={props?.grid?.lg||props?.grid?.md||12}
                   md={props?.grid?.md||12}
                   sm={props?.grid?.sm||12}
                   xs={props?.grid?.xs||12}
                  // sx={props?.grid} 
                   >  
                    <PostCard post={post} componentViewType={componentViewType}/>
                    </Grid>)})}
         </Grid>}
                 

            {forceRenderer}
            <Dialog
              open={openedDialog == "moreText"}
              onClose={() => {
                setOpenedDialog("");
              }}
            >
              {moreText && (
                <>
                  {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
                  {moreText.content && (
                    <DialogContent>
                      <DialogContentText>{parse(moreText.content)}</DialogContentText>
                    </DialogContent>
                  )}
                </>
              )}
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenedDialog("");
                  }}
                >
                  close
                </Button>
              </DialogActions>
            </Dialog> 
      </Container>
      {forceRerender}
    </>
  );
}

export default PostList;
