/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
const queryClient = new QueryClient();
ReactDOM.render(
  <BrowserRouter>
  <QueryClientProvider client={queryClient}>
    <App />
    </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();