import { toast } from "react-toastify";
import { HttpService } from "./httpService";

var CryptoJS = require("crypto-js");
var encryptionSecretLocal = "employment1234";
export class CommonApiServices {
  static addReaction = async (dataD) => {
    let res = { success: false, record: null, message: "" };
    if(dataD){
      
    let data=dataD 
    try {
      let followRes = await this.postDocuments("/api/reaction/addreaction",data);
      if (followRes.success) {
        res.success = followRes.success;
      }
    } catch (err) {
      // console.log("addreaction err", err);
    }
  }
    return res;
  };

  static repost = async (dataD) => {
    let res = { success: false, record: null, message: "" };
    if(dataD){
      
    let data=dataD 
    try {
      
      let toastId = toast.loading("Reposting...",{
        position: toast?.POSITION.BOTTOM_LEFT
      }) 
      let followRes = await this.postDocuments("/api/post/repost",data);
      if (followRes.success) {
        res.success = followRes.success; 
      }  
      toast?.update(toastId, { render: followRes.success?"reposted":"try again", type: followRes.success?"success":"warn", isLoading: false , autoClose: 2000});
      
    } catch (err) {
      // console.log("addreaction err", err);
    }
  }
    return res;
  };

  static addView = async (dataD) => {
    let res = { success: false, record: null, message: "" };
    if(dataD){
      
    let data=dataD 
    try {
      let followRes = await this.postDocuments("/api/view/addview",data);
      if (followRes.success) {
        res.success = followRes.success;
      }
    } catch (err) {
      // console.log("addreaction err", err);
    }
  }
    return res;
  };


  static addImpression = async (dataD) => {
    let res = { success: false, record: null, message: "" };
    if(dataD){
      
    let data=dataD 
    try {
      let followRes = await this.postDocuments("/api/engagement/addimpression",data);
      if (followRes.success) {
        res.success = followRes.success;
      }
    } catch (err) {
      // console.log("addreaction err", err);
    }
  }
    return res;
  };

  static addShare = async (dataD) => {
    let res = { success: false, record: null, message: "" };
    if(dataD){
      
    let data=dataD 
    try {
      let followRes = await this.postDocuments("/api/share/addshare",data);
      if (followRes.success) {
        res.success = followRes.success;
      }
    } catch (err) {
      // console.log("add share err", err);
    }
  }
    return res;
  };

  static addBookmark = async (dataD) => {
    let res = { success: false, record: null, message: "" };
    if(dataD){
      
    let data = dataD 
    try {
      let followRes = await this.postDocuments("/api/bookmark/addbookmark",data);
      if (followRes.success) {
        res.success = followRes.success;
      }
    } catch (err) {
      // console.log("addbookmark err", err);
    }
  }
    return res;
  };
  
  static deletePost = async (postId) => {
    let res = [];
    try {
      
      let toastId = toast.loading("deleting post...",{
        position: toast?.POSITION.BOTTOM_LEFT
      }) 
      let followRes = await this.getDocumentsAll("/api/post/delete/" + postId, {}); 
      toast?.update(toastId, { render: followRes.success?"deleted":"try again", type: followRes.success?"success":"warn", isLoading: false , autoClose: 2000});
      
      if (followRes.success) {
        if (followRes.record) {
        res = followRes.record;
    }
    }
    } catch (err) {
      // console.log("reactions err", err);
    }
    return res;
  };
  
  
  static removeReaction = async (dataD) => {
    let res = { success: false, record: null, message: "" };
    if(dataD){
      
    let data=dataD
    // {
    //   PostId:dataD.PostId,
    //   ReactionTypeId:dataD.ReactionTypeId, 
    // }
    try {
      let followRes = await this.postDocuments("/api/reaction/removereaction",data);
      if (followRes.success) {
        res.success = followRes.success;
      }
    } catch (err) {
      // console.log("addreaction err", err);
    }
  }
    return res;
  };
  
  static removeBookmark = async (dataD) => {
    let res = { success: false, record: null, message: "" };
    if(dataD){
      
    let data=dataD
    // {
    //   PostId:dataD.PostId, 
    // }
    try {
      let followRes = await this.postDocuments("/api/bookmark/removebookmark",data);
      if (followRes.success) {
        res.success = followRes.success;
      }
    } catch (err) {
      // console.log("addreaction err", err);
    }
  }
    return res;
  };
  
  static getPostReactions = async (postId) => {
    let res = [];
    try {
      let followRes = await this.getDocumentsAll("/api/reaction/getpostreactions/" + postId, {});
      if (followRes.success) {
        if (followRes.record) {
        res = followRes.record;
    }
    }
    } catch (err) {
      console.log("reactions err", err);
    }
    return res;
  };
  
  static addComment = async (commentData) => {
    let res = { success: false, record: null, message: "" };
    if(commentData){
      
    let data=commentData
    // {
    //   PostId:commentData.PostId,
    //   CommentTypeId:commentData.CommentTypeId,
    //   CommentAreaId:commentData.CommentAreaId,
    //   message:commentData.message
    // }
    try {
      let followRes = await this.postDocuments("/api/comment/addcomment",data);
      if (followRes.success) {
        res.success = followRes.success;
      }
    } catch (err) {
      console.log("addcomment err", err);
    }
  }
    return res;
  };
  
  static getMyChats = async () => {
    let res = [];
    try {
      let followRes = await this.getDocumentsAll("/api/chat/mychats" , {});
      if (followRes.success) {
        if (followRes.record) {
        res = followRes.record;
    }
    }
    } catch (err) {
      console.log("followings err", err);
    }
    return res;
  };
  
  static getPostComments = async (postId) => {
    let res = [];
    try {
      let followRes = await this.getDocumentsAll("/api/comment/getpostcomments/" + postId, {});
      if (followRes.success) {
        if (followRes.record) {
        res = followRes.record;
    }
    }
    } catch (err) {
      console.log("followings err", err);
    }
    return res;
  };
  
  static follow = async (profileId) => {
    let res = { success: false, record: null, message: "" };
    try {
      let followRes = await this.getDocumentsAll("/api/profilefollowers/follow/" + profileId, {});
      if (followRes.success) {
        res.success = followRes.success;
      }
    } catch (err) {
      console.log("uploadFiles err", err);
    }
    return res;
  };

  static unfollow = async (profileId) => {
    let res = { success: false, record: null, message: "" };
    try {
      let followRes = await this.getDocumentsAll("/api/profilefollowers/unfollow/" + profileId, {});
      if (followRes.success) {
        res.success = followRes.success;
      }
    } catch (err) {
      console.log("uploadFiles err", err);
    }
    return res;
  };

  static followers = async (profileId) => {
    let res = [];
    try {
      let followRes = await this.getDocumentsAll("/api/profilefollowers/followers/" + profileId, {});
      if (followRes.success) {
        if (followRes.record) {
          if (followRes.record.length) {
        res = followRes.record[0]?.followers;
      }
    }
    }
    } catch (err) {
      console.log("followers err", err);
    }
    return res;
  };

  static followings = async (profileId) => {
    let res = [];
    try {
      let followRes = await this.getDocumentsAll("/api/profilefollowers/followings/" + profileId, {});
      if (followRes.success) {
        if (followRes.record) {
          if (followRes.record.length) {
            res = followRes.record[0]?.followings;
      }
    }
    }
    } catch (err) {
      console.log("followings err", err);
    }
    return res;
  };

  static freindships = async (profileId) => {
    let res = [];
    try {
      let followRes = await this.getDocumentsAll("/api/profilefollowers/freindships/" + profileId, {});
      if (followRes.success) {
        if (followRes.record) {
        res = followRes.record;
    }
    }
    } catch (err) {
      console.log("followings err", err);
    }
    return res;
  };

  static getProfilePostsByPostField = async (ProfileId,postQueryData) => {
    let res =[];
    try {
      let data=postQueryData
      data.ProfileId=ProfileId
      let posts = await this.postDocuments("/api/post/getprofilepostsbypostfield", data);
      if (posts.success) {
        if (posts.record) {
          if (posts.record.length) {
        res= posts.record
      }
    }
  }
    } catch (err) {
      console.log("getProfilePostsByPostField err", err);
    }
    return res;
  };
 
  static searchProfileByKeyword = async (searchproKeyword) => {
    let res =[];
    try { 
      let path = "/api/profile/searchbykeyword/" + searchproKeyword; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
      let profiles = await this.getDocumentsAll(path, {});
      if (profiles.success) {
        if (profiles.record) {
          if (profiles.record.length) {
        res= profiles.record
      }
    }
  }
    } catch (err) {
      console.log("serach profiles err", err);
    }
    return res;
  };

  static uploadAttachments = async (attachments, properties) => {
    // console.log("uploadFiles",filesD,filePathD);
    let res = [];
    let dropzoneTempImages1 = filesD || dropzoneFiles;
    try {
      let filePath = "./public/files/users/user_25/new-folder3";
      const formData = new FormData();
      _.filter(dropzoneTempImages1, (file) => {
        // formData.append("folderPath", uploadingFolderPath);
        formData.append("folderPath", filePath);
        formData.append("file", file);
        return file;
      });

      let getHeaderAndURLRes = await HttpService.getHeaderAndURL();
      let baseUrl = getHeaderAndURLRes.baseUrl;
      let additionalData = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        // onUploadProgress: progressEvent => {
        //   if(progressEvent){
        //     // console.log("progressEvent", Math.round((progressEvent.loaded * 100) / progressEvent.total))
        //       setuploadPercentage(parseInt(
        //       Math.round((progressEvent.loaded * 100) / progressEvent.total)
        //         ))
        //         }
        // }
      };

      let uploadFileRes = await postDocuments(
        "uploadFile",
        "/api/file/photos",
        formData,
        additionalData
      );
      if (uploadFileRes) {
        if (uploadFileRes.record) {
          if (uploadFileRes.record.length) {
            uploadFileRes.record.forEach((imgFile) => {
              // var destination=imgFile.destination.replace('./public',"");
              var destination = imgFile.destination.replace("./public", "/public");
              var filename = "/" + imgFile.filename;
              var pathN = baseUrl + destination + filename;
              var imgpath = pathN;
              res.push(imgpath);
            });
          }
        }
      }
      // console.log("uploadFileRes  ",uploadFileRes);
      // console.log("uploadFileRes res",res);
      // await axios.post(baseUrl+"/api/file/photos", formData,{additionalData})
      // .then(function (response) {
      //   let imgs=[]
      //   // console.log("file  dropzoneval, uploaded",dropzoneTempImages1,response.data);
      //   response.data.forEach(imgFile => {
      //     var destination=imgFile.destination.replace('./public',"");
      //     var filename="/"+imgFile.filename
      //     var pathN=baseUrl+destination+filename;
      //     var imgpath=pathN
      //     imgs.push(imgpath)
      //   });
      //   // props.uplodedImages(imgs,props.uploadingImageType);
      //     // setuploadedFiles([...uploadedFiles, ...imageFiles])
      // })
      // .catch(function (error) {
      // });
    } catch (err) {
      // console.log("uploadFiles err",err);
    }
    return res;
  };

  static getDocumentsAll = async (relativePath, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    }
    return responseD;
  };

  static postDocuments = async (relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
    }
    return responseD;
  };
}
