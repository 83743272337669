/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";

// NewProduct page components 
import { useEffect, useState } from "react"; 
import MKEditor from "components/MKEditor";
import { TextField } from "@mui/material";
import { useCurrentUser } from "Api-contexts/currentUserContext";

function Benefits(props) {  
  const [forceRerender, setForceRerender] = useState(false);
  const [sectionValues, setSectionValues] = useState(props.sectionValues); 
   const [currentData, updateContext] = useCurrentUser();
  const { employmentSalaryDealTypes   } =
    currentData; 
  
  useEffect(() => {
    console.log("props.sectionValues", props.sectionValues)
    props.validateSection(props.sectionValues)
  }, []);

  const changeInputValue = (field,value) =>
  {
    let sectionValuesD=sectionValues
    sectionValuesD[field].value=value
    setSectionValues(sectionValuesD)
    setForceRerender(!forceRerender)
    props.changeSectionValues("benefitsSectionValues",sectionValuesD)
  }

  return (<>
  {forceRerender} 
    <MKBox>


{/* //Benefits 
      isSalaryMentioned: DataTypes.BOOLEAN, 
      salary: DataTypes.DOUBLE,     
      salaryCurrency: DataTypes.DOUBLE,     
      allowances: DataTypes.TEXT,   
      salaryDuration: DataTypes.DOUBLE,    */}

      <MKTypography variant="h5">Benefits</MKTypography>
      <MKBox mb={5}>
              <MKBox mb={2} display="inline-block">
                <MKTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Salary Deal&nbsp;&nbsp;
               {(sectionValues.salaryDealTypeId.required)&& <MKTypography variant="caption" color="text">
                  (required)
                </MKTypography>}
                </MKTypography>
              </MKBox>
              <Autocomplete 
                
                defaultValue={props.getDataFromArray(
                  employmentSalaryDealTypes,
                    "id",
                    sectionValues.salaryDealTypeId.value,
                    "single"
                  )}
                 
                onChange={(e,val)=>{
                  if(val){
                    if(val.id){  
                      changeInputValue("salaryDealTypeId", val.id)
                    }
                  }
                }}  
                
                getOptionLabel={(option) => option.title}
                options={employmentSalaryDealTypes}
                renderInput={(params) => <MKInput {...params}   />}
              />
       </MKBox>
         <>
       {(sectionValues.salaryDealTypeId.value==1) &&
      <MKBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={12}>
          <MKBox mb={2} display="inline-block">
                <MKTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Salary Amount &nbsp;&nbsp;
               {(sectionValues.salary.required)&& <MKTypography variant="caption" color="text">
                  (required)
                </MKTypography>}
                </MKTypography>
              </MKBox>
            <TextField  type="number" label="Salary"
               defaultValue={sectionValues.salary.value}
               onChange={(e)=>{
                  if(e){
                    changeInputValue("salary",e.target.value )
                  }
                }}  placeholder="99.00" />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
          <MKBox mb={2} display="inline-block">
                <MKTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                 Currency   &nbsp;&nbsp;
               {(sectionValues.salaryCurrency.required)&& <MKTypography variant="caption" color="text">
                  (required)
                </MKTypography>}
                </MKTypography>
              </MKBox>
            <Autocomplete defaultValue={sectionValues.salaryCurrency.value}
              onChange={(e,val)=>{
                if(val){   
                    changeInputValue("salaryCurrency", val)
                  } 
              }}
              options={["ETB", "USD","BTC", "CNY", "EUR", "GBP", "INR"]}
              renderInput={(params) => <MKInput {...params}  />}
            />
          </Grid>
          <Grid item xs={12} sm={5} sx={{ mt: 2 }}>  
          <MKBox mb={2} display="inline-block">
                <MKTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                 Salary Duration    &nbsp;&nbsp;
               {(sectionValues.salaryDuration.required)&& <MKTypography variant="caption" color="text">
                  (required)
                </MKTypography>}
                </MKTypography>
              </MKBox>
          <Autocomplete 
              defaultValue={sectionValues.salaryDuration.value}
              onChange={(e,val)=>{
                if(val){   
                    changeInputValue("salaryDuration", val)
                  } 
              }}
              options={["Hourly", "Daily","Weekly", "Monthly", "Annaul",]}
              renderInput={(params) => <MKInput {...params}   />}
            />
            {/* <TextField  type="text" label="SKU" placeholder="71283476591" /> */}
          </Grid> 
        </Grid>
      </MKBox>}
      <MKBox mt={5}>
      <MKBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MKTypography component="label" variant="button" fontWeight="regular" color="text">
              Allowances&nbsp;&nbsp;
              {(sectionValues.allowances.required)&& <MKTypography variant="caption" color="text">
                  (required)
                </MKTypography>}
              </MKTypography>
            </MKBox>
            <MKEditor value={sectionValues.allowances.value}  
            //  onChange={setDescription}  
              onChange={(e)=>{
              if(e){
                  changeInputValue("allowances", e )
              }
            }}
             />
         </MKBox>
          </>
      {/* <MKBox mt={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MKBox my={2} display="inline-block">
              <MKTypography component="label" variant="button" fontWeight="regular" color="text">
                Tags
              </MKTypography>
            </MKBox>
            <Autocomplete
              multiple
              defaultValue={["In Stock", "Out of Stock"]}
              options={["Black Friday", "Expired", "Out of Stock", "In Stock", "Sale"]}
              renderInput={(params) => <MKInput {...params} variant="standard" />}
            />
          </Grid>
        </Grid>
      </MKBox> */}
    </MKBox>
    </>
  );
}

export default Benefits;
