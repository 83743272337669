// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { cities, specificAreas, subCities, citiesOnly } from "Api-master-datas/masterDatas.js";
import queryString from "query-string";
 
import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography"; 
// import MKDatePicker from "components/MKDatePicker";
import MKButton from "components/MKButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect, useRef } from "react";
import { HttpService } from "Api-Services/httpService";

import ReactGA from "react-ga4";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { 
  Box,
  Button, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,  
  Skeleton, 
} from "@mui/material";
import { 
  ArrowForwardIosSharp, 
  Search, 
} from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices";

import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import PostList from "pages/Posts/Sections/postList";  
import JobsSideBar from "./JobsSideBar";
import { isMobile } from "react-device-detect";
import InfiniteScroll from "react-infinite-scroller";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0.5px solid ${theme.palette.divider}`,
  borderBottom: "0.5px darkgreen solid",
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
}));

function SearchJob() { 
  const [forceRenderer, setForceRenderer] = React.useState(false);
  const [searchExpanded, setSearchExpanded] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [searchResult, setSearchResult] = React.useState(null);

  const [forceRerender, setForceRerender] = React.useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [searchedKeyword, setSearchedKeyword] = React.useState("");
  const [isFirstTimeSearch, setIsFirstTimeSearch] = React.useState(true);
  const [isPostLoading, setIsPostLoading] = React.useState(true);
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [jobsTab, setJobsTab] = React.useState("best-match");
  // const [jobPosts, setJobPosts] = React.useState([]);
  const [searchedJobPosts, setSearchedJobPosts] = React.useState([]);
  const [isEndOfJobPostsList, setIsEndOfJobPostsList] = React.useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const searchParams = queryString.parse(location?.search);
 

  if (params?.jobTab) {
    tabn = params?.jobTab;
  }
 
  let filterData={}  
  try { 
    let searchParamsD = queryString.parse(location?.search); 
    let filter=searchParamsD?.filter
      if(filter?.length){
      filter=JSON.parse(filter)
      } 
        if (searchParamsD||params) {    
         filterData={
          post:filter?.postFilter ,
          job:filter?.jobFilter, 
          profile:filter?.profileFilter, 
         } 
        // let postResultRes = await HttpService.postApiData("/api/post/searchmarketplace" ,{filter:filterData});
        // if(postResultRes?.length){
        //   postResultD=postResultRes
        // }
      }   
  } catch (error) {
    // console.log("errore ocuuu",error)
  }   
   

      
    const loadMore=async ( {pageParam=1} )=>{ 
      // console.log("pageParam",pageParam)
      // if(isEndOfJobPostsList) return []
      // return []
    // let relativePath="/api/profilefollowers/followings/"+myCurrentProfile?.id+"?pageSize="+8+"&page="+pageParam
      let resD =  await HttpService.postService("/api/post/searchjob"+"?pageSize="+8+"&page="+pageParam ,{filter:filterData},{});;
      let ress=[] 
      // console.log("resD",resD)
      resD=resD?.data 
      if(resD ){ 
        if(resD?.isEndOfList)setIsEndOfJobPostsList(true)
        if(resD?.record?.length){  
            ress=resD?.record   
      }}
      return ress
  } 
  
  const {
  data:jobPosts,
  error,
  fetchNextPage, 
  hasNextPage,
  isFetching,
  isFetchingNextPage,
  status,
  } = useInfiniteQuery({
  // queryKey: ["/api/profilefollowers/followings/"+myCurrentProfile?.id],
  queryKey: ["/api/post/searchjob/" ,{filter:filterData}],
  queryFn: loadMore,
  // initialPageParam:1,
  getNextPageParam: (lastPage, pages) =>{ 
    // console.log("pages", pages);
    // return lastPage.nextCursor
    let lastLen= 1
         lastLen=pages?.length+1
    return lastLen},
  })
  
  let profilesD=[] 
  try { 
    {jobPosts?.pages?.forEach(element => {
      element.forEach(element11 => {
        profilesD.push(element11)
      });
    }); 
   }
    // console.log("profilesD", profilesD);
    // console.log("jobPosts", jobPosts);
  } catch (error) {
    // console.log("errrrrrrrrrrr", error);
  } 

  useEffect(() => {
    let pageTabD = "best-match";
    let postTypeIdD = params?.postTypeId||1;
    let postTypeNameD = params?.postTypeName|| "feeds";
    // console.log("params", params);
    if (!params?.pageTab) {
      // navigate("/market-place/"+pageTabD)
      // "/find/:postTypeName?/:postTypeId?/:pageTab?/:postId?"
    }
    if (params?.postId) {
      setOpenedDialog("jobDetails");
    } else {
      setOpenedDialog("");
    }
  }, [params]);

  const handleTabChange = (event, newValue) => {
    setJobsTab(newValue);
    // navigate("/jobs/find/"+newValue)
  };

  useEffect(() => {
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch) {
        if (locationSearch.search) {
          searchByKeyword(locationSearch.search);
        }
      }
    }
  }, [location.pathname, location.search]);

  const searchByKeyword = async (searchD) => {
    let pathSearch;
    let searchKeywordD = "";
    try {
      setJobsTab("search")
      if (searchD) {
        let pathSearchData = CommonServices.decryptLocalData(searchD);
        // console.log("pathSearchData", pathSearchData);
        searchKeywordD = pathSearchData.keyword;
        setSearchKeyword(searchKeywordD);
        // jobSeekerSearchData = pathSearchData.jobSeekerSearch;
        // jobSearchData = pathSearchData.jobSearch;
      }
      else {
        searchKeywordD = searchKeyword;
        if (searchKeyword) {
          // jobSearchData = jobSearchFieldsandValue;
          pathSearch = { keyword: searchKeywordD };
          pathSearch = CommonServices.encryptLocalData(pathSearch);
          let path = "/pages/jobs?search=" + pathSearch; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
          // navigateComp(path);
        }
      }

      let data = {
        keyword: searchKeywordD,
      }; 

      try {
        setIsFirstTimeSearch(false); 
        let relativePath = "/api/post/searchjobbykeyword";
        setLoading(true); 
        let contactUsRes = await postDocuments("contactus", relativePath, data, {});
        setSearchedKeyword(searchKeywordD);
        setLoading(false);
        // console.log("contactUsRes",contactUsRes)
        // toast.update(toastId, { render: contactUsRes.message, type: contactUsRes.success?"success":"warn", isLoading: false , autoClose: 5000});
        // contactUsRes.professionTitle = professionTitle || "";
        // contactUsRes.employees = null;
        let searchResultD = [];
        if (contactUsRes.success) {
          if (contactUsRes.record) {
            setSearchedJobPosts(contactUsRes.record)
            if (contactUsRes.record.length) {
              searchResultD = contactUsRes.record;
              setSearchExpanded(false);
            }
          }
        }

        if (contactUsRes.success) {
          ReactGA.event({
            category: "job search",
            action: "job search found",
            label: searchKeywordD, // optional
            value: contactUsRes.record ? contactUsRes.record.length : 0, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        } else {
          ReactGA.event({
            category: "job search",
            action: "job search not found",
            label: searchKeywordD, // optional
            value: 0, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        }
        setSearchResult(searchResultD);
        // handleSearchResult(contactUsRes);
        if (contactUsRes.success) {
          // toast.success(contactUsRes.message, {
          //   position: toast.POSITION.TOP_CENTER,
          // });
        } else {
          // toast.error(contactUsRes.message, {
          //   position: toast.POSITION.TOP_LEFT,
          // });
        }
      } catch (error) {
        // setLoading(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log(documentName, " data: ", documentsResponse   );
    // setLoading(false);

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    }

    // console.log(documentName, " responseD: ", responseD);
    // setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };

  const jobsLoading = (num) => {
    try {
      return (
        <MKBox  style={{ marginBottom: "10px" }} sx={{ mx:isMobile?0:4 ,p:isMobile?0:2}}>
          {/* {<Stack spacing={1}> 
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> 
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={60} />
            <Skeleton variant="rounded" width={210} height={60} />
          </Stack>
} */}

          <Grid  container justifyContent={"space-between"}>
            {[...Array(num ? num : 3)].map((n,i) => (  
                      <Grid item 
                      xs={12}
                      // sx={{xs:12,sm:12,lg:12, }}
                      >
                       
                         <Box key={"i"} mb={2} >
                         <Box sx={{ pt: 0.5 }}>
                            <Skeleton />
                            <Skeleton width="80%" />
                          </Box> 
                          <Skeleton variant="rectangular"  height={isMobile?130:110} />
                         
                          </Box>
                      </Grid>  
             ))} 
          </Grid>
        </MKBox>
      );
    } catch (error) {
      return <></>;
    }
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="flex-start">
        
      <Grid
          item
          sm={12}
          xs={12}
          md={4}
          lg={3.5}
          xl={2.3}
          display={{ xs: "12", sm: "12", md: "flex" }}
          
        > 
          <JobsSideBar /> 
        </Grid>
        <Grid item
         xs={12} sm={12} md={8} lg={8.5 } xl={9.7} 
         sx={{mt:isMobile?0:2}}
         >
  
          {(profilesD?.length ? true : false) ? ( 
        <InfiniteScroll
            loadMore={() => fetchNextPage()}
            hasMore={!isEndOfJobPostsList}
            loader={jobsLoading(isMobile?2:4)}
          >   
          <PostList posts={profilesD}  grid={{xs:12,sm:12,md:12,lg:12,xl:12}}/>
          {/* <ProfileLists  profiles={profilesD} loadMore={()=>{}} /> */}
        </InfiniteScroll> 
          ) :null} 
        </Grid>

      </Grid>
      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{parse(moreText.content)}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
 

      {forceRerender}

      {forceRenderer}
    </>
  );
}

export default SearchJob;
