 
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import queryString from "query-string";
import MKTypography from "components/MKTypography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
// Images
import ReactGA from "react-ga4"; 
import { useCurrentUser } from "Api-contexts/currentUserContext";
import Card from "@mui/material/Card";
import React, { useEffect, useState } from "react";
import {   Edit } from "@mui/icons-material";
import MKInput from "components/MKInput";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress, 
  Divider,
  FormControl,
  FormControlLabel, 
  FormLabel,
  List,
  ListItem, 
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Stack, 
  TextField,
  Typography,
} from "@mui/material";
import { HttpService } from "Api-Services/httpService";
import FileUploadComp from "components/common-components/fileUploadComp"; 
import MKEditor from "components/MKEditor";
import { makeStyles } from "@mui/styles";
import { CommonServices } from "Api-Services/commonServices";
import {   useQuery, useQueryClient } from "@tanstack/react-query";
import TruncateMarkup from "react-truncate-markup";
import MKButton from "components/MKButton";

const useStyles1  = makeStyles((theme) => ({
  root: {
    "& .css-9igssw-MuiDialogContent-root": {
      padding: "5px",
    },
    "& .ql-toolbar.ql-snow ": {
      padding: "0px",
    },
  },
  dropzoneClass: {
    // maxHeight: "124px  !important",
    minHeight: "5px !important",
    "& .css-f8f0pk-MuiSvgIcon-root ": {
      height: "30px",
      width: "30px",
    },
    "& .css-1g96e5k-MuiTypography-root": {
      fontSize: "0.9rem",
      fontWeight: "700",
      marginTop: "4px",
      marginBottom: "2px",
    },
  },
  MKEditor: {
    "& .ql-editor ": {
      minHeight: "120px",
    },
  },
}));

function JobInfo(props) {
  const styles1=useStyles1()
  
  const queryClient = useQueryClient()
  const [currentData, updateContext] = useCurrentUser();
  const { myCurrentProfile ,isUserLoggedIn   } = currentData;
  const [expanded, setExpanded] = React.useState(false); 
  const [loadingSave, setLoadingSave] = React.useState(false);  
  const [profileSectionIndex, setProfileSectionIndex] = React.useState(0);
  const [forceRerender, setForceRerender] = React.useState(false);
  const [dropzoneFiles, setDropzoneFiles] = React.useState([]); 

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "JobSeeker Profile" });
    setForceRerender(!forceRerender);
  }, [isUserLoggedIn, myCurrentProfile]);

  useEffect(() => {
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch.tab) {
        setProfileSectionIndex(locationSearch.tab);
      }
    }
  }, [location.search, location.pathName]);
 
  
  // mynotifications
  const { data: jobSettings, isFetched :jobSettingsFetched  } = useQuery({
    queryKey: ["/api/profilesetting/jobsetting/" +props?.profileId],
    queryFn: async () => { 
      // console.log("getMyChats", )  
      let mm= await HttpService.getApiData("/api/profilesetting/jobsetting/"+props?.profileId );  
      return mm
    },
    // initialData:{}
  });
 
   
  const postDocuments = async (documentName, relativePath, data, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    documentsResponse = await HttpService.postService(relativePath, data, additionalData || {}, "");

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true; 
    } else {
      responseD.record = documentsResponse.data;
    }
    return responseD;
  };
 
 
  const updatemyCurrentProfile = async (dataD, parentData, dataParentName, fieldType) => {
    console.log("data", dataD,parentData, dataParentName,fieldType);
    // return
    try {
      let id;
      let relativePath;
      let refreshVariable = "";
      let value;
      let data = {  };
      if (dataD) {
        if (dataD.value) {
          value = dataD.value;
        }
      }
 
      console.log("    value 1", value);
      if (dataParentName == "profile") {
        // id = jobSettings?.id;
        relativePath = "/api/profilesetting/jobsetting/edit";
      }
      if (dataParentName == "workExperiance") {
        id = parentData.id;
        data["id"]=id
        relativePath = "/api/employmentjobseekerworkexperiance/edit";
        refreshVariable = "currentProfile";
      } else if (dataParentName == "myCurrentProfile") {
        id = jobSettings?.id;
        data["id"]=id
        relativePath = "/api/profilesetting/jobsetting/edit";
      }  
      
      console.log("    relativePath && id 1",  relativePath , jobSettings);
      if (relativePath ) {
        if ("fileUrl" == fieldType) {
          if (dataD.value) {
            let filesD = await uploadFiles(dataD.value);
            if (filesD) {
              if (filesD.length) {
                if (filesD.length > 0) {
                  value = filesD[0];
                }
              }
            }
          }
        }
        if ("imageUrl" == fieldType) {
          if (dataD.value) {
            let filesD = await uploadFiles(dataD.value);
            if (filesD) {
              if (filesD.length) {
                if (filesD.length > 0) {
                  value = filesD[0];
                }
              }
            }
          }
        }
          console.log("  data value", value);
        if (value) {
          data[dataD.fieldName] = value;
          console.log("HttpService data", data);

          // let toastId = toast.loading("Updating Profile data...",{
          //   position: toast.POSITION.BOTTOM_LEFT
          // })
          setLoadingSave(true);
          let changeUserProfileRes = await HttpService?.postService( relativePath, data, {});
          //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
          setLoadingSave(false);

          if (changeUserProfileRes?.success) {
            setExpanded(false);
            queryClient.invalidateQueries({ 
              queryKey: ["/api/profilesetting/jobsetting/my"],
          })
          }
        }
      }
    } catch (error) {
      console.log("error", error);

    }
  };
   
  function EditInputComponent({
    fieldLabel ,
    valueD
  }) {
    const lines=5
    const accordionTitle=valueD
    const [expanded, setExpanded] = useState(false);
    const [isTruncated, setIsTruncated] = useState(false);
  
    const handleToggle = () => {
      setExpanded(!expanded);
    };
  
    // const editInputComponent = (fieldName, fieldLabel, valueD, fieldType, options, selectedField) => {
    // console.log("fieldName",fieldName, valueD);
    let res = <></>;
    try { 
      res = (
        <>
          <ListItem> 
        <ListItemText primary={
              <MKTypography
                variant="body2"
                color="text"
                fontWeight="regular"
                pl={1}
              >
                {fieldLabel}{" "}
              </MKTypography>} 
              secondary={     <div>
                {expanded ? (
                  <MKTypography
                    sx={{ color: 'text.secondary' }}
                    variant="body2"
                    color="text"
                    fontWeight="regular"
                    pl={1}
                  >
                    <strong>{ CommonServices?.parseText(accordionTitle)}</strong>
                  </MKTypography>
                ) : (
                  <TruncateMarkup
                    lines={lines}
                    onTruncate={(wasTruncated) => setIsTruncated(wasTruncated)}
                  >
                    <MKTypography
                      sx={{ color: 'text.secondary' }}
                      variant="body2"
                      color="text"
                      fontWeight="regular"
                      pl={1}
                    >
                      <strong>{CommonServices?.parseText(accordionTitle)}</strong>
                    </MKTypography>
                  </TruncateMarkup>
                )}
                {isTruncated && (
                  <Typography color={"primary"} sx={{ml:2}} variant="caption" size="small" onClick={handleToggle}>
                    {expanded ? 'See Less' : 'See More'}
                  </Typography>
                )}
              </div>
          
          } />
      </ListItem>       
      <Divider sx={{my:0,mt:1}} variant="inset"  />

        </>
      );
    } catch (error) {
      console.log("inp comp", error);
    }
    return res;
  };

  const uploadFiles = async (filesD, filePathD) => {
    // console.log("uploadFiles",filesD,filePathD);
    let res = [];
    let dropzoneTempImages1 = filesD || dropzoneFiles;
    try {
      let filePath = "./public/files/users/user_25/new-folder3";
      const formData = new FormData();
      _.filter(dropzoneTempImages1, (file) => {
        // formData.append("folderPath", uploadingFolderPath);
        formData.append("folderPath", filePath);
        formData.append("file", file);
        return file;
      });

      let getHeaderAndURLRes = await HttpService.getHeaderAndURL();
      let baseUrl = getHeaderAndURLRes.baseUrl;
      let additionalData = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        // onUploadProgress: progressEvent => {
        //   if(progressEvent){
        //     // console.log("progressEvent", Math.round((progressEvent.loaded * 100) / progressEvent.total))
        //       setuploadPercentage(parseInt(
        //       Math.round((progressEvent.loaded * 100) / progressEvent.total)
        //         ))
        //         }
        // }
      };

      // console.log("formData  ",formData);
      let uploadFileRes = await postDocuments(
        "uploadFile",
        "/api/file/photos",
        formData,
        additionalData
      );
      if (uploadFileRes) {
        if (uploadFileRes.record) {
          if (uploadFileRes.record.length) {
            uploadFileRes.record.forEach((imgFile) => {
              // var destination=imgFile.destination.replace('./public',"");
              var destination = imgFile.destination.replace("./public", "/public");
              var filename = "/" + imgFile.filename;
              var pathN = baseUrl + destination + filename;
              var imgpath = pathN;
              res.push(imgpath);
            });
          }
        }
      }
      // console.log("uploadFileRes res",res);
      // await axios.post(baseUrl+"/api/file/photos", formData,{additionalData})
      // .then(function (response) {
      //   let imgs=[]
      //   // console.log("file  dropzoneval, uploaded",dropzoneTempImages1,response.data);
      //   response.data.forEach(imgFile => {
      //     var destination=imgFile.destination.replace('./public',"");
      //     var filename="/"+imgFile.filename
      //     var pathN=baseUrl+destination+filename;
      //     var imgpath=pathN
      //     imgs.push(imgpath)
      //   });
      //   // props.uplodedImages(imgs,props.uploadingImageType);
      //     // setuploadedFiles([...uploadedFiles, ...imageFiles])
      // })
      // .catch(function (error) {
      // });
    } catch (err) {
      // console.log("uploadFiles err",err);
    }
    return res;
  };
  

  return (
    <>    
                {/* <Card sx={{ width: "100%" }}> */}
                    <MKBox mb={2}>   
                     <MKTypography
                          variant="h6"
                          mt={2}
                          style={{ color: "darkgreen" }}
                          // mb={2}
                          // mx={2}
                        >
                        Described as:
                        </MKTypography>
                     <MKTypography
                          variant="h6"
                          // mx={2}
                        > 
                         {jobSettings?.isEmployer?" Employer ":""}
                         {jobSettings?.isEmploymentAgency?" -  Employment Agency":""}
                         {jobSettings?.isJobSeeker?" - Job Seeker":""}
                        </MKTypography>
 
                      </MKBox> 
                  
                    
 
            {props?.isOverview?
            <MKBox elevation={3} mt={2}  > 
                <List  dense sx={{ width: '100%',  bgcolor: 'background.paper' }}>
<EditInputComponent fieldLabel={"Description"} valueD={jobSettings?.description}/>
                 
                  </List>
            </MKBox>
            :<>  
            
            <Grid  alignItems="center">
                    <Grid item xs={12} lg={12}> 
                      <MKBox >    
<EditInputComponent fieldLabel={"Display Name"} valueD={jobSettings?.displayName}/>
<EditInputComponent fieldLabel={"Title"} valueD={jobSettings?.title}/>
<EditInputComponent fieldLabel={"Contact Email"} valueD={jobSettings?.email}/>
<EditInputComponent fieldLabel={"Contact Phone"} valueD={jobSettings?.phone}/>
     
                      </MKBox>
                    </Grid>
                  </Grid> 
 
             { ((jobSettings?.isJobSeeker))&&<Grid xs={12} justifyContent="center" mx="auto">
                  <MKBox py={{ sm: 0, md: 3 }}  >
                  <List elevation={3} dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  <EditInputComponent fieldLabel={"Available to be hired"} valueD={jobSettings?.isAvailable?"Yes":"No"}/>
 
</List>                    
                    <Paper
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        p: 2,
                        my: 2,
                      }}
                      component="ul"
                    >
                        <ListItem 
                          > 
                            <ListItemText
                              primary ={<MKTypography
                              variant="h6" 
                              style={{ color: "dark" }} 
                            >Professions / Talents    </MKTypography>}
                              secondary ={jobSettings?.JobsSeeking?.length?jobSettings?.JobsSeeking?.map((data) => { 
                                return (
                                    <Chip sx={{mb:0.5, mr:0.5}}
                                      // icon={icon}
                                      label={data?.title} 
                                      // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                                    />
                                ); 
                              }):"No job Added"}
                            />
                          </ListItem>
                      
                    </Paper>
                    
                    <MKTypography
                      variant="h6"
                      mt={2}
                      style={{ color: "darkgreen" }}
                      mb={2}
                      mx={2}
                    >
                      Expecting Salary | Benefit
                    </MKTypography>
                  <EditInputComponent fieldLabel={"Salary Per Hour"} valueD={(jobSettings?.expectingSalaryCurrency||"")+" "+(jobSettings?.expectingSalaryPerHour||"")}/>
                  <EditInputComponent fieldLabel={"Salary Per Hour In USD"} valueD={(jobSettings?.expectingSalaryPerHourConvertedInUSD||"")}/>
   
                  </MKBox> 
              </Grid>}
              
              { ((jobSettings?.isEmployer||jobSettings?.isEmploymentAgency))&&
              <Grid xs={12} justifyContent="center"  mx="auto"> 
                      <MKBox  >

                      <Paper
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 2,
                            my: 2,
                          }}
                          component="ul"
                        >
                            <ListItem 
                              > 
                                <ListItemText
                                 primary ={<MKTypography
                                  variant="h6" 
                                  style={{ color: "dark" }} 
                                > Hiring Professions / Talents     </MKTypography>}
                                 secondary ={jobSettings?.JobsHiring?.length?jobSettings?.JobsHiring?.map((data) => { 
                                    return (
                                        <Chip  sx={{mb:0.5, mr:0.5}}
                                          // icon={icon}
                                          label={data?.title} 
                                        />
                                    ); 
                                  }):"No job Added"}
                                />
                              </ListItem>
                          
                        </Paper> 
                    </MKBox>  
                </Grid>}

              {jobSettings?.description?<Grid xs={12} justifyContent="center" mx="auto">
                  <MKBox py={{ sm: 0, md: 3 }}  >
                  <EditInputComponent fieldLabel={"Description"} valueD={(jobSettings?.description||"")}/>
                   </MKBox>
              </Grid>:null}
            </>   }
      {forceRerender}
 
    </>
  );
}

export default JobInfo;
