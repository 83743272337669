// @mui material components
import Grid from "@mui/material/Grid";
 
import parse from "html-react-parser"; 
// import MKDatePicker from "components/MKDatePicker";
import MKButton from "components/MKButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect, useRef } from "react";
import { HttpService } from "Api-Services/httpService";

import ReactGA from "react-ga4"; 
import { 
  Avatar,
  Box,
  Button, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,  
  List,  
  ListItem,  
  ListItemAvatar,  
  ListItemButton,  
  ListItemText,   
} from "@mui/material"; 
import { CommonServices } from "Api-Services/commonServices";

import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
// import JobsSideBar from "./JobsSideBar";
import { isMobile } from "react-device-detect";
import InfiniteScroll from "react-infinite-scroller";
 
import "swiper/swiper.min.css";
import { Autoplay, EffectCoverflow, FreeMode, Navigation, Pagination, Zoom } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Stories from 'react-insta-stories';
import StoryPosts from "./StoryPosts";
import MKBox from "components/MKBox";
function AllStories() { 
  const navigate = useNavigate();
  const params = useParams();  
  const swiperRef = useRef(null);

  const [forceRenderer, setForceRenderer] = React.useState(false); 
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  // const [jobPosts, setJobPosts] = React.useState([]);
  const [isEndOfStoryPostsList, setIsEndOfStoryPostsList] = React.useState(false);
  const [storiesProfile, setStoriesProfile] = React.useState(null);
  const [activeStoriesProfileId, setActiveStoriesProfileId] = React.useState(params?.profileId);
  const [activeStoriesProfileIndex, setActiveStoriesProfileIndex] = React.useState(0);
  

  const goToSlide = (index) => {
    // console.log("swiperRef",swiperRef)
    if (swiperRef.current && swiperRef.current.slideTo) {
      swiperRef.current.slideTo(index);
      setForceRenderer(!forceRenderer)
      // console.log(`Navigated to slide ${index}`);
    } else {
      // console.log('Swiper instance is not available.');
    }

    // if (swiperRef.current && swiperRef.current.swiper) {
    //   swiperRef.current.swiper.slideTo(2);
    //   console.log("goToSlide")
    
    // }
  };
    
  const loadMoreStories=async ( {pageParam=1} )=>{ 
      // console.log("pageParam",pageParam)
      // if(isEndOfJobPostsList) return []
      // return []
    // let relativePath="/api/profilefollowers/followings/"+myCurrentProfile?.id+"?pageSize="+8+"&page="+pageParam
      let resD =  await HttpService.getService("/api/post/story"+"?pageSize="+8+"&page="+pageParam  +"&startProfileId="+params?.profileId  ,{});;
      let ress=[] 
      // console.log("story resD",resD)
      resD=resD?.data 
      if(resD ){ 
        if(resD?.isEndOfList)setIsEndOfStoryPostsList(true)
        if(resD?.record?.length){   
            ress=resD?.record   
            try {
             if(!storiesProfile){
                setStoriesProfile(ress[0]||null) 
                setForceRenderer(!forceRenderer)
              } x
            } catch (error) {
              
            }
      }}
      return ress
  } 
   
  const {
      data:storyProfiles,
      error:storyProfilesError,
      fetchNextPage:fetchStoriesNextPage, 
      // hasNextPage,
      // isFetching,
      // isFetchingNextPage,
      // status,
      } = useInfiniteQuery({ 
      queryKey: ["/api/post/story",{startProfileId:params?.profileId}],
      queryFn: loadMoreStories,
      // initialPageParam:1,
      getNextPageParam: (lastPage, pages) =>{ 
    // console.log("pages", pages);
    // return lastPage.nextCursor
       let lastLen= 1
         lastLen=pages?.length+1
    return lastLen},
  })
  
  
  React.useEffect(() => {
    try { 
      if(!storiesProfile&&(params?.profileId)){ 
        storyProfiles?.pages?.forEach(storyProfilesD => {
          storyProfilesD?.forEach(storyProfile => {
            if(storyProfile?.id==params?.profileId){
            setStoriesProfile(storyProfile||null) 
            setForceRenderer(!forceRenderer)
            }
        });
      }); 
      }
    } catch (error) {
    console.log("props?.storiesProfile error"  , error);
    }
  }, [storiesProfile]);
 
 
  function StoryList(){
    console.log("StoryList")
    return (<>
    {/* <h1>mesfin</h1> */}
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
       <InfiniteScroll
            loadMore={() => fetchStoriesNextPage()}
            hasMore={!isEndOfStoryPostsList}
            loader={"loading..."}
          >    
      {storyProfiles?.pages?.map((storyprofilesD,index1)=><>
        {storyprofilesD?.map((storyProfile,index2)=><ListItemButton 
          onClick={()=>{
            goToSlide(index1 * 8 + index2)
            // setStoriesProfile(storyProfile) 
            
            setActiveStoriesProfileIndex(index1*8+index2)
            setActiveStoriesProfileId(storyProfile?.id)
            setForceRenderer(!forceRenderer)
          }}
          // selected={(activeStoriesProfileId)==storyProfile?.id}
          selected={(index1*8+index2)==activeStoriesProfileIndex}
          alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="Remy Sharp" src={storyProfile?.profileImageUrl}/>
          </ListItemAvatar>
          <ListItemText
            primary={CommonServices?.getProfileName(storyProfile)}
            // secondary={
            //   <React.Fragment>
            //     <Typography
            //       sx={{ display: 'inline' }}
            //       component="span"
            //       variant="body2"
            //       color="text.primary"
            //     >
            //       Ali Connors
            //     </Typography>
            //     {" — I'll be in your neighborhood doing errands this…"}
            //   </React.Fragment>
            // }
          />
        </ListItemButton>)}
      </>
      )}
        </InfiniteScroll> 
    </List>  
    </>)
  }
  // let storiesProfiles=[]

  return (
    <>
     {forceRenderer}
      <Grid container justifyContent="space-between" alignItems="flex-start">
     
      <Grid
          item
          sm={12}
          xs={12}
          md={5}
          lg={4}
          xl={3}
          display={{ xs: "none", sm: "none", md: "flex" }}
        >  
        <StoryList/> 
        </Grid>
        <Grid item  
         xs={12} sm={12} md={7} lg={8 } xl={9} 
        //  sx={{mt:isMobile?0:2}}
         >
      <Grid   container
  direction="row"
  justifyContent="center"
  alignItems="center"
>

      <Grid item  
         xs={12} sm={12} md={7} lg={6 } xl={5} 
         >
                  {storyProfiles?.pages?.length?
                         <StoryPosts
                           // selected={(index1 * 8 + index2)==swiperRef?.current?.activeIndex}
                            onStoryStart={()=>{}}
                            onStoryEnd={()=>{}}
                            activeProfileIndex={activeStoriesProfileIndex}
                            isPaused={false}
                            // isPaused={(index1*8+index2)!=activeStoriesProfileIndex}
                            onAllStoriesEnd={()=>{
                              // console.log("onAllStoriesEnd")
                              // goToNextSlide() 
                            }}
                            // storiesProfile={JobSeeker}
                            storiesProfiles={storyProfiles}
                        //  stories={stories}
                          />:null }

</Grid>
</Grid>
</Grid>

      </Grid>
      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{parse(moreText.content)}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>

      {forceRenderer}
    </>
  );
}

export default AllStories;
