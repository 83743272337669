import React, { Suspense, lazy, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Icon from "@mui/material/Icon";
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";

// Material Kit 2 PRO React routes
// import routes from "routes";

import { isMobile } from "react-device-detect";
import { CurrentUserProvider } from "Api-contexts/currentUserContext";
import MKBox from "components/MKBox";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import customRoutes from "customRoutes";
import {
  Box, 
  CircularProgress, 
  Grid,
  LinearProgress,
} from "@mui/material";
import MobileNavBar from "examples/MobileNavBar/MobileNavBar";
import BottomNavBar from "examples/BottomNavBar/BottomNavBar";
import AllGlobalDialogBoxs from "pages/GlobalComponents/allGlobalDialogBoxs";
import DefaultNavbar from "examples/Navbars/DefaultNavbar"; 
import SearchJob from "pages/Search/SearchJobs";
import { HttpService } from "Api-Services/httpService";
import { Apps, Home, Work } from "@mui/icons-material"; 
import UserProfile from "pages/UserProfile";
import SideBarMenu from "pages/GlobalComponents/SideBarMenu/Sections/SideBarMenu"; 
import EditProfile from "pages/GlobalComponents/EditProfile/Sections/EditProfile";
import Friendship from "pages/UserProfile/Components/Friendship/Friendship";
import CreateAnArticle from "pages/Posts/Post/CreateAnArticle/CreateAnArticle";
import CreateDiscussion from "pages/Posts/Post/CreateDiscussion";
import { Helmet } from "react-helmet";
import MetaDecorator from "metaDecorator";
import CreateMarketPlace from "pages/Posts/Post/CreateMarketPlace";
import CreateVehicle from "pages/Posts/Post/CreateMarketPlace/CreateVehicle/CreateVehicle";
import CreateMarketPlacePost from "pages/Posts/Post/CreateMarketPlace/CreateMarketPlacePost";
import MarketPlace from "pages/Search/MarketPlace";
import SwitchMyProfilesBox from "pages/UserProfile/Sections/SwitchMyProfilesBox";
import Chats from "pages/GlobalComponents/Message/Sections";
import PostCard from "pages/Posts/Sections/postCard";
import Notifications from "pages/GlobalComponents/Notifications/Sections";
import Find from "pages/Search/Find";
import Settings from "pages/GlobalComponents/Settings/Sections";
import SideBarMenuContent from "examples/Navbars/DefaultNavbar/SideBarMenuContent";
import SelectYourJobCatagory from "pages/GlobalComponents/Settings/Sections/JobSetting/Components/SelectFieldOfStodies";
import Contacts from "pages/GlobalComponents/Contacts/Contacts";
import AllStories from "pages/Posts/StoryPosts";
import CreateNewPost from "pages/Posts/Post/Components/CreatePost/CreateNewPost";
import CreateNewStoryPost from "pages/Posts/Post/Components/CreateStoryPost/CreateNewStoryPost";
import EducationalHistory from "pages/UserProfile/Components/EducationalHistory";
import WorkHistory from "pages/UserProfile/Components/WorkHistory";
import Profession from "pages/UserProfile/Components/Profession";
import Portfolio from "pages/UserProfile/Components/Portfolio";
import { requestPermission } from "./Api-Services/firebase";

// componennts

// const AllGlobalDialogBoxs = lazy(() => import("pages/GlobalComponents/allGlobalDialogBoxs"));
// const MobileNavBar = lazy(() => import("examples/MobileNavBar/MobileNavBar"));
// const BottomNavBar = lazy(() => import("examples/BottomNavBar/BottomNavBar"));
const ExtremeLandingPage = lazy(() => import("pages/LandingPages/Extreme"));
// const DefaultNavbar = lazy(() => import("layouts/Navbars/DefaultNavbar"));
const Presentation = lazy(() => import("layouts/pages/presentation"));
///g-ga4 id=G-9WH6WP5G6X
const queryClient = new QueryClient();
export default function App() {
  const { pathname } = useLocation();

  // const [postTypeMenu, setPostTypeMenu] = React.useState([]);

  // useEffect(() => {
  //   requestPermission()
  // }, []);

  const { data: postTypes } = useQuery({
    queryKey: ["/api/post/posttype/all"],
    queryFn: async () => {
      return await HttpService.getApiData("/api/post/posttype/all");
    },
  }); 

  let postMenusD = [
    {
      isAllowedForAgent: true,
      name: "Home",
      route: "/home",
      icon: <Home />,
      component: (
        <Suspense fallback={<Loading />}>
          <ExtremeLandingPage />
        </Suspense>
      ),
    },
  ];
  if (postTypes?.length) {
    postTypes.forEach((element) => {
      if (element?.displayOnMenu) {
        let titleWithNoSpace=element?.titleWithNoSpace||element?.id
        let ddd = {
          isAllowedForAgent: true,
          name: element?.title,
          route: "/" + titleWithNoSpace,
          icon: element.icon,
        };
        postMenusD.push(ddd);
      }
    });
    // setPostTypeMenu(postMenusD);
  }
  postMenusD.push({
    isAllowedForAgent: true,
    name: "Find",
    route: "/find",
    icon: <Apps />,
    component: (
      <Suspense fallback={<Loading />}>
        <Find />
      </Suspense>
    ),
  });
  

  const routes = postMenusD;

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  function Loading() {
    return (
      <Box sx={{ width: "100%", m: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <CurrentUserProvider>
        <ThemeProvider theme={theme}> 
        <MetaDecorator  description="Baally Business Platform" title="Baally" />
          <ToastContainer />

          <CssBaseline />
          {/* <NotificationBar/> */}
          {isMobile ? (
            // <Suspense fallback={<Loading />}>
            <MobileNavBar />
          ) : (
            // {/* </Suspense> */}
            <DefaultNavbar
              routes={routes}
              action={{
                type: "external",
                route: "https://www.creative-tim.com/product/material-kit-pro-react",
                label: "Login",
                color: "info",
              }}
              sticky //transparent
              light
            />
          )}
          <MKBox sx={{ mt: isMobile ? 0 : 7 }}>
            {/* {isMobile ? (
        homeContent
      ) : ( */}
            <Grid container spacing={2} justifyContent="space-between" alignItems="flex-start">
              {/* <Grid item lg={3.2} xl={3.5} display={{ xs: "none", sm: "none", lg: "flex" }}>
                
                <GeneralSideBarContent />
              </Grid> */}
              {/* <Grid item xs={12} sm={12} md={12} lg={8.8} xl={8.5}> */}
              <Grid item xs={12} sm={12} md={12}  >
                {/* <>{homeContent}</> */}

                <Routes>
                  {/* {getRoutes(routes)} */}
                  {getRoutes(customRoutes)}
                   
                  <Route
                    path="/:userName/:tab?/:aboutTab?"
                    element={
                      <Suspense fallback={<Loading />}>
                        <UserProfile />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/profile/educational-history"
                    element={
                      <Suspense fallback={<Loading />}>
                        <EducationalHistory />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/profile/work-history"
                    element={
                      <Suspense fallback={<Loading />}>
                        <WorkHistory />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/profile/profession"
                    element={
                      <Suspense fallback={<Loading />}>
                        <Profession />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/profile/portfolio"
                    element={
                      <Suspense fallback={<Loading />}>
                        <Portfolio />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/presentation"
                    element={
                      <Suspense fallback={<Loading />}>
                        <Presentation />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <Suspense fallback={<Loading />}>
                        <ExtremeLandingPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/menu"
                    element={
                      <Suspense fallback={<Loading />}>
                        <SideBarMenuContent />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/chats/:chatTypeId?/:chatId?/:profileId?"
                    element={
                      <Suspense fallback={<Loading />}>
                        <Chats />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/Notifications?"
                    element={
                      <Suspense fallback={<Loading />}>
                        <Notifications />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/Settings/:tab?"
                    element={
                      <Suspense fallback={<Loading />}>
                        <Settings />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/Settings/job/catagory"
                    element={
                      <Suspense fallback={<Loading />}>
                        <SelectYourJobCatagory />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/profile/messages"
                    element={
                      <Suspense fallback={<Loading />}>
                        <Chats />
                      </Suspense>
                    }
                  /> 
                  <Route 
                    path="/contacts/:userName/:tab?"
                    element={
                      <Suspense fallback={<Loading />}>
                        <Contacts />
                      </Suspense>
                    }
                  />
                  <Route 
                    path="/profile/switch-profile"
                    element={
                      <Suspense fallback={<Loading />}>
                        <SwitchMyProfilesBox />
                      </Suspense>
                    }
                  />
                  <Route 
                    path="/profile/edit"
                    element={
                      <Suspense fallback={<Loading />}>
                        <EditProfile />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/home"
                    element={
                      <Suspense fallback={<Loading />}>
                        <ExtremeLandingPage />
                      </Suspense>
                    }
                  />
                  {/* <Route
                    path="/jobs/:id"
                    element={
                      <Suspense fallback={<Loading />}>
                        <ExtremeLandingPage />
                      </Suspense>
                    }
                  /> */}
                  {/* /:jobTab? */}
                  
                  <Route
                    path="/Market-Place/:pageTab?"
                    element={
                      <Suspense fallback={<Loading />}>
                        <MarketPlace />
                      </Suspense>
                    }
                  />
                  
                  <Route
                    path="/Market-Place/category/:listingTypeName/:listingTypeId?"
                    element={
                      <Suspense fallback={<Loading />}>
                        <MarketPlace />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/Market-Place"
                    element={
                      <Suspense fallback={<Loading />}>
                        <MarketPlace />
                      </Suspense>
                    }
                  />
                  
                  <Route
                    path="/stories/:profileId"
                    element={
                      <Suspense fallback={<Loading />}>
                        <AllStories />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/Jobs"
                    element={
                      <Suspense fallback={<Loading />}>
                        <SearchJob />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/Find"
                    element={
                      <Suspense fallback={<Loading />}>
                        <Find />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/Jobs/category/:categoryName?/:categoryId?"
                    element={
                      <Suspense fallback={<Loading />}>
                        <SearchJob />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/:username/posts/:postId?"
                    element={
                      <Suspense fallback={<Loading />}>
                        <PostCard />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/create/Create-Market-Place"
                    element={
                      <Suspense fallback={<Loading />}>
                        <CreateMarketPlace/>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/Market-Place/:mode?/:postIdOrMarketPlaceListingTypeId?"
                    // path="/find/:postTypeName/:postTypeId?/:pageTab?/:postId?"
                    element={
                      <Suspense fallback={<Loading />}>
                        <CreateMarketPlacePost/>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/create/create-article"
                    element={
                      <Suspense fallback={<Loading />}>
                        <CreateAnArticle />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/create/feed"
                    element={
                      <Suspense fallback={<Loading />}>
                        <CreateNewPost />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/create/story"
                    element={
                      <Suspense fallback={<Loading />}>
                        <CreateNewStoryPost />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/create/create-discussion"
                    element={
                      <Suspense fallback={<Loading />}>
                        <CreateDiscussion />
                      </Suspense>
                    }
                  />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </Grid>
            </Grid>
            {/* // )} */}
          </MKBox>
          {/* { (!isMobile)&&
      <Box pt={6} px={1} mt={6}> 
         <FooterTwo content={footerRoutes} />  
         </Box>
       } */}
          <AllGlobalDialogBoxs />
        </ThemeProvider>
        {isMobile && (
          // <Suspense fallback={<Loading />}>
          <BottomNavBar />
          // </Suspense>
        )}
      </CurrentUserProvider>
    </QueryClientProvider>
  );
}
