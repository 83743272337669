 

import parse from "html-react-parser";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React, { useState, useEffect, useRef } from "react";

// Rental page components
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { Link, useNavigate, useParams } from "react-router-dom";
// Direct React component imports

import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";

import { FreeMode, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  AddCircle,
  ArrowBackIos,
  ArrowForwardIos,
  CheckCircle,
  Favorite,
  Logout,
  MoreVert,
  Person,
  Preview,
  School,
  Send,
} from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import { CommonApiServices } from "Api-Services/commonApiServices";
import { DataManagementService } from "Api-Services/dataManagementService";
import { CommonServices } from "Api-Services/commonServices";
import { HttpService } from "Api-Services/httpService";
import { useQuery } from "@tanstack/react-query";
import { InView } from "react-intersection-observer";
import { makeStyles } from "@mui/styles"; 
import MKButton from "components/MKButton";
// import SwiperSlide from 'swiper/react';

// Define the custom styles using makeStyles
const useStyles = makeStyles(() => ({
  swiperButtonPrev: {
    color: "black",
    bottom: "150px",
    top: "auto",
    transform: "translateY(0)",
    width: "30px",
    height: "30px",
    left: "-54px", // Adjust the position of the left arrow
  },
  swiperButtonNext: {
    color: "black",
    bottom: "150px",
    top: "auto",
    transform: "translateY(0)",
    width: "30px",
    height: "30px",
    right: "-54px", // Adjust the position of the right arrow
  },
}));
function EducationalHistoriesList(props) {
    

  const classes = useStyles(); // Use the defined styles
  const [currentData,updateContext] = useCurrentUser();
  const {   myCurrentProfile } = currentData;
  const navigate = useNavigate();
  
  let {data:educationalHistories} = useQuery({
    queryKey: ["/api/educationalHistory/find"+props?.profileId],
    queryFn: async () => {
      return await HttpService.getApiData("/api/educationalHistory/find/"+props?.profileId);
    },
  });
 
  const checkAndGoToLoginOrRegisterBox=()=>{
    if(myCurrentProfile){
      return true
    }
    else{
      let data = {
        dialogId: "SwitchProfileDialogBox",
        data: {},
      };
      updateContext("currentGlobalDialogBoxData", data);
    return false
    }
  }
  
return <>  
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
      <Grid item sx={{ mb: 2 }}>
        <MKTypography variant="subtitle2">{educationalHistories?.length||"No "} Educational Histories</MKTypography>
      </Grid> 
    </Grid> 
         <List
   > 
            {educationalHistories?.map((educationalHistory,index)=>(props?.isOverview?(index<2):true)&&<>
            <ListItem
              secondaryAction={(myCurrentProfile?.id==props?.profileId)?
                <Button
                  size={"small"}
                  onClick={() => {
                    try {
                    if(props?.editEducationalHistory){
                      props?.editEducationalHistory(educationalHistory) 
                    }
                    else navigate("/profile/educational-history");
                    } catch (error) {
                    }
                  }}
                  // variant={"outlined"}
                  // color="warning"
                >
                  Edit 
                </Button>:null
              }
              >
                {/* <ListItemIcon>
                  <School />
                </ListItemIcon> */}
                <ListItemText
                  secondary={<>
                    <MKTypography variant="subtitle2">
                   { educationalHistory?.schoolName?educationalHistory?.schoolName:""}
                   { educationalHistory?.Address?.Country?.name?" - "+educationalHistory?.Address?.Country?.name:""}
                   { educationalHistory?.Address?.City?.name?" - "+educationalHistory?.Address?.City?.name:""}
                  </MKTypography></>}
                  primary={<>
                    <MKTypography variant="h6">
                    { educationalHistory?.EducationLevel?.title?educationalHistory?.EducationLevel?.title+"":""}
                    {<small style={{color:"grey"}} > {(CommonServices?.parseDate(educationalHistory?.startDate)||"")+"-"+(CommonServices?.parseDate(educationalHistory?.endDate)||"" )} </small>}
                      </MKTypography>
                    <MKTypography variant="subtitle1">
                   { educationalHistory?.FieldOfStudy?.title?educationalHistory?.FieldOfStudy?.title:""}
                   { educationalHistory?.fieldOfStudyTitle?" - "+educationalHistory?.fieldOfStudyTitle+"":""}
                    </MKTypography>
                  </>
                  }
                /> 
              </ListItem> 
              <Divider/>
                </>)}
        </List> 
  </>
}

export default EducationalHistoriesList;
