import * as React from "react"; 
import Box from "@mui/material/Box"; 
import PropTypes from "prop-types";
 
import { useCurrentUser } from "Api-contexts/currentUserContext";
 
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
 
import FriendshipDialogBox from "./Freindship/FriendshipDialogBox";
import EditProfileDialogBox from "./EditProfile/EditProfileDialogBox";
import SwitchProfileDialogBox from "./SwitchProfile/SwitchProfileDialogBox";
import SideBarMenuDialogBox from "./SideBarMenu/SideBarMenuDialogBox";
import PostCommentsDialogBox from "./PostComments/PostCommentsDialogBox"; 
import MessageDialogBox from "./Message/MessageDialogBox";
import CreatePostDialogBox from "./CreatePost/CreatePostDialogBox";
import PostDetailsDialogBox from "./PostDetails/PostDetailsDialogBox";
import ProfileDialogBox from "./Profile/ProfileDialogBox";
import NotificationsDialogBox from "./Notifications/NotificationsDialogBox";
import CreateStoryPostDialogBox from "./CreateStoryPost/CreatePostDialogBox";
 
function HideOnScroll(props) {
  const { children, window } = props;
    const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function AllGlobalDialogBoxs(props) {  
  const [forceRerender, setForceRerender] = React.useState(false);
  const [dialogId, setDialogId] = React.useState(false);
 
  const [currentData,updateContext] = useCurrentUser();
  const { currentGlobalDialogBoxData} =
    currentData;
 
  React.useEffect(() => { 
    // console.log("  currentGlobalDialogBoxData", currentGlobalDialogBoxData);
    setDialogId(currentGlobalDialogBoxData?.dialogId)
    setForceRerender(!forceRerender)
  }, [location.pathname,currentGlobalDialogBoxData]);
 
 

  return (<>
   { (currentGlobalDialogBoxData?currentGlobalDialogBoxData.dialogId?true:false:false)&& 
   <Box sx={{ flexGrow: 1, mb: 7 }}> 
   {(dialogId == "PostCommentsDialogBox")&& <PostCommentsDialogBox  onClose={() => {setDialogId(false); }}  currentGlobalDialogBoxData={currentGlobalDialogBoxData}/> 
     }
      <ProfileDialogBox currentGlobalDialogBoxData={currentGlobalDialogBoxData}/> 
       <PostDetailsDialogBox  currentGlobalDialogBoxData={currentGlobalDialogBoxData}/> 
       <SideBarMenuDialogBox  currentGlobalDialogBoxData={currentGlobalDialogBoxData}/> 
     
     {(dialogId == "FriendshipDialogBox")&& <FriendshipDialogBox  onClose={() => { setDialogId(false);}}  currentGlobalDialogBoxData={currentGlobalDialogBoxData}/> 
       }
      {(dialogId == "EditProfileDialogBox")&& <EditProfileDialogBox  onClose={() => { setDialogId(false);}}  currentGlobalDialogBoxData={currentGlobalDialogBoxData}/> 
        }
      {(dialogId == "SwitchProfileDialogBox")&& <SwitchProfileDialogBox  onClose={() => { setDialogId(false);}}  currentGlobalDialogBoxData={currentGlobalDialogBoxData}/> 
        }
      {(dialogId == "MessageDialogBox")&& <MessageDialogBox  onClose={() => { setDialogId(false);}} currentGlobalDialogBoxData={currentGlobalDialogBoxData}/> 
       }
      {(dialogId == "NotificationsDialogBox")&& <NotificationsDialogBox  onClose={() => { setDialogId(false);}} currentGlobalDialogBoxData={currentGlobalDialogBoxData}/> 
       }
      {(dialogId == "CreatePostDialogBox")&& <CreatePostDialogBox onClose={() => { setDialogId(false);}} currentGlobalDialogBoxData={currentGlobalDialogBoxData}/> 
       }
      {(dialogId == "CreateStoryPostDialogBox")&& <CreateStoryPostDialogBox onClose={() => { setDialogId(false);}} currentGlobalDialogBoxData={currentGlobalDialogBoxData}/> 
       }
      {forceRerender}
    </Box>}</>
  );
}

AllGlobalDialogBoxs.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
