 
import queryString from "query-string";
  
import {   useParams } from "react-router-dom"; 
import React from "react";
 
import { isMobile } from "react-device-detect"; 
import {   MainContainer   } from "@chatscope/chat-ui-kit-react";
 
import ChatsList from "./ChatsList"; 
import ChatBox from "./ChatBox";
 
 
// var socket,selectedChatCompare
function Chats() {   
  return (
    <>  
    <MainContainer
        // responsive 
        style={{ position:"fixed",width:"100%"  ,
          // height: '600px'
          height:  (isMobile)?"calc(-7rem + 100vh)":"calc(-3.5rem + 100vh)",
        }}
        > 
      <ChatsList/> 
      <ChatBox />
    </MainContainer>
    </>
  );
}

export default Chats;
