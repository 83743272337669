 
import React, { useEffect } from "react";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { 
  Dialog,  
} from "@mui/material"; 
import { isMobile } from "react-device-detect";  
import { useNavigate } from "react-router-dom";
import CreateNewPost from "pages/Posts/Post/Components/CreatePost/CreateNewPost";
import CreateNewStoryPost from "pages/Posts/Post/Components/CreateStoryPost/CreateNewStoryPost";
 
// {"& .css-1agvk75" :{
//   minHeight: "250px"}}
function CreateStoryPostDialogBox(props) {
  // profile={myCurrentProfile} friendshipTab={friendshipTab}
  const navigate = useNavigate();
  const [forceRerender, setForceRerender] = React.useState(true);
  const [currentData,updateContext] = useCurrentUser();
  const {myCurrentProfile, currentGlobalDialogBoxData} =  currentData;
  const [dialogOpened, setDialogOpened] = React.useState(false); 
  
  window.addEventListener("popstate", (event) => {setDialogOpened(false)}) 

  useEffect(async () => { 
    if ( currentGlobalDialogBoxData?.dialogId == "CreateStoryPostDialogBox") {
      let path
      path="/create/story" ;  
      window.history.pushState(null, "  CreateStoryPostDialogBox", path);  
      setDialogOpened(true) 
      } 
  }, [ currentGlobalDialogBoxData]);
 
  useEffect(async () => { 
    let path ="/create/story" ; 
    if ( location.pathname != path) { 
    // if ( location.pathname != "/create") { 
       setDialogOpened(false)
      } 
  }, [ location.pathname]);
 



  return (
    <>
      {((currentGlobalDialogBoxData
        ? currentGlobalDialogBoxData.dialogId
          ? true
          : false
        : false) && myCurrentProfile
        ? true
        : false) && (
        <Dialog 

          open={dialogOpened}
          // TransitionComponent={Transition}
          scroll={"paper"}
          sx={{ "& .MuiDialog-paper": { width: "100%" } }}
          maxWidth="xs"
          onClose={() => {
           setDialogOpened(false) 
           navigate(-1)
         }}
          fullScreen={isMobile ? true : false}
        >
          
           
        <CreateNewStoryPost 
              onClose={() => {
               setDialogOpened(false) 
               navigate(-1)
             }}
          
        />
           
        </Dialog>
      )}
      {forceRerender}
    </>
  );
}

export default CreateStoryPostDialogBox;
