/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import queryString from "query-string";
import MKTypography from "components/MKTypography";
import Typography from "@mui/material/Typography";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import Card from "@mui/material/Card";
import React, { useEffect } from "react";
import {
  ArrowBack,
  ArrowDropDownRounded,
  ArrowForwardIosRounded,
  AttachMoney,
  CalendarViewMonth,
  CheckCircleRounded,
  CurrencyExchange,
  Delete,
  Edit,
  Login,
  Menu,
  Paid,
  Person,
  Place,
  School,
  Sell,
  SwitchAccount,
  Work,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import MKInput from "components/MKInput";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  styled,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import { grey, pink } from "@mui/material/colors";
import { UseAuth } from "Api-Services/useAuth";
import { DataManagementService } from "Api-Services/dataManagementService";
import { Link } from "react-router-dom";
import MKButton from "components/MKButton";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

function SwitchMyProfilesBox(props) {
  const [currentData, updateContext] = useCurrentUser();
  const { currentUser,isUserLoggedIn, myCurrentProfile, myProfiles } = currentData;
  const [forceRerender, setForceRerender] = React.useState(false);
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });

  useEffect(() => {
    // console.log("myCurrentProfile",myCurrentProfile)
  }, [currentUser, myProfiles]);

  return (<>
   {isUserLoggedIn? <DialogContent
    dividers={true}
    sx={{ padding: "0rem", pb: "35px", minHeight: isMobile ? "300px" : "460px" }}
  > 
  
    <>
      {" "}
      <MKBox mb={2} mt={isMobile ? 2 : 2}>
        <StyledBox
          sx={{
            position: "absolute",
            top: -150,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          {/* <Typography sx={{ p: 2, color: "text.secondary" }}>51 results</Typography> */}
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <Skeleton variant="rectangular" height="100%" />

          <Grid container item xs={12} p={1} justifyContent="center">
            <Card sx={{ width: "100%" }}>
              <Grid container alignItems="center">
                <Grid p={1} item xs={12} lg={12}>
                  <MKBox py={1} px={4}>
                    <MKTypography variant="h5" mb={1}>
                      {"Switch Profiles"}
                    </MKTypography>
                  </MKBox>
                  <MKBox pb={{ sm: 0, md: 0 }} px={{ sm: 2, md: 3 }}>
                    <List>
                      {/* <Divider/> */}

                      {myCurrentProfile && (
                        <>
                          <ListItem
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              props?.onClose;
                            }}
                            secondaryAction={
                              <IconButton edge="end" aria-label="delete">
                                <CheckCircleRounded />
                              </IconButton>
                            }
                          >
                            {" "}
                            <ListItemAvatar>
                              <Avatar
                                alt="Remy Sharp"
                                sx={{ width: 30, height: 30 }}
                                src={myCurrentProfile ? myCurrentProfile.profileImageUrl : ""}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              sx={{ cursor: "pointer" }}
                              // secondary={ "see your agencies" }
                              primary={
                                myCurrentProfile.profileName
                                  ? myCurrentProfile.profileName
                                  : myCurrentProfile.firstName + " " + myCurrentProfile.lastName
                              }
                            />
                          </ListItem>
                          <Divider />
                        </>
                      )}

                      {(myProfiles ? (myProfiles.length ? true : false) : false) ? (
                        <>
                          {myProfiles.map((agency, i) => (
                            <>
                              {(myCurrentProfile
                                ? agency.id != myCurrentProfile.id
                                  ? true
                                  : false
                                : true) && (
                                <>
                                  <ListItem
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                      UseAuth.switchProfileById(agency.id);
                                    }}
                                  >
                                    {" "}
                                    <ListItemAvatar>
                                      <Avatar
                                        alt="Remy Sharp"
                                        sx={{ width: 30, height: 30 }}
                                        src={agency ? agency.profileImageUrl : ""}
                                      />
                                    </ListItemAvatar>
                                    <ListItemText
                                      // secondary={ "see your agencies" }
                                      // primary={agency.fullName}
                                      primary={
                                        agency.profileName
                                          ? agency.profileName
                                          : agency.firstName + " " + agency.lastName
                                      }
                                    />
                                  </ListItem>{" "}
                                  <Divider />{" "}
                                </>
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          <ListItem sx={{ m: 2 }}>
                            <ListItemText
                              primary={
                                <MKTypography color={"dark"} variant="h6">
                                  {" No profile created!"}
                                </MKTypography>
                              }
                              secondary={
                                <MKTypography color={"warning"} variant="body2">
                                  {"See below to create new"}
                                </MKTypography>
                              }
                            />
                          </ListItem>
                        </>
                      )}
                      
                      <ListItem
                        sx={{ m: 2 }}
                        onClick={() => {
                          props?.onClose;
                        }}
                        secondaryAction={
                          <IconButton edge="end" aria-label="delete">
                            <ArrowForwardIosRounded />
                          </IconButton>
                        }
                      >
                        <ListItemText
                          // secondary={ "see your agencies" }
                          primary={
                            <MKButton
                              component={Link}
                              to={"/profile/create-new-profile"}
                              sx={{ width: "100%" }}
                              onClick={() => {
                                props?.onClose;
                              }}
                              color="info"
                              variant="outlined"
                              fullwidth
                              startIcon={<Work />}
                            >
                              {" "}
                              Add New Profile / Page  {" "}
                            </MKButton>
                          }
                        />
                      </ListItem>
                    </List>
                  </MKBox>
                </Grid>
              </Grid>
            </Card>

            <Divider />
          </Grid>
        </StyledBox>
      </MKBox>
    </>
  </DialogContent>:null}
  <DialogActions>
          
  <Grid p={1} item xs={12} lg={12}>
                  <MKBox py={1} px={4}>
                    <MKTypography variant="h5" mb={1}>
                      {"Account"}
                    </MKTypography>
                  </MKBox>
                  <MKBox pb={{ sm: 0, md: 1 }} px={{ sm: 2, md: 3 }}>
                    <List>
                      {" "}
                      <ListItem
                        sx={{ m: 2 }}
                        onClick={() => {
                          props?.onClose;
                        }}
                        secondaryAction={
                          <IconButton edge="end" aria-label="delete">
                            <ArrowForwardIosRounded />
                          </IconButton>
                        }
                      >
                        <ListItemText
                          // secondary={ "see your agencies" }
                          primary={
                            <MKButton
                              // component={Link}
                              variant="outlined"
                              onClick={() => {
                                UseAuth.goToAuthLoginPage();
                                // props?.onClose();
                              }}
                              sx={{ width: "100%" }}
                              color="info"
                              fullwidth
                              startIcon={<Login />}
                            >
                              {" "}
                              Login{" "}
                            </MKButton>
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{ m: 2 }}
                        onClick={() => {
                          props?.onClose;
                        }}
                        secondaryAction={
                          <IconButton edge="end" aria-label="delete">
                            <ArrowForwardIosRounded />
                          </IconButton>
                        }
                      >
                        <ListItemText
                          // secondary={ "see your agencies" }
                          primary={
                            <MKButton
                              // component={Link}
                              variant="outlined"
                              onClick={() => {
                                UseAuth.goToAuthRegisterPage();
                                // props?.onClose();
                              }}
                              sx={{ width: "100%" }}
                              color="info"
                              fullwidth
                              startIcon={<Person />}
                            >
                              {" "}
                              Create Account{" "}
                            </MKButton>
                          }
                        />
                      </ListItem>
                      <Divider />
                    </List>
                  </MKBox>
                </Grid>
  </DialogActions>
  
      {forceRerender}
      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{moreText.title}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{moreText.content}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
  
  
  </>
  );
}

export default SwitchMyProfilesBox;
