import React, { useState } from 'react';
import { useCurrentUser } from 'Api-contexts/currentUserContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Container, Grid, TextField, Autocomplete, Card, CircularProgress } from '@mui/material';
import MKButton from 'components/MKButton';
import MKTypography from 'components/MKTypography';
import EducationalHistoriesList from './educationalHistoriesList';
import { HttpService } from 'Api-Services/httpService';

function EducationalHistory() {
  const [addOrEditMode, setAddOrEditMode] = useState(false);
  const [editingEducationalHistoryData, setEditingEducationalHistoryData] = useState(null);
  const [forceRerender, setForceRerender] = useState(false);
 
  const [currentData, updateContext] = useCurrentUser();
  const { myCurrentProfile } = currentData;
  const navigate = useNavigate();
  const params = useParams();
  
  const { data: profilesYouMayKnow } = useQuery({
    queryKey: ['/api/profile/profilesyoumayknow'],
    queryFn: () => HttpService.getApiData('/api/profile/profilesyoumayknow'),
  });

  const { data: educationLevels } = useQuery({
    queryKey: ['/api/educationLevel/all'],
    queryFn: () => HttpService.getApiData('/api/educationLevel/all'),
    initialData: [],
  });

  const { data: cities } = useQuery({
    queryKey: ['/api/masterdata/city/all'],
    queryFn: () => HttpService.getApiData('/api/masterdata/city/all'),
    initialData: [],
  });

  const { data: jobs } = useQuery({
    queryKey: ['/api/job/all'],
    queryFn: () => HttpService.getApiData('/api/job/all'),
    initialData: [],
  });

  const editEducationalHistory = (educationalHistory) => {
    if (educationalHistory) {
      setEditingEducationalHistoryData(educationalHistory);
      setForceRerender(!forceRerender);
      setAddOrEditMode(true);
    }
  };

  const checkAndGoToLoginOrRegisterBox = () => {
    if (myCurrentProfile) {
      return true;
    } else {
      const data = {
        dialogId: 'SwitchProfileDialogBox',
        data: {},
      };
      updateContext('currentGlobalDialogBoxData', data);
      return false;
    }
  };

  return (
    <Container sx={{ my: 5 }}>
      {profilesYouMayKnow?.length > 0 && (
        <>
          <Grid container sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item >
              <MKTypography variant="h4">Your Educational Histories</MKTypography>
            </Grid>
            <Grid item>
              {addOrEditMode ? (
                <MKButton onClick={() => setAddOrEditMode(false)}>
                  Back to Lists
                </MKButton>
              ) : (
                <MKButton onClick={() => {
                  setEditingEducationalHistoryData(null);
                  setAddOrEditMode(true);
                }}>
                  Add New
                </MKButton>
              )}
            </Grid>
          </Grid>
          {!addOrEditMode ? (
            <EducationalHistoriesList editEducationalHistory={editEducationalHistory} profileId={myCurrentProfile?.id} />
          ) : (
            <AddOrEditHistory
              educationLevels={educationLevels}
              jobs={jobs}
              cities={cities}
              editingEducationalHistoryData={editingEducationalHistoryData}
              setEditingEducationalHistoryData={setEditingEducationalHistoryData}
              forceRerender={forceRerender}
              setForceRerender={setForceRerender}
              myCurrentProfile={myCurrentProfile}
              setAddOrEditMode={setAddOrEditMode} // Pass this prop
            />
          )}
        </>
      )}
    </Container>
  );
}

function AddOrEditHistory({
  educationLevels,
  jobs,
  cities,
  editingEducationalHistoryData,
  setEditingEducationalHistoryData,
  forceRerender,
  setForceRerender,
  myCurrentProfile,
  setAddOrEditMode // Add this prop
}) {
  const [educationLevelId, setEducationLevelId] = useState(editingEducationalHistoryData?.EducationLevelId || null);
  const [fieldOfStudyId, setFieldOfStudyId] = useState(editingEducationalHistoryData?.FieldOfStudyId || null);
  const [cityId, setCityId] = useState(editingEducationalHistoryData?.Address?.CityId || null);
  const [schoolName, setSchoolName] = useState(editingEducationalHistoryData?.schoolName || '');
  const [fieldOfStudyTitle, setFieldOfStudyTitle] = useState(editingEducationalHistoryData?.fieldOfStudyTitle || '');
  const [startDate, setStartDate] = useState(editingEducationalHistoryData?.startDate || '');
  const [endDate, setEndDate] = useState(editingEducationalHistoryData?.endDate || '');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const todayDate = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

  const selectedEducationLevel = educationLevels?.find((educationLevel) => educationLevel.id === educationLevelId);
  const isHigherEducation = selectedEducationLevel?.isHigherEducation;

  const handleSave = async () => {
    setLoading(true);
    setError(null); // Reset error state

    try {
      const educationalHistory = {
        id: editingEducationalHistoryData?.id || undefined, // Use addressId if available
        EducationLevelId:educationLevelId,
        FieldOfStudyId: fieldOfStudyId,
        schoolName,
        fieldOfStudyTitle,
        startDate,
        endDate,
      };
      const address = {
        CityId:cityId,
        EducationalHistoryId: editingEducationalHistoryData?.id || undefined,
        id: editingEducationalHistoryData?.Address?.id || undefined, // Use addressId if available
      };
    
      const data = {
        educationalHistory,
        address
      };
    
      // Determine API route based on whether we're editing or creating
      const apiRoute = editingEducationalHistoryData ? '/api/educationalhistory/edit' : '/api/educationalhistory/new';
    
      await HttpService.postApiData(apiRoute, data);

      // Reset form
      setEditingEducationalHistoryData(null);
      setEducationLevelId(null);
      setFieldOfStudyId(null);
      setSchoolName('');
      setFieldOfStudyTitle('');
      setStartDate('');
      setEndDate('');
      setAddOrEditMode(false); // Set addOrEditMode to false on successful save
      // alert('Educational history saved successfully!');
    } catch (error) {
      console.error('Error saving data:', error);
      setError('Failed to save educational history. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setEditingEducationalHistoryData(null);
    setEducationLevelId(null);
    setFieldOfStudyId(null);
    setSchoolName('');
    setFieldOfStudyTitle('');
    setStartDate('');
    setEndDate('');
    
    // Set addOrEditMode to false
    setAddOrEditMode(false);
  };

  return (
    <Card>
      <Container>
        <Grid container sx={{ my: 2 }} justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <MKTypography variant="h4">Edit History</MKTypography>
          </Grid>
          <Grid item>
            <MKButton
              variant="outlined"
              color="success"
              onClick={handleSave}
              disabled={loading}
              startIcon={loading ? <CircularProgress size={24} /> : null}
            >
              {loading ? '' : 'Save'}
            </MKButton>
            <MKButton onClick={handleCancel} disabled={loading}>Cancel</MKButton>
          </Grid>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <MKTypography color="error">{error}</MKTypography>
          </Grid>
        )}

        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={12} lg={6}>
            <Autocomplete
              fullWidth
              id="educationLevel"
              value={educationLevels?.find((educationLevel) => educationLevel.id === educationLevelId) || null}
              onChange={(e, val) => setEducationLevelId(val?.id || null)}
              options={educationLevels || []}
              getOptionLabel={(option) => option.title || ""}
              renderInput={(params) => <TextField {...params} label="Education Level" />}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              value={schoolName}
              onChange={(e) => setSchoolName(e.target.value)}
              label="School or College Name"
            />
          </Grid>
          {isHigherEducation && (
            <>
              <Grid item xs={12} lg={6}>
                <Autocomplete
                  fullWidth
                  id="fieldOfStudy"
                  value={jobs?.find((job) => job.id === fieldOfStudyId) || null}
                  onChange={(e, val) => setFieldOfStudyId(val?.id || null)}
                  options={jobs || []}
                  getOptionLabel={(option) => option.title || ""}
                  renderInput={(params) => <TextField {...params} label="Field of Study" />}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  fullWidth
                  value={fieldOfStudyTitle}
                  onChange={(e) => setFieldOfStudyTitle(e.target.value)}
                  label="Other Field of Study"
                  helperText="If not listed above."
                />
              </Grid>
            </>
          )}


          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              type="date"
              label="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: todayDate,
              }}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              type="date"
              label="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: todayDate,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              id="addressCity"
              value={cities?.find((city) => city.id === cityId) || null}
              onChange={(e, val) => setCityId(val?.id || null)}
              options={cities || []}
              groupBy={(option) => option.REGION || ""}
              getOptionLabel={(option) => option.name || ""}
              renderInput={(params) => <TextField {...params} label="Educated City" />}
            />
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
}

export default EducationalHistory;
