// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { cities, specificAreas, subCities, citiesOnly } from "Api-master-datas/masterDatas.js";
import queryString from "query-string";
 
import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography"; 
// import MKDatePicker from "components/MKDatePicker";
import MKButton from "components/MKButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HttpService } from "Api-Services/httpService";

import ReactGA from "react-ga4";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { 
  AppBar, 
  AvatarGroup, 
  Badge, 
  Box,
  Button, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,  
  Divider,  
  IconButton,  
  InputBase,  
  List,  
  ListItem,  
  ListItemAvatar,  
  ListItemText,  
  Paper,  
  Skeleton,
  Toolbar,
  Typography, 
} from "@mui/material";
import { 
  ArrowBack,
  ArrowForwardIosSharp, 
  Assignment, 
  Bookmark, 
  Comment, 
  Favorite, 
  Folder, 
  ModeComment, 
  PersonAdd, 
  Recommend, 
  RecommendRounded, 
  RecommendTwoTone, 
  Repeat, 
  Reply, 
  Send,
  Visibility, 
} from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices";

import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import PostList from "pages/Posts/Sections/postList";   
import { isMobile } from "react-device-detect";
import ChatsSideBar from "./ChatsList";
import { Avatar, ChatContainer, Conversation, ConversationHeader, ConversationList, ExpansionPanel, InfoButton, Loader, MainContainer, Message, MessageGroup, MessageInput, MessageList, MessageSeparator, Search, Sidebar, TypingIndicator, VideoCallButton, VoiceCallButton } from "@chatscope/chat-ui-kit-react";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { CommonApiServices } from "Api-Services/commonApiServices";
import ChatsList from "./ChatsList";
import { io } from "socket.io-client";
import { InView } from "react-intersection-observer";
import { green } from "@mui/material/colors";
import MKAvatar from "components/MKAvatar";

import TruncateMarkup from "react-truncate-markup";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0.5px solid ${theme.palette.divider}`,
  borderBottom: "0.5px darkgreen solid",
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
}));

// var socket,selectedChatCompare
function Notifications(props) { 
  const [forceRenderer, setForceRenderer] = React.useState(false); 

  const queryClient = useQueryClient()
  const [currentData,updateContex] = useCurrentUser();
  const { myCurrentProfileLoading, socket,connectedSocket, myCurrentProfile } = currentData;
  const [forceRerender, setForceRerender] = React.useState(false); 
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });  
  const [currentMessage, setCurrentMessage] = React.useState("");
  const navigate = useNavigate();  
  const [sentMessage, setSentMessage] = React.useState(null);
  // const [directMessageParticipator, setDirectMessageParticipator] = React.useState(null);
  const params = useParams();
  const searchParams = queryString.parse(location?.search);
  
const [chatContainerStyle, setChatContainerStyle ] = useState({});  
const [isTyping, setIsTyping] = useState(false); 
const [chatId, setChatId] = useState(params?.chatId);
 
useEffect(() => { 
  if (params?.chatId ) { 
    setChatId(params?.chatId); 
    setChatContainerStyle({}); 
  } else { 
    setChatContainerStyle({
      display:"none"
    });
  }
}, [params?.chatId]);
 
   

useEffect(() => {
  try {   
  if(socket&&chatId){
     socket.emit("join chat",chatId) 
 
    socket.on("message recieved",(newMessage)=>{ 
      if(chatId==newMessage?.Chat?.id){
      queryClient.invalidateQueries({ 
        queryKey: ["/api/chat/"+chatId+"/messages" ],
     }) }
    })  
    socket.on("typing",(roomChatId)=>{  
      // console.log("  typing",roomChatId   )
      if(chatId==roomChatId){
      setIsTyping(true)
     }
    })  
    socket.on("stop typing",(roomChatId)=>{  
      // console.log("stop typing",roomChatId   )
      if(chatId==roomChatId){
      setIsTyping(false) 
    }
    })  
    } 
  } catch (error) { 
  }
},[socket,chatId]);

 

  if (params?.jobTab) {
    tabn = params?.jobTab;
  }
 
  let filterData={}  
  try { 
    let searchParamsD = queryString.parse(location?.search); 
    let filter=searchParamsD?.filter
      if(filter?.length){
      filter=JSON.parse(filter)
      } 
        if (searchParamsD||params) {    
         filterData={
          post:filter?.postFilter ,
          marketPlaceListing:filter?.listingFilter,
          marketPlaceVehicle:filter?.vehicleFilter,
          marketPlaceProperty:filter?.propertyFilter,
         }  
      }   
  } catch (error) {
    // console.log("errore ocuuu",error)
  }   
  
  let chatRelativePath="/api/chat/find/"+chatId 
  if(chatId=="new"){
    chatRelativePath="/api/chat/getorcreate" 
  } 
  
  // mynotifications
  const { data: notifications, isFetched :isNotificationsFetched  } = useQuery({
    queryKey: ["/api/notification/mynotifications" ],
    queryFn: async () => { 
      console.log("getMyChats", )
      let c=[]
      let mm= await HttpService.getApiData("/api/notification/mynotifications" );
    
      try { 
      if(mm?.length){
        mm= groupNotifications(mm)//
        c=mm
      }
        // console.log("lastMessageDate",mm)
        // console.log("chats",c)
      } catch (error) {
        // console.log("chats error",error)
        
      }
         return c
    },
    initialData:[]
  });
  
  const handleSend = async (text) => {
    let success=false
    let currentMessageD=currentMessage
    setSentMessage(currentMessageD)
    let reletv= "/api/chat/"+chatId+"/sendmessage"
    let message = {content:currentMessageD} ;
    let resp={}
    if ( text ) { 
      resp= await postDocuments(reletv,message)
      
    socket.emit("new message",{Chat:currentChat,Profile:myCurrentProfile})
      success=true
    }
    return success

  };

  let groupNotifications=(notificationsD)=>{
    let notificationsRest=notificationsD
    let notificationsGrouped=[]
    let checkedNotisId=[]
    if(notificationsRest?.length){
      notificationsRest.forEach((currentNotifi) => { 
        if(!(checkedNotisId?.includes(currentNotifi?.id))){
        checkedNotisId.push(currentNotifi?.id)
        let newGroup={mainNotification:currentNotifi,notifications:[] }
        let newGroupNotifications=[currentNotifi]
        notificationsRest.forEach((checkNotifi,index) => {
        if((currentNotifi.id!=checkNotifi.id)&&(!(checkedNotisId?.includes(checkNotifi?.id)))) {
         if(
              (currentNotifi.EngagedProfileId==checkNotifi.EngagedProfileId)&&
              (currentNotifi.EngagedPostId==checkNotifi.EngagedPostId)&&
              (currentNotifi.EngagementTypeId==checkNotifi.EngagementTypeId)&&
              (currentNotifi.EngagementAreaId==checkNotifi.EngagementAreaId)
          ){
            checkedNotisId.push(checkNotifi?.id)
            newGroupNotifications.push(checkNotifi)
            notificationsRest.splice(index, 1);
            }}
        });
        newGroup.notifications=newGroupNotifications
        notificationsGrouped.push(newGroup)
      }});
    }
            console.log("notificationsGrouped",notificationsGrouped)
            // console.log("currentNotifi",currentNotifi)
    return notificationsGrouped
  }
    

  const {mutate:notificationsMutation} = useMutation({
    mutationFn:handleSend,
    onSettled: () => { 
      // console.log("send message")
      queryClient.invalidateQueries({ 
         queryKey: ["/api/notification/mynotifications"  ],
      })
    },
  })
 
    

  // socket=io(endpoint)

  const jobsLoading = (num) => {
    try {
      return (
        <MKBox  style={{ marginBottom: "10px",position:"fixed",width:"100%" }} sx={{ mx:isMobile?0:4 ,p:isMobile?0:2}}>
          {/* {<Stack spacing={1}> 
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> 
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={60} />
            <Skeleton variant="rounded" width={210} height={60} />
          </Stack>
} */}

          <Grid  container justifyContent={"space-between"}>
            {[...Array(num ? num : 6)].map((n,i) => (  
                      <Grid item 
                      xs={12}
                      // sx={{xs:12,sm:12,lg:12, }}
                      >
                       
                         <Box key={"i"} mb={2} >
                         <Box sx={{ pt: 0.5 }}>
                            <Skeleton />
                            <Skeleton width="80%" />
                          </Box> 
                          <Skeleton variant="rectangular"  height={isMobile?130:110} />
                         
                          </Box>
                      </Grid>  
             ))} 
          </Grid>
        </MKBox>
      );
    } catch (error) {
      return <></>;
    }
  };
  
  
  const postDocuments = async (relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false }; 
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true; 
    } 
    return responseD; 
  };
    
function NotificationBody(propsNotificationBody){

  const [notification, setNotification] = React.useState(propsNotificationBody?.notification); 
  const [mainNotification, setMainNotification] = React.useState(propsNotificationBody?.notification?.mainNotification); 
  const [notificationPhrase, setNotificationPhrase] = React.useState(""); 
  const [notificationMessage, setNotificationMessage] = React.useState(null); 
  const [notificationAvator, setNotificationAvator] = React.useState(null); 
  const [notificationLink, setNotificationLink] = React.useState("/");  
   // let mainNotification=notification?.mainNotification||{}
        
      
useEffect(() => { 
  try {
    
  let mainN=propsNotificationBody?.notification?.mainNotification
  let icon=null
  let phraseD=""
  let notyLink="/"
  if(mainN?.EngagementAreaId==1){
     notyLink="/"
     notyLink=notyLink+""+mainN?.Profile?.userName+"/posts"
     notyLink=notyLink+"/"+mainN?.EngagedPost?.id
     setNotificationLink(notyLink)
     setNotificationMessage(
      
      <React.Fragment>
      <TruncateMarkup
            lines={1}  
          >
        <Typography
          sx={{ display: 'inline' }}
          component="span"
          variant="body2"
          color="text.primary"
        > 
        {parse(mainNotification?.EngagedPost?.title||mainNotification?.EngagedPost?.message)}
        </Typography>
      </TruncateMarkup>
      </React.Fragment>
    )

  }
  else if(mainN?.EngagementAreaId==2){
     notyLink="/"
     notyLink=notyLink+""+mainN?.Profile?.userName+"/friendships"
    //  notyLink=notyLink+"/"+mainN?.EngagedPost?.id
     setNotificationLink(notyLink)

     setNotificationMessage(
      <React.Fragment>
      <TruncateMarkup
            lines={1}  
          >
        <Typography
          sx={{ display: 'inline' }}
          component="span"
          variant="body2"
          color="text.primary"
        > 
        {/* {parse(mainNotification?.EngagedPost?.title||mainNotification?.EngagedPost?.message)} */}
        </Typography>
      </TruncateMarkup>
      </React.Fragment>
    )
  }

    if(mainN?.EngagementType?.title=="reaction"){
          phraseD="reacted on your post"
          setNotificationAvator(<Avatar   
          style={{ padding:"4.2px", backgroundColor: "red", minWidth:  "26px", minHeight:  "26px",   width: "26px", height: "26px" }}>
            <Favorite  style={{color:"white", width: "17px", height: "17px"}}/>
         </Avatar>)
    }
    else if(mainN?.EngagementType?.title=="comment"){
          phraseD="commented on your post"
          setNotificationAvator(<Avatar   
          style={{ padding:"4.2px", backgroundColor: "blue", minWidth:  "26px", minHeight:  "26px",   width: "26px", height: "26px" }}>
            <Comment  style={{color:"white", width: "17px", height: "17px"}}/>
         </Avatar>)
    }
    else if(mainN?.EngagementType?.title=="view"){
          phraseD="viewed your post"
          setNotificationAvator(<Avatar   
          style={{ padding:"4.2px", backgroundColor: "green", minWidth:  "26px", minHeight:  "26px",   width: "26px", height: "26px" }}>
            <Visibility  style={{color:"white", width: "17px", height: "17px"}}/>
         </Avatar>)
    }
    else if(mainN?.EngagementType?.title=="share"){
          phraseD="shared your post"
          setNotificationAvator(<Avatar   
          style={{ padding:"4.2px", backgroundColor: "green", minWidth:  "26px", minHeight:  "26px",   width: "26px", height: "26px" }}>
            <Reply  style={{color:"white", width: "17px", height: "17px"}}/>
         </Avatar>)
    }
    else if(mainN?.EngagementType?.title=="bookmark"){
          phraseD="bookmarked your post"
          setNotificationAvator(<Avatar   
          style={{ padding:"4.2px", backgroundColor: "#F6BE00", minWidth:  "26px", minHeight:  "26px",   width: "26px", height: "26px" }}>
            <Bookmark  style={{color:"white", width: "17px", height: "17px"}}/>
         </Avatar>)
    }
    else if(mainN?.EngagementType?.title=="repost"){
          phraseD="reposted your post"
          setNotificationAvator(<Avatar   
          style={{ padding:"4.2px", backgroundColor: "#F6BE00", minWidth:  "26px", minHeight:  "26px",   width: "26px", height: "26px" }}>
            <Repeat  style={{color:"white", width: "17px", height: "17px"}}/>
         </Avatar>)
    }
    else if(mainN?.EngagementType?.title=="follow"){
          phraseD="followed you"
          setNotificationAvator(<Avatar   
          style={{ padding:"4.2px", backgroundColor: "green", minWidth:  "26px", minHeight:  "26px",   width: "26px", height: "26px" }}>
            <PersonAdd  style={{color:"white", width: "17px", height: "17px"}}/>
         </Avatar>)
    }

  setNotificationPhrase(
    <TruncateMarkup
          lines={2}   >
            <MKTypography
  sx={{ display: 'inline' }}
  component="span"
  variant="subtitle2"
  color="text.primary"
>
    {" "+phraseD}
    </MKTypography>
    </TruncateMarkup> 
    )
  } catch (error) {
    
  }
}, []);  
        
        
  return <>
            <ListItem alignItems="flex-start">
              <ListItemAvatar> 
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={notificationAvator}
              >
               <Avatar alt="Remy Sharp" src={mainNotification?.EngagerProfile?.profileImageUrl} />
              </Badge>    {/* } */}
              </ListItemAvatar>
              <ListItemText style={{cursor:"pointer"}} onClick={()=>navigate(notificationLink)} 
                primary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline', }}
                          component="span"
                          variant="h6"
                          color="text.primary"
                        > {
                            CommonServices?.getProfileName(mainNotification?.EngagerProfile)
                            }  
                        </Typography>
                        {(notification?.notifications?.length>1)? <>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                        {" and "+(notification?.notifications?.length-1)+" others"}
                        </Typography> 
                        
             </>:null} 
                        {notificationPhrase}
                      </React.Fragment> }
                secondary={notificationMessage}
              />
            </ListItem>
            <Divider variant="inset" component="li" /> 
          </>
}
    

  return (
     <Container> 
        <MKBox sx={{ flexGrow: 1, mb:(!isMobile&&!props)?7:0 }}>
          
    <>  
    
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              try { 
              props?.onClose();
              } catch (error) {
                
              }
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Notifications
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
      <Divider variant="inset" component="li" /> 
      <MKBox  >
      {(notifications?.length>1)?
      <List dense={true} sx={{ width: '100%',px:1, bgcolor: 'background.paper' }}>
          { notifications?.map((notification)=><NotificationBody notification={notification} />
           )}
          </List>:null}
        </MKBox>
    </Box> 
      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{parse(moreText.content)}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
 

      {forceRerender}

      {forceRenderer}
    </>
        </MKBox>  </Container>
  );
}

export default Notifications;
