// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { cities, specificAreas, subCities, citiesOnly } from "Api-master-datas/masterDatas.js";
import queryString from "query-string";
 
import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography"; 
// import MKDatePicker from "components/MKDatePicker";
import MKButton from "components/MKButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HttpService } from "Api-Services/httpService";

import ReactGA from "react-ga4";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import AvatarNormal from "@mui/material/Avatar";

import { 
  AppBar, 
  Box,
  Button, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,   
  Skeleton, 
} from "@mui/material";
import { 
  ArrowForwardIosSharp, 
  EmojiEmotions, 
  Send, 
} from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices";

import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import PostList from "pages/Posts/Sections/postList";   
import { isMobile } from "react-device-detect";
import ChatsSideBar from "./ChatsList";
import { AttachmentButton, Avatar, ChatContainer, Conversation, ConversationHeader, ConversationList, ExpansionPanel, InfoButton, Loader, MainContainer, Message, MessageGroup, MessageInput, MessageList, MessageSeparator, Search, SendButton, Sidebar, TypingIndicator, VideoCallButton, VoiceCallButton } from "@chatscope/chat-ui-kit-react";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { CommonApiServices } from "Api-Services/commonApiServices";
import ChatsList from "./ChatsList"; 
import { InView } from "react-intersection-observer";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0.5px solid ${theme.palette.divider}`,
  borderBottom: "0.5px darkgreen solid",
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
}));

// var socket,selectedChatCompare
function ChatBox() {  
  const queryClient = useQueryClient()
  const [currentData,updateContex] = useCurrentUser();
  const { myCurrentProfileLoading, socket,connectedSocket, myCurrentProfile } = currentData;
  const [forceRerender, setForceRerender] = React.useState(false); 
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });  
  const [currentMessage, setCurrentMessage] = React.useState("");
  const navigate = useNavigate();  
  const [sentMessage, setSentMessage] = React.useState(null);
  // const [directMessageParticipator, setDirectMessageParticipator] = React.useState(null);
  const params = useParams();
  const searchParams = queryString.parse(location?.search);
 
 const [sidebarVisible, setSidebarVisible] = useState(false); 
const [chatContainerStyle, setChatContainerStyle ] = useState({});  
const [isTyping, setIsTyping] = useState(false);
const [typing, setTyping] = useState(false);
const [chatId, setChatId] = useState(params?.chatId);
const [chatRelativeData, setChatRelativeData] = useState(null);
// const [conversationHeaderData, setConversationHeaderData] = useState({});
const [chattingWithProfile, setChattingWithProfile] = useState(null);
const [chattingWithPost, setChattingWithPost] = useState(null);
 
useEffect(() => { 
  // if ((chatId)&&(chatId!="new")) { 
    setChatId(params?.chatId);  
  // } 
  // else 
  if(params?.chatId=="new") { 
    if( parseInt(params?.chatTypeId)==1){
      setChatRelativeData({
        // ProfileId:params?.profileId,
        Chat:{
          ChatTypeId:params?.chatTypeId,
        },
        ChatParticipants :[{ProfileId:params?.profileId }]
      }) 
    }
    else if( parseInt(params?.chatTypeId)==2){
      setChatRelativeData({ 
        Chat:{
          ChatTypeId:params?.chatTypeId,
          PostId:params?.profileId,
        },
        ChatParticipants :[{ProfileId:params?.profileId }]
      }) 
    } 
  }
  setForceRerender(!forceRerender)
}, [params,params?.chatId,params?.profileId]);


useEffect(() => { 
  if (chatId ) {  
    setChatContainerStyle({}); 
  } else { 
    setChatContainerStyle({
      display:"none"
    });
  }
}, [chatId]);
 
   

useEffect(() => {
  try {   
  if(socket&&chatId){
     socket.emit("join chat",chatId) 
 
    socket.on("message recieved",(newMessage)=>{ 
      if(chatId==newMessage?.Chat?.id){
      queryClient.invalidateQueries({ 
        queryKey: ["/api/chat/"+chatId+"/messages" ],
     }) }
    })  
    socket.on("typing",(roomChatId)=>{  
      // console.log("  typing",roomChatId   )
      if(chatId==roomChatId){
      setIsTyping(true)
     }
    })  
    socket.on("stop typing",(roomChatId)=>{  
      // console.log("stop typing",roomChatId   )
      if(chatId==roomChatId){
      setIsTyping(false) 
    }
    })  
    } 
  } catch (error) { 
  }
},[socket,chatId]);


const handleTyping = (e) =>{
  if(!socket) return

  if(!typing){
    setTyping(true)
    socket.emit("typing",chatId)
  }
  let lastTypingTime= new Date().getTime()
  var timerLength=3000;
  setTimeout(() => { 
    let timeNow=new Date().getTime()
    let tDiff=timeNow-lastTypingTime
    if(tDiff>=timerLength&&typing){
        setTyping(false)
        socket.emit("stop typing",chatId)
      }
    }, timerLength);


}
 

  if (params?.jobTab) {
    tabn = params?.jobTab;
  }
 
  let filterData={}  
  try { 
    let searchParamsD = queryString.parse(location?.search); 
    let filter=searchParamsD?.filter
      if(filter?.length){
      filter=JSON.parse(filter)
      } 
        if (searchParamsD||params) {    
         filterData={
          post:filter?.postFilter ,
          marketPlaceListing:filter?.listingFilter,
          marketPlaceVehicle:filter?.vehicleFilter,
          marketPlaceProperty:filter?.propertyFilter,
         }  
      }   
  } catch (error) {
    // console.log("errore ocuuu",error)
  }   
  
  let chatRelativePath="/api/chat/find/"+chatId 
  if(chatId=="new"){
    chatRelativePath="/api/chat/getbyfield" 
  }

  const { data: currentChat,isChatFetched  } = useQuery({
    queryKey: [chatRelativePath,chatRelativeData],
    queryFn: async () => {
      let mesgs=null
      let secProfile=null
      if(chatId) {
        if(chatId=="new") { 
          mesgs= await HttpService.postApiData(chatRelativePath,chatRelativeData);
          if(mesgs?.id){
          setChatId(mesgs?.id)
        }}
        else{
          mesgs= await HttpService.getApiData(chatRelativePath);
        } 
        if(!mesgs) { 
        let chatRelativeDataD=chatRelativeData
        let chatParts=[] 
        let chatD=chatRelativeDataD?.Chat 
          // console.log("secProfile 1",params )
          if(params?.chatTypeId==1){
              secProfile= await HttpService.getApiData("/api/profile/find/"+params?.profileId);
              setChattingWithProfile(secProfile)
              if(secProfile?.id){
               chatParts=[{ProfileId:secProfile?.id}
              //  ,{ProfileId:myCurrentProfile?.id}
               ]
              }
          }
          if(params?.chatTypeId==2){
              secProfile= await HttpService.getApiData("/api/post/find/"+params?.profileId);
              setChattingWithPost(secProfile)
              if(secProfile?.id){
                chatD["name"]=CommonServices?.getProfileName(myCurrentProfile)+" - "+ secProfile?.title
                chatD["PostId"]=secProfile?.id
                chatParts=[{ProfileId:secProfile?.Profile?.id},
              //  {ProfileId:myCurrentProfile?.id}
               ]
              }
          }
          chatRelativeDataD["ChatParticipants"]=chatParts
          chatRelativeDataD["Chat"]=chatD
          setChatRelativeData(chatRelativeDataD)
        }
        setSentMessage(null)
      }
        // console.log("chatId",chatId)
        // console.log("currentChat",mesgs)
        // console.log("secProfile",secProfile)
        // constructChatData(mesgs)
      return mesgs
    },
    // initialData:{}
  }); 
  

 
   

  const conversationHeaderComp = () => { 
    let conversationHeaderDataD={}
    //  useEffect(() => {
     try {  
          let currentChatD= currentChat
      // console.log("chatId",chatId)
      // console.log("params", params)
        if( currentChatD){ 
          let isGroup=(currentChatD?.ChatTypeId!=1)?true:false/// currentChatD?.participants?.length>2
          if(isGroup){
            conversationHeaderDataD={
                name:currentChatD?.name,
                src:"Group",
                // info:"Active 10 mins ago" ,
                // userName:"Group",
                userName:currentChatD?.name,
            }  
          }
          else{
            let secondProfile={}
            currentChatD?.participants?.forEach(element => {
              if(element.id!=myCurrentProfile?.id){
                secondProfile=element
              }
            });
            conversationHeaderDataD={
                name:CommonServices?.getProfileName(secondProfile),
                src:secondProfile?.profileImageUrl,
                info:"Active 10 mins ago" ,
                userName: CommonServices?.getProfileName(secondProfile) ,
            }  
          }
        }
        else if( (parseInt(params?.chatTypeId)==1)&&(chatId=="new")){
            conversationHeaderDataD={
              name:CommonServices?.getProfileName(chattingWithProfile),
              src:chattingWithProfile?.profileImageUrl,
              info:"Active 10 mins ago" ,
              userName: CommonServices?.getProfileName(chattingWithProfile) ,
          }  
        }
        else if( (parseInt(params?.chatTypeId)==2)&&(chatId=="new")){
             conversationHeaderDataD={
              name:chattingWithPost?.title,
              src:chattingWithPost?.Attachments[0]?.url,
              // info:"Active 10 mins ago" ,
              userName:chattingWithPost?.title ,
          }  
        }
        
        } catch (error) {
            // console.log(error, "conversationHeaderProps errrrrr")
          } 
       let conversationHeaderData=conversationHeaderDataD
  

          return (
            <ConversationHeader>
             <ConversationHeader.Back onClick={()=>navigate("/chats")} /> 
              <Avatar
                 // name={conversationHeaderData?.name}
                 src={conversationHeaderData?.src} 
               /> 
               <ConversationHeader.Content
                 info={conversationHeaderData?.info} 
                 userName={conversationHeaderData?.userName}  
               /> 
             <ConversationHeader.Actions>
               <VoiceCallButton />
               <VideoCallButton />
               <InfoButton />
             </ConversationHeader.Actions>
           </ConversationHeader>)
  };
  
  const handleSend = async (text) => {
    let resp={}
    let success=false
    try {
          
        let currentMessageD=currentMessage
        setSentMessage(currentMessageD)
        let message=null
        let reletv= null
        if(chatId=="new"){
          message=chatRelativeData
          reletv= "/api/chat/new/sendmessage"
          message.content=currentMessageD
        }
        else{  
        reletv= "/api/chat/"+chatId+"/sendmessage"
        message = {content:currentMessageD} ;
        }
        if ( reletv &&message ) { 
          resp= await HttpService.postApiData(reletv,message)
          socket.emit("new message",{Chat:currentChat,Profile:myCurrentProfile})
          // console.log("sent msg  resp",resp)
        if((chatId=="new")&&(resp?.ChatId)){ 
          // console.log("sent msg  navigate",chatId,resp?.ChatId)
          navigate("/chats/"+params?.chatTypeId+"/"+resp?.ChatId)
          }
          success=true
        }
    } catch (error) {
      
      // console.log("sent msg  error",error)
    }
    return success
  };
  
   

  const { data: messages,isFetched  } = useQuery({
    queryKey: ["/api/chat/"+chatId+"/messages" ],
    queryFn: async () => {
      // console.log("fetch messages")
      let mesgs=[]
      if(chatId!="new") {
       mesgs= await HttpService.getApiData("/api/chat/"+chatId+"/messages"  );  
        setSentMessage(null)
      }
      return mesgs
    },
    initialData:[]
  });

  const {mutate:messagesMutation} = useMutation({
    mutationFn:handleSend,
    onSettled: () => { 
      // console.log("send message")
      queryClient.invalidateQueries({ 
         queryKey: ["/api/chat/"+chatId+"/messages" ],
      })
    },
  })
 
    

  // socket=io(endpoint)

  const jobsLoading = (num) => {
    try {
      return (
        <MKBox  style={{ marginBottom: "10px",position:"fixed",width:"100%" }} sx={{ mx:isMobile?0:4 ,p:isMobile?0:2}}>
          {/* {<Stack spacing={1}> 
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> 
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={60} />
            <Skeleton variant="rounded" width={210} height={60} />
          </Stack>
} */}

          <Grid  container justifyContent={"space-between"}>
            {[...Array(num ? num : 6)].map((n,i) => (  
                      <Grid item 
                      xs={12}
                      // sx={{xs:12,sm:12,lg:12, }}
                      >
                       
                         <Box key={"i"} mb={2} >
                         <Box sx={{ pt: 0.5 }}>
                            <Skeleton />
                            <Skeleton width="80%" />
                          </Box> 
                          <Skeleton variant="rectangular"  height={isMobile?130:110} />
                         
                          </Box>
                      </Grid>  
             ))} 
          </Grid>
        </MKBox>
      );
    } catch (error) {
      return <></>;
    }
  };
  
  
  const postDocuments = async (relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false }; 
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true; 
    } 
    return responseD; 
  };
   
  function MessageBody(messageProps){ 
    
  const [message, setMessage] = React.useState(messageProps?.message); 
  const [index, setIndex] = React.useState(messageProps?.index); 
  const [messageComponent, setMessageComponent] = React.useState([]); 
  const [isMessageSeen, setIsMessageSeen] = React.useState(messageProps?.message?.seen); 

  const makeMassegeAsSeen = async (msgId) => {
    let success=false 
    if (!isMessageSeen&&msgId) {  
    let reletv= "/api/chat/"+chatId+"/message/"+msgId +"/makeseen"
      let mesRes= await HttpService.getApiData(reletv);
    // socket.emit("new message",{Chat:currentChat,Profile:myCurrentProfile})
      success=mesRes?true:false
    }
    setIsMessageSeen(success) 
  };

  
    useEffect(() => {
          try {
              let messageD=[] 
              let positioinD="single"
                if((index>0)&&(messages?.length>index)){
                  if((message?.ProfileId==messages[index-1]?.ProfileId)&&(message?.ProfileId==messages[index+1]?.ProfileId)){
                    positioinD=  'normal'//||'first'||'single'||'last',
                  }
                  if((message?.ProfileId!=messages[index-1]?.ProfileId)&&(message?.ProfileId!=messages[index+1]?.ProfileId)){
                    positioinD= 'single'//||'last',
                  }
                  if((message?.ProfileId==messages[index-1]?.ProfileId)&&(message?.ProfileId!=messages[index+1]?.ProfileId)){
                    positioinD= 'last'//||'last',
                  }
                  if((message?.ProfileId!=messages[index-1]?.ProfileId)&&(message?.ProfileId==messages[index+1]?.ProfileId)){
                    positioinD= 'first'//||'last',
                  } 
                } 
              messageD.push(
                <InView as="div" onChange={(inView, entry) =>{ 
                  if(inView&&(message?.ProfileId!=myCurrentProfile?.id)&&(!isMessageSeen)){
                    // console.log("Inview seen:", inView) 
                    makeMassegeAsSeen(message?.id)
                  }
                  // console.log("Inview:", inView)
                }}
                  >
                  
              </InView>
              )
              messageD.push( <Message
                key={message?.id}
          avatarSpacer={!(positioinD=="single"||positioinD=="last"||(message?.ProfileId==myCurrentProfile?.id))}
              model={{
                direction: (message?.ProfileId==myCurrentProfile?.id)?'outgoing':'incoming',
                message: message?.content,
                position:positioinD,
                // position: 'normal'||'first'||'single'||'last',
                sender: CommonServices?.getProfileName(message?.Profile),
                sentTime: '15 mins ago'
              }}
            >
            {((message?.ProfileId!=myCurrentProfile?.id)&&(positioinD=="single"||positioinD=="last"))?<Avatar
                name={CommonServices?.getProfileName(message?.Profile)}
                src={message?.Profile?.profileImageUrl}
              />:null}
              
               { (message?.Attachments?.length>0)&&
               <Message.ImageContent src={message?.Profile?.profileImageUrl} alt="Akane avatar" width={200}/>}
            </Message>)
          setMessageComponent(messageD)
          } catch (error) {
            // console.log(error, "mesage body errrrrr")
          } 
      },[messageProps] )

      return messageComponent
    
    }


  return (
    <>  
  
       {/* {currentChat?.ChatTypeId&& */}
       {
        <ChatContainer  style={chatContainerStyle}>
        {conversationHeaderComp()}
   <MessageList typingIndicator={isTyping?<TypingIndicator content="typing" />:null}>
      {/* <MessageSeparator content="Saturday, 30 November 2019" /> */}
      
      {/* <Message
        avatarSpacer
        model={{
          direction: 'incoming',
          message: 'Hello my friend',
          position: 'first'||'single',
          sender: 'Zoe',
          sentTime: '15 mins ago'
        }}
      />   */}
     {messages?.map((message,index)=><MessageBody message={message} index={index} />)} 
      
    {sentMessage&& <Message
        model={{
          direction: 'outgoing' ,
          message:sentMessage,
          position:"last",
          // position: 'normal'||'first'||'single'||'last',
          sender: CommonServices?.getProfileName(myCurrentProfile),
          sentTime: ''
        }}
      > 
      </Message>}
    </MessageList> 
    {/* { <Loader />
    } */}
    
    <div as={MessageInput} style={{display:"flex", flexDirection:"row", borderTop: "1px dashed #d1dbe4"}}>
    
      <EmojiEmotions  sx={{ width: 24, height: 24,my:1.2,ml:0.5 , color:"lightskyblue"}} style={{fontSize:"20px",}}
        onClick={() => alert("Important message!")} /> 
    <MessageInput
      
      // onAttachClick={()=>console.log("Add attachment button clicked")} 
      onChange={(e)=>{ handleTyping(e)
        // console.log("e",e)
        setCurrentMessage(e)
      }}  
      onSend={messagesMutation } 
      placeholder="Type message here"
        // ref={inputRef} 
        // value={msgInputValue}
        sendButton={currentMessage?.length?true:false}
        attachButton={false} 
        style={{ flexGrow: 1, borderTop: 0, flexShrink:"initial",  }} />
      {/* <SendButton onClick={() => messagesMutation(currentMessage)} disabled={currentMessage?.length === 0} style={{fontSize:"1.2em", marginLeft:0, paddingLeft: "0.2em", paddingRight:"0.2em"}} />
     */}
      {!currentMessage?.length&& <AttachmentButton style={{fontSize:"1.2em", paddingLeft: "0.2em", paddingRight:"0.5em"}}
        onClick={()=>console.log("Add attachment button clicked")}  />}
   
    </div>
    {/* <MessageInput 
        onAttachClick={()=>console.log("Add attachment button clicked")} 
        onChange={(e)=>{ handleTyping(e)
          // console.log("e",e)
          setCurrentMessage(e)
        }}  
        onSend={messagesMutation } 
        placeholder="Type message here" /> */}
  </ChatContainer> }
 {/* { currentChat?.ChatTypeId&&<Sidebar position="right">
    <ExpansionPanel
      open
      title="INFO"
    >
      <p>
        Lorem ipsum
      </p>
      <p>
        Lorem ipsum
      </p>
      <p>
        Lorem ipsum
      </p>
      <p>
        Lorem ipsum
      </p>
    </ExpansionPanel>
    <ExpansionPanel title="LOCALIZATION">
      <p>
        Lorem ipsum
      </p>
      <p>
        Lorem ipsum
      </p>
      <p>
        Lorem ipsum
      </p>
      <p>
        Lorem ipsum
      </p>
    </ExpansionPanel>
    <ExpansionPanel title="MEDIA">
      <p>
        Lorem ipsum
      </p>
      <p>
        Lorem ipsum
      </p>
      <p>
        Lorem ipsum
      </p>
      <p>
        Lorem ipsum
      </p>
    </ExpansionPanel>
    <ExpansionPanel title="SURVEY">
      <p>
        Lorem ipsum
      </p>
      <p>
        Lorem ipsum
      </p>
      <p>
        Lorem ipsum
      </p>
      <p>
        Lorem ipsum
      </p>
    </ExpansionPanel>
    <ExpansionPanel title="OPTIONS">
      <p>
        Lorem ipsum
      </p>
      <p>
        Lorem ipsum
      </p>
      <p>
        Lorem ipsum
      </p>
      <p>
        Lorem ipsum
      </p>
    </ExpansionPanel>
  </Sidebar>} */} 

      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{parse(moreText.content)}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
 

      {forceRerender} 
    </>
  );
}

export default ChatBox;
