// @mui material components
import React, { useEffect } from "react";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { Dialog, DialogContent, Drawer, Slide } from "@mui/material";
import { isMobile } from "react-device-detect"; 
import PostComments from "./Sections/PostComments";

// {"& .css-1agvk75" :{
//   minHeight: "250px"}}
function PostCommentsDialogBox(props) {
  // profile={myCurrentProfile} friendshipTab={friendshipTab}
  const [currentData] = useCurrentUser();
  const [forceRerender, setForceRerender] = React.useState(true); 
  const [currentProfile, setCurrentProfile] = React.useState(null);
  const {myCurrentProfile, currentGlobalDialogBoxData} =  currentData;
  const [dialogOpened, setDialogOpened] = React.useState(false); 

  window.addEventListener("popstate", (event) => {setDialogOpened(false)})
  useEffect(async () => {
    let profileR = null;
    if (currentGlobalDialogBoxData) {
      if (currentGlobalDialogBoxData.data) {
        let dataDD = currentGlobalDialogBoxData.data;
        if (dataDD.profile) {
          profileR = dataDD.profile;
        }
        setCurrentProfile(profileR);
        setForceRerender(!forceRerender);
      }
    }
    // console.log("SideBarMenuDialogBox profileR", profileR);

    setForceRerender(!forceRerender);
  }, [currentGlobalDialogBoxData]);

  return (
    <>
      {((currentGlobalDialogBoxData ? (currentGlobalDialogBoxData.dialogId ? true : false) : false)
        ? true
        : false) && (
        <>
          {/* {isMobile ? ( */}
            <Dialog
              open={dialogOpened}
              // TransitionComponent={Transition}
              // TransitionComponent={Transition}
              scroll={"paper"}
              sx={{ "& .MuiDialog-paper": { width: "100%" } }}
              maxWidth="xs"
              onClose={() => {
                setDialogOpened(false) 
                navigate(-1)
              }}
              fullScreen={isMobile ? true : false}
            >
              {/* {currentGlobalDialogBoxData.data && (
            <> */}
              <PostComments
                onClose={() => {
                  setDialogOpened(false) 
                  navigate(-1)
                }}
                currentGlobalDialogBoxData={currentGlobalDialogBoxData}
                currentProfile={currentProfile}
              />
              {/* </>
          )} */}
            </Dialog>
          {/* // ) : (
          //   <Drawer
          //     open={currentGlobalDialogBoxData.dialogId == "PostCommentsDialogBox"}
          //     // TransitionComponent={Transition}
          //     scroll={"paper"}
          //     anchor={"right"}
          //     sx={{ "& .MuiDialog-paper": { width: "100%" } }}
          //     // maxWidth="xs"
          //     onClose={() => setCurrentGlobalDialogBoxData(null)}
          //     fullScreen={isMobile ? true : false}
          //   > 
          //     <PostComments
          //       onClose={() => setCurrentGlobalDialogBoxData(null)}
          //       currentGlobalDialogBoxData={currentGlobalDialogBoxData}
          //       currentProfile={currentProfile}
          //     /> 
          //   </Drawer>
          // )} */}
        </>
      )}
      {forceRerender}
    </>
  );
}

export default PostCommentsDialogBox;
