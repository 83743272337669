 
import React, { useEffect } from "react";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { 
  Dialog, 
  DialogContent, 
  Drawer, 
  Slide, 
} from "@mui/material"; 
import { isMobile } from "react-device-detect";  
import { useNavigate } from "react-router-dom"; 
import ChatsList from "./Sections/ChatsList";
 
 
function MessageDialogBox(props) { 
  const navigate= useNavigate(); 
  const [forceRerender, setForceRerender] = React.useState(true);
  const [currentData,updateContext] = useCurrentUser();
  const {myCurrentProfile, currentGlobalDialogBoxData} =  currentData;
  const [dialogOpened, setDialogOpened] = React.useState(false); 
   
  window.addEventListener("popstate", (event) => {{
    setDialogOpened(null) 
  }})
  useEffect(async () => { 
    if ( currentGlobalDialogBoxData?.dialogId == "MessageDialogBox") {
      let path
      path="/chats" ; 
      window.history.pushState(null, "Profile MessageDialogBox", path); 
      setDialogOpened(true)  
      } 
  }, [ currentGlobalDialogBoxData]);
 
  useEffect(async () => { 
    if ( location.pathname != "/chats") { 
       setDialogOpened(false)
      } 
  }, [ location.pathname]);
 
  return (
    <>
      {((currentGlobalDialogBoxData
        ? currentGlobalDialogBoxData.dialogId
          ? true
          : false
        : false) && myCurrentProfile
        ? true
        : false) && (<>{isMobile?
        <Dialog
          open={dialogOpened}
          // TransitionComponent={Transition}
          scroll={"paper"}
          sx={{ "& .MuiDialog-paper": { width: "100%" } }}
          maxWidth="xs"
          onClose={() => {
            setDialogOpened(false) 
            navigate(-1)
          }}
          fullScreen={isMobile ? true : false}
        >
          {/* {currentGlobalDialogBoxData.data && (
            <> */}
          {/* <FriendshipHeader
            onClose={() => setCurrentGlobalDialogBoxData(null)}
            changeFriendshipTab={(taab) => {
              setFriendshipTab(taab);
            }}
            currentGlobalDialogBoxData={currentGlobalDialogBoxData}
            currentProfile={currentProfile}
            friendshipTab={friendshipTab}
          /> */} 
          <DialogContent dividers={true} sx={{ pb: "75px",}}>
            <ChatsList
                onClose={() => {
                setDialogOpened(false) 
                navigate(-1)
              }}
              currentGlobalDialogBoxData={currentGlobalDialogBoxData}
              // currentProfile={currentProfile}
              // friendshipTab={friendshipTab}
            />
          </DialogContent>
          {/* </>
          )} */}
        </Dialog>:
            <Drawer
              open={dialogOpened}
              // TransitionComponent={Transition}
              scroll={"paper"}
              anchor={"right"}
              sx={{ "& .MuiDialog-paper": { width:"400px" },
                   "& .css-1160xiw-MuiPaper-root-MuiDrawer-paper":{ width:"400px" }, }}
              // maxWidth="xs" 
              onClose={() => {
                setDialogOpened(false) 
                navigate(-1)
              }}
              fullScreen={isMobile ? true : false}
            >
                <ChatsList
                onClose={() => {
                setDialogOpened(false) 
                navigate(-1)
              }}
              currentGlobalDialogBoxData={currentGlobalDialogBoxData}
              // currentProfile={currentProfile}
              // friendshipTab={friendshipTab}
            />
              </Drawer>}</>
      )}
      {forceRerender}
    </>
  );
}

export default MessageDialogBox;
