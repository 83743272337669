/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKEditor from "components/MKEditor";
import MKInput from "components/MKInput";

// NewProduct page components
// import TextField  from "layouts/ecommerce/products/new-product/components/TextField ";
// import { employmentTypes } from "Api-master-datas/masterDatas";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Checkbox, Chip, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, InputBase, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useCurrentUser } from "Api-contexts/currentUserContext";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MKEditor from "components/MKEditor";
import { makeStyles } from "@mui/styles";
import { CheckBox, ExpandLess, ExpandMore, TagFaces, Work } from "@mui/icons-material";
import Search from "@mui/icons-material/Search";
import { CommonServices } from "Api-Services/commonServices";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const postStylesDropzone = makeStyles((theme) => ({
  root: {
    "& .css-9igssw-MuiDialogContent-root": {
      padding: "5px",
    },
    "& .ql-toolbar.ql-snow ": {
      padding: "0px",
    },
  },
  dropzoneClass: {
    // maxHeight: "124px  !important",
    minHeight: "5px !important",
    "& .css-f8f0pk-MuiSvgIcon-root ": {
      height: "30px",
      width: "30px",
    },
    "& .css-1g96e5k-MuiTypography-root": {
      fontSize: "0.9rem",
      fontWeight: "700",
      marginTop: "4px",
      marginBottom: "2px",
    },
  },
  postTextArea: {
    "& .ql-editor ": {
      minHeight: "120px",
    },
  },
}));
function SelectYourJobCatagory(props) {
  const postStyles =postStylesDropzone()
  const [searchTab, setSearchTab] = useState("search");
  const [forceRerender, setForceRerender] = useState(false);
  const [sectionValues, setSectionValues] = useState(props.sectionValues);
  const [jobCategories, setJobCategories] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [selectedjobs, setSelectedjobs] = useState([]);
  const [openedDialog,setOpenedDialog]=useState(false)
  const [openedCatagoryId, setOpenedCatagoryId] = useState(false);
  const [currentData, updateContext] = useCurrentUser();
  const [currentCatagory, setCurrentCatagory] = useState([]);
  const { } =
    currentData; 
  useEffect(() => {
    // console.log("props.sectionValues", jobs);
    // props.validateSection(props.sectionValues);
  }, []);

  useEffect(() => { 
    setJobCategories(props?.jobCategories||[]);
    setJobs(props?.jobs||[]); 
  }, [props?.jobCategories,props?.jobs]);

  useEffect(() => {  
    setSelectedjobs(props?.selectedjobs||[]); 
  }, [props?.selectedjobs]);
 
  
  const checkIfSelected = (data) => {
    let res=false
    // console.log("fffff",field, value)  
    let sl= selectedjobs?.filter((item)=>data?.id==item?.id)
    if(sl?.length){
      res=true
    }
    return res
  };
 
    const addNewJobs = (selectedNewJobs) => {
      // setForceRerender(!forceRerender);
      // let sf=selectedjobs
      let newsf=  selectedjobs.concat(selectedNewJobs)
      setSelectedjobs(newsf)
      setForceRerender(!forceRerender)
      props?.addNewJobs(selectedNewJobs)
      // props.changeSectionValues("fieldOfStudySectionValues", sectionValuesD);
    };
 

  function JobCategoryComp(){
    const [forceRerender2, setForceRerender2] = useState(false);
    const [selectedModelFields, setSelectedModelFields] = useState([]);
 
    const changeSelectedModalFields = (data, checked) => {
      let selectedFieldsD = selectedModelFields;
        selectedFieldsD = selectedFieldsD.filter(item => item?.id !== data?.id)
      if(checked){
        selectedFieldsD?.push(data)
      } 
      setSelectedModelFields(selectedFieldsD)
      setForceRerender2(!forceRerender2)
    };

 
    
    return (  <>
      <DialogContent>
        {forceRerender2}
       <MKBox>
          {currentCatagory?.Jobs?.length? 

           <List >
          {currentCatagory?.Jobs?.map((jobM,indexx)=>(jobM?.title)&&
             <ListItem
                  key={indexx}
                  onChange={(e)=>{changeSelectedModalFields(jobM,e?.target?.checked)}}
                  secondaryAction={
                    <Checkbox
                      edge="end"
                      // checked={false}
                      defaultChecked={checkIfSelected(jobM)}
                      inputProps={{ 'aria-labelledby': indexx }}
                    />
                  }
                  disablePadding
                >
                <ListItemButton 
                  onClick={() => {
                  let data={
                    listingTypeName:jobM?.title,
                    listingTypeId:jobM?.id
                  };
                  // goToListing(data);
                }}
                sx={{ pl: 1}}> 
                  <ListItemText primary={
                <Typography
                  sx={{ fontWeight: "500", fontSize: "0.9rem" }}
                  variant="body2"
                >
                  {jobM?.title}
                </Typography>} />
                </ListItemButton>
                <Divider sx={{ my: 0,py:0 }} variant="middle" />
                 
             </ListItem>)}
           </List> :null}
        </MKBox> 
         </DialogContent>
         <DialogActions>
          <Button
            onClick={() => {
              addNewJobs(selectedModelFields)
              setOpenedDialog("");
            }}
          >
            Done
          </Button>
        </DialogActions>
       </> )
  }

  function SearchJobComp(){
    const [forceRerender2, setForceRerender2] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [selectedFields, setSelectedFields] = useState(props?.sectionValues?.fieldOfStudies?.value||[]);
 
    const onSearch=(keyWord)=>{
      try {
        console.log("dddddd",jobs,)
        let res=jobs?.filter((item)=>{
        let keyWordD=keyWord?.toLowerCase()
        let titleD=item?.title?.toLowerCase()
        return titleD?.indexOf(keyWordD)>-1
      }
      )
      console.log("dddddd",jobs,res)
      setSearchResult(res)
      setForceRerender2(!forceRerender2)
      } catch (error) {
      console.log("errrrrrrrr",error)
      }
    }

    return (  <> 
        {forceRerender2}
        <Paper
      // component="form"
      // sx={{ p: '2px 4px', display: 'flex', alignItems: 'center',}}
      square={false}
      elevation={0}
      shadow={"none"}
      sx={{
        mx: 2,
        mt: 0.5,
        mb: 3,
        backgroundColor: "#f0f2f5",
        // mr: "6px",
        display: "flex",
        // alignItems: "center",
        // width: "158px",
         width: "95%" 
        // width: "min-content",
      }}
    > 
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <Search />
      </IconButton> 
      <InputBase 
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search field here"
         autoFocus={true}
        onChange={(e)=>onSearch(e?.target?.value)}
        inputProps={{ 'aria-label': 'search google maps' }}
      />
    </Paper>
       <MKBox> 
           <List >
          {searchResult?.map((jobM,indexx)=>(jobM?.title)&&
             <ListItem
                  key={indexx} 
                  secondaryAction={
                     <Button 
                     onClick={(e)=>{addNewJobs([jobM])}}>Add</Button>
                  }
                  disablePadding
                >
                <ListItemButton 
                  onClick={() => {
                  let data={
                    listingTypeName:jobM?.title,
                    listingTypeId:jobM?.id
                  };
                  // goToListing(data);
                }}
                sx={{ pl: 4 }}> 
                  <ListItemText primary={
                <Typography
                  sx={{ fontWeight: "500", fontSize: "0.9rem" }}
                  variant="body2"
                >
                  {jobM?.title}
                </Typography>} />
                </ListItemButton>
             </ListItem>)}
           </List> 
        </MKBox> 
         
       </> )
  }

  return (
    <>
      {forceRerender} 
            {/* <DialogContentText></DialogContentText> */}
      
      <MKBox>
        <MKBox mt={2}> 
        <MKTypography variant="h5">Job category / field of studies</MKTypography>   
        </MKBox>
        <MKBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
            <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
    <Grid direction="row" spacing={1}>
      {selectedjobs?.map((data,indx) => {
  
        return ( 
          <>
           { data&&<Chip sx={{mb:0.5,mr:0.5}}
              // icon={icon}
              // label={ "rr"}
              color="success" 
              variant="outlined"
              label={data?.title }
              // label={CommonServices?.getDataFromArray(props?.jobs,  "id", data, "single", "title")||""}
              onDelete={()=>{
                let sj=selectedjobs?.filter((item)=>item?.id!=data?.id)
                setSelectedjobs(sj)
                setForceRerender(!forceRerender)
                props?.removeJobs([data?.id])
                // let sssd=selectedjobs.filter(item=>item!=data)
                // changeInputValue("fieldOfStudies",sssd)
              }}

            />}</> 
        );
      })}
          </Grid>
    </Paper>
            </Grid>
           <Grid item xs={12} sm={12} sx={{ width: '100%' }}>
            <Tabs
              value={searchTab}
              onChange={(e,v)=>setSearchTab(v)}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab value="search" label="Search" /> 
              <Tab value="category" label="Categories" />
            </Tabs>
          </Grid>


          {(searchTab=="search")&&<Grid item xs={12} sm={12}>
               <SearchJobComp/>
              </Grid>}
            {(searchTab=="category")&&<Grid item xs={12} sm={12}>

            <MKBox  >  
                    {(jobCategories ? (jobCategories?.length ? true : false) : false) && (
                    <MKBox xs={12} p={1} justifyContent="left">
                      
                <List sx={{width:"100%"}}
                      // subheader={
                      //   <ListSubheader sx={{ backgroundColor: "inherit" }}>
                      //     {"Catagories"}
                      //   </ListSubheader>
                      // }
                    >
                      { 
                        jobCategories?.map((jobCategory) => (<>
                      
                          
                    <ListItemButton  onClick={() => {
                      setCurrentCatagory(jobCategory)
                      setOpenedDialog("selectJobs")
                      setForceRerender(!forceRerender)
                                            // setOpenedCatagoryId((openedCatagoryId==jobCategory?.id)?false:jobCategory?.id)
                                            // let data={
                                            //   listingTypeName:jobCategory?.pathName,
                                            //   listingTypeId:jobCategory?.id
                                            // };
                                            // goToListing(data);
                                          }}>
                                <ListItemIcon> 
                                          <Avatar size="small">
                                            <Work/>
                                          </Avatar> 
                                </ListItemIcon>
                                <ListItemText primary={
                                  <Typography
                                    sx={{ fontWeight: "500", fontSize: "0.9rem" }}
                                    variant="body2"
                                  >
                                    {jobCategory.title}
                                  </Typography>
                                }/>
                          {(openedCatagoryId==jobCategory?.id)?  <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>  
                        </>))}

                      <Divider sx={{ my: 1 }} variant="middle" />
                    </List> 
                    </MKBox>
                    )}   
              </MKBox>

              </Grid>}
          </Grid>
        </MKBox>
      </MKBox>



       
      <Dialog
        open={openedDialog == "selectJobs"}
        scroll='paper'
        onClose={() => {
          setOpenedDialog("");
        }}
      >   
      <DialogTitle>Select {currentCatagory?.title} Jobs</DialogTitle>
      
          <JobCategoryComp />
      </Dialog>
 
    </>
  );
}

export default SelectYourJobCatagory;
