// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { cities, specificAreas, subCities, citiesOnly } from "Api-master-datas/masterDatas.js";
import queryString from "query-string";
 
import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography"; 
// import MKDatePicker from "components/MKDatePicker";
import MKButton from "components/MKButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HttpService } from "Api-Services/httpService";

import ReactGA from "react-ga4";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { 
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar, 
  AvatarGroup, 
  Badge, 
  Box,
  Button, 
  Collapse, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,  
  Divider,  
  FormControl,  
  FormControlLabel,  
  FormGroup,  
  FormLabel,  
  IconButton,  
  InputBase,  
  List,  
  ListItem,  
  ListItemAvatar,  
  ListItemButton,  
  ListItemIcon,  
  ListItemText,  
  ListSubheader,  
  Paper,  
  Radio,  
  RadioGroup,  
  Skeleton,
  Switch,
  Toolbar,
  Typography, 
} from "@mui/material";
import { 
  AccountCircle,
  ArrowBack, 
  Bluetooth,  
  ExpandMore,  
  NotificationImportant,  
  PrivacyTipOutlined,  
  SettingsOutlined, 
  ShoppingBag, 
  ShoppingCart, 
  Wifi,
  Work, 
} from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices";

import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { isMobile } from "react-device-detect";
import EditProfile from "./EditProfile";
import JobSetting from "./JobSetting";
 
  

// var socket,selectedChatCompare
function Settings(props) { 
  const [forceRenderer, setForceRenderer] = React.useState(false); 

  const queryClient = useQueryClient()
  const [currentData,updateContex] = useCurrentUser();
  const { myCurrentProfileLoading, socket,connectedSocket, myCurrentProfile } = currentData;
  const [forceRerender, setForceRerender] = React.useState(false); 
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });  
  const [currentMessage, setCurrentMessage] = React.useState("");
  const navigate = useNavigate();  
  const [sentMessage, setSentMessage] = React.useState(null);
  // const [directMessageParticipator, setDirectMessageParticipator] = React.useState(null);
  const params = useParams();
  const searchParams = queryString.parse(location?.search);
  
const [chatContainerStyle, setChatContainerStyle ] = useState({});  
const [isTyping, setIsTyping] = useState(false); 
const [settingTab, setSettingTab] = useState(false); 
const [chatId, setChatId] = useState(params?.chatId);
const [expandedAccordion, setExpandedAccordion] = useState(false);
 
useEffect(() => { 
  if (params?.chatId ) { 
    setChatId(params?.chatId); 
    setChatContainerStyle({}); 
  } else { 
    setChatContainerStyle({
      display:"none"
    });
  }
}, [params?.chatId]);

useEffect(() => { 
  try {
    let tabb=params?.tab?.toLowerCase() 
    setSettingTab(tabb) 
  } catch (error) {
  }
}, [params?.tab]);
 
   

useEffect(() => {
  try {   
  if(socket&&chatId){
     socket.emit("join chat",chatId) 
 
    socket.on("message recieved",(newMessage)=>{ 
      if(chatId==newMessage?.Chat?.id){
      queryClient.invalidateQueries({ 
        queryKey: ["/api/chat/"+chatId+"/messages" ],
     }) }
    })  
    socket.on("typing",(roomChatId)=>{  
      // console.log("  typing",roomChatId   )
      if(chatId==roomChatId){
      setIsTyping(true)
     }
    })  
    socket.on("stop typing",(roomChatId)=>{  
      // console.log("stop typing",roomChatId   )
      if(chatId==roomChatId){
      setIsTyping(false) 
    }
    })  
    } 
  } catch (error) { 
  }
},[socket,chatId]);

 

  if (params?.jobTab) {
    tabn = params?.jobTab;
  }
 
  let filterData={}  
  try { 
    let searchParamsD = queryString.parse(location?.search); 
    let filter=searchParamsD?.filter
      if(filter?.length){
      filter=JSON.parse(filter)
      } 
        if (searchParamsD||params) {    
         filterData={
          post:filter?.postFilter ,
          marketPlaceListing:filter?.listingFilter,
          marketPlaceVehicle:filter?.vehicleFilter,
          marketPlaceProperty:filter?.propertyFilter,
         }  
      }   
  } catch (error) {
    // console.log("errore ocuuu",error)
  }   
  
  let chatRelativePath="/api/chat/find/"+chatId 
  if(chatId=="new"){
    chatRelativePath="/api/chat/getorcreate" 
  } 
   
  const { data: notificationChannelType, isFetched :notificationChannelTypesFetched  } = useQuery({
    queryKey: ["/api/profilesetting/notificationchanneltype/all" ],
    queryFn: async () => { 
      // console.log("getMyChats", )  
      let mm= await HttpService.getApiData("/api/profilesetting/notificationchanneltype/all" );  
      return mm
    },
    // initialData:{}
  });
  
  const { data: privacyTypes, isFetched :privacyTypesFetched  } = useQuery({
    queryKey: ["/api/profilesetting/privacytype/all" ],
    queryFn: async () => { 
      // console.log("getMyChats", )  
      let mm= await HttpService.getApiData("/api/profilesetting/privacytype/all" );  
      return mm
    },
    // initialData:{}
  });

  // mynotifications
  const { data: notificationSettings, isFetched :notificationSettingsFetched  } = useQuery({
    queryKey: ["/api/profilesetting/notificationsetting/all" ],
    queryFn: async () => { 
      // console.log("getMyChats", )  
      let mm= await HttpService.getApiData("/api/profilesetting/notificationsetting/all" );  
      return mm
    },
    // initialData:{}
  });
  
  const { data: privacySettings, isFetched :privacySettingsFetched  } = useQuery({
    queryKey: ["/api/profilesetting/privacysetting/all" ],
    queryFn: async () => { 
      // console.log("getMyChats", )  
      let mm= await HttpService.getApiData("/api/profilesetting/privacysetting/all" );  
      return mm
    },
    // initialData:{}
  });
  
  const updateNotificationSettings = async (data) => {
    let reletv= "/api/profilesetting/notificationsetting/edit" 
    let resp={}
    if ( data ) {  
        resp= await HttpService.postApiData(reletv,data)
    // socket.emit("new message",{Chat:currentChat,Profile:myCurrentProfile})
    //   success=true
    // 
    }
    return resp
  };

  const updatePrivacySettings = async (data) => {
    let reletv= "/api/profilesetting/privacysetting/edit" 
    let resp={}
    if ( data ) { 
        resp= await HttpService.postApiData(reletv,data)
    // socket.emit("new message",{Chat:currentChat,Profile:myCurrentProfile})
    //   success=true
    // 
    }
    return resp
  };
   
  function GeneralSettings(){
   
    return <> 
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}

      >
        <ListItem>
          <ListItemIcon>
            <Wifi/>
          </ListItemIcon>
          <ListItemText id="switch-list-label-wifi" primary="Wi-Fi" />
          <Switch
            edge="end"
            // onChange={handleToggle('wifi')}
            // checked={checked.indexOf('wifi') !== -1}
            inputProps={{
              'aria-labelledby': 'switch-list-label-wifi',
            }}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Bluetooth />
          </ListItemIcon>
          <ListItemText id="switch-list-label-bluetooth" primary="Bluetooth" />
          <Switch
            edge="end"
            // onChange={handleToggle('bluetooth')}
            // checked={checked.indexOf('bluetooth') !== -1}
            inputProps={{
              'aria-labelledby': 'switch-list-label-bluetooth',
            }}
          />
        </ListItem>
      </List>
            </>
  }
      
  function PrivacySettings(){
const [privacySettings2, setPrivacySettings2] = useState(privacySettings||{});
    
  const {mutate:privacySettingsMutation} = useMutation({
    mutationFn:updatePrivacySettings,
    onSettled: () => { 
      // console.log("send message")
      queryClient.invalidateQueries({ 
         queryKey: ["/api/profilesetting/privacysetting/all"],
      })
    },
  })
  
    const privacyOptionChangeItem=({label,fieldName,fieldValue })=>{
      var fieldValueD=fieldValue||1
      // let selectedValue=fieldValue||
    return (
    <Accordion expanded={expandedAccordion === "Privacy"+label} 
    onChange={()=>{ 
      let ea=(expandedAccordion=="Privacy"+label)?false:("Privacy"+label)
      // console.log("ea",ea)

       setExpandedAccordion(ea)
    }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel4bh-content"
        id="panel4bh-header"
      >
      <Typography sx={{ width: '53%', flexShrink: 0 }}>
        {label}
      </Typography>
      <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
       {
 CommonServices?.getDataFromArray(privacyTypes, "id", fieldValueD, "single", "title")   }
      </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography  variant="subtitle2">
           {"Who can see " +label+" ?"}
        </Typography>
          <FormControl>
          {/* <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel> */}
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            defaultValue={fieldValueD}
            onChange={(e)=>{
              let data={}
              data[fieldName]=e?.target?.value
              // console.log("data",data)
              fieldValueD=e?.target?.value
              let ps2=privacySettings2
              ps2[fieldName]=e?.target?.value
              setPrivacySettings2(ps2)
              privacySettingsMutation(data)
            }}
          >
           {privacyTypes?.map((pt)=> <FormControlLabel 
              labelPlacement="start" value={pt?.id} control={<Radio />}  label={pt?.title} />) }
          </RadioGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion> )
    }
   return <>   
   <Typography variant="body2" component="div" sx={{ flexGrow: 1 ,p:2}}>
     Visibility
   </Typography>
   {privacyOptionChangeItem({label:"Last Seen",fieldName:"LastSeen_PTID",fieldValue:privacySettings2?.LastSeen_PTID})}
   {privacyOptionChangeItem({label:"Phone",fieldName:"Phone_PTID",fieldValue:privacySettings2?.Phone_PTID})}
   {privacyOptionChangeItem({label:"Profile Photo",fieldName:"ProfilePhoto_PTID",fieldValue:privacySettings2?.ProfilePhoto_PTID})} 
   {privacyOptionChangeItem({label:"Bio",fieldName:"Bio_PTID",fieldValue:privacySettings2?.Bio_PTID})} 
   {privacyOptionChangeItem({label:"Date of Birth",fieldName:"DateOfBirth_PTID",fieldValue:privacySettings2?.DateOfBirth_PTID})} 
   
   <Typography variant="body2" component="div" sx={{ flexGrow: 1 ,p:2}}>
     Engagement
   </Typography>
   {privacyOptionChangeItem({label:"Direct Message",fieldName:"DirectMessage_PTID",fieldValue:privacySettings2?.DirectMessage_PTID})} 
   {privacyOptionChangeItem({label:"Forward Message",fieldName:"ForwardMessage_PTID",fieldValue:privacySettings2?.ForwardMessage_PTID})} 
   {privacyOptionChangeItem({label:"Call",fieldName:"Call_PTID",fieldValue:privacySettings2?.Call_PTID})} 
   {privacyOptionChangeItem({label:"Voice message",fieldName:"VoiceMessage_PTID",fieldValue:privacySettings2?.VoiceMessage_PTID})} 
   {privacyOptionChangeItem({label:"Add to Communities / Groups",fieldName:"AddToCommunities_PTID",fieldValue:privacySettings2?.AddToCommunities_PTID})} 
           </>
 }
    
  function NotificationSettings(){
    const [notificationSettings2, setNotificationSettings2] = useState(notificationSettings||{});
    
    const {mutate:notificationSettingsMutation} = useMutation({
      mutationFn:updateNotificationSettings,
      onSettled: () => { 
        // console.log("send message")
        queryClient.invalidateQueries({ 
           queryKey: ["/api/profilesetting/notificationsetting/all"],
        })
      },
    })
    
     const notificationOptionChangeItem=({label,fieldStartName  })=>{
      
    // repost_Via_Email: DataTypes.BOOLEAN,
    // repost_Via_Push: DataTypes.BOOLEAN,
    // repost_Via_SMS: DataTypes.BOOLEAN,
      return (
        <Accordion expanded={expandedAccordion === "Notification"+label} 
        onChange={()=>{
          let ea=(expandedAccordion=="Notification"+label)?false:("Notification"+label)
          // console.log("ea",ea)
          setExpandedAccordion(ea)
          }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
          <Typography sx={{ width: '43%', flexShrink: 0 }}>
            {label}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {(notificationSettings2[fieldStartName+"_Via_Push"])?"Push . ":null}
          {(notificationSettings2[fieldStartName+"_Via_Email"])?"Email . ":null}
          {(notificationSettings2[fieldStartName+"_Via_SMS"])?"SMS . ":null} 
          </Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Typography  variant="subtitle2">
          Notification channel type
          </Typography>
            
          <FormControl component="fieldset" variant="standard">
            {/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
            <FormGroup>
               <FormControlLabel
                control={
                  <Switch checked={notificationSettings2?.[fieldStartName+"_Via_Push"]} color="secondary"
                  edge="end" 
                  onChange={(e)=>{ 
                    if(1){ 
                    let ps2=notificationSettings2
                    ps2[fieldStartName+"_Via_Push"]=e?.target?.checked
                    console.log("ps2",ps2)
                    let data={}
                    data[fieldStartName+"_Via_Push"]=e?.target?.checked
                    setNotificationSettings2(ps2)
                    notificationSettingsMutation(data)

                    }
                  }}
                   name={"Push"} />
                }
                label="Push"
              /> 
               <FormControlLabel
                control={
                  <Switch checked={notificationSettings2?.[fieldStartName+"_Via_Email"]} color="secondary"
                  edge="end" 
                  onChange={(e)=>{
                    
                  if(1){ 
                    let ps2=notificationSettings2
                    ps2[fieldStartName+"_Via_Email"]=e?.target?.checked
                    console.log("ps2",ps2)
                    let data={}
                    data[fieldStartName+"_Via_Email"]=e?.target?.checked
                    setNotificationSettings2(ps2)
                    notificationSettingsMutation(data)
  
                    } 
                  }}
                name={"Email"} />}
                
                label={"Email"}
              /> 
               <FormControlLabel
                control={
                  <Switch checked={notificationSettings2?.[fieldStartName+"_Via_SMS"]} color="secondary"
                  edge="end" 
                  onChange={(e)=>{
                    if(1){ 
                      let ps2=notificationSettings2
                      ps2[fieldStartName+"_Via_SMS"]=e?.target?.checked
                      let data={}
                      data[fieldStartName+"_Via_SMS"]=e?.target?.checked
                      console.log("ps2",ps2)
                      setNotificationSettings2(ps2)
                      notificationSettingsMutation(data)
                      }  
                  }} name={"SMS"} />
                }
                label="SMS"
              /> 
            </FormGroup>
            {/* <FormHelperText>Be careful</FormHelperText> */}
          </FormControl>
          </AccordionDetails>
        </Accordion> )
     }
    return <>   
    <Typography variant="body2" component="div" sx={{ flexGrow: 1 ,p:2}}>
      Engagement Notifications
    </Typography>
    {notificationOptionChangeItem({label:"Comment",fieldStartName:"comment"})}
    {notificationOptionChangeItem({label:"Like",fieldStartName:"like"})}
    {notificationOptionChangeItem({label:"Bookmark",fieldStartName:"bookmark"})} 
    {notificationOptionChangeItem({label:"Repost",fieldStartName:"repost"})} 
    {notificationOptionChangeItem({label:"Message",fieldStartName:"message"})} 
    
    <Typography variant="body2" component="div" sx={{ flexGrow: 1 ,p:2}}>
      Post Notifications
    </Typography>
    {notificationOptionChangeItem({label:"Feeds",fieldStartName:"feed"})} 
    {notificationOptionChangeItem({label:"Stories",fieldStartName:"story"})} 
    {notificationOptionChangeItem({label:"Events",fieldStartName:"event"})} 
    {notificationOptionChangeItem({label:"Articles",fieldStartName:"article"})} 
    {notificationOptionChangeItem({label:"Job",fieldStartName:"job"})} 
    {notificationOptionChangeItem({label:"Market Place",fieldStartName:"marketPlace"})} 
            </>
  }
    
  function AccountSettings(){
     
    return <EditProfile/>
  }
  function JobSettings(){ 
    return <JobSetting/>
  }
    

  return (
    <>    
     <>  
     <Grid container spacing={2} justifyContent="center" >
     
     {(settingTab&&isMobile)?null:
      <Grid item xs={12} md={4}> 
        <Paper  style={{ 
                      // width:"80%",
                      overflowY: "auto", 
                  // position: isMobile?"relative": "fixed", 
                  minHeight:  isMobile?"100%":"calc(-2rem + 100vh)", 
                  }
                  }  elevation={3}  sx={{ flexGrow: 1, mb:isMobile?0:7 }}
        >
          <Box 
                   sx={{ flexGrow: 1 }}>
                    
                  <AppBar position="static">
                    <Toolbar>
                      <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => {
                          try { 
                          props?.onClose();
                          } catch (error) {
                            
                          }
                        }}
                      >
                        <ArrowBack />
                      </IconButton>
                      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                      Settings
                      </Typography>
                      {/* <Button color="inherit">Login</Button> */}
                    </Toolbar>
                  </AppBar>
            <Divider variant="inset" component="li" /> 
            <List
                sx={{ width: '100%',  bgcolor: 'background.paper' }}
                // subheader={<ListSubheader>Settings</ListSubheader>}
                
              > 
                  {/* <ListItemButton
                    // selected={selectedIndex === 1}
                    // onClick={(event) => handleListItemClick(event, 1)}
                    onClick={(event) => navigate("/Settings/General")}
                  >
                    <ListItemIcon>
                      <SettingsOutlined/>
                    </ListItemIcon>
                    <ListItemText primary="General" />
                  </ListItemButton> */}
                  <ListItemButton
                    // selected={selectedIndex === 1}
                    onClick={(event) => navigate("/Settings/Account")}
                  >
                    <ListItemIcon>
                      <AccountCircle />
                    </ListItemIcon>
                    <ListItemText primary="Account" />
                  </ListItemButton>
                  <ListItemButton
                    // selected={selectedIndex === 1}
                    onClick={(event) => navigate("/Settings/Job")}
                  >
                    <ListItemIcon>
                      <Work />
                    </ListItemIcon>
                    <ListItemText primary="Job Setting" />
                  </ListItemButton>
                  <ListItemButton
                    // selected={selectedIndex === 1}
                    onClick={(event) => navigate("/Settings/MarketPlace")}
                  >
                    <ListItemIcon>
                      <ShoppingCart />
                    </ListItemIcon>
                    <ListItemText primary="Market Place Setting" />
                  </ListItemButton>
                  <ListItemButton
                    // selected={selectedIndex === 1}
                    onClick={(event) => navigate("/Settings/Privacy")}
                  >
                    <ListItemIcon>
                      <PrivacyTipOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Privacy" />
                  </ListItemButton>
                  <ListItemButton
                    // selected={selectedIndex === 1}
                    onClick={(event) => navigate("/Settings/Notification")}
                  >
                    <ListItemIcon>
                      <NotificationImportant />
                    </ListItemIcon>
                    <ListItemText primary="Notification" />
                  </ListItemButton>
              </List>
          </Box>  
        </Paper>  
      </Grid>}
      {(settingTab)?
      <Grid   item xs={12} md={8}> 
         
        <Paper elevation={3}  style={{ 
                      // width:"80%",
                      overflowY: "auto", 
                  // position: isMobile?"relative": "fixed", 
                  minHeight:  isMobile?"100%":"calc(-2rem + 100vh)", 
                  }
                  }   sx={{ flexGrow: 1, mb:isMobile?0:7 }}>
            
            <MKBox sx={{p:2}} >
              
            <AppBar position="static">
                    <Toolbar>
                      <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => {
                          try { 
                          navigate(-1);
                          } catch (error) {
                            
                          }
                        }}
                      >
                        <ArrowBack />
                      </IconButton>
                      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                     
          {
          (settingTab=="general")? "general":
          (settingTab=="privacy")? "privacy":
          (settingTab=="job")? "job settings":
          (settingTab=="marketplace")? "market place settings":
          (settingTab=="account")? "account":
          (settingTab=="notification")? "notification":null
          }
                      </Typography> 
                    </Toolbar>
                  </AppBar>
          {
          (settingTab=="general")? <   GeneralSettings/>:
          (settingTab=="privacy")? <   PrivacySettings/>:
          (settingTab=="account")? <   AccountSettings/>:
          (settingTab=="job")? <   JobSettings/>:
          (settingTab=="marketplace")? <   AccountSettings/>:
          (settingTab=="notification")? <   NotificationSettings/>:null
          }
              </MKBox>
        </Paper>
      </Grid>:null}
    </Grid> 
        </>
      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{parse(moreText.content)}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
 

      {forceRerender}

      {forceRenderer}
    </>
  );
}

export default Settings;
