// @mui material components
import React, { useEffect } from "react";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { Dialog, DialogContent, Drawer, Slide } from "@mui/material";
import { isMobile } from "react-device-detect";
import PostDetailsModal from "./Sections/PostDetailsModal";
import { useNavigate, useParams } from "react-router-dom";
 
function PostDetailsDialogBox(props) { 
  const navigate = useNavigate(); 
  const params = useParams(); 
  const [forceRerender, setForceRerender] = React.useState(true); 
  const [currentData,updateContext] = useCurrentUser();
  const { currentGlobalDialogBoxData} =  currentData;
  const [dialogOpened, setDialogOpened] = React.useState(false); 

   window.addEventListener("popstate", (event) => {setDialogOpened(false)})

  useEffect(async () => { 
      if ( currentGlobalDialogBoxData?.dialogId == "PostDetailsDialogBox") {
        let path;
        path =  "/" +  currentGlobalDialogBoxData?.data?.post?.Profile?.userName +
          "/posts/" +  currentGlobalDialogBoxData?.data?.post?.id;
        window.history.pushState(null, "Job Details", path);  
        setDialogOpened(true)
      }  
  }, [ currentGlobalDialogBoxData]);
   
  React.useEffect(() => {  
    if ( !(location?.pathname?.indexOf("/posts/")>-1)) { 
      // console.log("location.pathname ",params,location.pathname)
      setDialogOpened(false) 
    }  
}, [location.pathname]);

  return (
    <>
      {(( currentGlobalDialogBoxData ? (currentGlobalDialogBoxData.dialogId ? true : false) : false)
        ? true
        : false) && (
        <>
          {/* {isMobile ? ( */}
            <Dialog
            open={dialogOpened}
              // TransitionComponent={Transition}
              // TransitionComponent={Transition}
              scroll={"paper"}
              sx={{ "& .MuiDialog-paper": { width: "100%" } }}
              maxWidth="md"
              onClose={() => {
                setDialogOpened(false) 
                navigate(-1)
              }}
              fullScreen={isMobile ? true : false}
            >
              {/* {currentGlobalDialogBoxData.data && (
            <> */}
              <PostDetailsModal
                onClose={() => {
                  setDialogOpened(false) 
                  navigate(-1)
                }}
                currentGlobalDialogBoxData={ currentGlobalDialogBoxData}
              />
              {/* </>
          )} */}
            </Dialog>
          {/* ) : (
            <Drawer
              open={dialogOpened}
              // TransitionComponent={Transition}
              scroll={"paper"}
              anchor={"right"}
              sx={{ "& .MuiDialog-paper": { width: "80%" } }}
              // maxWidth="xs"
              onClose={() => {
                setDialogOpened(false) 
                navigate(-1)
              }}
              fullScreen //={isMobile ? true : false}
            >
              <PostDetailsModal
                 onClose={() => {
                  setDialogOpened(false) 
                  navigate(-1)
                }}
                currentGlobalDialogBoxData={ currentGlobalDialogBoxData}
              />
            </Drawer>
          )} */}
        </>
      )}
      {forceRerender}
    </>
  );
}

export default PostDetailsDialogBox;
