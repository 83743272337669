 

import parse from "html-react-parser";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React, { useState, useEffect, useRef } from "react";

// Rental page components
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { Link, useNavigate, useParams } from "react-router-dom";
// Direct React component imports

import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";

import { FreeMode, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  AddCircle,
  ArrowBackIos,
  ArrowForwardIos,
  CheckCircle,
  Favorite,
  Logout,
  MoreVert,
  Person,
  Preview,
  Send,
} from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import { CommonApiServices } from "Api-Services/commonApiServices";
import { DataManagementService } from "Api-Services/dataManagementService";
import { CommonServices } from "Api-Services/commonServices";
import { HttpService } from "Api-Services/httpService";
import { useQuery } from "@tanstack/react-query";
import { InView } from "react-intersection-observer";
import { makeStyles } from "@mui/styles";
// import SwiperSlide from 'swiper/react';

// Define the custom styles using makeStyles
const useStyles = makeStyles(() => ({
  swiperButtonPrev: {
    color: "black",
    bottom: "150px",
    top: "auto",
    transform: "translateY(0)",
    width: "30px",
    height: "30px",
    left: "-54px", // Adjust the position of the left arrow
  },
  swiperButtonNext: {
    color: "black",
    bottom: "150px",
    top: "auto",
    transform: "translateY(0)",
    width: "30px",
    height: "30px",
    right: "-54px", // Adjust the position of the right arrow
  },
}));
function ProfilesYouMayKnow() {

  const classes = useStyles(); // Use the defined styles
  const [currentData,updateContext] = useCurrentUser();
  const { newJobSeekers, newJobSeekersLoading, myFriendships, myCurrentProfile } = currentData;
  const navigate = useNavigate();
  const params = useParams();
 

  let {data:profilesYouMayKnow} = useQuery({
    queryKey: ["/api/profile/profilesyoumayknow"],
    queryFn: async () => {
      return await HttpService.getApiData("/api/profile/profilesyoumayknow");
    },
  });
 
  const addImpression = async (id) => {
     if(!myCurrentProfile) return
    try {
      // if(!(post?.iViewed||IViewed)){
      if(id){
        let data = {
          ProfileId: id, 
          EngagementAreaId: 2,
        };
        let addReactRes = await CommonApiServices.addImpression(data);
        if(addReactRes.success){
          // setIViewed(true);
        }
      }
      // }
    } catch (error) {}
  };
  
  
  function FriendshipListItem(FriendshipListItemprops) {
    const [friendshipType, setFriendshipType] = React.useState("");
    const [friendshipItem, setFriendshipItem] = React.useState(null);
    useEffect(() => {
      // console.log("FriendshipListItem", FriendshipListItemprops.friendshipItem);

      // if (FriendshipListItemprops.friendshipItem && myFriendships) {
        if (FriendshipListItemprops.friendshipItem) {
        let friendshipItemR = FriendshipListItemprops.friendshipItem;
        setFriendshipItem(friendshipItemR);
        let fT = ""//CommonServices.checkFriendship(friendshipItemR.id, myFriendships);
       
            if (friendshipItemR?.iAmFollowing  && friendshipItemR?.myFollower) {
              fT = "friend";
            } else if (friendshipItemR?.myFollower) {
              fT = "follower";
            } else if (friendshipItemR?.iAmFollowing) {
              fT = "following";
            }
        if (fT) {
          if ((friendshipItemR.id ? true : false)) {
            // console.log("fT",fT)
            setFriendshipType(fT);
          }
        }
      }
    }, [FriendshipListItemprops]);

    let res = <></>;
    try {
      // let friendshipType = CommonServices.checkFriendship(friendshipItem.id, myFriendships);
      // console.log("friendshipType", friendshipType);

      res = (
        <>
          {friendshipItem && (
            <>{
              friendshipType == "friend" ? (
                <Chip
                  sx={{ px: "6px" }}
                  icon={<Send />}
                  onClick={() => {
                    // CommonApiServices.unfollow(friendshipItem.id)
                    // setFriendshipType("")
                    // console.log("mmmmmmmmmmm", following);
                  }}
                  // label={"Follow"}
                  label={"Message"}
                />
              ) : friendshipType == "follower" ? (
                <Chip
                  sx={{ px: "6px" }}
                  color={"info"}
                  icon={<AddCircle />}
                  onClick={() => {
                    if(!checkAndGoToLoginOrRegisterBox())return
                    CommonApiServices.follow(friendshipItem.id);
                    // friendshipType=!following;
                    setFriendshipType("friend");
                  }}
                  label={"Follow Back"}
                />
              ) : friendshipType == "following" ? (
                <Chip
                  sx={{ px: "6px" }}
                  icon={<Logout />}
                  onClick={() => {
                    if(!checkAndGoToLoginOrRegisterBox())return
                    CommonApiServices.unfollow(friendshipItem.id);
                    setFriendshipType("");
                  }}
                  // label={"Follow"}
                  label={"Unfollow"}
                />
              ) : (
                <Chip
                  sx={{ px: "6px" }}
                  color={"info"}
                  icon={<AddCircle />}
                  onClick={() => {
                    if(!checkAndGoToLoginOrRegisterBox())return
                    CommonApiServices.follow(friendshipItem.id);
                    setFriendshipType("following");
                  }}
                  // label={"Follow"}
                  label={"Follow"}
                />
              )
            }
            </>
          )} 
        </>
      );
    } catch (error) {
      console.log("FriendshipListItem errr", error);
    }
    return res;
  }


  function ProfileBox(propsProfileBox) {
    let JobSeeker = propsProfileBox.JobSeeker;
    let res = <></>;
    // let following = true;
    const [following1, setFollowing1] = useState(false);
    try {
      res = (
        <>
          <>
            <Card>
              <img
                // class="MuiCardMedia-root MuiCardMedia-media MuiCardMedia-img css-75vil5-MuiCardMedia-root"
                loading="lazy"
                height={isMobile ? "130" : " 145"}
                alt="user"
                style={{
                  m: "0px",
                  cursor: "pointer",
                  display: "block",
                  "-webkitBackgroundSize": "cover",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  "-webkitBackgroundPosition": "center",
                  backgroundPosition: "center",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "0.75rem",
                  // margin: "1rem 1rem 0",
                  width: "auto",
                  height: isMobile ? "140" : " 154",
                }}
                src={JobSeeker.profileImageUrl}
                onClick={() => {
                  DataManagementService.setLocalstorageDataSecured(JobSeeker, "jobSeekerDetail");
                  navigate("/" + JobSeeker.userName);
                }}
              />

              
              <CardContent
                sx={{ pt: "2px" }}
                onClick={() => {
                  // viewDetails(jobVacancy.id);
                }}
                // sx={{textAlign: "left",pl:2}}
              >
                <Grid
                  // sx={{ marginBottom: "-39px" }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <MKTypography
                      sx={{
                        textTransform: "capitalize",
                        maxWidth: "max-content",
                        textAlign: "center",
                        overflow: "hidden",
                        textWrap: "nowrap",
                      }}
                      variant="body2"
                    >
                      {/* <small> */} {CommonServices.getProfileName(JobSeeker)} {/* </small> */}
                    </MKTypography>
                  </Grid>
                  {/* {myCurrentProfile && ( */}
                    <Grid item>
                    <InView as="div" onChange={(inView, entry) => {
                      // console.log("gggggggg",inView)
                      if(inView){addImpression(JobSeeker?.id)}}}>
                    </InView>
                      <FriendshipListItem friendshipItem={JobSeeker} />
                    </Grid>
                  {/* )} */}
                </Grid>
              </CardContent>
            </Card>
          </>
        </>
      );
    } catch (error) {
      console.log("errrrrrrrrrrr", error);
    }
    return res;
  }

  
  const checkAndGoToLoginOrRegisterBox=()=>{
    if(myCurrentProfile){
      return true
    }
    else{
      let data = {
        dialogId: "SwitchProfileDialogBox",
        data: {},
      };
      updateContext("currentGlobalDialogBoxData", data);
    return false
    }
  }
  
return <>
  {/* //  newJobSeekersLoading ? (
  //   loadingComp()
  // ) : ( */}
    <>
      {(profilesYouMayKnow ? (profilesYouMayKnow.length ? true : false) : false) && (
        <Container sx={{ mx: 0, py: 3, px: 0 }}>
          <>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <MKTypography variant={"h6"}>Profiles You May Know</MKTypography>
              </Grid>
              <Grid item>
                <Button component={Link} variant="text" to={"/contacts/"+myCurrentProfile?.userName+ "/suggestions"}>
                  See more
                </Button>
              </Grid>
            </Grid>

            {(profilesYouMayKnow ? (profilesYouMayKnow.length ? true : false) : false) && (
              <div>
                <Swiper
                  slidesPerView={3}
                  spaceBetween={8}
                  freeMode={true}
                  lazy={true}
                  // pagination={{
                  //   clickable: true,
                  // }}

                  //  navigation={true} // Enable built-in navigation buttons
                          
                  navigation={{
                    prevEl: `.${classes.swiperButtonPrev}`, // Apply the custom classes
                    nextEl: `.${classes.swiperButtonNext}`,
                  }}
                  modules={[FreeMode, Pagination, Navigation]}
                  className="mySwiper"
                  breakpoints={{
                    640: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    724: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    824: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    1124: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    1400: {
                      slidesPerView: 5,
                      spaceBetween: 10,
                    },
                    1600: {
                      slidesPerView: 6,
                      spaceBetween: 10,
                    },
                  }}
                >
                  {profilesYouMayKnow.map((JobSeeker) => {
                    if (JobSeeker.profileImageUrl) {
                      return (
                        <SwiperSlide>
                          <ProfileBox JobSeeker={JobSeeker} />
                        </SwiperSlide>
                      );
                    }
                  })} 
                </Swiper>

        {/* Add the custom navigation buttons */}
        {!isMobile?<div>
        <div className={`swiper-button-prev ${classes.swiperButtonPrev}`} />
        <div className={`swiper-button-next ${classes.swiperButtonNext}`} />
         </div>:null}
              </div>
            )}

            {/* {selectedAgencyTabIndex == 0 && <JobSeekersList jobSeekers={newJobSeekers} />} */}
          </>
        </Container>
      )}
    </>
  {/* // ); */}
  </>
}

export default ProfilesYouMayKnow;
