/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";
// @mui material components
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Grid, IconButton, Tooltip } from "@mui/material";
import MKButton from "components/MKButton";
import { Work } from "@mui/icons-material";

function DefaultNavbarDropdown({
  name,
  icon,
  children,
  collapseStatus,
  light,
  href,
  route,
  collapse,
  ...rest
}) {
  const linkComponent = {
    component: "a",
    href,
    target: "_blank",
    rel: "noreferrer",
  };

  const routeComponent = {
    component: Link,
    to: route,
  };

  return (
    <>
      {/* <MKBox */}
      <Grid
        item
        xs={2}
        {...rest}
        // mx={1}
        // py={0}
        // pl={0}
        // pr={4}
        // px={2}
        px={"auto"}
        // display="flex"
        justifyContent="center"
        alignItems="center"
        color={light ? "white" : "dark"}
        opacity={light ? 1 : 0.6}
        sx={{
          cursor: "pointer",
          userSelect: "none",
          borderBottom: (location?.pathname?.toLowerCase().indexOf(route?.toLowerCase())==0) ? "3px solid red" : "",
        }}
        {...(route && routeComponent)}
        {...(href && linkComponent)}
      >
        {/* <MKTypography
          variant="body2"
          lineHeight={1}
          color="inherit"
          sx={{ alignSelf: "center", "& *": { verticalAlign: "middle" } }}
        >
          {icon}
        </MKTypography> */}
        {/* <MKTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
        >
          {"name"}
        </MKTypography> */}
        {/* <MKTypography variant="body2" color={"white"} ml="auto"> */}
        <Grid
  container
  direction="row"
  justifyContent="center"
  alignItems="baseline"
>
        <Tooltip title={name}>
          <IconButton
            aria-label={name}
            size="large"
            color={(location?.pathname?.toLowerCase().indexOf(route?.toLowerCase())==0)? "primary" : "text"}
          >
           <Icon>{icon}</Icon>
          </IconButton>
        </Tooltip></Grid>
        {/* </MKTypography> */}
      </Grid>
      {/* </MKBox> */}
      {/* {children && (
        <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
          {children}
        </Collapse>
      )} */}
    </>
  );
}

// Setting default values for the props of DefaultNavbarDropdown
DefaultNavbarDropdown.defaultProps = {
  children: false,
  collapseStatus: false,
  light: false,
  href: "",
  route: "",
};

// Typechecking props for the DefaultNavbarDropdown
DefaultNavbarDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  light: PropTypes.bool,
  href: PropTypes.string,
  route: PropTypes.string,
  collapse: PropTypes.bool.isRequired,
};

export default DefaultNavbarDropdown;
