// @mui material components
import FbImageLibrary from "react-fb-image-grid";
import "./botstrap.css";
import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect } from "react";
import { HttpService } from "Api-Services/httpService";
 
import {
  Avatar,
  Button,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  Typography,
  Grid,
  List,
  ListItem,
  Divider,
  ListItemAvatar,
  ListItemText,
  Paper,
  IconButton,
  InputBase,
  Toolbar,
  AppBar,
  Box, 
  SwipeableDrawer,
  Tabs,
  Tab,
  Stack, 
} from "@mui/material";
import {  AddCircle, ArrowBack, BookmarkBorder, ChatBubbleOutline, Comment, ContactMail, Edit, Favorite, FavoriteBorder, Margin, Navigation, Person, Reply, Send } from "@mui/icons-material";

import MoreVert from "@mui/icons-material/MoreVert";
import ReactTimeago from "react-timeago";
import TruncateMarkup from "react-truncate-markup";
import { CommonServices } from "Api-Services/commonServices";
import { isMobile } from "react-device-detect";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { CommonApiServices } from "Api-Services/commonApiServices";
import { blue, green, pink, red } from "@mui/material/colors";
import JobPostDetails from "./JobPostDetails";
import { PhotoSlider } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import ArticlePostDetails from "./ArticlePostDetails";
import MKButton from "components/MKButton";
var approx = require('approximate-number');
 
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";
import MetaDecorator from "metaDecorator";
 
 
function PostDetails(props) {
  const navigateComp = useNavigate();
  const params = useParams();
  
  const [photoSliderVisible, setPhotoSliderVisible] = React.useState(false);
  const [photoSliderIndex, setPhotoSliderIndex] = React.useState(0);
  const queryClient = useQueryClient();
  const [currentData, updateCurrentUser] = useCurrentUser();
  const { myCurrentProfile, myFriendships ,isUserLoggedIn} = currentData;
  const [showMore, setShowMore] = React.useState(false);
  const [openedDialog, setOpenedDialog] = React.useState("");
  // const [post, setPost] = React.useState(null);
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });

  let postId = null;
  if (props?.post?.id) {
    postId = props?.post?.id;
  } else {
    postId = params?.postId;
  }

  let { data: post } = useQuery({
    queryKey: ["/api/post/find/" + postId],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/post/find/" + postId);
    },
    initialData: props?.post,
  });
 
 
  // const targetRef = useRef(null); 
  const { ref:targetRef, inView:isVisible } = useInView();

  // console.log("sVisible",isVisible)
  useEffect(() => { 
    try {
        // if(props?.data?.section){
          // console.log("scroll to view", props?.data?.section)
          var element = document.getElementById("comments");
          element.scrollIntoView();
        // }
    } catch (error) {}
  }, []);

  // const [isVisible, setIsVisible] = React.useState(false);
    let jobOverviewComponent = <></>;
    // console.log("feedpost", jobVacancy);
    const [iReacted, setIReacted] = React.useState(post?.iReacted);
    // let iReacted=false
    const addReaction = async (reactionTypeId) => {
      try {
        let data = {
          PostId: post?.id,
          ReactionTypeId: 1,
          ReactionAreaId: 1,
        };
        let addReactRes = await CommonApiServices.addReaction(data);
        setIReacted(addReactRes.success);
      } catch (error) {}
    };

    const removeReaction = async (reactionTypeId) => {
      try {
        let data = {
          // id:reactionId
          PostId: post?.id,
          ReactionTypeId: reactionTypeId,
        };
        let removeReactRes = await CommonApiServices.removeReaction(data);
        setIReacted(removeReactRes.success ? false : true);
      } catch (error) {}
    };
 
    const applyComponent = () => {
      return ( 
              <>
                {isUserLoggedIn ? (
                  <>
                    {myCurrentProfile ? ( 
                        <>
                          {/* {isJobAlreadyApplied(post?.EmploymentJobVacancy?.id) ? ( */}
                          {false ? (
                            <>
                              <MKButton
                              size="small"
                                disabled={post?.EmploymentJobVacancy?.isJobClosed}
                                // onClick={() => {
                                //   navigateComp("/profile/my-applications");
                                // }}
                                // variant="outlined"
                                // color="success" 
                                sx={{ m: 1 }}
                              >
                                Already Applied
                              </MKButton>
                              <MKButton
                              size="small"
                                endIcon={<ContactMail />}
                                disabled={post?.EmploymentJobVacancy?.isJobClosed}
                                onClick={() => {
                                  navigateComp("/"+post?.Profile?.userName);
                                }}
                                variant="outlined"
                                color="primary" 
                                sx={{ m: 1 }}
                              >
                                Contact Employer
                              </MKButton>
                            </>
                          ) : (<>
                            {post?.EmploymentJobVacancy?.isJobClosed?
                              <MKTypography display="block" variant="button" color="warning" fontWeight="regular">
                                <strong> {"Job Closed"} </strong>
                              </MKTypography>
                             : 
                              <MKButton
                              endIcon={<Send />}
                              disabled={post?.EmploymentJobVacancy?.isJobClosed}
                              // onClick={() => setOpenedDialog("sendRequest")}
                              onClick={() => {
                                navigateComp("/jobs/"+post?.EmploymentJobVacancy?.id+"/apply");
                              }}
                              variant="gradient"
                              color="success"
                              size="small"
                              sx={{ m: 1 }}
                            >
                              Apply
                            </MKButton>}
                          </>)}
                        </> 
                    ) : (
                      <MKButton
                      size="small"
                        //  disabled={employmentJobVacancy.isJobClosed}
                        variant="gradient"
                        color="success" 
                        sx={{ my: 2 }}
                        onClick={() => {
                          let data = {
                            dialogId: "SwitchProfileDialogBox",
                            data: {},
                          };
                          updateCurrentUser("currentGlobalDialogBoxData", data);
                          //   setFriendshipTab("suggestions")
                          //  setDrawerOpened("freindship")
                        }}
                        // sx={{ color: ({ palette: { dark } }) => dark.main }}
                      >
                        Enter to profile to Apply
                      </MKButton>
                    )}
                  </>
                ) : ( 
                    <MKButton
                       size="small"
                      //  disabled={employmentJobVacancy.isJobClosed}
                      variant="gradient"
                      color="success" 
                      sx={{ my: 2 }}
                      component={Link}
                      // variant="outlined"
                      // color="default"
                      to={"/login?redirectTo=/pages/jobs"}
                      // sx={{ color: ({ palette: { dark } }) => dark.main }}
                    >
                      Login and Apply 
                    </MKButton> 
                )}
              </> 
      );
    };

  const getPostImages = (attachmentsD) => {
    let urlsd = [];
    attachmentsD.forEach((element) => {
      urlsd.push(element.url);
    });
    return urlsd;
  };


  function Comments(commentProps) {
    let postId = commentProps.postId;
    const [commentMessage, setCommentMessage] = React.useState("");
    const query = useQuery(
      ["comments" + postId],
      async () => await CommonApiServices.getPostComments(postId)
    );
    const sendComment = async () => {
      if (postId) {
        if (postId && commentMessage) {
          let data = {
            PostId: postId,
            CommentTypeId: 1,
            CommentAreaId: 1,
            message: commentMessage,
          };
          mutation.mutate(data);
        }
      }
    };

    // Mutations
    const mutation = useMutation((newComment) => CommonApiServices.addComment(newComment), {
      onSuccess: () => {
        // Invalidate and refetch
        setCommentMessage("");
        queryClient.invalidateQueries({ queryKey: ["comments" + postId] });
      },
    });
    try {
      return (
        <>
          {/* <DialogContent */}
            {/* sx={{
              // minHeight: isMobile ? "300px" : "460px",
              p: "0px",
              m: "0px",
            }}
          > */}

{myCurrentProfile && (
              <>
                <Paper
                  // component="form"
                  elevation={0}
                  square={false}
                  // component="form"
                  shadow={"none"}
                  sx={{
                    mx: 0,
                    mt: 0.5,
                    backgroundColor: "#f0f2f5",
                    // mr: "6px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    //  width: "min-content",
                  }}
                >
                  <IconButton sx={{}} aria-label="menu">
                    <Avatar size="sm" src={myCurrentProfile.profileImageUrl} />
                  </IconButton>
                  <InputBase
                    fullWidth
                    // startAdornment={<SearchIcon sx={{ mr: 1 }} />}
                    // autoFocus={true}
                    sx={{ ml: 1, pr: 1, flex: 1, backgroundColor: "aliceblue" }}
                    // sx={{ ml: 1, flex: 1 }}
                    placeholder="Write Comment"
                    inputProps={{ "aria-label": "Write Comment" }}
                    value={commentMessage}
                    onChange={(e) => {
                      setCommentMessage(e.target.value);
                    }}
                    onKeyDown={(event) => {
                      if (event.keyCode) {
                        if (event.keyCode === 13) {
                          sendComment();
                        }
                      }
                    }}
                    type="search"
                  />
                  <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                    <Edit />
                  </IconButton>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    onClick={() => {
                      sendComment();
                    }}
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                  >
                    <Send />
                  </IconButton>
                </Paper>
              </>
            )}

                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <MKBox display="flex"   flexDirection="column">
              {query.isLoading ? (
                <h1>Loading</h1>
              ) : (query.data ? (query.data.length ? true : false) : false) ? (
                <Grid sx={{ width: "100%",   bgcolor: "background.paper" }}>
                  {query.data?.map(
                    (comment) =>
                      comment?.message &&
                      comment?.EngagerProfile && (
                        <>
                        
                <Paper
                  // component="form"
                  elevation={0}
                  square={false}
                  // component="form"
                  shadow={"none"}
                  sx={{
                    mx: 0,
                    mt: 0.5,
                    // backgroundColor: "#f0f2f5",
                    // mr: "6px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    //  width: "min-content",
                  }}
                >
                  
                  <Grid 
                         container
                         direction="row"
                         justifyContent="flex-start"
                         alignItems="flex-start"
                       >
 
                         {/* <Grid item  xs={2}>   */}
                          <IconButton sx={{}} aria-label="menu">
                            <Avatar sx={{width:35,height:35}} src={comment?.EngagerProfile?.profileImageUrl} />
                          </IconButton> 
                         {/* </Grid> */}
                         <Grid item  >  
                          <Grid 
                                // sx={{  backgroundColor: "#f0f2f5",}}
                                     
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                      >
                                
                         <Grid item xs={12} sx={{py:0}}>
                              {/* <Typography
                              // sx={{ display: "inline" }}
                              component="span"
                              variant="h6"
                              color="text.primary"
                            >
                              {CommonServices.getProfileName(comment.Profile)}
                            </Typography> */}
                               <React.Fragment>  
                                  <Typography
                                      // sx={{ display: "inline" }} 
                                      variant="h6"
                                      color="text.primary"
                                    >
                                  {CommonServices.getProfileName(comment?.EngagerProfile)}
                                </Typography>
                                  <Typography 
                                  //  sx={{mt:-2}}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {comment?.message}
                                </Typography>
                             </React.Fragment>
                              {/* <IconButton type="button" sx={{ px: "10px" }} aria-label="search">
                                <MoreVert />
                              </IconButton> */}
                          </Grid>
                         <Grid item xs={12} sx={{py:0}}> 
                                </Grid>
                          </Grid>
                         </Grid>
                         </Grid>


                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                 
                </Paper>
                          {/* <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              <Avatar alt="profile" src={comment.Profile.profileImageUrl} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={CommonServices.getProfileName(comment.Profile)}
                              secondary={
                                  <Typography
                                    // sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {comment.message}
                                  </Typography>
                              }
                            />
                          </ListItem> */}
                          <Divider variant="inset" component="li" />{" "}
                        </>
                      )
                  )}
                </Grid>
              ) : (
                <MKTypography variant={"body2"}>Be the first to comment </MKTypography>
              )}
            </MKBox>
          {/* </DialogContent> */}
          {/* <DialogActions> */}
            {/* </> */}
          {/* </DialogActions> */}
        </>
      );
    } catch (error) {}
  }
 
   
  return (
    <>
    
    <Grid container justifyContent="space-between" alignItems="flex-start">
        
        <Grid 
            item
            sm={12}
            xs={12}
            md={4}
            lg={3.5}
            xl={2.3}
            display={{ xs: "12", sm: "12", md: "flex" }}
            >
          </Grid>
        <Grid item 
        // xs={12} lg={(isMobile||((props?.post)))?12:8}
        
        xs={12} sm={12} md={8} lg={5}  
        sx={{mt:isMobile?0:2}}
        
        
        >
          {!props?.post && (
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    navigateComp(-1);
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Post
                </Typography>
                {/* <Button color="inherit">Login</Button> */}
              </Toolbar>
            </AppBar>
          )}
          {post ?    <>  
       <MetaDecorator  description={post?.title||"Baally post"} title={post?.message}  
        imageUrl={post?.Attachments[0]?.url}
        imageAlt={"post Image"}
        />
              <MKBox display="flex" flexDirection="column">
                <Card
                 sx={{width: "100%"}}
                >
                  {post?.Profile && (
                    <CardHeader
                      avatar={
                        <Avatar
                          onClick={() => navigateComp("/" + post?.Profile?.userName)}
                          sx={{ width: 44, height: 44 }}
                          src={post?.Profile?.profileImageUrl}
                          aria-label="recipe"
                        />
                      }
                      action={
                        // props.componentViewType == 3 ? (
                        <MoreVert
                        //  color="success"
                        //  fontSize="large"
                        />
                        // ) : null
                      }
                      title={
                        <TruncateMarkup lines={1}>
                          <MKTypography
                            variant="h6"
                            onClick={() => navigateComp("/" + post?.Profile.userName)}
                          >
                            {CommonServices.getProfileName(post?.Profile)}
                          </MKTypography>
                        </TruncateMarkup>
                      }
                      subheader={
                        <Typography variant="body2" color="text.secondary">
                          <ReactTimeago date={post?.createdAt} />

                          {/* <PeopleOutlineRounded /> {1461 + jobVacancy.numberOfMembers} {" Members"} */}
                        </Typography>
                      }
                    />
                  )}
                  <CardContent
                    sx={{ my: "1px", py: "1px" }}
                    onClick={() => {
                      // viewDetails(jobVacancy.id);
                    }}
                    // sx={{textAlign: "left",pl:2}}
                  >
                    {post?.message && (
                      <>
                        {showMore == "description" ? (
                          <>
                            <Typography variant="body2">{parse(post?.message)}</Typography>
                            <Typography
                              color={"info"}
                              variant="caption"
                              onClick={() => setShowMore("")}
                              // component={Link}
                            >
                              {" see less"}
                            </Typography>
                          </>
                        ) : (
                          <TruncateMarkup
                            lines={(post ? (post?.Attachments ? true : false) : false) ? 3 : 8}
                            ellipsis={
                              <span>
                                ...{" "}
                                <Typography
                                  color={"info"}
                                  variant="caption"
                                  onClick={() => setShowMore("description")}
                                  // component={Link}
                                >
                                  see more
                                </Typography>
                              </span>
                            }
                          >
                            <Typography variant="body2">{parse(post?.message)}</Typography>
                          </TruncateMarkup>
                        )}
                      </>
                    )}
                  </CardContent>

                  {post?.PostTypeId == 3 && (
                    <CardContent
                      sx={{ my: "1px", py: "1px" }}
                      onClick={() => {
                        // viewDetails(jobVacancy.id);
                      }}
                      // sx={{textAlign: "left",pl:2}}
                    >
                      <JobPostDetails
                        post={post}
                        employmentJobVacancy={post?.EmploymentJobVacancy}
                      />
                    </CardContent>
                  )}
                  {post?.PostTypeId == 7 && (
                    <CardContent
                      sx={{ my: "1px",mx:0, px:1.5,py: "1px" }}
                      onClick={() => {
                        // viewDetails(jobVacancy.id);
                      }}
                      // sx={{textAlign: "left",pl:2}}
                    >
                      <ArticlePostDetails post={post} article={post?.Articles[0]} />
                    </CardContent>
                  )}

                  {post?.Attachments && (
                    <>
                      <PhotoSlider
                        images={getPostImages(post?.Attachments)?.map((item) => ({
                          src: item,
                          key: item,
                        }))}
                        visible={photoSliderVisible}
                        onClose={() => {
                          setPhotoSliderVisible(false);
                          // navigateComp(-1)
                        }}
                        index={photoSliderIndex}
                        onIndexChange={setPhotoSliderIndex}
                      />
                      <div className={"FbImageLibrary"}>
                        <FbImageLibrary
                          images={getPostImages(post?.Attachments)}
                          onClickEach={({ src, index }) => {
                            // let path="/post/"+jobVacancy?.id;
                            // window.history.pushState(null, "Job Details", path);
                            setPhotoSliderIndex(index);
                            setPhotoSliderVisible(true);
                          }}
                          hideOverlay={true}
                        />
                      </div>
                    </>
                  )}


                  {/* COmmments */}
                  {/* COmmments */}
                  {/* COmmments */}
                  {/* COmmments */}
                  {/* COmmments */}
                  {/* COmmments */}
                  {/* COmmments */}
                  {/* COmmments */}
                  {/* COmmments */}
                  {/* COmmments */}
                  {/* COmmments */}

                 {!isVisible&& <CardContent
                    // sx={{ marginBottom: "-11px" }}
                    onClick={() => {
                      // viewDetails(jobVacancy.id);
                    }}
                    // sx={{textAlign: "left",pl:2}}
                  >
                    {/* <Divider sx={{ my: 0 }} /> */}
 
                  {(post?.PostTypeId==3)&&  <Grid
                         
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                      >

                        <Grid item  > </Grid>  
                          <Grid item sx={{ position:"fixed",  bottom:props?.post?"30px":"60px",marginLeft:"-14px" }}>
                          <MKBox sx={{ }}>
                            <Stack     elevation={0} 
                                  direction="column"
                                  // justifyContent="flex-end"
                                  alignItems="center"
                                  spacing={0.5}
                                > 
                                    {applyComponent()}
                               </Stack>
                           </MKBox>
                        </Grid>   
                     </Grid>  
                   }

                    <Grid 
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                      >

                        <Grid item  >  </Grid>  
                          <Grid item sx={{ position:"fixed",  bottom:props?.post?"30px":"60px",marginRight:'-14px' }}>
                          <MKBox sx={{ }}>
                            <Stack     elevation={0} 
                                  direction="column"
                                  // justifyContent="flex-end"
                                  alignItems="center"
                                  spacing={0.5}
                                > 
                                 
                                <Avatar
                                  onClick={() => navigateComp("/" + post?.Profile?.userName)}
                                  sx={{ width: 34, height: 34, marginBottom:"15px" }}
                                  src={post?.Profile?.profileImageUrl}
                                  aria-label="recipe"
                                /> 
                                
                                 {iReacted?<Favorite  
                                  sx={{ width: 30, height: 30, color:red[500]}}
                                    disabled={!(myCurrentProfile)}
                              onClick={() => {
                                setIReacted(!iReacted);
                                removeReaction(1);
                              }}  />:
                                  <Favorite  
                                  sx={{ width: 30, height: 30, color:"white" }}
                                  disabled={!(myCurrentProfile)}  onClick={() => {
                                setIReacted(!iReacted);
                                addReaction(1);
                              }}   />   
                                  }
                                <Typography variant="h6">{approx(post.reactionsCount||0) }</Typography>  

                                    <Comment 
                                       disabled={!(myCurrentProfile)}
                                      onClick={() => {
                                        var element = document.getElementById("comments");
                                            element.scrollIntoView();

                                        // targetRef?.scrollIntoView();
                                        // setOpenedDialog("comments");
                                        // let data = {
                                        //   dialogId: "PostCommentsDialogBox",
                                        //   data: { post: jobVacancy  },
                                        // };
                                        // updateCurrentUser("currentGlobalDialogBoxData", data);
                                      }} 
                                       sx={{ width: 28, height: 28,  color: blue[500] }} />  
                                   <Typography variant="h6">{approx(post.commentsCount||0) }</Typography>  

                                    <BookmarkBorder  disabled={!(myCurrentProfile)}    sx={{width: 30, height: 30, color: green[500] }} />  
                                    <Typography sx={{marginTop:"-4px"}}  variant="h6">{approx(post.commentsCount||0) }</Typography>  

                                    <Reply fontSize="large" sx={{width: 30, height: 30,  color: green[500] }} />  
                                   <Typography variant="h6">{approx(post.commentsCount||0) }</Typography>  
                                   
                               </Stack>
                           </MKBox>
                        </Grid>   
                     </Grid>  
                     
                  </CardContent> }
                     <Box sx={{ width: '100%' }}>   
                  
                     {post?.PostTypeId == 3 && <Box sx={{ mx:1}}>   
                     {applyComponent()}
                     </Box>}
                   <Tabs ref={targetRef}  
        id="engegmentbox"
        // onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab  
        icon ={iReacted?<Favorite  
                                 
                                 sx={{  color:red[500]}}
                                    disabled={!(myCurrentProfile)}
                              onClick={() => {
                                setIReacted(!iReacted);
                                removeReaction(1);
                              }}  />:
                                  <FavoriteBorder  
                                  sx={{ color:red[500]}}
                                  disabled={!(myCurrentProfile)}  onClick={() => {
                                setIReacted(!iReacted);
                                addReaction(1);
                              }}   />   
                                  }
                         label={approx(post.reactionsCount||0) } />
 
                         <Tab disabled={!(myCurrentProfile)}    onClick={() => {
                                        setOpenedDialog("comments");
                                        // let data = {
                                        //   dialogId: "PostCommentsDialogBox",
                                        //   data: { post: jobVacancy  },
                                        // };
                                        // updateCurrentUser("currentGlobalDialogBoxData", data);
                                      }} 
                                    icon ={   <Comment     sx={{  color: blue[500] }} /> }
                                                    label={approx(post.commentsCount||0) } 
                            />
                           <Tab  disabled={!(myCurrentProfile)}    icon={<BookmarkBorder 
                                  sx={{   color: green[500] }} /> } 
                                    label={approx(post.commentsCount||0) } 

                                  />
                                <Tab icon={
                                    <Reply  
                                  sx={{  color: green[500] }} />}  
                                 label={approx(post.commentsCount||0) } />
                    </Tabs> 
                  {/* <CardContent
                    // sx={{ marginBottom: "-11px" }}
                    onClick={() => {
                      // viewDetails(jobVacancy.id);
                    }}
                    // sx={{textAlign: "left",pl:2}}
                  > */}
                    <Divider sx={{ my: 2}} />
                    <Typography  id="comments" variant="h6" component="div" sx={{ mx:2,flexGrow: 1 }}>
                      Comments
                    </Typography> 

                      {post && (
                        <> 
                          <Comments  postId={post?.id} />
                        </>
                      )}

                   </Box> </Card>
              </MKBox> 
        </> : null}
       
        </Grid>
        <Grid
            item
            sm={12}
            xs={12}
            md={4}
            lg={3.5}
            xl={2.3}
            display={{ xs: "12", sm: "12", md: "flex" }}>
          
        </Grid>
      </Grid>
 
 

      {/* {forceRerender} */}
    </>
  );
}
export default PostDetails;
