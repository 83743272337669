 

import { useEffect, useState } from "react";

import imageCompression from "browser-image-compression";
// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
 

// NewProduct page components
// import ProductInfo from "layouts/ecommerce/products/new-product/components/ProductInfo";
// import Media from "layouts/ecommerce/products/new-product/components/Media";
// import Socials from "layouts/ecommerce/products/new-product/components/Socials";
// import Pricing from "layouts/ecommerce/products/new-product/components/Pricing"; 
// import VacancyFewDetail from "../vacancy-detail/vacancyFewDetail";
import { Autocomplete, Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, IconButton, ImageList, ImageListItem, ImageListItemBar, LinearProgress, TextField } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HttpService } from "Api-Services/httpService";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import MKInput from "components/MKInput";
import { isMobile } from "react-device-detect";
import { useQuery } from "@tanstack/react-query";
import MKEditor from "components/MKEditor";
import queryString from "query-string";
import { 
  subCities,
  specificAreas,
} from "Api-master-datas/masterDatas.js";
import { makeStyles } from "@mui/styles";
import { DropzoneArea } from "mui-file-dropzone";
import { Delete } from "@mui/icons-material";
import { pink } from "@mui/material/colors";

function getSteps() {
  return ["1. Basic Info","2. Attachment", "3. Listing Info", "4. Advanced "];
}

const postStylesDropzone = makeStyles((theme) => ({
  root: {
    "& .css-9igssw-MuiDialogContent-root": {
      padding: "5px",
    },
    "& .ql-toolbar.ql-snow ": {
      padding: "0px",
    },
  },
  dropzoneClass: {
    // maxHeight: "124px  !important",
    minHeight: "5px !important",
    "& .css-f8f0pk-MuiSvgIcon-root ": {
      height: "30px",
      width: "30px",
    },
    "& .css-1g96e5k-MuiTypography-root": {
      fontSize: "0.9rem",
      fontWeight: "700",
      marginTop: "4px",
      marginBottom: "2px",
    },
    padding:"5px"
  },
  postTextArea: {
    "& .ql-editor ": {
      minHeight: "120px",
    },
  },
}));
 
function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

function CreateMarketPlacePost() { 
  const postStyles = postStylesDropzone(); 
  const [currentContextData, updateContext  ] = useCurrentUser();  
  const { myCurrentProfile}  = currentContextData;  
  const params=useParams() 
  const navigate=useNavigate()

  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [vacancySubmittedResponse, setVacancySubmittedResponse] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isCurrentSectionValid, setIsCurrentSectionValid] = useState(false);
  const [vacancyData, setVacancyData] = useState({});
  const [post, setPost] = useState(null);
  const [mode, setMode] = useState(params?.mode);  
  const [profile, setProfile] = useState(null);  
  const [isReviewSection, setIsReviewSection] = useState(false);
  const [forceRerender, setForceRerender] = useState(false);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

   const [postSectionValues, setPostSectionValues] = useState(null); 
   const [attachementSectionValues, setAttachementSectionValues] = useState(null);  
   const [addressSectionValues, setAddressSectionValues] = useState(null); 

   const [listingSectionValues, setListingSectionValues] = useState(
     null);

   const [vehicleSectionValues, setVehicleSectionValues] = useState(
     null);

    const [propertySectionValues, setPropertySectionValues] = useState(
    null);
    
    let {data:marketPlaceListingTypes} = useQuery({
      queryKey: ["/api/marketplace/listingtype/all/"],
      queryFn: async () => {
        // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
        // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
        return await HttpService.getApiData("/api/marketplace/listingtype/all/");
      }, 
    });

    let {data:marketPlaceListingPersonTypes} = useQuery({
      queryKey: ["/api/marketplace/marketplacelistingpersontype/all/"],
      queryFn: async () => {
        // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
        // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
        return await HttpService.getApiData("/api/marketplace/marketplacelistingpersontype/all/");
      }, 
    });
  
    let {data:marketPlaceListingConditions} = useQuery({
      queryKey: ["/api/marketplace/marketplacelistingcondition/all/"],
      queryFn: async () => {
        // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
        // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
        return await HttpService.getApiData("/api/marketplace/marketplacelistingcondition/all/");
      }, 
    });
  

    let {data:cities} = useQuery({
      queryKey: ["/api/masterdata/city/all"],
      queryFn: async () => {
             let resJc=[] 
             let resJcD= await HttpService.getApiData("/api/masterdata/city/all");
             if(resJcD?.length){
              resJc=resJcD
             }
            return resJc
      }, 
      // initialData:[]
    });
  useEffect( async () => {
    try {
      
    let postId=null
    let paramsMarketPlaceListingTypeId=null
    if(params?.mode=="create"){
      if(params?.postIdOrMarketPlaceListingTypeId){
        paramsMarketPlaceListingTypeId=params?.postIdOrMarketPlaceListingTypeId
      } 
    }

    else if(params?.mode=="edit"){
      if(params?.postIdOrMarketPlaceListingTypeId){
        postId=params?.postIdOrMarketPlaceListingTypeId
      } 
    }

    let postData=null
    let marketPlaceListingData=null
    let addressData=null
    let attachmentsData=null
    let marketPlaceVehicleData=null
    let marketPlacePropertyData=null
    setFirstLoading(true)
    // if(false){
    if(postId){
    let apiData={
      id:postId,
      PostTypeId:4,
      // isDraft:true
    }
    let relativePath = "/api/post/getdraft";
    let postDataRes= await postDocuments("postvacancy",relativePath,apiData)
      if(postDataRes?.record){
        postData=postDataRes?.record
      }

    }
    else if(paramsMarketPlaceListingTypeId){ 
    let apiData={
    post:{ 
        PostTypeId:4,//markjet place
        isDraft:true
      },
    marketPlaceListing:{ 
        MarketPlaceListingTypeId:paramsMarketPlaceListingTypeId, 
      },
  }
    let relativePath = "/api/post/createdraft/marketplace";
      let postDataRes= await postDocuments("postvacancy",relativePath,apiData)
      if(postDataRes?.record){
        postData=postDataRes?.record
      }
    }


    if(postData){
        setProfile(postData?.Profile)
        marketPlaceListingData=postData?.MarketPlaceListing
        addressData=postData?.Address
        attachmentsData=postData?.Attachments
        marketPlacePropertyData=postData?.MarketPlaceListing?.MarketPlaceProperty
        marketPlaceVehicleData=postData?.MarketPlaceListing?.MarketPlaceVehicle
      // setPost(postData)
      setPostSectionValues(
        {
          id:{value:postData?.id ,required:true},
          isDraft:{value:false ,required:false},
          title:{value:postData?.title ,required:true},
          message:{value:postData?.message ,required:false} ,    
          // jobLocationTypeId:{value:1,required:false},//inPerson//remote//ontheroad
          // country: {value:"",required:false},
          // city: {value:postData?.city||"Addis Ababa",required:false},
          // subCity: {value:postData?.subCity||"Any",required:false},
          // specificArea: {value:postData?.specificArea||"Any",required:false},   
          // isExpired:{value:postData?.isExpired||false,required:false},   
          PostTypeId:{value:postData?.PostTypeId||4,required:false},  
          PostPrivacyId:{value:postData?.PostPrivacyId||1,required:false},  
        });
        
      setAddressSectionValues(
          { 
            CityId: {value:addressData?.CityId||1,required:false}, 
            subCity: {value:addressData?.subCity||"Any",required:false},
            specificArea: {value:addressData?.specificArea||"Any",required:false},  
          }); 
      // market listing

      setAttachementSectionValues(
        {  
          attachments:{value:attachmentsData ,required:false},
          newAttachments:{value:[] ,required:false},
        }
        )

      setListingSectionValues(
        { 
          id:{value:marketPlaceListingData?.id ,required:true},
          // PostId: {value:marketPlaceListingData?.PostId,required:false}, 
          price:  {value:marketPlaceListingData?.price,required:false}, 
          currency: {value:marketPlaceListingData?.currency,required:false}, 
          paymentDuration: {value:marketPlaceListingData?.duration,required:false},  
          // contactPhone1: {value:marketPlaceListingData?.contactPhone1,required:false}, 
          // contactPhone2: {value:marketPlaceListingData?.contactPhone2,required:false}, 
          isNegotiable:{value:marketPlaceListingData?.isNegotiable,required:false}, 
          isForRent: {value:marketPlaceListingData?.isForRent,required:false}, 
          isForSell: {value:marketPlaceListingData?.isForSell,required:false}, 
          serviceCharge:{value:marketPlaceListingData?.serviceCharge,required:false}, 
          MarketPlaceListingTypeId: {value:marketPlaceListingData?.MarketPlaceListingTypeId,required:false}, 
          MarketPlaceListingPersonTypeId: {value:marketPlaceListingData?.MarketPlaceListingPersonTypeId,required:false}, 
          MarketPlaceListingConditionId:{value:marketPlaceListingData?.MarketPlaceListingConditionId,required:false}, 
      })

      /// market vehicle
      setVehicleSectionValues(
        { 
          id:{value:marketPlaceVehicleData?.id ,required:true},
          seats: {value:marketPlaceVehicleData?.seats ,required:false},
          yearOfManufacture:{value:marketPlaceVehicleData?.yearOfManufacture ,required:false},
          engineSize: {value:marketPlaceVehicleData?.engineSize ,required:false},
          numberOfCylinders:{value:marketPlaceVehicleData?.numberOfCylinders ,required:false},
          capacity:{value:marketPlaceVehicleData?.capacity ,required:false},
          length:{value:marketPlaceVehicleData?.length ,required:false},
          width:{value:marketPlaceVehicleData?.width ,required:false},
          height:{value:marketPlaceVehicleData?.height ,required:false},
          numberOfTires: {value:marketPlaceVehicleData?.numberOfTires ,required:false},
          horsePower: {value:marketPlaceVehicleData?.horsePower ,required:false},
          model: {value:marketPlaceVehicleData?.model ,required:false},
          color:{value:marketPlaceVehicleData?.color ,required:false},
          interiorColor:{value:marketPlaceVehicleData?.interiorColor ,required:false},
          isPossibleToExchange:{value:marketPlaceVehicleData?.isPossibleToExchange ,required:false}, 

          VehicleTypeId:{value:marketPlaceVehicleData?.VehicleTypeId,required:false}, 
          VehicleMakeId:{value:marketPlaceVehicleData?.VehicleMakeId,required:false}, 
          VehicleTransmissionId:{value:marketPlaceVehicleData?.VehicleTransmissionId,required:false}, 
          FuelTypeId:{value:marketPlaceVehicleData?.FuelTypeId,required:false}, 
        }); 
      /// market vehicle
      
      setPropertySectionValues(
        { 
          id:{value:marketPlacePropertyData?.id ,required:true}, 
  
          isThereParking: {value:marketPlacePropertyData?.isThereParking,required:false},
          isThereLaundry: {value:marketPlacePropertyData?.isThereLaundry,required:false},
          isThereHeating: {value:marketPlacePropertyData?.isThereHeating,required:false},
          isThereAirConditioning: {value:marketPlacePropertyData?.isThereAirConditioning,required:false},

          area:{value:marketPlacePropertyData?.area,required:false},
          length:{value:marketPlacePropertyData?.FuelTypeId,required:false},
          width:{value:marketPlacePropertyData?.FuelTypeId,required:false},
          height:{value:marketPlacePropertyData?.FuelTypeId,required:false}, 
          numberOfBedRooms:{value:marketPlacePropertyData?.numberOfBedRooms,required:false},
          numberOfBedToilets:{value:marketPlacePropertyData?.numberOfBedToilets,required:false},
          parkingSpaces:{value:marketPlacePropertyData?.parkingSpaces,required:false},
          isFurnished:{value:marketPlacePropertyData?.isFurnished,required:false},
          catFriendly:{value:marketPlacePropertyData?.catFriendly,required:false},
          dogFriendly:{value:marketPlacePropertyData?.dogFriendly,required:false}, 

          MarketPlaceListingId:{value:marketPlacePropertyData?.MarketPlaceListingId,required:false},
          PropertyTypeId:{value:marketPlacePropertyData?.PropertyTypeId,required:false},
          ParkingTypeId:{value:marketPlacePropertyData?.ParkingTypeId,required:false}, 
          LaundryTypeId:{value:marketPlacePropertyData?.LaundryTypeId,required:false},
          HeatingTypeId:{value:marketPlacePropertyData?.HeatingTypeId,required:false},
          AirConditioningTypeId:{value:marketPlacePropertyData?.AirConditioningTypeId,required:false}, 
            
        } )
      }

    setForceRerender(!forceRerender)
    setFirstLoading(false)
    console.log("post", post);

  } catch (error) {
    console.log("post error", error);
      
  }
  }, [params?.postIdOrMarketPlaceListingTypeId]);

  const handleNext = () =>{
      console.log("active step",activeStep)
    setActiveStep(activeStep + 1)

    };

  const handleBack = () =>{ setActiveStep(activeStep - 1)};

  const handleReview =async () => { 
  
    setSubmitting(true)

    let basicInfoD= Object.entries(postSectionValues)  
    let basicInfoD2={}
    basicInfoD.forEach(element => {
     if(element.length){
      basicInfoD2[element[0]]=element[1].value
     }
    });
    submitAttachmentPost({id:basicInfoD2?.id})
    createPostAddress({id:basicInfoD2?.id})
 
    let requirementsD= Object.entries(listingSectionValues)  
    let requirementsD2={}
    requirementsD.forEach(element => {
     if(element.length){
      requirementsD2[element[0]]=element[1].value
     }
    }); 

    postDocuments("marketplacelisting", "/api/marketplace/marketplacelisting/edit/"+requirementsD2?.id, requirementsD2, {});
     
    if(requirementsD2?.MarketPlaceListingTypeId==3){
        let marketPlaceVehicleD = Object.entries(vehicleSectionValues)  
        let marketPlaceVehicleD2 = {}
        marketPlaceVehicleD.forEach(element => {
        if(element.length){
          marketPlaceVehicleD2[element[0]]=element[1].value
        }
        }); 
        postDocuments("marketplacevehicle", "/api/marketplace/marketplacevehicle/edit/"+marketPlaceVehicleD2?.id, marketPlaceVehicleD2, {});
      }

    if(requirementsD2?.MarketPlaceListingTypeId==2){
        let marketPlacePropertyD = Object.entries(propertySectionValues)  
        let marketPlacePropertyD2 = {}
        marketPlacePropertyD.forEach(element => {
        if(element.length){
          marketPlacePropertyD2[element[0]]=element[1].value
        }
        }); 
        postDocuments("marketplaceproperty", "/api/marketplace/marketplaceproperty/edit/"+marketPlacePropertyD2?.id, marketPlacePropertyD2, {});
      } 
      
      await postDocuments("post", "/api/post/edit/"+basicInfoD2?.id, basicInfoD2, {});
      setSubmitting(false) 
      navigate("/"+profile?.userName+"/posts/"+basicInfoD2?.id)
  };

  const handleSubmit = async(postData) => {
    try {
      if(postData){
        if(postData.id){

    let vacancyDataD= Object.entries(vacancyData)  
    let data={
      "PostId":postData.id, 
    }

    vacancyDataD.forEach(element => {
     if(element.length){
      if(element[1]){
          let vacancyDataDProperties=Object.entries(element[1])  
           vacancyDataDProperties.forEach(elementP => {
            if(element.length){
            // console.log("  elementP",   elementP[0]," : ",elementP[1])
            data[elementP[0]]=elementP[1]
            }
          })
      } 
     }
    });

    // console.log("  myBusinessEmploymentAgency",   myBusinessEmploymentAgency)
    // data.employmentAgencyId=myBusinessEmploymentAgency.id
    // data.employmentAgencyFullName=myBusinessEmploymentAgency.fullName||myBusinessEmploymentAgency.brandName
    // data.employmentAgencyEmail=myBusinessEmploymentAgency.contactEmail
    // data.employmentAgencyPhone=myBusinessEmploymentAgency.phone1||myBusinessEmploymentAgency.phone2

    // vacancyDataD.application=applicationD2
    // console.log("  data",   data)
    // return
    let relativePath = "/api/employmentjobvacancy/new";
    setLoading(true)
    let postvacancyRes= await postDocuments("postvacancy",relativePath,data)
    setLoading(false)
    setVacancySubmittedResponse(postvacancyRes)
      // setVacancyData(vacancyDataD)
      // setLoading(false)
      // setVacancySubmittedResponse(null)
      // setIsReviewSection(true)
      
        }
      }
    } catch (error) {
      
    }
    
  };

  const postNewJob = async() => {
    try { 
      if(myCurrentProfile){ 
          if(myCurrentProfile.id){
      
    let data={
      title :"Job Opening", 
      message :"Job Opening", 
      isExpired:false,  
      PostTypeId:3, 
      PostPrivacyId:1,  
    }
   
    setLoading(true)
    let newpostRes= await submitPost(data)
    if(newpostRes.success){
      if(newpostRes.record){
       let postData=newpostRes.record
        createPostAddress(postData)
        handleSubmit(postData)
    }
  }
    setLoading(false) 
      console.log("newpostRes",newpostRes)
      // setLoading(false)
      // setVacancySubmittedResponse(null)
      // setIsReviewSection(true)
    }
  } 
    } catch (error) {
      
      console.log("newpostRes error",error)
    }
    
  };
  

  const createPostAddress = async(postData) => {
    console.log("  createPostAddress")

    try {
      if(postData){
        if(postData.id){
          let fieldOfStudyD= Object.entries(addressSectionValues)  
          let fieldOfStudyD2={"PostId":postData.id, }
          fieldOfStudyD.forEach(element => {
          if(element.length){
            fieldOfStudyD2[element[0]]=element[1].value
          }
          }); 
        let relativePath = "/api/address/new";
        postDocuments("postvacancy",relativePath,fieldOfStudyD2)
        }
      }
    } catch (error) {
      console.log("createPostAddress",error)
    }
  };

  const submitPost = async (postData) => {
    let res = {};
    try { 
      let relativePath = "/api/post/new"; 
      let additionalData = {}; 
      let data=postData  
      let postFeedRes = await postDocuments("uploadFile", relativePath, data, additionalData);
      if (postFeedRes) {
        if (postFeedRes.success) { 
            res=postFeedRes 
        }
      }
      console.log("uploadFiles  ", postFeedRes, res);
      // props?.onClose();
    } catch (err) {
      console.log("uploadFiles err", err);
    }
    return res;
  };
 
  
  const submitAttachmentPost = async (postData) => {
    let res = [];
    try {

      let isPostWithAttachment = false;
      // let relativePath = "/api/post/newfeed";
      let relativePath = "/api/post/addpostattachment"; 
      let filesData = attachementSectionValues?.newAttachments?.value || [];
      // console.log("postAttachments",postAttachments)
      let filesDataNew = [];
      let additionalData = [];
      // let postData = dataD?.postData
      const formData = new FormData();

      // return
      const options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        fileType: "image/jpeg",
      };
      
      console.log("filesData",filesData)
      if (filesData) {
        if (filesData.length) { 
          isPostWithAttachment = true;
          for (let index = 0; index < filesData.length; index++) {
            const imageFile = filesData[index];
            console.log("img imageFile", imageFile);
            // imageFile.name="mesfin.jpg"
            const compressedFile = await imageCompression(imageFile, options);
            // const compressedFile = imageFile;
            filesDataNew.push(compressedFile);
          }

          console.log("compressedFiles",filesDataNew) 
          let filePath = "./public/files/post_files";
          const aa = _.filter(filesDataNew, (file) => {
            console.log("file",file) 

            formData.append("file", file,file.name);
            formData.append("fileTypeExtension", ".jpg");
            formData.append("folderPath", filePath);
            return file;
          });

          let postDataRes = Object.entries(postData);
          postDataRes.forEach((element) => {
            formData.append(element[0], element[1]);
          });
          // formData.append("fileTypeExtension", ".jpg");
          // formData.append("folderPath", filePath);

          additionalData = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            // onUploadProgress: progressEvent => {
            //   if(progressEvent){
            //     // console.log("progressEvent", Math.round((progressEvent.loaded * 100) / progressEvent.total))
            //       setuploadPercentage(parseInt(
            //       Math.round((progressEvent.loaded * 100) / progressEvent.total)
            //         ))
            //         }
            // }
          };
        }
      }
      let getHeaderAndURLRes = await HttpService.getHeaderAndURL();
      let baseUrl = getHeaderAndURLRes.baseUrl;

      console.log("img chng", formData, additionalData);
      const data = isPostWithAttachment ? formData : postData;
      
      
      // let toastId = toast.loading("posting...",{
      //   position: toast?.POSITION.BOTTOM_LEFT
      // })   
      let postFeedRes = await postDocuments("uploadFile", relativePath, data, additionalData);
    
      // toast?.update(toastId, { render: postFeedRes.success?"posted":"try again", type: postFeedRes.success?"success":"warn", isLoading: false , autoClose: 2000});
      
      if (postFeedRes) {
        if (postFeedRes.record) {
          if (postFeedRes.record.length) {
            postFeedRes.record.forEach((imgFile) => {
              // var destination=imgFile.destination.replace('./public',"");
              var destination = imgFile.destination.replace("./public", "/public");
              var filename = "/" + imgFile.filename;
              var pathN = baseUrl + destination + filename;
              var imgpath = pathN;
              res.push(imgpath);
            });
          }
        }
      }
      console.log("uploadFiles  ", postFeedRes, res);
      // props?.onClose;
    } catch (err) {
      console.log("uploadFiles err", err);
    }
    return res;
  };

const postDocuments = async (documentName, relativePath, data) => {
  let responseD = { success: false, message: "", record: null };
  let documentsResponse = { success: false }; 

  documentsResponse = await HttpService.postService(
    relativePath,
    data,
    {},
    ""
  );
  if (documentsResponse.data) {
    responseD.record = documentsResponse.data.record;
    responseD.message = documentsResponse.data.message;
  }
  if (documentsResponse.success) {
    responseD.success = true;
  } else {
    responseD.message = documentsResponse.message;

  }  
  return responseD; 
};
 
const validateCurrentSection = (values) =>
{
  // setIsCurrentSectionValid(isValid)
  let isValidSec=true
  let valuesEnt= Object.entries(values)   
    valuesEnt.forEach(element => {
    if(element.length){
      let elementD=element[1]||{}
      if(elementD.required){
      if(elementD.value==""||elementD.value==null){
          isValidSec=false
        }
      }
    }
    });
  //  console.log("  isValidSec",   isValidSec)
    setIsCurrentSectionValid(isValidSec)

}

const changeSectionValues = (field,values) =>
{
  try {
  // console.log("values index",field,values)
  if("postSectionValues"==field){
    setPostSectionValues(values)
  } 
  if("addressSectionValues"==field){
    setAddressSectionValues(values)
  } 
  if("listingSectionValues"==field){
    setListingSectionValues(values)
  } 
  if("vehicleSectionValues"==field){
    setVehicleSectionValues(values)
  } 
  if("propertySectionValues"==field){
    setPropertySectionValues(values)
  }  
  validateCurrentSection(values)
  // setForceRerender(!forceRerender)
    
  } catch (error) {
    console.log("errrrr",error)
  }
}

const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
  let res = null;
  // console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
  try {
    if (dataArray && searchField && searchValue) {
      if (dataArray.length > 0) {
        let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
        if (filteredLevels.length) {
          res = filteredLevels;
          if (returnRows == "single") {
            let row = filteredLevels[0];
            res = row;
            if (returnField) {
              let rowField = row[returnField];
              res = rowField;
            }
          }
        }
      }
    }
    // console.log("filtered city  ", res);
  } catch (error) {
    // console.log("filtered city error", error);
  }
  return res;
};

const getStepContent=(stepIndex)=> { 
  switch (stepIndex) {//stepIndex
    case 0:
      return postSectionValues?<BasicInfo getDataFromArray={getDataFromArray} validateSection={validateCurrentSection} sectionValues={postSectionValues} changeSectionValues={changeSectionValues}/>:null;
    case 1:
      return <AddressComp getDataFromArray={getDataFromArray} validateSection={validateCurrentSection} sectionValues={addressSectionValues} changeSectionValues={changeSectionValues}/> ;
    case 2:
      return postSectionValues?<AttachementInfo getDataFromArray={getDataFromArray} validateSection={validateCurrentSection} sectionValues={attachementSectionValues} changeSectionValues={changeSectionValues}/>:null;
    case 3:
      return listingSectionValues? <Requirements getDataFromArray={getDataFromArray} validateSection={validateCurrentSection} sectionValues={listingSectionValues} changeSectionValues={changeSectionValues} />:null;
    case 4:
      return <>
      {(propertySectionValues&&(listingSectionValues?.MarketPlaceListingTypeId?.value==2))?
        <MarketPlacePropertyComp getDataFromArray={getDataFromArray} validateSection={validateCurrentSection} sectionValues={propertySectionValues} changeSectionValues={changeSectionValues} />:null
      } 
      {vehicleSectionValues&&(listingSectionValues?.MarketPlaceListingTypeId?.value==3)?
        <MarketPlaceVehicleComp getDataFromArray={getDataFromArray} validateSection={validateCurrentSection} sectionValues={vehicleSectionValues} changeSectionValues={changeSectionValues} />:null
      }  
      </> 
    default:
      return null;
  }
} 
  
function Requirements(props) { 
  const [forceRerender, setForceRerender] = useState(false);
  const [sectionValues, setSectionValues] = useState(props.sectionValues);
  
  useEffect(() => {
    // console.log("props.sectionValues", props.sectionValues)
    props.validateSection(props.sectionValues)
  }, []);

  const changeInputValue = (field,value) =>
  {
    try {
    let sectionValuesD=sectionValues
    sectionValuesD[field].value=value
    setSectionValues(sectionValuesD)
    setForceRerender(!forceRerender)
    props.changeSectionValues("listingSectionValues",sectionValuesD)
    } catch (error) {
      
    }  }

  return (<>
  {forceRerender}
  <MKBox>


    <MKTypography variant="h5">Listing Info</MKTypography>
    <>
    <MKBox mt={3}>
      <Grid container spacing={3}>
        
      <Grid item xs={12} sm={12}  md={6} >
          <FormGroup>
          <FormControlLabel control={<Checkbox checked={sectionValues?.isForRent?.value} onChange={(e,v)=>  changeInputValue("isForRent", v)}/>} label="For Rent" />
          <FormControlLabel control={<Checkbox checked={sectionValues?.isForSell?.value}  onChange={(e,v)=>  changeInputValue("isForSell",v)}/>} label="For Sell" />
          </FormGroup>
        </Grid>
      <Grid item xs={12} sm={12}  md={6} >
          <FormGroup>  <FormControlLabel control={<Checkbox checked={sectionValues?.isNegotiable?.value}  onChange={(e,v)=>  changeInputValue("isNegotiable", v)}/>} label="Negotiable" />
          <FormControlLabel control={<Checkbox checked={sectionValues?.serviceCharge?.value}  onChange={(e,v)=>  changeInputValue("serviceCharge",v)}/>} label="Service Charge" />
        </FormGroup>
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="price"
            defaultValue={sectionValues?.price?.value}
            onChange={(e)=>{
                if(e){
                  changeInputValue("price",e.target?.value )
                }
              }}  placeholder="99.00" />
        </Grid>
        <Grid item xs={12} sm={12}  md={6} > 
          <Autocomplete defaultValue={sectionValues?.currency?.value}
            onChange={(e,val)=>{
              if(val){   
                  changeInputValue("currency", val)
                } 
            }}
            options={["ETB", "USD","BTC", "CNY", "EUR", "GBP", "INR"]}
            renderInput={(params) => <MKInput label={"Currency"} {...params}  />}
          />
        </Grid>
       {(sectionValues?.isForRent?.value)&&<Grid item xs={12} sm={12}  md={6} > 
        <Autocomplete 
            defaultValue={sectionValues?.paymentDuration?.value}
            onChange={(e,val)=>{
              if(val){   
                  changeInputValue("paymentDuration", val)
                } 
            }}
            options={["Hourly", "Daily","Weekly", "Monthly", "Annaul",]}
            renderInput={(params) => <MKInput label={"Payment Duration "} {...params}   />}
          /> 
        </Grid> }
        <Grid item xs={12} sm={12}  md={6} > 
        <Autocomplete  
            defaultValue={props.getDataFromArray(
              marketPlaceListingConditions,
                      "id",
                      sectionValues?.MarketPlaceListingConditionId?.value,
                      "single"
                    )}
            onChange={(e,val)=>{
              if(val){   
                  changeInputValue("MarketPlaceListingConditionId",val?.id)
                } 
            }}
            options={marketPlaceListingConditions||[]}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => <MKInput label={"Condition"} {...params}   />}
          /> 
        </Grid> 
        <Grid item xs={12} sm={12}  md={6} > 
        <Autocomplete  
            defaultValue={props.getDataFromArray(
              marketPlaceListingPersonTypes,
                      "id",
                      sectionValues?.MarketPlaceListingPersonTypeId?.value,
                      "single"
                    )}
            onChange={(e,val)=>{
              if(val){   
                  changeInputValue("MarketPlaceListingPersonTypeId",val?.id)
                } 
            }}
            options={marketPlaceListingPersonTypes||[]}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => <MKInput label={"Person Types"} {...params}   />}
          /> 
        </Grid> 
      </Grid>
    </MKBox>
        </>
  </MKBox></>
);
}

function BasicInfo(props) {
  const [forceRerender, setForceRerender] = useState(false);
  const [sectionValues, setSectionValues] = useState(props.sectionValues);
 
  useEffect(() => {
    console.log("props. BasicInfo"  );
    props.validateSection(props.sectionValues);
  }, []);

  const changeInputValue = (field, value) => {
    try {
    let sectionValuesD = sectionValues;
    sectionValuesD[field].value = value;
    setSectionValues(sectionValuesD);
    setForceRerender(!forceRerender);
    props.changeSectionValues("postSectionValues", sectionValuesD);
    } catch (error) {
      
    } };

  return (
    <>
      {forceRerender}
      <MKBox>
        <MKTypography variant="h5">Basic Information</MKTypography>
        <MKBox mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              {/* <MKBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MKTypography component="label" variant="button" fontWeight="regular" color="text">
                  Title&nbsp;&nbsp;
                  {sectionValues.title.required && (
                    <MKTypography variant="caption" color="text">
                      (required)
                    </MKTypography>
                  )}
                </MKTypography>
              </MKBox> */}
              <TextField fullWidth
                // type="text"
                label={"Title"}
                defaultValue={sectionValues?.title?.value||""}
                onChange={(e) => {
                  if (e) {
                    changeInputValue("title", e.target.value);
                  }
                }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
            <TextField  type="text" label="Weight" />
          </Grid> */}
          </Grid>
        </MKBox>
        <MKBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MKBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MKTypography component="label" variant="button" fontWeight="regular" color="text">
                  Short Description&nbsp;&nbsp;
                  {sectionValues.message.required && (
                    <MKTypography variant="caption" color="text">
                      (required)
                    </MKTypography>
                  )}
                </MKTypography>
              </MKBox>
              
        <MKBox spacing="2" mb={1} className={postStyles.postTextArea}>
          {" "} 
              <MKEditor
                value={sectionValues?.message?.value||""}
                //  onChange={setDescription}
                onChange={(e) => {
                  if (e) {
                    changeInputValue("message", e);
                  }
                }}
              />
        </MKBox>
            </Grid> 
 
          </Grid>
        </MKBox>  
      </MKBox>
    </>
  );
}

  
function AddressComp(props) {
  const [forceRerender, setForceRerender] = useState(false);
  const [sectionValues, setSectionValues] = useState(props.sectionValues);
 
  useEffect(() => { 
    props.validateSection(props.sectionValues);
  }, []);

  const changeInputValue = (field, value) => {
    try {
    let sectionValuesD = sectionValues;
    sectionValuesD[field].value = value;
    setSectionValues(sectionValuesD);
    setForceRerender(!forceRerender);
    props.changeSectionValues("addressSectionValues", sectionValuesD);
    } catch (error) {
      
    } };

  return (
    <>
      {forceRerender}
      <MKBox>
        <MKTypography variant="h5">Address </MKTypography>
        
        <MKBox mt={2}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={3}>
            <TextField  type="text" label="Salary" placeholder="99.00" />
          </Grid> */}
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            {/* <Autocomplete
              defaultValue="ETB"
              options={cities}
              renderInput={(params) => <MKInput {...params} variant="standard" />}
            /> */}
            
            {/* <MKBox mb={2} display="inline-block">
                <MKTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  City  &nbsp;&nbsp;
               {(sectionValues.city.required)&& <MKTypography variant="caption" color="text">
                  (required)
                </MKTypography>}
                </MKTypography>
              </MKBox> */}

          {(cities)&& <Autocomplete
                  fullWidth
                  id="optionsListComp"
                  // sx={{ width: 400 }}
                  groupBy={(option) =>  option["REGION"] } 
                  onChange={(e, val) => {
                    if (val) {
                    if(val.id){  
                      changeInputValue("CityId", val.id)
                    }
                    }
                  }}
                  value={props.getDataFromArray(
                    cities,
                    "id",
                    sectionValues?.CityId?.value,
                    "single"
                  )}
                  // "name",
                  // "REGION"
                  options={cities||[]} 
                  // defaultValue={options?(optionsSelectedField? value :{}):{}}
                  // defaultValue="ETB"
                  getOptionLabel={(option) =>  option["name"] 
                  }
                  renderInput={(params) => (
                    <MKInput {...params}  label="City"   id="optionsList"/>
                  )}
                />}



          </Grid>
         { (sectionValues?.CityId?.value==1)&&<>
          <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
            

          {(subCities)&& <Autocomplete
                  fullWidth
                  id="optionsListComp"
                  // sx={{ width: 400 }}
                  // groupBy={(option) =>  option["REGION"] } 
                  value={props.getDataFromArray(
                    subCities,
                    "title",
                    sectionValues?.subCity.value,
                    "single"
                  )}
                  onChange={(e, val) => {
                    if (val) {
                    if(val.title){  
                      changeInputValue("subCity", val.title)
                    }
                    }
                  }}
                  
                  // "name",
                  // "REGION"
                  options={subCities||[]} 
                  // defaultValue={options?(optionsSelectedField? value :{}):{}}
                  // defaultValue="ETB"
                  getOptionLabel={(option) =>  option["title"] 
                  }
                  renderInput={(params) => (
                    <MKInput {...params} label="Sub-city"   id="optionsList"/>
                  )}
                />}



          </Grid>
          <Grid item xs={12} sm={6} sx={{ mt: 2 }}>  
        
              {(specificAreas)&& <Autocomplete
                  fullWidth
                  id="optionsListComp"
                  // sx={{ width: 400 }}
                  // groupBy={(option) =>  option["REGION"] } 
                  value={props.getDataFromArray(
                    specificAreas,
                    "title",
                    sectionValues.specificArea.value,
                    "single"
                  )}
                  onChange={(e, val) => {
                    if (val) {
                    if(val.title){  
                      changeInputValue("specificArea", val.title)
                    }
                    }
                  }}
                  
                  // "name",
                  // "REGION"
                  options={specificAreas||[]} 
                  // defaultValue={options?(optionsSelectedField? value :{}):{}}
                  // defaultValue="ETB"
                  getOptionLabel={(option) =>  option["title"] 
                  }
                  renderInput={(params) => (
                    <MKInput {...params}  label="Specific Area"   id="optionsList"/>
                  )}
                />}
            {/* <TextField  type="text" label="SKU" placeholder="71283476591" /> */}
          </Grid>
          </>}
        </Grid>
      </MKBox> 
      </MKBox>
    </>
  );
}


function AttachementInfo(props) {
  const [forceRerender, setForceRerender] = useState(false);
  const [sectionValues, setSectionValues] = useState(props.sectionValues);
 
  useEffect(() => {
    // console.log("props.sectionValues", jobs);
    props.validateSection(props.sectionValues);
  }, []);

  
  const handleFileChange = async (filesData) => {
    try {
      if (filesData) {
        if (filesData.length) {
          // setPostAttachments(filesData);
          changeInputValue("newAttachments", filesData)
        }
      }
    } catch (error) {
      console.log("handleFileChange err", error);
    }
  };
  
  const deleteAttachment = async (index) => {
    let success=false
    try {
      let attachment=sectionValues?.attachments?.value[index]
      if (attachment) { 
        sectionValues?.attachments?.value?.splice(index, 1); 
        let resatt=await HttpService.getService("/api/attachment/delete/"+attachment?.id+"/post",{});
        if(resatt?.success){
           success=true
        }
        changeInputValue("attachments", sectionValues?.attachments?.value)
      }
     } catch (error) {
      console.log("handleFileChange err", error);
    }
    return success
  };


  const changeInputValue = (field, value) => {
    try {
    let sectionValuesD = sectionValues;
    sectionValuesD[field].value = value;
    setSectionValues(sectionValuesD);
    setForceRerender(!forceRerender);
    props.changeSectionValues("attachementSectionValues", sectionValuesD);
    } catch (error) {
      
    } };

  return (
    <>
      {forceRerender}
      <MKBox>
        <MKTypography variant="h5">Attachement</MKTypography>
         
        <MKBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MKBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                 
        <MKBox spacing="2" p={2}>
          <DropzoneArea
            showAlerts={["error", "info"]}
            showPreviews
            initialFiles={sectionValues?.newAttachments?.value||[]}
            filesLimit={10}
            maxFileSize={50000000}
            showPreviewsInDropzone={false}
            dropzoneClass={postStyles.dropzoneClass}
            acceptedFiles={["image/*"]}
            dropzoneText={
              isMobile ? "Add Photo" : "Drag and drop images here or click"
            }
            // onChange={(files) => console.log("Files:", files)}
            onChange={(e) => {
              handleFileChange(e);
            }}
          />
        </MKBox>
              </MKBox>
               
            </Grid> 
          {(sectionValues?.attachments?.value?.length?true:false)&&  <Grid item xs={12} sm={12}>
              <MKBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <ImageList
      sx={{
        width: 500,
        height: 450,
        // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
        transform: 'translateZ(0)',
      }}
      // rowHeight={200}
      // gap={1}
    >
      {sectionValues?.attachments?.value?.map((item,index) => {
        const cols = item?.featured ? 2 : 1;
        const rows = item?.featured ? 2 : 1;
        let deleted=false
        return (!deleted&&
          <ImageListItem key={item.url} cols={cols} rows={rows}>
            <img
              {...srcset(item.url, 250, 200, rows, cols)}
              alt={"item.title"}
              loading="lazy"
            />
            <ImageListItemBar
              sx={{
                background:
                  'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                  'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
              }}
              // title={"Delete"}
              position="top"
              actionIcon={
                <IconButton
                  // sx={{ }} 
                  onClick={async()=>deleted=await deleteAttachment(index)}
                 sx={{ backgroundColor: 'white', color: pink[500] }}
                  // aria-label={`star ${"item.title"}`}
                >
                  <Delete />
                </IconButton>
              }
              actionPosition="right"
            />
          </ImageListItem>
        );
      })}
    </ImageList>
              </MKBox>
              </Grid>}
 
          </Grid>
        </MKBox> 
      </MKBox>
    </>
  );
}

function MarketPlaceVehicleComp(props) { 
  const [forceRerender, setForceRerender] = useState(false);
  const [sectionValues, setSectionValues] = useState(props.sectionValues);
   
  let {data:fuelTypes} = useQuery({
    queryKey: ["/api/marketplace/fueltype/all/"],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/marketplace/fueltype/all/");
    }, 
    initialData:[]
  });

  let {data:vehicleTransmissions} = useQuery({
    queryKey: ["/api/marketplace/vehicletransmission/all/"],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/marketplace/vehicletransmission/all/");
    }, 
    initialData:[]
  });

  let {data:vehicleMakes} = useQuery({
    queryKey: ["/api/marketplace/vehiclemake/all/"],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/marketplace/vehiclemake/all/");
    }, 
    initialData:[]
  });

  let {data:vehicleTypes} = useQuery({
    queryKey: ["/api/marketplace/vehicletype/all/"],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/marketplace/vehicletype/all/");
    }, 
    initialData:[]
  }); 

  useEffect(() => {
    // console.log("props.sectionValues", props.sectionValues)
    props.validateSection(props.sectionValues)
  }, []);

  const changeInputValue = (field,value) =>
  {

    let sectionValuesD=sectionValues
    sectionValuesD[field].value=value
    setSectionValues(sectionValuesD)
    setForceRerender(!forceRerender)
    props.changeSectionValues("vehicleSectionValues",sectionValuesD)
  }

  return (<>
  {forceRerender}
  <MKBox>


    <MKTypography variant="h5"> Vehicle Info</MKTypography>
     <>
    <MKBox mt={3}>
      <Grid container spacing={3}>
         
         <Grid item xs={12} sm={12}  md={6} > 
         <Autocomplete  
             onChange={(e,val)=>{
               if(val){   
                   changeInputValue("VehicleTypeId", val?.id)
                 } 
             }}
             defaultValue={props.getDataFromArray(
               vehicleTypes,
                       "id",
                       sectionValues?.VehicleTypeId?.value,
                       "single"
                     )}
             options={vehicleTypes||[]}
             getOptionLabel={(option) => option?.title}
             renderInput={(params) => <MKInput label={"Vehicle Type "} {...params}   />}
           /> 
         </Grid> 
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Year Of Manufacture"
             defaultValue={sectionValues?.yearOfManufacture?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("yearOfManufacture",e.target?.value )
                }
              }}   />
        </Grid>
         <Grid item xs={12} sm={12}  md={6}  > 
         <Autocomplete 
             defaultValue={props.getDataFromArray(
               vehicleMakes,
                       "id",
                       sectionValues?.VehicleMakeId?.value,
                       "single"
                     )}
             onChange={(e,val)=>{
               if(val){   
                   changeInputValue("VehicleMakeId",val?.id)
                 } 
             }}
             options={vehicleMakes||[]}
             getOptionLabel={(option) => option.title}
             renderInput={(params) => <MKInput label={"Vehicle Make "} {...params}   />}
           /> 
         </Grid> 
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Model"
             defaultValue={sectionValues?.model?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("model",e.target?.value )
                }
              }}   />
        </Grid>
         <Grid item xs={12} sm={12}  md={6} > 
         <Autocomplete 
             defaultValue={props.getDataFromArray(
               fuelTypes,
                       "id",
                       sectionValues?.FuelTypeId?.value,
                       "single"
                     )}
             onChange={(e,val)=>{
               if(val){   
                   changeInputValue("FuelTypeId",val?.id)
                 } 
             }}
             options={fuelTypes||[]}
             getOptionLabel={(option) => option.title}
             renderInput={(params) => <MKInput label={"Fuel Type "} {...params}   />}
           /> 
         </Grid> 
         <Grid item xs={12} sm={12}  md={6}  > 
         <Autocomplete 
             defaultValue={props.getDataFromArray(
               vehicleTransmissions,
                       "id",
                       sectionValues?.VehicleTransmissionId?.value,
                       "single"
                     )}
             onChange={(e,val)=>{
               if(val){   
                   changeInputValue("VehicleTransmissionId",val?.id)
                 } 
             }}
             options={vehicleTransmissions||[]}
             getOptionLabel={(option) => option.title}
             renderInput={(params) => <MKInput label={"Vehicle Transmission "} {...params}   />}
           /> 
         </Grid> 
        <Grid item xs={12} sm={12}  md={6} > 
          <Autocomplete defaultValue={sectionValues?.color?.value}  sx={{p:0,m:0}} 
        
            onChange={(e,val)=>{
              if(val){   
                  changeInputValue("color", val)
                } 
            }}
            options={["white", "black","green", "other" ]}
            renderInput={(params) => <MKInput label={"Color"} {...params}  />}
          />
        </Grid>
        <Grid item xs={12} sm={12}  md={6} > 
          <Autocomplete defaultValue={sectionValues?.interiorColor?.value}  
            onChange={(e,val)=>{
              if(val){   
                  changeInputValue("interiorColor", val)
                } 
            }}
            options={["white", "black","green", "other" ]}
            renderInput={(params) => <MKInput label={"Interior Color"} {...params}  />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Number Of Seats"
             defaultValue={sectionValues?.seats?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("seats",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Number Of Tires"
             defaultValue={sectionValues?.numberOfTires?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("numberOfTires",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Number Of Cylinders"
             defaultValue={sectionValues?.numberOfCylinders?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("numberOfCylinders",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Engine Size"
             defaultValue={sectionValues?.engineSize?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("engineSize",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Capacity"
             defaultValue={sectionValues?.capacity?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("capacity",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Length"
             defaultValue={sectionValues?.length?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("length",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Width"
             defaultValue={sectionValues?.width?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("width",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Height"
             defaultValue={sectionValues?.height?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("height",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Horse Power"
             defaultValue={sectionValues?.horsePower?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("horsePower",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12}  md={6} sx={{ mt: 2 }}>
          <FormGroup>
          <FormControlLabel control={<Checkbox checked={sectionValues?.isPossibleToExchange?.value} onChange={(e,v)=>  changeInputValue("isPossibleToExchange", v)}/>} label="is Possible To Exchange" />
            </FormGroup>
        </Grid>
      </Grid>
    </MKBox>
        </>
  </MKBox></>
);
}


function MarketPlacePropertyComp(props) { 
  const [forceRerender, setForceRerender] = useState(false);
  const [sectionValues, setSectionValues] = useState(props.sectionValues);
   
  let {data:propertyTypes} = useQuery({
    queryKey: ["/api/marketplace/propertytype/all/"],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/marketplace/propertytype/all/");
    }, 
    initialData:[]
  }); 

  let {data:parkingTypes} = useQuery({
    queryKey: ["/api/marketplace/parkingtype/all/"],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/marketplace/parkingtype/all/");
    }, 
    initialData:[]
  }); 

  let {data:laundryTypes} = useQuery({
    queryKey: ["/api/marketplace/laundrytype/all/"],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/marketplace/laundrytype/all/");
    }, 
    initialData:[]
  }); 

  let {data:heatingTypes} = useQuery({
    queryKey: ["/api/marketplace/heatingtype/all/"],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/marketplace/heatingtype/all/");
    }, 
    initialData:[]
  }); 

  let {data:airConditioningTypes} = useQuery({
    queryKey: ["/api/marketplace/airconditioningtype/all/"],
    queryFn: async () => {
      // let jobTitle = employmentJobVacancyRes.record.title || "Unknown";
      // ReactGA.send({ hitType: "pageview", page: location.pathname, title: jobTitle });
      return await HttpService.getApiData("/api/marketplace/airconditioningtype/all/");
    }, 
    initialData:[]
  }); 

  useEffect(() => {
    // console.log("props.sectionValues", props.sectionValues)
    props.validateSection(props.sectionValues)
  }, []);

  const changeInputValue = (field,value) =>
  {
    try {
    let sectionValuesD=sectionValues
    sectionValuesD[field].value=value
    setSectionValues(sectionValuesD)
    setForceRerender(!forceRerender)
    props.changeSectionValues("propertySectionValues",sectionValuesD)
 
    } catch (error) {
      
    } }

  return (<>
  {forceRerender}
  <MKBox>


    <MKTypography variant="h5"> Advanced Property Info</MKTypography>
     <>
    <MKBox mt={3}>
      <Grid container spacing={3}>
         
        <Grid item xs={12} sm={12}  md={6}  >
          <FormGroup>
          <FormControlLabel control={<Checkbox checked={sectionValues?.isThereParking?.value} onChange={(e,v)=>  changeInputValue("isThereParking", v)}/>} label="is There Parking?" />
            </FormGroup> 
          <FormGroup>
          <FormControlLabel control={<Checkbox checked={sectionValues?.isThereLaundry?.value} onChange={(e,v)=>  changeInputValue("isThereLaundry", v)}/>} label="is There Laundry?" />
            </FormGroup> 
          <FormGroup>
          <FormControlLabel control={<Checkbox checked={sectionValues?.isThereHeating?.value} onChange={(e,v)=>  changeInputValue("isThereHeating", v)}/>} label="is There Heating?" />
            </FormGroup> 
            
        </Grid>
        <Grid item xs={12} sm={12}  md={6}  >
          <FormGroup>
          <FormControlLabel control={<Checkbox checked={sectionValues?.isThereAirConditioning?.value} onChange={(e,v)=>  changeInputValue("isThereAirConditioning", v)}/>} label="is There Air Conditioning" />
            </FormGroup> 
          <FormGroup>
          <FormControlLabel control={<Checkbox checked={sectionValues?.isPossibleToExchange?.value} onChange={(e,v)=>  changeInputValue("isPossibleToExchange", v)}/>} label="is Possible To Exchange" />
            </FormGroup>
        </Grid>

         <Grid item xs={12} sm={12}  md={6} > 
         <Autocomplete  
             onChange={(e,val)=>{
               if(val){   
                   changeInputValue("PropertyTypeId",val?.id)
                 } 
             }}
             defaultValue={props.getDataFromArray(
              propertyTypes,
                       "id",
                       sectionValues?.PropertyTypeId?.value,
                       "single"
                     )}
             options={propertyTypes||[]}
             getOptionLabel={(option) => option?.title}
             renderInput={(params) => <MKInput label={"Property Type "} {...params}   />}
           /> 
         </Grid> 
        {(sectionValues?.isThereParking?.value)&& <Grid item xs={12} sm={12}  md={6} > 
         <Autocomplete  
             onChange={(e,val)=>{
               if(val){   
                   changeInputValue("ParkingTypeId",val?.id)
                 } 
             }}
             defaultValue={props.getDataFromArray(
              parkingTypes,
                       "id",
                       sectionValues?.ParkingTypeId?.value,
                       "single"
                     )}
             options={parkingTypes||[]}
             getOptionLabel={(option) => option?.title}
             renderInput={(params) => <MKInput label={"Parking Type "} {...params}   />}
           /> 
         </Grid> }
         
         {(sectionValues?.isThereLaundry?.value)&& <Grid item xs={12} sm={12}  md={6} > 
         <Autocomplete  
             onChange={(e,val)=>{
               if(val){   
                   changeInputValue("LaundryTypeId",val?.id)
                 } 
             }}
             defaultValue={props.getDataFromArray(
              laundryTypes,
                       "id",
                       sectionValues?.LaundryTypeId?.value,
                       "single"
                     )}
             options={laundryTypes||[]}
             getOptionLabel={(option) => option?.title}
             renderInput={(params) => <MKInput label={"Laundry Type "} {...params}   />}
           /> 
         </Grid> }
       
         {(sectionValues?.isThereHeating?.value)&& <Grid item xs={12} sm={12}  md={6} > 
         <Autocomplete  
             onChange={(e,val)=>{
               if(val){   
                   changeInputValue("HeatingTypeId",val?.id)
                 } 
             }}
             defaultValue={props.getDataFromArray(
              heatingTypes,
                       "id",
                       sectionValues?.HeatingTypeId?.value,
                       "single"
                     )}
             options={heatingTypes||[]}
             getOptionLabel={(option) => option?.title}
             renderInput={(params) => <MKInput label={"Heating Type "} {...params}   />}
           /> 
         </Grid> }
        
         {(sectionValues?.isThereAirConditioning?.value)&&  <Grid item xs={12} sm={12}  md={6} > 
         <Autocomplete  
             onChange={(e,val)=>{
               if(val){   
                   changeInputValue("AirConditioningTypeId",val?.id)
                 } 
             }}
             defaultValue={props.getDataFromArray(
              airConditioningTypes,
                       "id",
                       sectionValues?.AirConditioningTypeId?.value,
                       "single"
                     )}
             options={airConditioningTypes||[]}
             getOptionLabel={(option) => option?.title}
             renderInput={(params) => <MKInput label={"Air Conditioning Type "} {...params}   />}
           /> 
         </Grid>  }

        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Area"
             defaultValue={sectionValues?.area?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("area",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Length"
             defaultValue={sectionValues?.length?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("length",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Width"
             defaultValue={sectionValues?.width?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("width",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Height"
             defaultValue={sectionValues?.height?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("height",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Number Of Bed Rooms"
             defaultValue={sectionValues?.numberOfBedRooms?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("numberOfBedRooms",e.target?.value )
                }
              }}   />
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Number Of Toilets"
             defaultValue={sectionValues?.numberOfToilets?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("numberOfToilets",e.target?.value )
                }
              }}   />
        </Grid>
       
        {(sectionValues?.isThereParking?.value)&&  <Grid item xs={12} sm={12} md={6}> 
          <TextField fullWidth type="number" label="Parking Spaces"
             defaultValue={sectionValues?.parkingSpaces?.value}
             onChange={(e)=>{
                if(e){
                  changeInputValue("parkingSpaces",e.target?.value )
                }
              }}   />
        </Grid>}
      
      </Grid>
    </MKBox>
        </>
  </MKBox></>
);
}

  return ( 
      <MKBox mx={1}  mb={9}>
        <Grid container justifyContent="center"> 
        {(!loading&&(vacancySubmittedResponse==null))&&  <Grid item xs={12} lg={10}>
            <MKBox my={3} textAlign="center">
              <MKBox mb={1}>
                <MKTypography uppercase variant="h3" fontWeight="bold">
                 {mode+" "+getDataFromArray(marketPlaceListingTypes,"id",listingSectionValues?.MarketPlaceListingTypeId?.value,"single","title")}
                </MKTypography>
              </MKBox>
              <MKTypography variant="h5" fontWeight="regular" color="secondary">
                The below information will describe more about the listing.
              </MKTypography>
            </MKBox>
             {!isReviewSection?
            <Card> <div>
              <MKBox mt={-3} mb={3} mx={2}>
               { isMobile? null:<Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>
                         {label}  </StepLabel>
                    </Step>
                  ))}
                </Stepper>}
              </MKBox>
              <MKBox p={2}>
                <MKBox> 
                  {getStepContent(activeStep)}
                  <MKBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <MKBox />
                    ) : (
                      <MKButton disabled={submitting||loading} variant="gradient" color="light" onClick={handleBack}>
                        back
                      </MKButton>
                    )}
                    <MKButton
                      disabled={!isCurrentSectionValid||submitting||loading}
                      variant="gradient"
                      color="success"
                      onClick={!isLastStep ? handleNext : handleReview}
                    >
                      {isLastStep ? "Finish and Publish" : "next"}
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox></div> 
               </Card>:<>
              {
              <>
              {/* <VacancyFewDetail 
              vacancyData={vacancyData}
              // vacancyData={{basicInfo:postSectionValues,requirements:listingSectionValues,benefits:vehicleSectionValues,location:locationSectionValues,application:applicationSectionValues}}
              /> */}
             
              <MKBox p={2}>
                <MKBox> 
                  <MKBox mt={3} width="100%" display="flex" justifyContent="space-between">
                      <MKButton variant="gradient" color="light" onClick={()=>setIsReviewSection(false)}>
                        Edit
                      </MKButton> 
                    <MKButton
                      // disabled={!isCurrentSectionValid}
                      variant="gradient"
                      color="success"
                      onClick={!isLastStep ? handleNext : postNewJob}
                    >
                      {"Submit"}
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox> </>}
          </>}
       
          </Grid>}

          {loading&&<Grid item xs={12} lg={10}>
            <MKBox p={2}>
                <MKBox> 
                <MKBox mb={1}>
              </MKBox>
              {/* <MKTypography variant="h5" fontWeight="regular" color="secondary">
                This information will describe more about the Vacancy.
              </MKTypography> */}
                  <MKBox mb={1} width="100%" display="flex" justifyContent="center">
                       <LinearProgress />
                       <CircularProgress />
                   </MKBox>
                  <MKBox mt={3} width="100%" display="flex" justifyContent="center">
                      <MKBox mb={1} width="100%" display="flex" justifyContent="center">
                        <MKTypography variant="h5" >
                          Submitting The Vacancy
                        </MKTypography>
                      </MKBox> 
                  </MKBox>
                </MKBox>
              </MKBox> 
            </Grid>}
            
          { vacancySubmittedResponse&&<Grid item xs={12} lg={10}>
            <MKBox p={2}>
                <MKBox>  
              {/* <MKTypography variant="h5" fontWeight="regular" color="secondary">
                This information will describe more about the Vacancy.
              </MKTypography> */} 
                  <MKBox mt={3} width="100%" display="flex" justifyContent="center">
                      <MKBox mb={1} width="100%" display="flex" justifyContent="center">
                        <MKTypography variant="h5" >
                          {vacancySubmittedResponse.message}
                        </MKTypography>
                      </MKBox> 
                  </MKBox>
 
                  <MKBox p={2}>
                  <MKBox mt={3}   width="100%" display="flex" justifyContent="center">
                     {!vacancySubmittedResponse.success&&
                       <MKButton variant="gradient" color="light" onClick={handleReview}>
                        Back To Review
                      </MKButton> }
                    {vacancySubmittedResponse.success&&<MKButton
                      // disabled={!isCurrentSectionValid}
                      variant="gradient"
                      color="success"
                       
                        size={"small"}
                        to={"/Employment/Job-Vacancies"}
                        component={Link}  
                      // onClick={!isLastStep ? handleNext : handleReview}
                    >
                      {"Go To Vacancy List"}
                    </MKButton>}
                  </MKBox> 
                </MKBox> 

                </MKBox>
              </MKBox> 
            </Grid>}

          {/* set vacancySubmittedResponse */}


        </Grid> 
{forceRerender}
      </MKBox> 
  );
}

export default CreateMarketPlacePost;
