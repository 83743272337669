/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
// Images
import { cities } from "Api-master-datas/masterDatas.js";

import ReactGA from "react-ga4";
import profilePicture from "assets/images/bruce-mars.jpg";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import Card from "@mui/material/Card";
import React, { useEffect } from "react";
import {
  AddCircle,
  ArrowDropDownCircleOutlined,
  Edit,
  FormatBold,
  FormatItalic,
  Logout,
  Menu,
  Paid,
  Place,
  Sell,
  Send,
} from "@mui/icons-material";
import MKInput from "components/MKInput";
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SwipeableDrawer,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { HttpService } from "Api-Services/httpService";
import { CommonServices } from "Api-Services/commonServices";
import { isMobile } from "react-device-detect";
import SwitchMyProfilesBox from "./SwitchMyProfilesBox";
import { CommonApiServices } from "Api-Services/commonApiServices";
import TruncateMarkup from "react-truncate-markup";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { Link } from "react-router-dom";
 
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

function ProfileHeader(props) {
  const [currentData, updateCurrentUser] = useCurrentUser();
  const { myFriendships, myCurrentProfile, currentUser, jobs } = currentData;
  const [currentProfile, setCurrentProfile] = React.useState(null);
  const [forceRerender, setForceRerender] = React.useState(false);
  const [isThisMyProfile, setIsThisMyProfile] = React.useState(false);
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [newWorkExperianceData, setNewWorkExperianceData] = React.useState({
    description: "",
    expectingSalaryPerHour: null,
    expectingSalaryPerMonth: null,
    isAvailable: true,
    jobId: null,
    workExperianceInMonth: null,
    skillsOrSubjects: [],
  });

  useEffect(() => {
    // console.log("props currentProfile", props);
    // ReactGA.send({ hitType: "pageview", page: location.pathname, title: "JobSeeker Profile" });
    if (props.currentProfile) {
      setCurrentProfile(props.currentProfile);
    }
    //  if(props.isThisMyProfile){
    setIsThisMyProfile(props.isThisMyProfile);
    // }
    setForceRerender(!forceRerender);
  }, [currentUser, props.isThisMyProfile, props.currentProfile]);

  const postDocuments = async (documentName, relativePath, data, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    documentsResponse = await HttpService.postService(relativePath, data, additionalData || {}, "");

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    } else {
      responseD.record = documentsResponse.data;
    }
    return responseD;
  };

  const getCityById = (city, rows, field) => {
    let res = field ? "" : {};
    try {
      if (cities && city) {
        let filteredLevels = cities.filter((level) => level.TOWN_NAME == city);
        if (filteredLevels.length) {
          res = filteredLevels;
          if (rows == "single") {
            let row = filteredLevels[0];
            res = row;
            if (field) {
              let rowField = row[field];
              res = rowField;
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  };

  const handleNewWorkExperianceData = async (field, value) => {
    try {
      let data = newWorkExperianceData;
      if (field && value) {
        data[field] = value;
        setNewWorkExperianceData(data);
        setForceRerender(!forceRerender);
      }
    } catch (error) {}
  };

  const addNewWorkExperiance = async () => {
    // console.log("data", dataD,parentData, dataParentName,fieldType);
    // return
    try {
      let data = newWorkExperianceData;
      let relativePath = "/api/employmentjobseekerworkexperiance/new";
      let refreshVariable = "currentProfile";
      if (data) {
        data.jobSeekerId = currentProfile.id;

        if (data.skillsOrSubjects) {
          let skils = "";
          if (data.skillsOrSubjects.length) {
            data.skillsOrSubjects.forEach((element) => {
              skils = skils + (skils ? "," : "") + element;
            });
          }
          data.skillsOrSubjects = skils;
        }
      }

      if (data.jobSeekerId) {
        if (data) {
          // setLoadingSave(true);
          let changeUserProfileRes = await postDocuments("changeUserInfo", relativePath, data);
          //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
          // setLoadingSave(false);

          if (changeUserProfileRes.success) {
            setOpenedDialog("");
            updateCurrentUser(refreshVariable);
          }
        }
      }
    } catch (error) {}
  };

  function FriendshipButton() {
    const [friendshipType, setFriendshipType] = React.useState("");
    useEffect(() => {
      if (currentProfile) {
        let fT = ""//CommonServices.checkFriendship(friendshipItemR.id, myFriendships);
       
            if (currentProfile?.iAmFollowing  && currentProfile?.myFollower) {
              fT = "friend";
            } else if (currentProfile?.myFollower) {
              fT = "follower";
            } else if (currentProfile?.iAmFollowing) {
              fT = "following";
            } 
        if (fT) {
          if ( (currentProfile?.id ? true : false)) {
            console.log("fT", fT);
            setFriendshipType(fT);
          }
        }
      }
    }, [currentProfile]);

    let res = <></>;
    try {
      res = (
        <>
          {currentProfile && (
            <>
              {friendshipType == "friend" ? null : friendshipType == "follower" ? (
                <Chip
                  sx={{ px: "15px" }}
                  color={"info"}
                  icon={<AddCircle />}
                  onClick={() => {
                    CommonApiServices.follow(currentProfile.id);
                    setFriendshipType("friend");
                  }}
                  // label={"Follow"}
                  label={"Follow Back"}
                />
              ) : friendshipType == "following" ? (
                <Chip
                  sx={{ px: "15px" }}
                  icon={<Logout />}
                  onClick={() => {
                    CommonApiServices.unfollow(currentProfile.id);
                    setFriendshipType("");
                    // console.log("mmmmmmmmmmm", following);
                  }}
                  // label={"Follow"}
                  label={"Unfollow"}
                />
              ) : (
                <Chip
                  sx={{ px: "15px" }}
                  color={"info"}
                  icon={<AddCircle />}
                  onClick={() => {
                    CommonApiServices.follow(currentProfile.id);
                    setFriendshipType("following");
                    // console.log("mmmmmmmmmmm", following);
                  }}
                  // label={"Follow"}
                  label={"Follow"}
                />
              )}
            </>
          )}
        </>
      );
    } catch (error) {
      // console.log("FriendshipListItem errr", error);
    }
    return res;
  }

  return (
    <>
      {" "}
      <MKBox mb={2} mt={isMobile ? 2 : 2}>
        {/* <Grid container item xs={12} justifyContent="center" mx="auto">
              <Grid container justifyContent="center"> */}
        {currentProfile && (
          <Card sx={{ width: "100%" }}>
            <Grid container alignItems="center">
              <Grid p={2}  item xs={12} lg={12}> 
                  <List>
                    {/* <Divider/> */}
                    {currentProfile && (
                      <>
                        <Grid
                          container
                          sx={{ p: "0px" }}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                        >
                          <Grid
                            item
                            xs={12}
                            // md={6} lg={6}
                            px={isMobile ? 0 : 1}
                            py={isMobile ? 0 : 0}
                          >
                            <ListItem  
                            >
                              <ListItemAvatar sx={{ marginRight: "10px" }}>
                                <PhotoProvider>
                                  {" "}
                                  <PhotoView key={1} src={currentProfile.profileImageUrl}>
                                    {currentProfile.isAvailable ? (
                                      <StyledBadge
                                        // sx={{  marginLeft: "-10px" }}
                                        color="primary"
                                        // overlap="circular"
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        variant="dot"
                                      >
                                        <Avatar
                                          sx={{
                                            width: isMobile ? 90 : 100,
                                            height: isMobile ? 90 : 100,
                                            marginBottom: "-10px",
                                            marginRight: "-10px",
                                          }}
                                          variant="circular"
                                          src={
                                            currentProfile
                                              ? currentProfile.profileImageUrl
                                              : profilePicture
                                          }
                                          // size="xl"
                                          // shadow="xl"
                                        />
                                      </StyledBadge>
                                    ) : (
                                      <Avatar
                                        sx={{
                                          width: isMobile ? 90 : 100,
                                          height: isMobile ? 90 : 100,
                                        }}
                                        variant="circular"
                                        src={
                                          currentProfile
                                            ? currentProfile.profileImageUrl
                                            : profilePicture
                                        }
                                        // size="xxl"
                                        // shadow="xl"
                                      />
                                    )}
                                  </PhotoView>
                                </PhotoProvider>
                              </ListItemAvatar>
                              <ListItemText
                              
                                secondary={
                                <TruncateMarkup
                                    lines={5} 
                                  >
                                  <MKTypography variant={ "body2" }>
                                    {"@"+currentProfile?.userName}
                                  </MKTypography>
                                </TruncateMarkup>
                                }
                                primary={
                                    <TruncateMarkup
                                        lines={5} 
                                      >
                                      <MKTypography variant={ "h5" }>
                                        {CommonServices.getProfileName(currentProfile)}
                                      </MKTypography>
                                      </TruncateMarkup>
                                }
                              />
                            </ListItem>
                          </Grid>
                          {/* <Divider orientation="vertical" flexItem />  */}
                          <Grid
                            item
                            xs={12}
                            // md={6}
                            // lg={6}
                            px={isMobile ? 0 : 3}
                            py={isMobile ? 0 : 0}
                            alignItems="flex-end"
                          >
                            <ListItem>
                              <ListItemText
                                primary={
                                  <Grid
                                    sx={{ my: 2 }}
                                    container
                                    direction="row"
                                    justifyContent={isMobile ? "space-around" : "flex-start"}
                                    alignItems="flex-start"
                                  >
                                    <Box
                                      sx={{ mx: 1, alignItems: "center", cursor: "pointer" }}
                                      onClick={() => {
                                        let data = {
                                          dialogId: "FriendshipDialogBox",
                                          data: {
                                            profile: currentProfile,
                                            friendshipTab: "followers",
                                          },
                                        };
                                        updateCurrentUser("currentGlobalDialogBoxData", data);
                                      }}
                                    >
                                      <Typography color="dark" variant="h6" size="small">
                                        {currentProfile.followersCount || 0}
                                      </Typography>
                                      <Typography variant="caption">Followers</Typography>
                                    </Box>
                                    <Box
                                      sx={{ mx: 1, alignItems: "center", cursor: "pointer" }}
                                      onClick={() => {
                                        let data = {
                                          dialogId: "FriendshipDialogBox",
                                          data: {
                                            profile: currentProfile,
                                            friendshipTab: "followings",
                                          },
                                        };
                                        updateCurrentUser("currentGlobalDialogBoxData", data);
                                      }}
                                    >
                                      <Typography color="dark" variant="h6" size="small">
                                        {currentProfile.followingCount || 0}
                                      </Typography>
                                      <Typography gutterBottom variant="caption">
                                        Following
                                      </Typography>
                                    </Box>
                                    <Box sx={{ mx: 1, alignItems: "center", cursor: "pointer" }}>
                                      <Typography variant="h6" size="small">
                                        {currentProfile.postsCount || 0}
                                      </Typography>
                                      <Typography gutterBottom variant="caption">
                                        Posts
                                      </Typography>
                                    </Box>
                                  </Grid>
                                }
                                secondary={
                                  currentProfile &&
                                  myCurrentProfile && (
                                    <>
                                      {isThisMyProfile ? (
                                        <>
                                          <Chip
                                            sx={{ mr: "13px", px: "15px" }}
                                            icon={<Edit />}
                                            component={Link}
                                            to={"/Settings/Account"}
                                            // label={"Follow"}
                                            label={"Edit Profile"}
                                          />
                                          {/* <Chip
                                          sx={{ mr: "13px", px: "15px" }}
                                          icon={<Edit />}
                                          onClick={() => {
                                            // EditProfileDialogBox
                                            let data = {
                                              dialogId: "EditProfileDialogBox",
                                              data: {
                                                // profile: currentProfile,
                                                // friendshipTab: "followers",
                                              },
                                            };
                                            updateCurrentUser("currentGlobalDialogBoxData", data);
                                          }}
                                          // label={"Follow"}
                                          label={"Creator Tools"}
                                        /> */}
                                        </>
                                      ) : (
                                        <>
                                          <Chip
                                            sx={{ mx: "13px", px: "15px" }}
                                            icon={<Send />}
                                            onClick={() => {
                                              // CommonApiServices.unfollow(JobSeeker.id)
                                              // friendshipType=!following;
                                              // console.log("mmmmmmmmmmm", following);
                                            }}
                                            // label={"Follow"}
                                            label={"Message"}
                                          />
                                          <FriendshipButton />
                                        </>
                                      )}{" "}
                                    </>
                                  )
                                }
                              />
                            </ListItem>
                          </Grid>
                        </Grid>
                        {(currentProfile.title || isThisMyProfile) && (
                          <>
                            <Divider sx={{ m:1, pb: 0 }} />
                            <ListItem>
                              <ListItemText
                                secondary={
                                  <MKTypography variant="body2">
                                    {currentProfile.title}
                                  </MKTypography>
                                }
                                // primary={
                                //   <MKTypography sx={{ mt: 0 }} variant="caption">
                                //     {"Title"}
                                //   </MKTypography>
                                // }
                              />
                            </ListItem>
                          </>
                        )}

                   
                      </>
                    )}
                  </List> 
              </Grid>
            </Grid>
          </Card>
        )}

        {/* </Grid>
          </Grid> */}
      </MKBox>
      {forceRerender}
      <Dialog
        open={openedDialog == "addWorkExperiance"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        <DialogTitle>Add Profession and Work Experiance</DialogTitle>
        <DialogContent style={{ paddingTop: "15px" }}>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}

          {jobs && (
            <Autocomplete
              fullWidth
              // sx={{ width: 400 }}
              onChange={(e, val) => {
                if (val) {
                  // console.log("val",val);
                  //  value=val;
                  handleNewWorkExperianceData("jobId", val.id);
                  // console.log("value",value);
                }
              }}
              options={jobs}
              // defaultValue={options?(optionsSelectedField?value[optionsSelectedLable]:value):""}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <MKInput {...params} label="Profession or Job" variant="outlined" />
              )}
            />
          )}

          <TextField
            autoFocus
            margin="dense"
            id="expectingSalaryPerMonth"
            defaultValue={newWorkExperianceData.expectingSalaryPerMonth}
            label="Expecting Salary (Per Month)"
            type="number"
            onChange={(e) => {
              handleNewWorkExperianceData("expectingSalaryPerMonth", e.target.value);
            }}
            fullWidth
            variant="outlined"
          />
          <TextField
            autoFocus
            margin="dense"
            id="workExperianceInMonth"
            defaultValue={newWorkExperianceData.workExperianceInMonth}
            onChange={(e) => {
              handleNewWorkExperianceData("workExperianceInMonth", e.target.value);
            }}
            label="Work Experiance (In Month)"
            type="number"
            fullWidth
            variant="outlined"
          />

          <Autocomplete
            multiple
            id="tags-filled"
            options={[]}
            // defaultValue={[top100Films[13].title]}
            // defaultValue={newWorkExperianceData.skillsOrSubjects}
            onChange={(e, data) => {
              handleNewWorkExperianceData("skillsOrSubjects", data);
            }}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Skills Or Subjects"
                placeholder="Add Skill"
              />
            )}
          />

          <TextField
            autoFocus
            margin="dense"
            multiline
            rows={4}
            id="Describe"
            defaultValue={newWorkExperianceData.description}
            onChange={(e) => {
              handleNewWorkExperianceData("description", e.target.value);
            }}
            label="More Describtion (about your skills) "
            // type="number"
            fullWidth
            variant="outlined"
          />
          {/* newWorkExperianceData */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={()=>{setOpenedDialog("")}}>Cancel</Button> */}
          <MKButton
            color="success"
            // variant="text"
            // size="small"
            disabled={!newWorkExperianceData.jobId}
            onClick={() => {
              addNewWorkExperiance();
            }}
          >
            <strong> Save </strong>
          </MKButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{moreText.title}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{moreText.content}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
      {isMobile ? (
        <SwipeableDrawer
          // sx={{ zIndex: 1111111 }}
          // container={container}
          anchor="bottom"
          open={openedDialog == "switchAgency"}
          onClose={() => setOpenedDialog("")}
          // onOpen={()=>setDrawer2Opened("switchAgency")}
          swipeAreaWidth={150}
          disableSwipeToOpen={true}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <SwitchMyProfilesBox
            onClose={() => {
              setOpenedDialog("");
            }}
          />
        </SwipeableDrawer>
      ) : (
        <Dialog
          open={openedDialog == "switchAgency"}
          // TransitionComponent={Transition}
          scroll={"paper"}
          sx={{ "& .MuiDialog-paper": { width: "100%" } }}
          maxWidth="xs"
          onClose={() => setOpenedDialog("")}
          fullScreen={isMobile ? true : false}
        >
          <DialogTitle>Profiles </DialogTitle>
          <DialogContent
            dividers={true}
            sx={{ padding: "0rem", pb: "35px", minHeight: isMobile ? "300px" : "460px" }}
          >
            <SwitchMyProfilesBox
              onClose={() => {
                setOpenedDialog("");
              }}
            />
          </DialogContent>
          {/* </>
          )} */}
        </Dialog>
      )}
    </>
  );
}

export default ProfileHeader;
